import React, { Component } from 'react';
import ReactGA from 'react-ga';
import { CardHeader, CardContent, Card, FormControl, Button, InputLabel, Chip, Avatar, Paper, Breadcrumbs,
    Stepper, Step, Input, FormHelperText, StepLabel, Select, MenuItem, CircularProgress, Typography } from '@material-ui/core';
import { API, graphqlOperation  } from "aws-amplify";
import { Home, Accessibility, Edit } from '@material-ui/icons';
import GetProfiles from '../../graphql/queries/GetProfiles';
import GetTargets from '../../graphql/queries/GetTargets';
import GetUserProfiles from '../../graphql/queries/GetUserProfiles';
import GetGroups from '../../graphql/queries/GetGroups';
import style from './Style'
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import update from 'immutability-helper';
import ReviewProfile from '../../components/ReviewProducts/ReviewProfile';
import ReviewTarget from '../../components/ReviewProducts/ReviewTarget';
import CreateProduct from '../../graphql/mutations/CreateProduct';
import ReviewProduct from '../../components/ReviewProducts/ReviewProduct';
import Alert from '../../components/Alert';
import { Link } from 'react-router-dom';
import GetCustomAMIs from '../../graphql/queries/GetCustomAMIs';
import base_amis from '../../content/baseAMIs';
import CheckSovereign from '../../functions/CheckSovereign';
import SelectBlueprint from './SelectBlueprint'

function getSteps() {
    return ['Name Product', 'Select a Target', 'Profile Selection', 'User Access', 'Server Image', 'Blueprints', 'Cost Centre', 'Review'];
}

function getSubHeader(step) {
    switch (step) {
        case 0:
            return 'Give a name to your product';
        case 1:
            return 'Select a Profile from the dropdown box';
        case 2:
            return 'Select a Target from the dropdown box';
        case 3:
            return 'Select users who have access to the profile';
        case 4:
            return 'Select Server Image';
        case 5:
            return '(Optional) Add a Blueprint';
        case 6:
            return '(Optional) Add Cost Centre Tag';
        case 7:
            return 'Review Product';
        default:
            return 'Unknown step';
    }
  }

class ProductForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            props: props,
            showAlert: false,
            targets: [{}],
            target: {},
            profile: '',
            blueprint: '',
            fileContent: '',
            controls: [{}],
            profiles: [{}],
            userProfiles: [],
            selectedUsers: [],
            selectedGroups: [],
            selectedBlueprints: [],
            showLoadingUserProfiles: true,
            showLoadingProfiles: true,
            showLoadingTargets: true,
            securityGroupsOpen: false,
            productName: '',
            activeStep: 0, //remove OS set to 0
            baseAMIs: base_amis,
            amis: [],
        };
    }

    //Stepper Code
    handleNext = () => {
        const { activeStep } = this.state;
        if( activeStep === 0 ) {
            if(this.state.productName === '') {
                this.setState({
                    showAlert: true,
                    alertError: true,
                    alertMessage: "Error - Please fill in the Product Name text field"
                });
            }
            else {
                this.setState({activeStep: activeStep + 1});
            }
        }
        else if( activeStep === 1 ) {
            if(!this.state.selectedProfile) {
                this.setState({
                    showAlert: true,
                    alertError: true,
                    alertMessage: "Error - Please select a Profile from the drop down box "
                });
            }
            else {
                this.setState({activeStep: activeStep + 1});
            }
        }
        else if( activeStep === 2 ) {
            if(!this.state.selectedTarget) {
                this.setState({
                    showAlert: true,
                    alertError: true,
                    alertMessage: "Error - Please select a Target from the drop down box "
                });
            }
            else {
                this.setState({activeStep: activeStep + 1});
            }
        }
        else if( activeStep === 4 ) {
            if(!this.state.selectedImage) {
                this.setState({
                    showAlert: true,
                    alertError: true,
                    alertMessage: "Error - Please select a Server Image "
                });
            }
            else {
                this.setState({activeStep: activeStep + 1});
            }
        }
        else if( activeStep === 5 ) {
            this.setState({activeStep: activeStep + 1});
        }
        else if( activeStep === 6 ) {
            if(!this.state.costName) {
                this.setState({
                    showAlert: true,
                    alertError: true,
                    alertMessage: "Error - Please fill in the Cost Centre Name text field"
                });
            }
            else {
                this.setState({activeStep: activeStep + 1, activeCost: true});
            }
        }
        else {
            this.setState({activeStep: activeStep + 1});
        }
    };

    handleBack = () => {
        this.setState(state => ({
            activeStep: state.activeStep - 1,
        }));
    };

    handleReset = () => {
        this.setState({
            activeStep: 0,
            productName: '',
            selectedTarget: null,
            selectedProfile: null,
            target: null,
            profile: null,
            selectedUsers: [],
            selectedGroups: [],
            costName: null,
            activeCost: null
        });
    };
    //End Stepper code

    async componentDidMount() {
        //Add Google tracking
        ReactGA.initialize('UA-141370158-1');
        ReactGA.pageview('/Products');
        this.refresh();
    }

    async refresh() {
        // Get existing Profiles, Targets, Users, Groups
        await API.graphql(graphqlOperation(GetProfiles.query))
        .then(result => {
            if (result && result.data.GetProfiles) {
                this.setState({
                    profiles: result.data.GetProfiles,
                    profile: result.data.GetProfiles[0],
                    showLoadingProfiles: false
                })
            } else {
                this.setState({
                    showLoadingProfiles: false,
                    showAlert: true,
                    alertError: true,
                    alertMessage: "No Profiles Found - if you think this is an error please check your internet connection and refresh the page"
                });
            }
        }).catch(err => {
            this.setState({
                showLoadingProfiles: false,
                showAlert: true,
                alertError: true,
                alertMessage: "There was an error fetching your profiles - please check your internet connection and refresh the page "
            });
        });

        await API.graphql(graphqlOperation(GetTargets.query))
        .then(result => {
            if (result && result.data.GetTargets) {
                this.setState({
                    targets: result.data.GetTargets,
                    target: result.data.GetTargets[0],
                    showLoadingTargets: false
                })
            } else {
                this.setState({
                    showLoadingTargets: false,
                    showAlert: true,
                    alertError: true,
                    alertMessage: "No Targets Found - if you think this is an error please check your internet connection and refresh the page"
                });
            }
        })
        .catch(err => {
            this.setState({
                showLoadingTargets: false,
                showAlert: true, 
                alertError: true,
                alertMessage: 'Oh No! something went wrong pulling your targets - Please try refreshing the page'
            })
        });

        await API.graphql(graphqlOperation(GetUserProfiles.query))
        .then(result => {
            if (result && result.data.GetUserProfiles) {
                this.setState({
                    userProfiles: result.data.GetUserProfiles,
                    showLoadingUserProfiles: false
                }); 
            } else {
                this.setState({
                    showLoadingUserProfiles: false,
                    showAlert: true,
                    alertError: true,
                    alertMessage: "No Users Found - if you think this is an error please check your internet connection and refresh the page"
                });
            }
        })
        .catch(err => {
            this.setState({
                showLoadingUserProfiles: false,
                showAlert: true, 
                alertError: true,
                alertMessage: 'Oh No! something went wrong pulling the user profiles - Please try refreshing the page'
            })
        });

        await API.graphql(graphqlOperation(GetGroups.query))
        .then(result => {
            if (result && result.data.GetGroups) {
                this.setState({
                    groups: result.data.GetGroups,
                    showLoadingGroups: false
                }); 
            } else {
                this.setState({
                    showLoadingGroups: false,
                    showAlert: true,
                    alertError: true,
                    alertMessage: "No Groups Found - if you think this is an error please check your internet connection and refresh the page"
                });
            }
        })
        .catch(err => {
            this.setState({
                showLoadingGroups: false,
                showAlert: true, 
                alertError: true,
                alertMessage: 'Oh No! something went wrong pulling the groups - Please try refreshing the page'
            })
        });
    }

    handleProductName = (e) => { this.setState({productName: e.target.value}) }

    handleCostName = (e) => { this.setState({costName: e.target.value}) }

    handleTarget = async (e) => {
        var target = this.state.targets[e.target.value]
        //Check Sovereign
        const isSovereign = await CheckSovereign(target.region, this.state.selectedProfile.sovereign)
        if(isSovereign) {
            this.setState({target: e.target.value, selectedTarget: target})
            this.getCustomAMIs(target)
        }
        else {
            this.setState({ showAlert: true, alertMessage: 'Unable to use Target due to Profile Sovereignity', alertSuccess: false})
        }
    }

    getCustomAMIs = (target) => {
        this.setState({loadingAMIs: true})
        let params = {
            accountName: target.credential,
            region: target.region
        }
        API.graphql(graphqlOperation(GetCustomAMIs.query, params))
        .then(result => {
            this.setState({amis: result.data.GetCustomAMIs, loadingAMIs: false})
        })
        .catch(err => {
            this.setState({ showAlert: true, alertMessage: err.errors[0].message, alertSuccess: false, loadingAMIs: false})
        })
    }

    handleImage = (e) => { 
        var os = this.state.baseAMIs.find(i => i.imageAMI === e.target.value) ? this.state.baseAMIs.find(i => i.imageAMI === e.target.value).OS : this.state.amis.find(i => i.imageAMI === e.target.value).os
        this.setState({selectedImage: e.target.value, os: os }) }

    handleProfile = (e) => {
        var profile = this.state.profiles[e.target.value]
        this.setState({profile: e.target.value, selectedProfile: profile})
    }

    handleAlertClose = () => {
        this.setState({showAlert: false, alertMessage: ''})
    }

    handleUsers = (event, info, value) => {
        var usr = info.props.value
        var index = this.state.selectedUsers.findIndex((emp) => emp.username === usr.username);
        if(index.toString() === '-1') {
            let newState = update(this.state, {
                selectedUsers: {  $push: [ usr ]}  
            })
            this.setState(newState);            
        }
        else {
            let newState = update(this.state, {
                selectedUsers: arr => arr.filter(item => item.username !== usr.username)
            });
            this.setState(newState);
        }
    }

    handleGroups = (event, info, value) => {
        var group = info.props.value
        var index = this.state.selectedGroups.findIndex((emp) => emp.key === group.key);
        if(index.toString() === '-1') {
            let newState = update(this.state, {
                selectedGroups: {  $push: [ group ]}  
            })
            this.setState(newState);            
        }
        else {
            let newState = update(this.state, {
                selectedGroups: arr => arr.filter(item => item.key !== group.key)
            });
            this.setState(newState);
        }
    }

    setBlueprints = (blueprints) => { this.setState({selectedBlueprints: blueprints }) }

    showAlert = (alert) => {
        this.setState({
            showAlert: alert.showAlert, 
            alertError: alert.alertError,
            alertMessage: alert.alertMessage
        })
    }

    submitForm = async () => {
        this.setState({showLoading: true});
        var product = {
            productName: this.state.productName,
            blueprints: this.state.selectedBlueprints.map(b => { return b.key } ),
            target: this.state.selectedTarget.key,
            image: this.state.selectedImage,
            profile: this.state.selectedProfile.key,
            costCentre: this.state.activeCost ? this.state.costName : null,
            users : JSON.stringify(this.state.selectedUsers.reduce((res, obj) => { 
                var key = obj.username; 
                res[key] = obj.key;
                return res;
            }, {})),
            groups: this.state.selectedGroups.map( (item) => {return item.key} )
        };
        await API.graphql(graphqlOperation(CreateProduct.mutation, product))
        .then(result => {
            if(result) {
                this.setState({
                    showLoading: false,
                    showAlert: true,
                    alertError: false,
                    alertMessage: `New Product Created: ${this.state.productName}`
                },
                    this.handleReset()
                );
            }
            else {
                this.setState({
                    showLoading: false,
                    showAlert: true,
                    alertError: true,
                    alertMessage: "Error creating Product please check the parameters and try again "
                });
            } 
        })
        .catch(error => {
            console.log(error)
            this.setState({
                showLoading: false,
                showAlert: true,
                alertError: true,
                alertMessage: "Error creating Product please check the parameters and try again "
            });
        });
    }

    render() {
        const { classes } = this.props;
        var product = {
            productName: this.state.productName,
            target: this.state.selectedTarget,
            profile: this.state.selectedProfile,
            users: this.state.selectedUsers,
            groups: this.state.selectedGroups,
            image: this.state.selectedImage,
            blueprints: this.state.selectedBlueprints,
            costCentre: this.state.activeCost ? this.state.costName : ''
        }
        const {showAlert, alertMessage, alertError, targets, showLoadingTargets, target, profile, profiles, showLoadingProfiles, productName, showLoadingGroups, groups, selectedGroups,
            selectedUsers, userProfiles, showLoading, showLoadingUserProfiles, selectedTarget, selectedProfile, activeStep, productNameErrMsg, productNameError, loadingAMIs, amis, baseAMIs,
            selectedImage, costName, costNameErrMsg, costNameError, selectedBlueprints } = this.state

        const steps = getSteps();

        return (
                <div>
                    <Alert showAlert={showAlert} alertSuccess={!alertError} alertMessage={alertMessage} handleAlertClose={this.handleAlertClose}/>
                    <Paper className={classes.bread}>
                        <Breadcrumbs aria-label="Breadcrumb" >
                            <Link color='primary' to='/'>
                                <Typography color='primary' className={classes.link} >
                                    <Home className={classes.icon}/>
                                    Home
                                </Typography>
                            </Link>
                            <Link color='primary' to={{
                                pathname: "/Products",
                                state: {
                                    users: [],
                                    tab: 0
                                }
                            }}>  
                                <Typography color='primary' className={classes.link} >
                                    <Accessibility className={classes.icon} />
                                    Products
                                </Typography>
                            </Link>
                            <Typography color='primary' className={classes.link}>
                                <Edit className={classes.icon} />
                                Product Form
                            </Typography>
                        </Breadcrumbs>
                    </Paper>
                    <Card className={classes.test}>
                        <Stepper activeStep={activeStep}>
                            {steps.map((label, index) => {
                                const props = {};
                                const labelProps = {};
                                return (
                                <Step key={label} {...props}>
                                    <StepLabel {...labelProps}>{label}</StepLabel>
                                </Step>
                                );
                            })}
                        </Stepper>
                    </Card>
                    <br/>
                <div>
                <Card>
                    <CardHeader
                        title={'Create Product'}
                        subheader={getSubHeader(activeStep)} />
                    <CardContent>
                    {activeStep === steps.length ? (
                        <div>
                           {/* Completed Form */}
                        <Typography className={classes.instructions}>
                            All steps completed - you&apos;re finished
                        </Typography>
                        <Button onClick={this.handleReset} className={classes.button}>
                            Reset
                        </Button>
                        </div>
                    ) : activeStep === 0 ? (
                        <div>
                            {/* Name Product */}
                            <FormControl className={classes.formControl} required error={productNameError}>
                                <Typography color="textSecondary" className={classes.typo}>Product Name</Typography>
                                <Input id="input-productName" value={productName} onChange={this.handleProductName} />
                                <FormHelperText error={productNameError}>{productNameErrMsg}</FormHelperText>
                            </FormControl> 
                        </div>
                    ) 
                    : activeStep === 1 ? (
                        <div>
                            {/* Select Profile */}
                            <FormControl className={classes.formControl}>
                                <InputLabel>Profile</InputLabel>
                                <Select
                                    value={profile}
                                    onChange={this.handleProfile}
                                >
                                    {
                                        profiles.map((item, index) =>{
                                            return <MenuItem key={index} name={item.profileName} value={index}>{item.profileName}</MenuItem>
                                        })
                                    }
                                </Select>
                                {showLoadingProfiles && <CircularProgress size={24} className={classes.buttonProgress} />}
                            </FormControl>  
                            { selectedProfile &&
                                <ReviewProfile selectedProfile={selectedProfile} classes={classes} dense={false}/>
                            }
                        </div>
                    ) 
                    : activeStep === 2 ? (
                        <div>
                            {/* Select Target */}
                            <FormControl className={classes.formControl}>
                                <InputLabel>Target</InputLabel>
                                <Select
                                    value={target}
                                    onChange={this.handleTarget}
                                >
                                    {
                                        targets.map((item, index) =>{
                                            return <MenuItem value={index}>{item.targetName}</MenuItem>
                                        })
                                    }
                                </Select>
                                {showLoadingTargets && <CircularProgress size={24} className={classes.buttonProgress} />}
                            </FormControl> 
                            { selectedTarget &&
                                <div className={classes.list}>
                                    <ReviewTarget selectedTarget={selectedTarget} dense={false}/>
                                </div>
                            }
                        </div> 
                    ) :  
                    activeStep === 3 ? (
                        <div> 
                            {/* Select User Group */}
                            <FormControl className={classes.formControl}>
                                <InputLabel>Groups</InputLabel>
                                <Select
                                    multiple
                                    disabled={showLoadingGroups}
                                    value={selectedGroups}
                                    onChange={this.handleGroups}
                                    input={<Input id="select-multiple-chip" />}
                                    renderValue= {
                                        selectedGroups => (
                                            <div className={classes.chips}>
                                            {  selectedGroups.map((value, index) => (
                                                <Chip key={index} value={value} label={value.groupName} className={classes.chip} />
                                            ))}
                                            </div>
                                    )}  
                                >
                                    {
                                        groups.map((item) =>{
                                            return <MenuItem value={item}>{item.groupName}</MenuItem>
                                        })
                                    }
                                </Select>
                                {showLoadingGroups && <CircularProgress size={24} className={classes.buttonProgress} />}
                            </FormControl>  
                            {/* Select Users */}     
                            <FormControl className={classes.formControl}>
                                <InputLabel>Individual Users</InputLabel>
                                <Select
                                    multiple
                                    disabled={showLoadingUserProfiles}
                                    value={selectedUsers}
                                    onChange={this.handleUsers}
                                    input={<Input id="select-multiple-chip" />}
                                    renderValue={selectedUsers => (
                                        <div className={classes.chips}>
                                        {selectedUsers.map( (value, index) => (
                                            <Chip key={index}  avatar={<Avatar alt={value.firstName} src={value.profilePic} />}
                                                value={value} label={value.firstName + ' ' + value.lastName} className={classes.chip} />
                                        ))}
                                        </div>
                                    )}  
                                >
                                    {
                                        userProfiles.map((item) =>{
                                            return <MenuItem value={item}>{item.firstName + ' ' + item.lastName}</MenuItem>
                                        })
                                    }
                                </Select>
                                {showLoadingUserProfiles && <CircularProgress size={24} className={classes.buttonProgress} />}
                            </FormControl>  
                        </div> 
                    ) : 
                    activeStep === 4 ? (
                        <div>      
                            {/* Operating Stysem */}  
                            <FormControl className={classes.formControl}>
                                <InputLabel>Select Server Image</InputLabel>
                                <Select
                                    disabled={loadingAMIs}
                                    value={selectedImage}
                                    onChange={this.handleImage}>
                                    {
                                        baseAMIs.map((item, index) =>{
                                            return <MenuItem key={item.imageAMI} value={item.imageAMI}>{item.imageName}</MenuItem>
                                        })
                                    }
                                    {
                                        amis.map((item, index) =>{
                                            return <MenuItem key={item.imageAMI} value={item.imageAMI}>{item.imageName}</MenuItem>
                                        })
                                    }
                                </Select>
                                {loadingAMIs && <CircularProgress size={24} className={classes.buttonProgress} />}
                            </FormControl> 
                        </div> 
                    ) :
                    activeStep === 5 ? (
                        <SelectBlueprint os={this.state.os} setBlueprints={this.setBlueprints} showAlert={showAlert} subheader={getSubHeader(activeStep)} selectedBlueprints={selectedBlueprints}/>
                    ) :
                    activeStep === 6 ? (
                        <div>      
                            {/* Cost Centre */}     
                            <FormControl className={classes.formControl} required error={costNameError}>
                                <Typography color="textSecondary" className={classes.typo}>Cost Centre</Typography>
                                <Input id="input-costName" value={costName} onChange={this.handleCostName} />
                                <FormHelperText error={costNameError}>{costNameErrMsg}</FormHelperText>
                            </FormControl> 
                        </div> 
                    ) :
                    activeStep === 7 ? (
                        <div>      
                            {/* Review */}     
                            <ReviewProduct 
                                product={product}
                                isEdit={false}
                                elevation={2} />
                        </div> 
                    ) : 
                    <div>
                        <div className={classes.wrapper}>
                            <Button
                                variant="outlined"
                                color="primary"
                                className={classes.button}
                                onClick={this.submitForm} >
                                Create Product
                            </Button>
                        </div>
                    </div>
                    }
                    </CardContent>
                </Card>
                </div>
                <br/>
                    <div>
                        <Button
                            disabled={activeStep === 0}
                            onClick={this.handleBack}
                            className={classes.stepperButton}>
                            Back
                        </Button>
                        {   activeStep === 5 || activeStep === 6 ? 
                            <Button
                                onClick={() => this.setState({activeStep: activeStep + 1}) }
                                className={classes.stepperButton}>
                                Skip
                            </Button>
                            : null
                        }
                        <Button
                            variant="contained"
                            color="primary"
                            disabled={showLoading}
                            onClick={activeStep === steps.length - 1 ? this.submitForm : this.handleNext }
                            className={classes.stepperButton} >
                            {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                        </Button>
                    </div>         
                </div>
        );
    }
}


ProductForm.propTypes = {
    classes: PropTypes.object.isRequired,
  };
  
  export default withStyles(style)(ProductForm);
