import React from 'react';
import { Button, DialogActions, Dialog, DialogTitle, DialogContent, DialogContentText, CircularProgress, 
List, ListItem, ListItemText, ListItemSecondaryAction, Checkbox } from '@material-ui/core/'

export default function DialogUpdateGroups(props) {
    const classes = props.classes
    return (
        <Dialog onClose={props.handleAddGroupsClose} aria-labelledby="form-dialog-title" open={props.addGroupsOpen}>
        <DialogTitle id="form-dialog-title">Add Groups</DialogTitle>
        <DialogContent>
        <DialogContentText>
            Select the groups you want to add to the product 
        </DialogContentText>
        <List>
            {
                props.selectedProduct ?
                props.groups.filter((o) => !props.selectedProduct.groups.includes(o.key)).map((group, index) => (
                    <ListItem button key={index}>
                        <ListItemText primary={group.groupName} />
                        <ListItemSecondaryAction>
                            <Checkbox
                                edge="end"
                                onChange={props.handleCheckGroup(group)}
                                checked={props.groupsChecked.indexOf(group) !== -1} />
                        </ListItemSecondaryAction>
                    </ListItem>
                ))
                :
                null
            }
        </List>
        </DialogContent>
        <DialogActions>
            <Button onClick={props.handleAddGroupsClose} disabled={props.updatingProducts} color="primary">
                Cancel
            </Button>
            <Button onClick={props.handleAddGroups} disabled={props.updatingProducts} color="primary">
                Add Groups
            </Button>
            {  props.updatingProducts && <CircularProgress size={24} className={classes.productsProgress} /> }
        </DialogActions>
    </Dialog>
    )
}