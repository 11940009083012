import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Table, TableBody, TableCell, TableFooter, TablePagination, TableRow, IconButton, Toolbar,
  Typography, InputBase, Tooltip, TableHead, TableSortLabel } from '@material-ui/core';
import {Add as AddIcon, Search as SearchIcon, Delete, Edit} from '@material-ui/icons';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  table: {
    minWidth: 500,
  },
  title: {
    flex: '0 0 auto',
  },  
  tableWrapper: {
    overflowX: 'auto',
  },
  headSearch: {
    width: 600,
    backgroundColor: fade('rgba(228, 233, 237, 50)', 0.15),
    '&:hover': {
        backgroundColor: fade('rgba(228, 233, 237, 1)', 0.25),
    },
    flexDirection: 'row'
  },
  spacer: {
      flex: '1 1 100%',
  },
  addButton: {
    float: 'right'
  }
}));

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

export default function ProvidersList(props) {
  const rows = props.rows
  const data = props.data
  const cloud = props.cloud
  //arrray of names used for provider form to stop duplicates
  const names = data.map(item => item.name)

  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [query, setQuery] = React.useState('');
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');

  const createSortHandler = property => event => {
    handleRequestSort(event, property);
  };

  const handleRequestSort = (event, property) => {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(property);
  };

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const search = (e) => { setQuery(e.target.value) }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  let filteredSearch;
    filteredSearch = data.filter(
      (item) => {return JSON.stringify(item).toLowerCase().indexOf(query.toLowerCase()) !== -1;}
    );

  return (
      <div className={classes.tableWrapper}>
        <Toolbar >
          <div className={classes.title}> 
            <Typography variant="h6" id="tableTitle">
              { cloud === 'aws' ? 'AWS Providers' : cloud === 'azure' ? 'Azure Providers' : 'AWS Providers' }
            </Typography>
          </div>
          <div className={classes.spacer} />              
          <div className={classes.headSearch}>
            <IconButton className={classes.iconButton} aria-label="Search">
                <SearchIcon />
            </IconButton>
            <InputBase 
                onChange={search}
                placeholder="Search..."
            />
            <Tooltip title={`Add New ${cloud.toUpperCase()} Account`}>
              <IconButton aria-label="Create" color='primary' className={classes.addButton}
                component={Link} to={{
                  pathname: "/ProvidersForm",
                  state: {
                    isEdit: false,
                    cloud: cloud,
                    names: names
                  }
                }} >
                <AddIcon />
              </IconButton>
            </Tooltip>
          </div>
        </Toolbar>
        <Table className={classes.table} aria-label="custom pagination table">
          <TableHead>
            <TableRow>
              { rows.map((row, index) => (
                  <TableCell
                    key={row.id}
                    align='left'
                    padding='default'
                    sortDirection={orderBy === row.id ? order : false} >
                    <Tooltip
                        title="Sort"
                        placement='bottom-start'
                        enterDelay={300} >
                    <TableSortLabel
                        active={orderBy === row.id}
                        direction={order}
                        onClick={createSortHandler(row.id)} >
                        {row.label}
                    </TableSortLabel>
                    </Tooltip>
                  </TableCell>
                )
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            { 
              stableSort(filteredSearch, getSorting(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(row => 
                cloud === 'aws' ?
                  <TableRow key={row.name}>
                    <TableCell component="th" scope="row"> {row.name} </TableCell>
                    <TableCell >{row.accountNumber}</TableCell>
                    <TableCell>{row.clientId}</TableCell>
                    <TableCell>{row.personal ? 'Personal' : 'Shared'}</TableCell>
                    <TableCell>
                    <IconButton aria-label="edit" color='primary' className={classes.iconButton}
                      component={Link} to={{
                        pathname: "/ProvidersForm",
                        state: {
                          isEdit: true,
                          provider: row,
                          cloud: cloud,
                          names: names
                        }
                      }} >
                        <Edit />
                      </IconButton>
                      <IconButton className={classes.iconButton} aria-label="delete" onClick={() => props.deleteAccount(row.name)}>
                          <Delete />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                :
                cloud === 'azure' ? 
                  <TableRow key={row.name}>
                    <TableCell component="th" scope="row"> {row.name} </TableCell>
                    <TableCell>{row.clientId}</TableCell>
                    <TableCell>{row.tenantId}</TableCell>
                    <TableCell>{row.subscriptionId}</TableCell>
                    <TableCell>{row.personal ? 'Personal' : 'Shared'}</TableCell>
                    <TableCell>
                    <IconButton aria-label="edit" color='primary' className={classes.iconButton}
                      component={Link} to={{
                        pathname: "/ProvidersForm",
                        state: {
                          isEdit: true,
                          provider: row,
                          cloud: cloud,
                          names: names
                        }
                      }} >
                        <Edit />
                      </IconButton>
                      <IconButton className={classes.iconButton} aria-label="delete" onClick={() => props.deleteAccount(row.name)}>
                          <Delete />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                :
                null
              )
            }
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={cloud === 'aws' ? 5 : cloud === 'azure' ? 7 : 0} />
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                colSpan={cloud === 'aws' ? 5 : cloud === 'azure' ? 7 : 0}
                count={data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: { 'aria-label': 'rows per page' },
                  native: true,
                }}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </div>
  );
}