import React, { Component } from 'react';
import ReactGA from 'react-ga';
import "tabler-react/dist/Tabler.css";
import { Page } from "tabler-react";
import UserProfileForm from '../components/UserProfileForm';
import { API, graphqlOperation  } from "aws-amplify";
import GetUserProfile from '../graphql/queries/GetUserProfile';

class UserProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  async componentDidMount() {

    //Add Google tracking
    ReactGA.initialize('UA-141370158-1');
    ReactGA.pageview('/UserProfile');

    //Get initial Data
    await API.graphql(graphqlOperation(GetUserProfile.query)).then(result => {
      this.setState({user: result.data.GetUserProfile})
    });
  }

  render() {
    return (
        <Page.Content>
          { this.state.user &&
            <UserProfileForm user={this.state.user}/>
          }
        </Page.Content>
    );
  }
}
export default UserProfile;
