const stripe_config = {
    apiKey: 'pk_live_92jM3T6uFjhWOUZmAdWZbrcb',
    earlyAccess: {
        name: 'cloudbuilder_early', 
        planId: 'plan_F1ilbIrYH37AIU'
    },
    startUp: {
      name: 'securecloud_startUp', 
      planId: 'plan_FBz9GVK9C1icBp'
  },

};

export default {
  ...stripe_config
};
