export default { mutation: `
mutation EditInventory(
    $accountName: String,
    $function: String, 
    $instanceId: String, 
    $location: String
    ) {
        EditInventory(
        accountName: $accountName,
        data: {
            function: $function
            instanceId: $instanceId
            location: $location
    }) {
    result
    payload
  }
}`
};
