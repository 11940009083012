import React from 'react';
import "tabler-react/dist/Tabler.css";
import { Button, Form, Icon, Grid, Card, Text } from "tabler-react";

export default class AdminAccountInput extends React.Component {
    constructor(props) {
        super(props);
        this.handleConfirmation = this.handleConfirmation.bind(this);
        this.state = {
            
        };
    }

    componentDidMount() {
        var codes = this.props.phoneCode;
        this.setState({
            countryCode: codes.substring( 0, codes.indexOf(",")),
            phoneCode: codes.substring(codes.indexOf(",")+1, codes.length)
        })
    }

    handleConfirmation(event) {
        switch (event.target.name) {
            case 'firstName-text-input':
                this.setState({firstName: event.target.value})
                if (event.target.value.match(/^(?=.{1,30}$)[a-zA-Z]+(?:['_.\s][a-zA-Z]+)*$/)) {
                    this.setState({firstNameComplete: true, firstNameError: false});
                } 
                else {
                    this.setState({firstNameComplete: false, firstNameError: true});
                }
                break;
            case 'lastName-text-input':
                this.setState({lastName: event.target.value})
                if (event.target.value.match(/^(?=.{1,30}$)[a-zA-Z]+(?:['_.\s][a-zA-Z]+)*$/)) {
                    this.setState({lastNameComplete: true, lastNameError: false});
                } 
                else {
                    this.setState({lastNameComplete: false, lastNameError: true});
                }
                break;
            case 'username-text-input':
                this.setState({username: event.target.value});
                if (event.target.value.match(/^[a-zA-Z0-9,.!?]*$/)) {
                    this.setState({usernameComplete: true, usernameError: false});
                } 
                else {
                    this.setState({usernameComplete: false, usernameError: true});
                }
                break;
            case 'companyId-text-input':
                this.setState({companyId: event.target.value});
                if (event.target.value.match(/^[a-zA-Z0-9,.!?]*$/)) {
                    this.setState({companyIdComplete: true, companyIdError: false});
                } 
                else {
                    this.setState({companyIdComplete: false, companyIdError: true});
                }
                break;
            case 'email-text-input':
                this.setState({email: event.target.value.toLowerCase()})
                if (event.target.value.match(/[a-z0-9._%+!$&*=^|~#%'`?{}/-]+@([a-z0-9-]+.){1,}([a-z]{2,16})/)) {
                    this.setState({emailComplete: true, emailError: false});
                } 
                else {
                    this.setState({emailComplete: false, emailError: true});
                }
                break;
            case 'phone-text-input':
                this.setState({phone: event.target.value.toLowerCase()})
                if (event.target.value.match(/^((?!(0))[0-9]{5,15})$/)) {
                    this.setState({phoneComplete: true, phoneError: false});
                } 
                else {
                    this.setState({phoneComplete: false, phoneError: true});
                }
                break;
            default:
                break;
        }
    }

    checkInputs() {
        var {firstNameComplete, lastNameComplete, phoneComplete, emailComplete, usernameComplete, companyIdComplete} = this.state
        if(firstNameComplete && lastNameComplete && phoneComplete && emailComplete && usernameComplete && companyIdComplete) {
            return true;
        }
        else {
            return false;
        }
    }

    nextStep() {
        var inputsComplete = this.checkInputs()
        this.setState({loading:true}) 
        if (inputsComplete) {
            var accountDetails = {
                firstName: this.state.firstName,
                lastName: this.state.lastName,
                email: this.state.email,
                username: this.state.companyId+'.'+this.state.username,
                phone: "+"+this.state.phoneCode+this.state.phone,
                companyId: this.state.companyId,
                user: this.state.username
            }
            this.setState({loading:false})
            this.props.nextStep(accountDetails)
        }
        else {
            this.props.showAlert('Input Error', 'one or more inputs are invalid please update and try again', 'danger')
        }
        
    }


    render() {
        const { countryCode, phoneCode } = this.state;
        const {firstNameComplete, firstName, firstNameError} = this.state;
        const {lastName, lastNameComplete, lastNameError} = this.state;
        const {username, usernameComplete, usernameError} = this.state;
        const {companyId, companyIdComplete, companyIdError} = this.state;
        const {phone, phoneComplete, phoneError} = this.state;
        const {email, emailComplete, emailError} = this.state;
        const { loading } = this.state
        return (
            <div>
            <Card statusColor="blue">
                <Card.Header>
                    <Card.Title>Master Account Details</Card.Title>
                </Card.Header>
                <div style={{ margin: 20 }}>
                    <Form>
                        <Grid.Row>
                            <Grid.Col width={6}>
                                <Form.Group
                                    isRequired
                                    label="First Name">
                                    <Form.Input 
                                        name="firstName-text-input" 
                                        tick={firstNameComplete}
                                        cross={firstNameError}
                                        value={firstName || ''} 
                                        onChange={this.handleConfirmation} />
                                </Form.Group>
                            </Grid.Col>
                            <Grid.Col  width={6}>
                                <Form.Group isRequired
                                    className="mb-0"
                                    label="Last Name">
                                    <Form.Input 
                                        name="lastName-text-input"
                                        tick={lastNameComplete}
                                        cross={lastNameError}
                                        value={lastName || ''} 
                                        onChange={this.handleConfirmation} />
                                </Form.Group>
                            </Grid.Col>
                        </Grid.Row>
                        <Form.Group
                            isRequired
                            label="Username">
                            <Form.Input 
                                name="username-text-input"
                                tick={usernameComplete}
                                cross={usernameError}
                                value={username || ''} 
                                onChange={this.handleConfirmation} />
                        </Form.Group>
                        <Form.Group
                            isRequired
                            label="Company Id">
                            <Form.Input 
                                name="companyId-text-input" 
                                tick={companyIdComplete}
                                cross={companyIdError}
                                value={companyId || ''} 
                                onChange={this.handleConfirmation} />
                            <Text.Small color="primary">
                                *Company Id is used by all your users to log into SecureStack
                            </Text.Small>
                        </Form.Group>
                        <Grid.Row>
                            <Grid.Col width={6}>
                                <Form.Group
                                    isRequired
                                    label="Email address">
                                    <Form.Input 
                                        name="email-text-input"
                                        type="email"
                                        tick={emailComplete}
                                        cross={emailError}
                                        value={email || ''} 
                                        onChange={this.handleConfirmation} />
                                </Form.Group>
                            </Grid.Col>
                            <Grid.Col  width={6}>
                                <Form.Group isRequired
                                    className="mb-0"
                                    label="Phone Number">
                                    <Form.InputGroup>
                                        <Form.InputGroupPrepend>
                                            { countryCode &&
                                                <div style={{backgroundColor: '#FBFBFD', margin: 'auto'}}>
                                                    <span>
                                                        <Icon prefix="flag" name={countryCode} />
                                                        &nbsp;
                                                        + {phoneCode} 
                                                        &nbsp; 
                                                    </span>          
                                                </div>
                                            }
                                        </Form.InputGroupPrepend>
                                        <Form.Input 
                                            name="phone-text-input"
                                            tick={phoneComplete}
                                            cross={phoneError}
                                            value={phone || ''} 
                                            onChange={this.handleConfirmation} />
                                    </Form.InputGroup>     
                                </Form.Group>
                            </Grid.Col>
                        </Grid.Row>
                    </Form>
                </div>
                <Button loading={loading} color="primary" onClick={() => this.nextStep()} >Sign Up</Button>
            </Card>
            </div>
        );
    }
}

