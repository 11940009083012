import React, {Component} from 'react';
import {Dialog, DialogContent, DialogActions, DialogContentText, DialogTitle, Button, CircularProgress, Input,
    FormControl, Typography, Chip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import {Add} from '@material-ui/icons/';
import update from 'immutability-helper';

const style = theme => ({ 
    inventoryProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12
    },
    formControl: {
        width: '100%',
        marginTop: 20
    },
    dialogActions: {
        marginTop: 20
    },
    tagInput: {
        marginRight: 20,
        flex: 1
    },
    chip: {
        margin: 2,
    },
    taglist: {
        marginTop: 10,
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
    },
})

class AddTagsDialog extends Component {
    state = {
       tags: []
    };

    handleAddTagsDialogClose = () => {
        this.setState({tags: []})
        this.props.handleAddTagsDialogClose()
    }

    handleTagName = (event) => {
        this.setState({tagName: event.target.value})
    }

    handleTagValue = (event) => {
        this.setState({tagValue: event.target.value})
    }

    handleDeleteTag = (tag) => {
        let newState = update(this.state, {
            tags: arr => arr.filter(item => item.name !== tag.name)
        });
        this.setState(newState);    
    }

    handleTag = () => {
        var tagName = this.state.tagName
        var tagValue = this.state.tagValue
        if(tagName && tagValue) {
            var newTag = {name: tagName, value: tagValue}
            let newState = update(this.state, {
                tags: {  $push: [ newTag ]}  
            })
            this.setState(newState, () => {
                this.setState({tagName:'', tagValue: ''})
            }); 
        }
    }

    render() {
        const { classes, loadingFunction, addTagsDialogOpen, handleAddTags  } = this.props;
        const { tags, tagName, tagValue } = this.state
        return (
            <Dialog open={addTagsDialogOpen} onClose={this.handleAddTagsDialogClose} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">Add Tags to Image</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Fill in the details below
                        </DialogContentText>
                        <FormControl className={classes.formControl}>
                            <Typography color="textSecondary">Tags</Typography>
                            <div className={classes.container}>
                                <Input id="input-TagName"  placeholder="Tag Name" value={tagName} className={classes.tagInput} onChange={this.handleTagName} />
                                <Input id="input-TagValue" placeholder="Tag Value" value={tagValue} className={classes.tagInput} onChange={this.handleTagValue} />
                                <Button variant="outlined" color='primary' size="small" onClick={this.handleTag}>
                                <Add />
                                    Add Tag
                                </Button>
                            </div>
                            <div className={classes.taglist}>
                                {
                                    tags.map(((tag, index) => {
                                        return <Chip
                                            key={index}
                                            color="primary"
                                            label={tag.name+": " +tag.value}
                                            onDelete={() => this.handleDeleteTag(tag)}
                                            className={classes.chip}
                                            variant="outlined"
                                        />
                                    }))
                                }
                            </div>
                        </FormControl>
                    </DialogContent>
                    <DialogActions className={classes.dialogActions}>
                        <Button onClick={this.handleAddTagsDialogClose} color="primary" disabled={loadingFunction}>
                            Cancel
                        </Button>
                        <Button onClick={() => handleAddTags(tags)} color="primary" disabled={loadingFunction}>
                            Add Tags
                        </Button>
                        { loadingFunction &&
                            <CircularProgress size={24} className={classes.inventoryProgress} />
                        }
                    </DialogActions>
            </Dialog>
        )
    }
}

AddTagsDialog.propTypes = {
    classes: PropTypes.object.isRequired,
};
  
  export default withStyles(style)(AddTagsDialog);
