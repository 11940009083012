export default { mutation: `
    mutation UpdateUserSettings(
        $provider: String,
    ) {
        UpdateUserSettings(
            userSettings: {
                provider: $provider
            }
        ) {
            provider
    }
    }`
};