import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { IconButton, GridList, GridListTile, Card, CardHeader, CardContent, GridListTileBar, Tooltip, InputBase,
    CircularProgress } from '@material-ui/core/';
import { Info, Delete, Search, Add } from '@material-ui/icons/';
import style from './Style'
import Alert from '../../components/Alert';
import { API, graphqlOperation } from "aws-amplify";
import GetBlueprints from '../../graphql/queries/GetBlueprints';
import DeleteBlueprint from '../../graphql/mutations/DeleteBlueprint';
import update from 'immutability-helper';
import { Link } from 'react-router-dom';

class Blueprints extends React.Component {
    state = {
        blueprints: [],
        query: '',
        loadingBlueprints: true
    };

    componentDidMount = async () => {
        //Get Blueprints
        await API.graphql(graphqlOperation(GetBlueprints.query))
        .then(result => {
           if(result) {
               this.setState({blueprints: result.data.GetBlueprints, loadingBlueprints: false})
           }
        })
        .catch(err => {
            this.setState({showAlert: true, alertMessage: 'Error fetching Blueprints', alertSuccess: false})
        });
    }

    handleAlertClose = () => {
        this.setState({showAlert: false, alertMessage: ''})
    }

    handleDeleteFile = async (filename, key, index) => {
        this.setState({showLoading: true})
        await API.graphql(graphqlOperation(DeleteBlueprint.mutation, {key: key} ))
        .then(result => {
            if(result.data.DeleteBlueprint.result) {
                let newState = update(this.state, {
                    blueprints: { $splice: [[index, 1]] },
                    showLoading: {$set: false},
                    showAlert: {$set: true},
                    alertSuccess: {$set: true},
                    alertMessage: {$set: `The Blueprint ${filename} has been successfully removed `}
                })
                this.setState(newState);
            }
            else {
                this.setState({ showLoading: false, showAlert: true, alertSuccess: false, alertMessage: `Unable to delete the Blueprint ${filename}`});
            }
        })
        .catch(err => {
            console.log(err)
            this.setState({ showLoading: false, showAlert: true, alertSuccess: false, alertMessage: "Unable to delete Blueprint - "+err});
        });
    }

    query(e) {
        this.setState({
            query: e.target.value,
        });
    }

    render() {
        const { classes } = this.props;
        const { showAlert, alertMessage, alertSuccess, showLoading, loadingBlueprints } = this.state;

        let filteredSearch;
        filteredSearch = this.state.blueprints.filter(
          (item) => {return JSON.stringify(item).toLowerCase().indexOf(this.state.query.toLowerCase()) !== -1;}
        );
          
        return (
            <div>
                <Alert showAlert={showAlert} alertSuccess={alertSuccess} alertMessage={alertMessage} handleAlertClose={this.handleAlertClose}/>
                <Card>
                    <CardHeader
                        title={'Blueprints: '}
                        subheader={'Cloudbuilder Blueprints'} 
                        action= {
                            <div className={classes.headSearch}>
                                <IconButton className={classes.iconButton} aria-label="Search">
                                    <Search />
                                </IconButton>
                                <InputBase 
                                    className={classes.input} 
                                    onChange={this.query.bind(this)}
                                    placeholder="Search..."
                                />
                                <Tooltip title="Create New Blueprint">
                                        <IconButton aria-label="Create" color='primary'
                                            component={Link} to={{
                                                pathname: "/BlueprintsForm",
                                                state: {
                                                    isEdit: false
                                                }
                                            }}>
                                            <Add />
                                        </IconButton>
                                </Tooltip>
                            </div>
                        }
                    />
                    <CardContent>
                        {loadingBlueprints && <CircularProgress size={24} className={classes.buttonProgress} />}
                        <GridList cols={4} cellHeight={180} className={classes.gridList}>
                            {filteredSearch.map((blueprint, index)=> (
                            <GridListTile key={blueprint.filename}>
                                <div style={{width:'100%'}}>
                                    <svg className={classes.fileImage} viewBox="0 0 24 24">
                                        <path fill="#0B96D8" d="M13,9V3.5L18.5,9M6,2C4.89,2 4,2.89 4,4V20A2,2 0 0,0 6,22H18A2,2 0 0,0 20,20V8L14,2H6Z" />
                                    </svg>
                                </div>
                                <GridListTileBar
                                title={blueprint.filename}
                                subtitle={<span>Last Modified: {blueprint.modified}</span>}
                                actionIcon={
                                    <div className={classes.tileActions}>
                                    {showLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
                                    <Tooltip title="Delete Blueprint">
                                        <IconButton aria-label={`delete ${blueprint.filename}`} disabled={showLoading || blueprint.active} className={classes.icon} onClick={() => this.handleDeleteFile(blueprint.filename, blueprint.key, index)}>
                                            <Delete/>
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Edit Blueprint">
                                        <IconButton  aria-label={`view ${blueprint.filename}`} className={classes.icon} disabled={showLoading}
                                            component={Link} to={{
                                                pathname: "/BlueprintsForm",
                                                state: {
                                                    isEdit: true,
                                                    blueprint: blueprint
                                                }
                                            }}>
                                            <Info />
                                        </IconButton>                                      
                                    </Tooltip>
                                    </div>
                                }
                                />
                            </GridListTile>
                            ))}
                        </GridList>
                    </CardContent>
                </Card>
            </div>
        );
    }
}

Blueprints.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(style)(Blueprints);