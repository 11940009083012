import React, { Component } from "react";
import { Page, Header, Container, Icon, Avatar }from "tabler-react";
import Template from '../components/Template';

export default class Support extends Component {

  render() {
    return (
      <Template hideLogin>
        <Page className="text-center">
            <Container>
                <Header.H1 className="display-1 text-muted mb-5">Help and Support</Header.H1>
                <Header.H2>Need Assistance?</Header.H2>
                <Header.H4 className="text-muted font-weight-normal">
                    No Worries! Please contact us via the options below and one of our friendly staff will be happy to assist:
                </Header.H4>
                <div>
                    <Icon name="mail" />
                    <Header.H4 className="text-muted font-weight-normal">
                        email: <a href = "mailto:support@securestack.com">support@securestack.com </a>
                    </Header.H4>
                </div>
                <div className="mb-7">
                    <Icon name="phone" />
                    <Header.H4 className="text-muted font-weight-normal">
                        USA phone: <a href = "tel:+1 (435) 565-1740">+1 (435) 565-1740</a>
                    </Header.H4>
                    <Header.H4 className="text-muted font-weight-normal">
                        Australia phone: <a href = "tel:+61474696407">+61 474 696 407</a>
                    </Header.H4>
                </div>
                <div className="mb-7">
                    <Avatar.List>
                        <Avatar size="xxl" imageURL="/images/paul.png" />
                        <Avatar size="xxl" imageURL="/images/adam.png" />
                        <Avatar size="xxl" imageURL="/images/abu.png" />
                    </Avatar.List>                    
                </div>
            </Container>
        </Page>
      </Template>
    );
  }
}
