import React from 'react';
import { Page, StatsCard, Grid, Alert, Header, Button, Tab, TabbedCard } from "tabler-react";

export default function Security(props) {
    return (
            <Page.Content title="SecureCloud Security">
		 <TabbedCard initialTab="Cloud Security">
                        <Tab title="Cloud Security">

        <Grid.Row cards deck>
          <Grid.Col md={2}>
            <StatsCard layout={1} movement={+5} total="82" label="Total Avg Security Score" />
          </Grid.Col>
          <Grid.Col md={2}>
            <StatsCard layout={1} movement={+10} total="85" label="AWS Avg Security Score" />
         </Grid.Col>
          <Grid.Col md={2}>
            <StatsCard layout={1} movement={+3} total="86" label="GCP Avg Security Score" />
         </Grid.Col>
          <Grid.Col md={2}>
            <StatsCard layout={1} movement={-5} total="80" label="Azure Avg Security Score" />
         </Grid.Col>
        </Grid.Row>

<Alert type="primary" icon="bell" isDismissible>
  <Header.H4>Security controls not applied to Azure instance</Header.H4>
  <p>
    SecureCloud has found that 1 required security control has not been applied to Azure instances
    Do you want to address it now?
  </p>
  <Button.List>
    <Button color="success" RootComponent="button">
      Okay 
    </Button>
    <Button color="secondary" RootComponent="button">
      Remind me later
    </Button>
  </Button.List>
</Alert>


		</Tab>
                <Tab title="Security Controls">
        <Grid.Row cards deck>
          <Grid.Col md={2}>
            <StatsCard layout={1} movement={-10} total="3" label="GuardDuty Events" />
          </Grid.Col>
          <Grid.Col md={2}>
            <StatsCard layout={1} movement={+15} total="28" label="Host IDS Events" />
         </Grid.Col>
          <Grid.Col md={2}>
            <StatsCard layout={1} movement={+12} total="77" label="Network IDS Events" />
         </Grid.Col>
        </Grid.Row>

<Alert type="primary" icon="bell" isDismissible>
  <Header.H4>Security Events</Header.H4>
  <p>
    You have security alerts that require your attention.
    Do you want to address it now?
  </p>
  <Button.List>
    <Button color="success" RootComponent="button">
      Okay
    </Button>
    <Button color="secondary" RootComponent="button">
      Remind me later
    </Button>
  </Button.List>
</Alert>
                </Tab>


                <Tab title="GuardDuty">
<Alert type="primary" icon="bell" isDismissible>
  <Header.H4>GuardDuty notifications</Header.H4>
  <p>
    GuardDuty has 4 notifications requiring your attention.
    Do you want to address it now?
  </p>
  <Button.List>
    <Button color="success" RootComponent="button">
      Okay
    </Button>
    <Button color="secondary" RootComponent="button">
      Remind me later
    </Button>
  </Button.List>
</Alert>
		</Tab>
		
               </TabbedCard>
            </Page.Content>
    );
}


