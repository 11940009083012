import React from 'react';
import { Button, DialogActions, Dialog, DialogTitle, DialogContent, DialogContentText, CircularProgress, 
List, ListItem, ListItemText, ListItemSecondaryAction, Checkbox, Avatar, ListItemAvatar } from '@material-ui/core/'

export default function DialogUpdateUser(props) {
    const classes = props.classes
    return (
        <Dialog onClose={props.handleAddUsersClose} aria-labelledby="form-dialog-title" open={props.addUsersOpen}>
            <DialogTitle id="form-dialog-title">Add Users</DialogTitle>
            <DialogContent>
            <DialogContentText>
                Select the users you want to add to the product 
            </DialogContentText>
            <List>
                {
                    props.selectedProduct ?
                    props.users.filter((o) => !props.selectedProduct.users.includes(o.key)).map((user , key) => (
                        <ListItem button key={key}>
                            <ListItemAvatar>
                            <Avatar  alt={user.firstName} src={user.profilePic} />
                            </ListItemAvatar>
                            <ListItemText primary={`${user.firstName} ${user.lastName}`} secondary={user.username.substring(user.username.indexOf('.') + 1)} />
                            <ListItemSecondaryAction>
                                <Checkbox
                                    edge="end"
                                    onChange={props.handleCheckUser(user)}
                                    checked={props.usersChecked.indexOf(user) !== -1} />
                            </ListItemSecondaryAction>
                        </ListItem>
                    ))
                    :
                    null
                }
            </List>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.handleAddUsersClose} disabled={props.updatingProducts} color="primary">
                    Cancel
                </Button>
                <Button onClick={props.handleAddUser} disabled={props.updatingProducts} color="primary">
                    Add Users
                </Button>
                {  props.updatingProducts && <CircularProgress size={24} className={classes.productsProgress} /> }
            </DialogActions>
        </Dialog>
    )
}