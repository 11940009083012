import React from "react";
import { Site, Grid, List } from "tabler-react";
import Copyright from './Copyright';

export default function Template (props) {
//Using div tags for footer to work around tabler bug
    return (
        <div>
        <Site>
            <Site.Header>
                <Site.Logo src="/images/logo.png"/>
            </Site.Header>
            {props.children}     
        </Site>
         <Site.Footer
         nav={
             <Grid.Col auto={true}>
                 <List className="list-inline list-inline-dots mb-0">
                     <List.Item className="list-inline-item">
                         <a href="https://securestack.com/cloudbuilder" target="_blank" rel="noopener noreferrer">About</a>
                     </List.Item>
                         <List.Item className="list-inline-item">
                             <a href="./Login" target="_blank" rel="noopener noreferrer">Login</a>
                         </List.Item>
                     { !props.hideResetPassword && (
                         <List.Item className="list-inline-item">
                             <a href="./Support" target="_blank" rel="noopener noreferrer">Support</a>
                         </List.Item>
                     )}
                     <List.Item className="list-inline-item">
                         <a href="./SignUp">Sign Up</a>
                     </List.Item>
                 </List>
             </Grid.Col>
         }
         copyright={<Copyright/>}
     />
     </div>
    );
};

