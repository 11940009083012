export default { mutation: `mutation DeleteFirewallRule($key: String) {
    DeleteFirewallRule (key: $key) 
    {
      firewallName
      sources
      destinations
      protocols {
        tcp
        udp
      }
      ports
      comment
      key
    }
  }`
};