export default { mutation: `
mutation CreateGroup(
  $groupName: String, 
  $users: AWSJSON
  ) {
    CreateGroup(group: {
    groupName: $groupName
    users: $users
  }) {
    groupName
    key
    users
    active
  }
}`
};
