export default { query: `
  query GetWebsites {
    GetWebsites {
      key
      name
      url
      vuln
    }
  }`
};
