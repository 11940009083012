import React from 'react';
import "tabler-react/dist/Tabler.css";
import { Button, Header, Card, PricingCard, Grid } from "tabler-react";

export default class EarlyAccess extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div>
            <Card statusColor="blue">
                <Card.Body>
                    <Header.H1 className="display-1 text-muted mb-5">CloudBuilder</Header.H1>
                    <Header.H4 className="text-muted font-weight-normal">
                        Build repeatable, standardized and secure environments in the cloud with our "build once, deploy anywhere" technology.  Centrally manage your teams use of access credentials, security groups, multi-factor authentication, ssh keys, user access and more. Gain visibility and meet compliance requirements effortlessly! 
                    </Header.H4>
                    <Header.H4 className="text-muted font-weight-normal">
                        Everything you create with CloudBuilder is hardened to CIS Level 1 specifications. Try it now and get the first 30 Days Free!
                    </Header.H4>
                    <div style={{display: 'flex',  justifyContent:'center', alignItems:'center'}}>  
                    <Grid>    
                        <Grid.Col sm={6} lg={3}>            
                        <PricingCard>
                            <PricingCard.Category>{"CloudBuilder Monthly"}</PricingCard.Category>
                            <PricingCard.Price>{"$200"} </PricingCard.Price>
                            <PricingCard.AttributeList>
                                <PricingCard.AttributeItem>
                                    <i>$100 per additional User</i>
                                </PricingCard.AttributeItem>
                                <PricingCard.AttributeItem>
                                    <strong>2 </strong>{"Users"}
                                </PricingCard.AttributeItem>
                                <PricingCard.AttributeItem hasIcon available>
                                    {"AWS Cloud Security"}
                                </PricingCard.AttributeItem>
                                <PricingCard.AttributeItem hasIcon available>
                                    {"Hardened Images"}
                                </PricingCard.AttributeItem>
                                <PricingCard.AttributeItem hasIcon available>
                                    {"Multi-User MFA"}
                                </PricingCard.AttributeItem>
                            </PricingCard.AttributeList>
                        </PricingCard>
                        </Grid.Col>
                    </Grid>
                    </div>
                </Card.Body>
                <Button color="primary" onClick={() => this.props.nextStep()} >Try Now</Button>
            </Card>
            </div>
        );
    }
}

