import React, { Component } from 'react';
import ReactGA from 'react-ga';
import { graphqlOperation, API } from "aws-amplify";
import GetBatchInventory from '../../graphql/queries/GetBatchInventory';
import GetAccounts from '../../graphql/queries/GetAccounts';
import {Card, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, SvgIcon, Collapse, CircularProgress, CardContent,Typography, Grid, CardHeader, CardMedia, CardActions, IconButton, Tooltip } from '@material-ui/core/';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {Email, ExpandMore} from '@material-ui/icons/';
import style from './Style'
import securestack_banner from '../../assets/images/securestack_banner.png';
import clsx from 'clsx';
import GetSIIPServer from '../../graphql/queries/GetSIIPServer';

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inventory: [],
      errors: [],
      loadingData: true,
      siip_ip: ''
    };
  }

  async componentDidMount() {
    ReactGA.initialize('UA-141370158-1');
    ReactGA.pageview('/home_authenticated');
    //fetch inventory data
    this.setState({loadingData: true})
    await API.graphql(graphqlOperation(GetAccounts.query))
    .then(async result => {
      var accounts = JSON.parse(result.data.GetAccounts.payload);
      const instances = accounts.map(async (item) => {
        let params = {
            accountName: item.name,
        }
        await API.graphql(graphqlOperation(GetBatchInventory.query, params)).then(result => {
            this.setState({loadingData: false});
            if (result.data.GetBatchInventory === null) {
                let tmp = this.state.errors;
                tmp.push("Issue with " + item + " account.");
                this.setState({
                    errors: tmp,
                });
            } else {
                result.data.GetBatchInventory.forEach((instance) => {
                  instance['cis'] = JSON.parse(instance.info).cis;
                  instance['vuln'] = JSON.parse(instance.info).vuln;
                  instance['securestack_managed'] = JSON.parse(instance.info).securestack_managed;
                  if (instance.serverName.split("@")[1] !== "undefined") {
                    let tmp = this.state.inventory;
                    let result = tmp.filter(item => item.resourceId === instance.resourceId);
                    if (result.length === 0) {
                        tmp.push(instance);
                    }
                    this.setState({
                        inventory: tmp,
                    });
                  }
                });
            }
        })
        .catch(err => {
            console.log(err);
        });

        API.graphql(graphqlOperation(GetSIIPServer.query))
        .then(result => { 
          this.setState({siip_ip: result.data.GetSIIPServer.ipAddress})
        })
        .catch(e=> console.log('no siip-server'))

      })
      //Set Headers
      await Promise.all(instances).then(() => {
        const inventory = this.state.inventory;

        //Get Instances
        const managedInstances = inventory.filter(x => {return (x.securestack_managed.toLowerCase() === 'true')});
        const unmanagedInstances = inventory.filter(x => {return (x.securestack_managed.toLowerCase() === 'false')});

        //get instances with score above 0
        const managedVulScore = managedInstances.filter(x => {return (x.vuln >= 1)});
        const unmanagedVulScore = unmanagedInstances.filter(x => {return (x.vuln >= 1)});
        
        //calculate average
        const managedSS = managedVulScore[0] ? managedVulScore.map(x => x.vuln).reduce((prev, next) => parseInt(prev) + parseInt(next)) / managedVulScore.length : 0;
        const unmanagedSS = unmanagedVulScore[0] ? unmanagedVulScore.map(x => x.vuln).reduce((prev, next) => parseInt(prev) + parseInt(next)) / unmanagedVulScore.length : 0;
        
        this.setState({
          loadingData: false,
          totalInstances: inventory.length,
          managedInstances: managedInstances.length, 
          unmanagedInstances: unmanagedInstances.length,
          unmanagedSS: Math.round(unmanagedSS * 100) / 100,
          managedSS: Math.round(managedSS * 100) / 100 
        })
      })
    }).catch(err => {
      console.log(err);
    })
  }

  handleExpandSupportClick = () => { this.setState({supportExpanded: !this.state.supportExpanded}) }

  render() {
    const { classes } = this.props
    const { unmanagedInstances, managedInstances, unmanagedSS, managedSS, totalInstances, loadingData, supportExpanded, siip_ip } = this.state;
    return (
        <div>
          <Grid container spacing={1}>
          <Grid item xs={2}>
            <Card className={classes.card}>
              <CardContent>
                <Typography className={classes.title} color="textSecondary" gutterBottom>
                  Unmanaged Instances
                </Typography>
                { loadingData &&
                  <CircularProgress className={classes.progress} />
                }
                <Typography variant="h5" component="h2">
                  {unmanagedInstances}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={2}>
            <Card className={classes.card}>
              <CardContent>
                <Typography className={classes.title} color="textSecondary" gutterBottom>
                  Managed Instances
                </Typography>
                { loadingData &&
                  <CircularProgress className={classes.progress} />
                }
                <Typography variant="h5" component="h2">
                  {managedInstances}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={2}>
            <Card className={classes.card}>
              <CardContent>
                <Typography className={classes.title} color="textSecondary" gutterBottom>
                  Total Instances
                </Typography>
                { loadingData &&
                  <CircularProgress className={classes.progress} />
                }
                <Typography variant="h5" component="h2">
                  {totalInstances}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={3}>
            <Card className={classes.card}>
              <CardContent>
                <Typography className={classes.title} color="textSecondary" gutterBottom>
                  Unmanaged Avg Security Score
                </Typography>
                { loadingData &&
                  <CircularProgress className={classes.progress} />
                }
                <Typography variant="h5" component="h2">
                  {unmanagedSS}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={3}>
            <Card className={classes.card}>
              <CardContent>
                <Typography className={classes.title} color="textSecondary" gutterBottom>
                  Managed Avg Security Score
                </Typography>
                { loadingData &&
                  <CircularProgress className={classes.progress} />
                }
                <Typography variant="h5" component="h2">
                  {managedSS}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          </Grid>
          <br/>
          <Grid container spacing={1}>
            <Grid item xs={5}>
              <Card className={classes.card}>
                <CardHeader
                  title="Security Infrastructure & Intelligence Platform"
                  subheader="Contact & Support"/>
		{
                /* <CardMedia
                  className={classes.media}
                  image={securestack_banner}
                  title="team" />
                  */
                }
<iframe width="100%" height="400" src="https://www.youtube.com/embed/Jg5BhAyIZ1Q" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                <CardContent>
                  <Typography variant="body2" color="textSecondary" component="p">
                    SecureStack CloudBuilder let's you build the same secure infrastructure in the multi-cloud & data centre. Manage your security controls and applications across all your environments from SecureCloud
                  </Typography>
                </CardContent>
                <CardActions disableSpacing>
                  <Tooltip title="Twitter"> 
                    <IconButton aria-label="twitter"  onClick={() => { window.open("https://twitter.com/securestackco", "_blank") }} >
                      <SvgIcon>
                        <path d="M5,3H19A2,2 0 0,1 21,5V19A2,2 0 0,1 19,21H5A2,2 0 0,1 3,19V5A2,2 0 0,1 5,3M17.71,9.33C18.19,8.93 18.75,8.45 19,7.92C18.59,8.13 18.1,8.26 17.56,8.33C18.06,7.97 18.47,7.5 18.68,6.86C18.16,7.14 17.63,7.38 16.97,7.5C15.42,5.63 11.71,7.15 12.37,9.95C9.76,9.79 8.17,8.61 6.85,7.16C6.1,8.38 6.75,10.23 7.64,10.74C7.18,10.71 6.83,10.57 6.5,10.41C6.54,11.95 7.39,12.69 8.58,13.09C8.22,13.16 7.82,13.18 7.44,13.12C7.81,14.19 8.58,14.86 9.9,15C9,15.76 7.34,16.29 6,16.08C7.15,16.81 8.46,17.39 10.28,17.31C14.69,17.11 17.64,13.95 17.71,9.33Z" />                   
                      </SvgIcon>
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Facebook"> 
                    <IconButton aria-label="facebook" onClick={() => { window.open("https://www.facebook.com/securestackco/", "_blank") }} >
                      <SvgIcon>
                        <path d="M5,3H19A2,2 0 0,1 21,5V19A2,2 0 0,1 19,21H5A2,2 0 0,1 3,19V5A2,2 0 0,1 5,3M18,5H15.5A3.5,3.5 0 0,0 12,8.5V11H10V14H12V21H15V14H18V11H15V9A1,1 0 0,1 16,8H18V5Z" />
                      </SvgIcon>
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="LinkedIn"> 
                    <IconButton aria-label="LinkedIn" onClick={() => { window.open("https://www.linkedin.com/company/securestack", "_blank") }} >
                      <SvgIcon>
                        <path d="M19,3A2,2 0 0,1 21,5V19A2,2 0 0,1 19,21H5A2,2 0 0,1 3,19V5A2,2 0 0,1 5,3H19M18.5,18.5V13.2A3.26,3.26 0 0,0 15.24,9.94C14.39,9.94 13.4,10.46 12.92,11.24V10.13H10.13V18.5H12.92V13.57C12.92,12.8 13.54,12.17 14.31,12.17A1.4,1.4 0 0,1 15.71,13.57V18.5H18.5M6.88,8.56A1.68,1.68 0 0,0 8.56,6.88C8.56,5.95 7.81,5.19 6.88,5.19A1.69,1.69 0 0,0 5.19,6.88C5.19,7.81 5.95,8.56 6.88,8.56M8.27,18.5V10.13H5.5V18.5H8.27Z" />               
                      </SvgIcon>
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Email"> 
                    <IconButton aria-label="Email" onClick={() => { window.open("mailto://support@securestack.com") }} >
                      <Email/>  
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Create Support Ticket"> 
                  <IconButton
                    className={clsx(classes.expand, {
                      [classes.expandOpen]: supportExpanded,
                    })}
                    onClick={this.handleExpandSupportClick}
                    aria-expanded={supportExpanded}
                    aria-label="Show more" >
                    <ExpandMore />
                  </IconButton>
                </Tooltip>
                </CardActions>
                <Collapse in={supportExpanded} timeout="auto" unmountOnExit>
                  <CardContent>
                    <Typography paragraph>Support Ticket</Typography>
                    <iframe title='Support Ticket' 
                    width="100%" height="580" src="https://share.hsforms.com/1Pqd7Wkx2RlGeAXx-O-AruQ2fc5k" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                  </CardContent>
                </Collapse>              
              </Card>
            </Grid>
            <Grid item xs={7}>
              <Card className={classes.card}>
                <CardHeader
                  title="Documentation - Learn about the SecureStack platform"
                />
                <ExpansionPanel>
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="panel1a-content"
                    id="panel1a-header">
                    <Typography className={classes.heading}>Security Infrastructure & Intelligence Platform</Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <a href="https://docs.google.com/presentation/d/1Wcwh6hodEFJzVhv6DZ1Z9yEbjZPQtyj_9uL0ATGc694/edit?usp=sharing" target="_blank" rel="noopener noreferrer">Introducing SecureStack Document</a>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel>
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="panel2a-content"
                    id="panel2a-header">
                    <Typography className={classes.heading}>CloudBuilder</Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <div style={{width: '100%'}}>
                      <a href="https://docs.google.com/document/d/1tPk1jKdjvTz97TmKXyeR2v1MP8oZLrIvvzuMCzFXOgc/edit?usp=sharing" target="_blank" rel="noopener noreferrer">CloudBuilder User Manual</a>
                      <br/>
                      <a href="https://drive.google.com/file/d/1HVmWqKm1lZzVMmZrHHQCynIgza71AA7a/view?usp=sharing" target="_blank" rel="noopener noreferrer">CloudBuilder Administrators Manual</a>
                      <br/>
                      <hr/>
                      <Typography>Videos</Typography>
                      <br/>
                      <Typography>Introduction to CloudBuilder</Typography>
                      <iframe title='Introduction' width="100%" height="285" src="https://www.youtube.com/embed/eUiCykewzvk" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen/>
                      <Typography>How to add a Provider</Typography>
                      <iframe title='Add a Provider' width="100%" height="285" src="https://www.youtube.com/embed/kMdqejbr0I8" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen/>
                      <Typography>Add Teams & Users</Typography>
                      <iframe title='Add Teams & Users' width="100%" height="285" src="https://www.youtube.com/embed/OewlSiOHeKI" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                      <br/>
                    </div>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel>
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="panel2a-content"
                    id="panel2a-header">
                    <Typography className={classes.heading}>SIIPServer SIEM</Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <div style={{width: '100%'}}>
                      <a href="https://docs.google.com/presentation/d/1-2_4bwPI9wO4JgIy6QFvHJZ4Lu5ZBVbeYTzpM_L6b9g/edit?usp=sharing" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen target="_blank" rel="noopener noreferrer">SIIPServer User Guide</a>
                      <br/>
                      <hr/>
                      <Typography>Videos</Typography>
                      <br/>
                      <Typography>Introduction to our SIEM</Typography>
                      <iframe title='Introduction to our SIEM' width="'100%'" height="285" src="https://www.youtube.com/embed/oaBxbiCv1UU" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen/>
                    </div>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel>
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="panel2a-content"
                    id="panel2a-header">
                    <Typography className={classes.heading}>SecureStack Enterprise</Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <div style={{width: '100%'}}>
                      <a href="https://docs.google.com/presentation/d/1H1Dkm8VIOWUzie6nEWRyi3RNUxWw7gvyW0vj01TuSfs/edit?usp=sharing" target="_blank" rel="noopener noreferrer">SecureStack Enterprise Installation and User Guide</a>                      <br/>
                      <br/>
                    </div>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              </Card>
              <Card className={classes.card}>
                <CardHeader
                  title="Training, Certification and Partners"
                />
                <ExpansionPanel>
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="panel2a-content"
                    id="panel2a-header">
                    <Typography className={classes.heading}>Training Courses</Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <div style={{width: '100%'}}>
                      <a href="https://forms.gle/zVfC5snPPTqBioB27" target="_blank" rel="noopener noreferrer">Introduction to the SecureStack Platform</a>
                      <br/>
                      <a href="https://forms.gle/qdviVuYZQmzDv7A36" target="_blank" rel="noopener noreferrer">CloudBuilder Training Course</a>
                      <br/>
                      <a href="https://forms.gle/ReSben6GARSiamE9A" target="_blank" rel="noopener noreferrer">SecureCloud Training Course</a>
                      <br/>
                      <a href="https://forms.gle/ReSben6GARSiamE9A" target="_blank" rel="noopener noreferrer">SecureStack Enterprise Training Course</a>
                      <br/>
                      <a href="https://forms.gle/ReSben6GARSiamE9A" target="_blank" rel="noopener noreferrer">SIIPServer SIEM Training Course</a>
                      <br/>
                    </div>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel>
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="panel2a-content"
                    id="panel2a-header">
                    <Typography className={classes.heading}>Certification Programs</Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <div style={{width: '100%'}}>
                      <a href="https://forms.gle/ReSben6GARSiamE9A" target="_blank" rel="noopener noreferrer">SecureStack Certification Course</a>
                      <br/>
                    </div>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel>
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="panel2a-content"
                    id="panel2a-header">
                    <Typography className={classes.heading}>SecureStack Partner Program</Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <div style={{width: '100%'}}>
                      <a href="https://forms.gle/Z2XyQb3ywyhKu5Qx6" target="_blank" rel="noopener noreferrer">Register a new lead</a>                      
                      <br/>
                      <br/>
                      <a href="https://forms.gle/mWyw3jJ7aSM5aj779" target="_blank" rel="noopener noreferrer">SecureStack Partner Application</a>                      
                      <br/>
                    </div>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              </Card>
            </Grid>
          </Grid>
        </div>
    );
  }
}

Home.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(style)(Home);

