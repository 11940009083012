import React from 'react';
import { Card, Tooltip, CardHeader, IconButton, CardContent, Toolbar, Chip } from '@material-ui/core';
import {Edit, Delete} from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import style from './Style';
import PropTypes from 'prop-types';

class TargetCard extends React.Component {
    render() {
        const {target, classes, cardLoading } = this.props;
        return ( 
            <div className={classes.card}>
                <Card key={target.key}>
                    <CardHeader
                        title={target.targetName}
                        subheader={target.provider + ' - ' + target.credential}
                        action= {
                            <Toolbar>
                            <Tooltip title="Edit Target" aria-label="Edit Target">     
                                <IconButton aria-label="arrow_back" disabled={cardLoading} onClick={()=>this.props.editTarget(target)}>
                                    <Edit />
                                </IconButton> 
                            </Tooltip>
                            <Tooltip title="Delete Target" aria-label="Delete Target">     
                                <IconButton disabled={cardLoading} aria-label="arrow_back" onClick={() => this.props.deleteTarget(target)}>
                                <Delete color="error"/>
                                </IconButton> 
                            </Tooltip>
                            </Toolbar>
                        }
                    />
                    <CardContent>
                    Instance Type: 
                        <Chip
                            label={target.instanceType}
                            className={classes.chip}
                            variant="outlined"
                        />
                    <br/>
                    Region:
                        <Chip
                            label={target.region}
                            className={classes.chip}
                            variant="outlined"
                        />
                    
                    </CardContent>
                </Card>
            </div>
        )
    }
}

TargetCard.propTypes = {
    classes: PropTypes.object.isRequired
};
  
export default withStyles(style)(TargetCard);