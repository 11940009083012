import { makeStyles } from '@material-ui/core/styles';

const style = makeStyles(theme => ({
    progressWrapper: {
        position: 'relative',
        width: '100%',
        height: 500
          
    },
    providersProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    }
}));

export default style;