import React from "react";
import { Route, Switch } from "react-router-dom";
import AuthenticatedRoute from "./components/AuthenticatedRoute";
import UnauthenticatedRoute from "./components/UnauthenticatedRoute";
import  Login from "./pages/Login/Login";
import  SignUp from "./pages/SignUp";
import  Early from "./pages/Early";
import  FreeTrial from "./pages/FreeTrial";
import  Home from "./pages/Home/Home";
import  UserProfile from "./pages/UserProfile";
import  Invoices from "./pages/Invoices";
import  Products from "./pages/Products/Products";
import  SignOut from "./pages/SignOut";
import  ErrorDefault from "./pages/ErrorDefault";
import  FirewallRules from "./pages/FirewallRules/FirewallRules";
import  FirewallForm from "./pages/FirewallForm/FirewallForm";
import  BillingInformation from "./pages/BillingInformation";
import  Profiles from "./pages/Profiles/Profiles";
import  Subscriptions from "./pages/Subscriptions";
import  Targets from "./pages/Targets/Targets";
import  Support from "./pages/Support";
import  Teams from "./pages/Teams/Teams";
import  UserForm from "./pages/UserForm/UserForm";
import  Inventory from "./pages/Inventory/Inventory";
import  Assets from "./pages/Assets/Assets";
import  ProductForm from "./pages/ProductForm/ProductForm";
import  Launch from "./pages/Launch/Launch";
import  Deploy from "./pages/Deploy/Deploy";
import  Components from "./pages/Components/Components";
import GroupForm from "./pages/GroupForm/GroupForm"
import Health from './pages/Health';
import Security from './pages/Security';
import Cost from './pages/Cost';
import Blueprints from './pages/Blueprints/Blueprints';
import BlueprintsForm from './pages/BlueprintsForm/BlueprintsForm';
import StartUpBundle from "./pages/StartUpBundle";
import Providers from "./pages/Providers/Providers";
import ProvidersForm from './pages/ProvidersForm/ProvidersForm';
import DomainForm from './pages/DomainForm/DomainForm';
import WebsiteForm from "./pages/WebsiteForm/WebsiteForm";

export default ({ childProps }) =>
<Switch>
  <UnauthenticatedRoute exact path="/Login" component={Login} props={childProps} />
  <UnauthenticatedRoute exact path="/SignUp" component={SignUp} props={childProps} />
  <UnauthenticatedRoute exact path="/EarlyAccess" component={Early} props={childProps} />
  <UnauthenticatedRoute exact path="/FreeTrial" component={FreeTrial} props={childProps} />
  <UnauthenticatedRoute exact path="/StartUp" component={StartUpBundle} props={childProps} />
  <AuthenticatedRoute exact path="/SignOut" component={SignOut} props={childProps}/>
  <AuthenticatedRoute path="/" exact component={Home} props={childProps} />
  <AuthenticatedRoute exact path="/Home"  component={Home} props={childProps} />
  <AuthenticatedRoute exact path="/SignOut" component={SignOut} props={childProps}/>
  <AuthenticatedRoute exact path="/ProvidersForm" component={ProvidersForm} props={childProps}/>
  <AuthenticatedRoute exact path="/Products"  component={Products} props={childProps} />
  <AuthenticatedRoute exact path="/Invoices" component={Invoices} props={childProps} />
  <AuthenticatedRoute exact path="/BillingInformation" component={BillingInformation} props={childProps} />
  <AuthenticatedRoute exact path="/Providers" component={Providers} props={childProps} />
  <AuthenticatedRoute exact path="/Profiles" component={Profiles} props={childProps} />
  <AuthenticatedRoute exact path="/FirewallRules" component={FirewallRules} props={childProps} />
  <AuthenticatedRoute exact path="/FirewallForm" component={FirewallForm} props={childProps} />
  <AuthenticatedRoute exact path="/Subscriptions" component={Subscriptions} props={childProps} />
  <AuthenticatedRoute exact path="/Targets" component={Targets} props={childProps} />
  <AuthenticatedRoute exact path="/Blueprints" component={Blueprints} props={childProps} />
  <AuthenticatedRoute exact path="/BlueprintsForm" component={BlueprintsForm} props={childProps} />
  <AuthenticatedRoute exact path="/Teams" component={Teams} props={childProps} />
  <AuthenticatedRoute exact path="/ManageGroup" component={GroupForm} props={childProps} />
  <AuthenticatedRoute exact path="/Inventory" component={Inventory} props={childProps} />
  <AuthenticatedRoute exact path="/Assets" component={Assets} props={childProps} />
  <AuthenticatedRoute exact path="/Invoices" component={Invoices} props={childProps}/>
  <AuthenticatedRoute exact path="/Launch" component={Launch} props={childProps} />
  <AuthenticatedRoute exact path="/Deploy" component={Deploy} props={childProps} />
  <AuthenticatedRoute exact path="/CreateProduct" component={ProductForm} props={childProps} />
  <AuthenticatedRoute exact path="/UserForm" component={UserForm} props={childProps} />
  <AuthenticatedRoute exact path="/UserProfile" component={UserProfile} props={childProps} />
  <AuthenticatedRoute exact path="/Components" component={Components} props={childProps} />
  <AuthenticatedRoute exact path="/Health" component={Health} props={childProps} />
  <AuthenticatedRoute exact path="/Security" component={Security} props={childProps} />
  <AuthenticatedRoute exact path="/Cost" component={Cost} props={childProps} />
  <AuthenticatedRoute exact path="/NewDomain" component={DomainForm} props={childProps} />
  <AuthenticatedRoute exact path="/UpdateDomain" component={DomainForm} props={childProps} />
  <AuthenticatedRoute exact path="/NewWebsite" component={WebsiteForm} props={childProps} />
  <AuthenticatedRoute exact path="/UpdateWebsite" component={WebsiteForm} props={childProps} />
  <Route exact path="/Error" component={ErrorDefault} />
  <Route exact path="/Support" component={Support} />
</Switch>;
