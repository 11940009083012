export default { mutation: `
    mutation UpdateWebsite($key: String, $website: WebsiteInput ) 
    {
        UpdateWebsite(key: $key, website: $website) 
    {
        key
        name
        url
        vuln
    }
    }`
};