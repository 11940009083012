import React from 'react';
import { Card, CardHeader, Tooltip, IconButton, CardContent, Typography, Input, FormHelperText, FormControl  } from '@material-ui/core/';
import { ArrowBack } from '@material-ui/icons';
import { API, graphqlOperation } from "aws-amplify";
import style from './Style'
import Alert from '../../components/Alert';
import { Link } from 'react-router-dom';
import UpdateDomain from '../../graphql/mutations/UpdateDomain';
import AddDomain from '../../graphql/mutations/AddDomain';
import FormButton from '../../components/FormButton';

const emptyValue = {
  value: '',
  error: false,
  errorMsg: ''
}

export default function DomainForm(props) {
  const classes = style();
  const isEdit = props.location.state ? true : false;
  const domain = isEdit ? props.location.state.domain : {}

  const [alert, updateAlert] = React.useState({
    alertMessage: '',
    alertSuccess: false,
    showAlert: false
  })

  const [showLoading, setShowLoading] = React.useState(false)

  //Global Form Vars
  const [name, setName] = React.useState({ value: isEdit  ? domain.name : '', errorMsg: '', error: false })
  const [url, setUrl] = React.useState({ value: isEdit  ? domain.url : '', errorMsg: '', error: false })

  const handleAlertClose = () => { updateAlert({ alertMessage: '', alertSuccess: false, showAlert: false }) }

  const clearForm = () => { setName(emptyValue); setUrl(emptyValue); }

  const handleName = (e) => { setName({ value: e.target.value, errorMsg: '', error: false }) }
  const handleUrl = (e) => { setUrl({ value: e.target.value, errorMsg: '', error: false }) }

  var domainValues = {
    name: name.value,
    url: url.value,
    vuln: 77
  }

  const handleDomain = async () => {
    setShowLoading(true)
    await API.graphql(graphqlOperation(isEdit ? UpdateDomain.mutation : AddDomain.mutation, isEdit ? { key: domain.key, domain: domainValues } : { domain: domainValues } ))
    .then(async result => { 
      setShowLoading(false)
      updateAlert({ alertMessage: 
        isEdit ? `The Domain ${name.value} has been updated`: `A new Domain account called ${name.value} has been added`, 
        alertSuccess: true, 
        showAlert: true 
      })
      if(!isEdit)
        clearForm()
    })
    .catch(err => {
      setShowLoading(false)
      updateAlert({ alertMessage: `Error ${isEdit ? 'updating' : 'adding'} the domain called ${name.value}`, alertSuccess: false, showAlert: true })
      console.log(err)
    })
  }

  var formComplete =  
      !name.error && name.value !== '' &&
      !url.error && url.value !== '' 

  return (
    <div>
      <Alert showAlert={alert.showAlert} alertSuccess={alert.alertSuccess} alertMessage={alert.alertMessage} handleAlertClose={handleAlertClose}/>
      <Card>
        <CardHeader
          title={(!isEdit ? "Add" : "Update" ) + ' Domain'}
          subheader={!isEdit ? "Add a new Domain" : `Update ${domain.name}`}
          action= {
            <Tooltip title="Back to Assets" aria-label="assets">     
                <IconButton aria-label="arrow_back" component={Link} to={{
                  pathname: "/Assets"
                }}>
                  <ArrowBack />
                </IconButton> 
            </Tooltip>
          }
        />
        <CardContent>
          <form>
            <FormControl className={classes.formControl} required error={name.error}>
              <Typography color="textSecondary" className={classes.typo}>Domain Name</Typography>
              <Input id="input-name" autoComplete="off" value={name.value} onChange={handleName}  />
              <FormHelperText error={name.error}>{name.errorMsg}</FormHelperText>
            </FormControl> 
            <FormControl className={classes.formControl} required error={url.error}>
              <Typography color="textSecondary" className={classes.typo}>URL</Typography>
              <Input id="input-url" autoComplete="off" value={url.value} onChange={handleUrl} />
              <FormHelperText error={url.error}>{url.errorMsg}</FormHelperText>
            </FormControl> 
            <FormButton 
              text='Domain'
              isEdit={isEdit}
              loading={showLoading}
              formComplete={formComplete}
              callback={handleDomain}
            /> 
          </form>
        </CardContent>
      </Card>
    </div>
  );
}

