import React, { Component } from 'react';
import ReactGA from 'react-ga';
import { Card, Grid, Tooltip, CardHeader, IconButton, CardContent, Toolbar, InputBase } from '@material-ui/core';
import { API, graphqlOperation  } from "aws-amplify";
import GetProfiles from '../../graphql/queries/GetProfiles';
import ProfileForm from '../../components/ProfileForm/ProfileForm';
import ProfileCard from '../../components/ProfileCard/ProfileCard';
import style from './Style'
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {Add, Search} from '@material-ui/icons';
import Alert from '../../components/Alert';

class Profiles extends Component {
    constructor(props) {
        super(props);
        this.updateProfiles = this.updateProfiles.bind(this);
        this.updateProfilesList = this.updateProfilesList.bind(this);
        this.editProfile = this.editProfile.bind(this);
        this.state = {
            props: props,
            profiles: [],
            showLoading: true,
            showList: true,
            modifying: false,
            type: 0,
            currentProfile: {},
            query: ""
        };
    }

    async componentDidMount() {
    //Add Google tracking
    ReactGA.initialize('UA-141370158-1');
    ReactGA.pageview('/Profiles');
    this.refresh();
    }

    async refresh() {
        // Get existing profiles
        await API.graphql(graphqlOperation(GetProfiles.query))
        .then(result => {
            if (result && result.data.GetProfiles) {
                this.setState({
                    profiles: result.data.GetProfiles, 
                    showLoading: false
                })
            } else {
                this.setState({
                    showLoading: false,
                    showAlert: true,
                    alertError: true,
                    alertMessage: "No Profiles Found - if you think this is an error please check your internet connection and refresh the page"
                });
            }
        }).catch(err => {
            this.setState({
                showLoading: false,
                showAlert: true,
                alertError: true,
                alertMessage: "There was an error fetching your profiles - please check your internet connection and refresh the page "
            });
        });
    }

    updateProfiles() {
        this.setState({profile: null, keyValue: null, isEdit: false, showList: true})
    }

    updateProfilesList(result) {
        this.setState({profiles: result, showLoading: false})
    }

    editProfile(profile, key) {
        this.setState({
            profile: profile, 
            keyValue: key, 
            isEdit: true, 
            showList: false,
            modifying: true
        })
    }

    async toggleForm(type, profile) {
        if (this.state.modifying) {
            this.setState({
                modifying: false,
                type: 0,
            });
        } else if (type === 999) {
            this.setState({
                modifying: false,
                type: 0,
                currentProfile: {},
            });
        } else {
            this.setState({
                modifying: true,
                type: type,
                currentProfile: profile,
            });
        }
        this.refresh();
    }

    handleAlertClose = () => {
        this.setState({showAlert: false})
    }

    query(e) {
        this.setState({
            query: e.target.value,
        });
    }

    showAlert = (alertMessage, alertError) => { this.setState({showAlert: true, alertMessage: alertMessage, alertError: alertError}) }

    render() {
        const { classes } = this.props;
        const {showAlert, alertMessage, alertError, currentUserAdmin, profiles} = this.state;

        let filteredSearch;
        filteredSearch = profiles.filter(
          (item) => {return JSON.stringify(item).toLowerCase().indexOf(this.state.query.toLowerCase()) !== -1;}
        );
        return (
                <div>
                    <Alert showAlert={showAlert} alertSuccess={!alertError} alertMessage={alertMessage} handleAlertClose={this.handleAlertClose}/>
                    {
                        !this.state.modifying ?
                        (
                            <Card>
                                <CardHeader
                                    title='Profiles'
                                    subheader={'Available Profiles'}
                                    action= {
                                        <Toolbar>
                                            <div className={classes.search}>
                                                <div className={classes.searchIcon}>
                                                    <Search />
                                                </div>
                                                <InputBase
                                                    onChange={this.query.bind(this)}
                                                    placeholder="Search..."
                                                    classes={{
                                                    root: classes.inputRoot,
                                                    input: classes.inputInput,
                                                    }}
                                                    inputProps={{ 'aria-label': 'Search' }}
                                                />
                                            </div>
                                            <Tooltip title="Create a new Profile" aria-label="Create a new Profile">     
                                                <IconButton aria-label="arrow_back" disabled={currentUserAdmin ? true : false} onClick={()=>this.toggleForm(1)}>
                                                    <Add />
                                                </IconButton> 
                                            </Tooltip>
                                        </Toolbar>
                                    }
                                />
                                <CardContent>
                                <Grid container spacing={8}>
                                    {
                                        filteredSearch.map((profile, key) => 
                                            <Grid item xs key={profile.key} >
                                                <ProfileCard 
                                                    profile={profile} 
                                                    key={profile.key} 
                                                    editProfile={this.editProfile} 
                                                    updateProfilesList={this.updateProfilesList}
                                                    toggleForm={(type, profile) => {
                                                        this.toggleForm(type, profile)
                                                    }}
                                                    showAlert={this.showAlert}
                                                />
                                            </Grid>
                                        )
                                    }
                                </Grid>
                                </CardContent>
                            </Card>
                        ):(
                            <ProfileForm
                                isCreate={this.state.type === 1 ? true : false}
                                handleProfile={this.updateProfiles}
                                keyValue={this.state.keyValue}
                                profile={this.state.currentProfile}
                                type={this.state.type}
                                toggleForm={this.toggleForm.bind(this)}
                            />
                        )
                    }
                </div>
        );
    }
}


Profiles.propTypes = {
    classes: PropTypes.object.isRequired,
  };
  
  export default withStyles(style)(Profiles);
