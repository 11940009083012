import React from 'react';
import PropTypes from 'prop-types';
import useStyles from './Style'
import { withStyles } from '@material-ui/core/styles';
import { Lock, People, Home, Edit } from '@material-ui/icons';
import { Card, InputAdornment, CardHeader, CardContent, FormControl, Typography, Input, FormHelperText, Button, 
    InputLabel, Select, MenuItem, TextField, CircularProgress, Checkbox, FormControlLabel, Paper, Breadcrumbs, Dialog,
    DialogActions, DialogTitle, DialogContent, DialogContentText } from '@material-ui/core';
import Alert from '../../components/Alert';
import CountryList from "../../content/countryList";
import { API, graphqlOperation  } from "aws-amplify";
import CreateUserProfile from '../../graphql/mutations/CreateUserProfile';
import EditUserProfile from '../../graphql/mutations/EditUserProfile';
import EditUserAccount from '../../graphql/mutations/EditUserAccount';
import CreateUserAccount from '../../graphql/mutations/CreateUserAccount';
import SetUserPassword from '../../graphql/mutations/SetUserPassword';
import PushNotification from '../../functions/PushNotification';
import ResetUserPassword from '../../graphql/mutations/ResetUserPassword';
import { Link } from 'react-router-dom';

class UserForm extends React.Component {
    constructor(props) {
        super(props);
        const { isEdit, user, allUsers} = this.props.location.state ? this.props.location.state : {isEdit: false, user: {}, allUsers: []};
        this.state = {
            isEdit: isEdit,
            allUsers: allUsers,
            user: user,
            country: [],
            countryCode: '',
            showLoading: false,
            businessId: this.props.user.businessId,
            companyName: this.props.user['cognito:username'].split(".")[0],
            firstName: isEdit ? user.firstName : '',
            lastName: isEdit ? user.lastName : '',
            username: isEdit ? user.username.substring(user.username.indexOf('.') + 1) : '',
            email: isEdit ? user.email : '',
            password: isEdit ? user.password : '',
            phoneNumber: isEdit ? user.phoneNumber : '',
            key: isEdit ? user.key : '',
            isAdmin: isEdit ? user.isAdmin : ''
        };
    } 

    handleAlertClose = () => { this.setState({showAlert: false, alertMessage: ''})}

    handleCountry = (e) => {  this.setState({country: e.target.value, countryCode: e.target.value[2]}) }

    handleFirstName = (e) => {
        this.setState({firstName: e.target.value})
        if (e.target.value.match(/^(?=.{1,30}$)[a-zA-Z]+(?:['_.\s][a-zA-Z]+)*$/)) {
            this.setState({firstNameErrMsg: '', firstNameError: false});
        } 
        else {
            this.setState({firstNameErrMsg: 'Invalid first name', firstNameError: true});
        }
    }

    handleLastName = (e) => {
        this.setState({lastName: e.target.value})
        if (e.target.value.match(/^(?=.{1,30}$)[a-zA-Z]+(?:['_.\s][a-zA-Z]+)*$/)) {
            this.setState({lastNameErrMsg: '', lastNameError: false});
        } 
        else {
            this.setState({lastNameErrMsg: 'Invalid last name', lastNameError: true});
        }
    }

    handleUsername = (e) => {
        this.setState({username: e.target.value});
        if (e.target.value.match(/^[a-zA-Z0-9,.!?]*$/)) {
            this.setState({usernameErrMsg: '', usernameError: false});
        } 
        else {
            this.setState({usernameErrMsg: 'Invalid Username', usernameError: true});
        }
    }

    handlePhoneNumber = (e) => {
        this.setState({phoneNumber: e.target.value})
        if (e.target.value.match(/^((?!(0))[0-9]{5,15})$/)) {
            this.setState({phoneNumberErrMsg: '', phoneNumberError: false});
        } 
        else {
            this.setState({phoneNumberErrMsg: 'Invalid phone number', phoneNumberError: true});
        }
    }

    handleEmail = (e) => {
        this.setState({email: e.target.value.toLowerCase()})
        if (e.target.value.match(/[a-z0-9._%+!$&*=^|~#%'`?{}/-]+@([a-z0-9-]+.){1,}([a-z]{2,16})/)) {
            this.setState({emailErrMsg: '', emailError: false});
        } 
        else {
            this.setState({emailErrMsg: '', emailError: true});
        }
    }

    handleDialogClose = () => { this.setState({dialogOpen: false}) }

    handlePassword = (e) => {
        this.setState({password: e.target.value})
        if (e.target.value.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{12,})/)) {
            this.setState({passwordErrMsg: ' ', passwordError: false});
        } 
        else {
            this.setState({passwordErrMsg: 'Password Policy: minimum 12 characters, at least 1 number, at least 1 special character and at least 1 uppercase character', passwordError: true});
        }
    }

    handleSetPassword = (e) => {
        this.setState({setPassword: e.target.value})
        if (e.target.value.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{12,})/)) {
            this.setState({setPasswordErrMsg: ' ', setPasswordError: false});
        } 
        else {
            this.setState({setPasswordErrMsg: 'Password Policy: minimum 12 characters, at least 1 number, at least 1 special character and at least 1 uppercase character', setPasswordError: true});
        } 
    }

    handleAdmin = (e) => {this.setState({isAdmin: !this.state.isAdmin})}

    clearForm = () => { this.setState({firstName:'', lastName:'', email:'', password:'', phoneNumber:'', username: ''}) }

    createUser = async () => {
        if(this.state.emailError || this.state.usernameError || this.state.passwordError || this.state.firstNameError || this.state.lastNameError || this.state.phoneNumberError) {
            this.setState({showAlert: true, alertMessage: 'Unable to create user - Invalid Values', alertSuccess: false})
        }
        else {
            const userAccount = {
                email: this.state.email,
                company: this.state.companyName,
                username: this.state.companyName + "." + this.state.username,
                temporaryPassword: this.state.password,
                firstName: this.state.firstName,
                lastName: this.state.lastName,
                phoneNumber: '+'+this.state.countryCode+this.state.phoneNumber,
                isAdmin: this.state.isAdmin
            };
            this.setState({showLoading: true});
            await API.graphql(graphqlOperation(CreateUserAccount.mutation, userAccount))
            .then(async result => {
                this.setState({showLoading: false});
                if (result.data.CreateUserAccount.result) {
                    const userProfile = {
                        company: this.state.companyName,
                        email: this.state.email,
                        firstName: this.state.firstName,
                        isAdmin: this.state.isAdmin,
                        lastName: this.state.lastName,
                        phoneNumber: '+'+this.state.countryCode+this.state.phoneNumber,
                        username: this.state.companyName + "." + this.state.username,
                        sub: JSON.parse(result.data.CreateUserAccount.payload).sub,
                    };
                    await API.graphql(graphqlOperation(CreateUserProfile.mutation, userProfile))
                    .then(result => {
                        PushNotification('New User Account Created', userProfile.username.substring(userProfile.username.indexOf('.') + 1), this.state.businessId)
                        this.setState({showAlert: true, alertMessage: 'New User Account Created for: '+userProfile.username.substring(userProfile.username.indexOf('.') + 1), alertSuccess: true})
                        this.clearForm();
                    })
                    .catch(err => {
                        this.setState({showAlert: true, alertMessage: 'Error creating the users profile  - please contact SecureStack support', alertSuccess: false})
                    });
                } else {
                    this.setState({showAlert: true, alertMessage: 'Error creating the users account  - please try again', alertSuccess: false})
                }
            })
            .catch(error => {
                this.setState({showAlert: true, alertMessage: 'Error creating the users account  - please try again', alertSuccess: false})
            });
        }
    } 

    updateUser = async() => {
        if(this.state.emailError|| this.state.firstNameError || this.state.lastNameError ) {
            this.setState({showAlert: true, alertMessage: 'Unable to update user - Invalid Values', alertSuccess: false})
        }
        else {
            this.setState({showLoading: true})
            const userProfile = {
                company: this.state.companyName,
                email: this.state.email,
                firstName: this.state.firstName,
                isAdmin: this.state.isAdmin,
                lastName: this.state.lastName,
                phoneNumber: this.state.phoneNumber,
                username: this.state.companyName + "." + this.state.username,
                key: this.state.key,
            };
            await API.graphql(graphqlOperation(EditUserAccount.mutation, userProfile))
            .then(async result => {
                await API.graphql(graphqlOperation(EditUserProfile.mutation, userProfile))
                .then(async result => {
                    this.setState({showLoading: false});
                    this.setState({showAlert: true, alertMessage: 'User account Updated', alertSuccess: true})
                })
                .catch(err => {
                    this.setState({showAlert: true, alertMessage: 'Error updating the user profile  - please contact SecureStack support', alertSuccess: false})
                });
            })
            .catch(err => {
                this.setState({showAlert: true, alertMessage: 'Error updating the user account  - please try again', alertSuccess: false})
            });
        }
    }

    resetPassword = async () => {
        this.setState({resetLoading: true})
        var password = {
            username: this.state.username,
        };
        await API.graphql(graphqlOperation(ResetUserPassword.mutation, password))
        .then(async result => {
            if(result.data.ResetUserPassword.result === true)
                this.setState({resetLoading: false, showAlert: true, alertMessage: 'Password successfully reset for user: ' + password.username.substring(password.username.indexOf('.') + 1) + ' they will receive an email with a verification code', alertSuccess: true})
            else
                this.setState({resetLoading: false, dialogOpen: true})
        })
        .catch(e => {
            this.setState({resetLoading: false, dialogOpen: true})
        })
    }

    setPassword = async () => {
        //resets the users password to a specified string - used if the user has never logged on before
        const username = this.state.username
        await API.graphql(graphqlOperation(SetUserPassword.mutation, {username: username, password: this.state.setPassword}))
        .then(async result => {
            if(result.data.SetUserPassword.result === true)
                this.setState({setPassword: '', dialogOpen: false, resetLoading: false, showAlert: true, alertMessage: 'Password successfully reset for user: ' + username.substring(username.indexOf('.') + 1), alertSuccess: true})
            else
                this.setState({resetLoading: false, showAlert: true, alertMessage: 'Error reseting password for ' + username.substring(username.indexOf('.') + 1), alertSuccess: false})
        })
        .catch(e => {
            this.setState({resetLoading: false, showAlert: true, alertMessage: 'Error reseting password for ' + username.substring(username.indexOf('.') + 1), alertSuccess: false})
        })
    }
    
    render() {
        const { classes } = this.props;
        const { isEdit, user, resetLoading, showLoading, showAlert, alertSuccess, alertMessage, firstName, firstNameErrMsg, firstNameError, lastNameErrMsg, lastName, lastNameError, username, usernameErrMsg, usernameError,
        email, emailErrMsg, emailError, password, passwordErrMsg, passwordError, phoneNumber, phoneNumberErrMsg, phoneNumberError, country, countryCode, isAdmin,
        setPassword, setPasswordError, setPasswordMsg, dialogOpen } = this.state;
        return ( 
            <div>
                <Dialog open={dialogOpen} onClose={this.handleDialogClose} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">Set User Password</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {username} has never logged into SecureStack please enter a new temporary password for the user
                        </DialogContentText>
                        <FormControl className={classes.formControl} required error={setPasswordError}>
                            <Typography>Temporary Password</Typography>
                            <Input id="input-setPassword" value={setPassword} onChange={this.handleSetPassword} />
                            <FormHelperText error={setPasswordError}>{setPasswordMsg}</FormHelperText>
                        </FormControl>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleDialogClose} color="primary" disabled={resetLoading}>
                            Cancel
                        </Button>
                        <Button onClick={this.setPassword} color="primary" disabled={setPasswordError || resetLoading}>
                            Set Password
                        </Button>
                        { resetLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
                    </DialogActions>
                </Dialog>
                <Alert showAlert={showAlert} alertSuccess={alertSuccess} alertMessage={alertMessage} handleAlertClose={this.handleAlertClose}/>
                <Paper className={classes.bread}>
                    <Breadcrumbs aria-label="Breadcrumb" >
                        <Link color='primary' to='/'>
                            <Home className={classes.icon}/>
                            Home
                        </Link>
                        <Link color='primary' to={{
                            pathname: "/Teams",
                            state: {
                                users: [],
                                tab: 0
                            }
                        }}>  
                            <People className={classes.icon} />
                            Teams
                        </Link>
                        <Typography color='primary' className={classes.link}>
                            <Edit className={classes.icon} />
                            User Form
                        </Typography>
                    </Breadcrumbs>
                </Paper>
                <Card>
                    <CardHeader
                        title={isEdit ?  'Edit User': 'Create User' }
                        subheader={isEdit ? `Update User ${user.username}` : 'Create a New User Account'}
                        action={
                            <div>
                                {isEdit &&
                                    <Button color="primary" disabled={resetLoading} onClick={this.resetPassword}>
                                        <Lock  />
                                        Reset Users Password
                                    </Button>
                                }
                                { resetLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
                            </div>
                          }
                    />
                    <CardContent>
                        <FormControl className={classes.formControl} required error={firstNameError}>
                            <Typography color="textSecondary" >First Name</Typography>
                            <Input id="input-firstName" value={firstName} onChange={this.handleFirstName} />
                            <FormHelperText error={firstNameError}>{firstNameErrMsg}</FormHelperText>
                        </FormControl>  
                        <FormControl className={classes.formControl} required error={lastNameError}>
                            <Typography color="textSecondary" >Last Name</Typography>
                            <Input id="input-lastName" value={lastName} onChange={this.handleLastName} />
                            <FormHelperText error={lastNameError}>{lastNameErrMsg}</FormHelperText>
                        </FormControl> 
                        <FormControl className={classes.formControl} required error={usernameError}>
                            <Typography color="textSecondary" >Username</Typography>
                            <Input id="input-username" disabled={isEdit ? true : false} value={username} onChange={this.handleUsername} />
                            <FormHelperText error={usernameError}>{usernameErrMsg}</FormHelperText>
                        </FormControl> 
                        {
                            !isEdit &&
                            <FormControl className={classes.formControl}>
                                <InputLabel>Country</InputLabel>
                                <Select
                                    value={country}
                                    onChange={this.handleCountry} >
                                    {
                                        CountryList.map((item, index) =>{
                                            return <MenuItem key={index} value={item}>{item[0]}</MenuItem>
                                        })
                                    }
                                </Select>
                            </FormControl>
                        }
                        <FormControl className={classes.formControl} required error={phoneNumberError}>
                            <Typography color="textSecondary">Phone Number</Typography>
                            <TextField id="input-phone" value={phoneNumber} disabled={isEdit ? true : false} onChange={this.handlePhoneNumber}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">{isEdit ? '' : '+'+countryCode}</InputAdornment>,
                                }}
                            />
                            <FormHelperText error={phoneNumberError}>{phoneNumberErrMsg}</FormHelperText>
                        </FormControl> 
                        <FormControl className={classes.formControl} required error={emailError}>
                            <Typography color="textSecondary">Email</Typography>
                            <Input id="input-email" value={email} onChange={this.handleEmail} />
                            <FormHelperText error={emailError}>{emailErrMsg}</FormHelperText>
                        </FormControl>   
                        {
                            !isEdit && 
                            <FormControl className={classes.formControl} required error={passwordError}>
                                <Typography color="textSecondary">Temporary Password</Typography>
                                <Input id="input-passsword" value={password} onChange={this.handlePassword} />
                                <FormHelperText error={passwordError}>{passwordErrMsg}</FormHelperText>
                            </FormControl>
                        }
                        <FormControl className={classes.formControl} required>
                            <FormControlLabel
                                control={
                                <Checkbox
                                    checked={isAdmin}
                                    onChange={this.handleAdmin}
                                    value={isAdmin}
                                    color="primary"
                                />
                                }
                                label="Admin Account"
                            />
                        </FormControl>
                        
                        <div className={classes.wrapper}>
                            <Button
                                variant="outlined"
                                color="primary"
                                className={classes.button}
                                disabled={showLoading}
                                onClick={isEdit ? this.updateUser : this.createUser} >
                            {isEdit ? "Update" : "Create"} User
                            </Button>
                            { showLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
                        </div>   
                    </CardContent>
                </Card>
            </div>
        )
    }
}


UserForm.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(useStyles)(UserForm);