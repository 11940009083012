import React, {Component} from 'react';
import style from './Style'
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { List, ListItem, ListItemText, Collapse, Tooltip } from '@material-ui/core';
import { ExpandLess, ExpandMore} from '@material-ui/icons';

class ReviewTarget extends Component {
    constructor(props) {
        super(props);
        this.state = {
            securityGroupsOpen: false
        };
    } 

    componentDidMount = () => { }

    handleSecurityGroups = (e) => {
        this.setState({securityGroupsOpen: !this.state.securityGroupsOpen})
    }

    handletargetName = () => {
    }

    render() {
        const {selectedTarget, dense, isEdit} = this.props
        const { classes } = this.props;
        const {securityGroupsOpen} = this.state
        return (
            <div className={classes.list}>
                <List dense={dense}>
                    <ListItem key='TargetName'>
                        <ListItemText
                        primary="Target Name:"
                        secondary={selectedTarget ? selectedTarget.targetName : ''}
                        onClick={isEdit ? () => this.handletargetName : null}
                        />
                    </ListItem>
                    <ListItem key='Provider'>
                        <ListItemText
                        primary="Provider:"
                        secondary={selectedTarget ? selectedTarget.provider : ''}
                        />
                    </ListItem>
                    <ListItem key='Credentials'>
                        <ListItemText
                        primary="Credentials:"
                        secondary={selectedTarget ? selectedTarget.credential : ''}
                        />
                    </ListItem>
                    <ListItem key='Type'>
                        <ListItemText
                        primary="Instance Type:"
                        secondary={selectedTarget ? selectedTarget.instanceType : ''}
                        />
                    </ListItem>
                    <ListItem key='Region'>
                        <ListItemText
                        primary="Region:"
                        secondary={selectedTarget ? selectedTarget.region : ''}
                        />
                    </ListItem>
                    <ListItem onClick={this.handleSecurityGroups} key='Security'>
                        <ListItemText
                        primary="Security Groups:"
                        />
                        <Tooltip title="View Security Groups" aria-label="ViewSecurityGroups">     
                            {securityGroupsOpen ? <ExpandLess /> : <ExpandMore />}
                        </Tooltip>
                    </ListItem>
                        <Collapse in={securityGroupsOpen} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding dense={dense}>
                                {    
                                    selectedTarget.securityGroups.map((item, index) =>{
                                            return (<ListItem className={classes.nestedListItem} key={index}>
                                            <ListItemText
                                                primary={item.name}
                                                secondary={item.id}
                                            />
                                            </ListItem>)
                                    })
                                }
                            </List>
                        </Collapse>
                    <ListItem key='vpc'>
                        <ListItemText
                        primary="VPC ID:"
                        secondary={selectedTarget ? selectedTarget.vpc : ''}
                        />
                    </ListItem>
                    <ListItem key='subnet'>
                        <ListItemText
                        primary="Subnet ID:"
                        secondary={selectedTarget ? selectedTarget.subnetId : ''}
                        />
                    </ListItem>
                </List>
            </div>)
    }
}

ReviewTarget.propTypes = {
    classes: PropTypes.object.isRequired
};
  
export default withStyles(style)(ReviewTarget);