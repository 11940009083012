export default { mutation: `
mutation LaunchVM($launchVMInput: LaunchVMInput) {
	LaunchVM(launchVMInput: $launchVMInput) {
		instanceId
		instanceType
		keyName
		launchTime
		privateDnsName
		privateIpAddress
		subnetId
		vpcId
		securityGroups
  }
}`
};
