import React, {Component} from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {Table, TableBody, TableCell, TableHead, TablePagination, TableRow, TableSortLabel, Toolbar, Typography, FormControl, Button, DialogActions,
    Paper, InputBase, IconButton, Tooltip, Dialog, DialogTitle, DialogContent, DialogContentText, CircularProgress, Input, FormHelperText,
    Chip, SvgIcon } from '@material-ui/core/'
import {Edit, Search as SearchIcon, Add as AddIcon, Delete as DeleteIcon } from '@material-ui/icons/';
import style from './Style'
import GetFirewallRules from "../../graphql/queries/GetFirewallRules";
import DeleteFirewallRule from "../../graphql/mutations/DeleteFirewallRule";
import { Link } from 'react-router-dom';
import { API, graphqlOperation } from "aws-amplify";
import Alert from '../../components/Alert';
import ReactGA from 'react-ga';
import ConfirmDeleteDialog from '../../components/ConfirmDeleteDialog';

function desc(a, b, orderBy) {
    if(a[orderBy] && b[orderBy]) {
        if (b[orderBy].toLowerCase() < a[orderBy].toLowerCase()) {
            return -1;
        }
        if (b[orderBy].toLowerCase() > a[orderBy].toLowerCase()) {
            return 1;
        }
        return 0;
    }
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

class FirewallRules extends Component {
    state = {
        firewallRules: [],
        selected: '',
        query: "",
        page: 0,
        rowsPerPage: 10,
        dialogOpen: false,
        tabValue: this.props.location.state ? this.props.location.state.tab : 0,
        loadingRules: true
    };

    componentDidMount = async () => {
        //Add Google tracking
        ReactGA.initialize('UA-141370158-1');
        ReactGA.pageview('/FirewallRules');
    
        //Get initial Data
        await API.graphql(graphqlOperation(GetFirewallRules.query))
        .then(result => { this.setState({firewallRules: result.data.GetFirewallRules, loadingRules: false}) })
        .catch(err => { this.setState({ showAlert: true, alertMessage:  "Error while retrieving firewall rules.", alertSuccess: false, loadingRules: false }) });
    }

    handleAlertClose = () => { this.setState({showAlert: false, alertMessage: '', selectedRule: {}}) }

    confirmDeleteRule = async () => {
        this.setState({showLoading: true})
        await API.graphql(graphqlOperation(DeleteFirewallRule.mutation, {key: this.state.selectedRule.key} ))
        .then(result => {
            this.setState({ firewallRules: result.data.DeleteFirewallRule, showLoading: false, showAlert: true, alertSuccess: true, alertMessage: "Firewall rule successfully removed", selected: '', selectedRule: {}, dialogOpen: false});
        })
        .catch(err => {
            this.setState({ showLoading: false, showAlert: true, alertSuccess: false, alertMessage: "Error while deleting the firewall rule.", dialogOpen: false});
        });
    }

    handleDeleteDialog = () => { this.setState({dialogOpen: true}) }

    handleDialogClose = () => { this.setState({dialogOpen: false}) }

    handleRequestSort = (event, property) => {
        const orderBy = property;
        let order = 'desc';
        if (this.state.orderBy === property && this.state.order === 'desc') {
            order = 'asc';
        }
        this.setState({ order, orderBy });
    };

    handleClick = (event, rule) => {
        if(this.state.selected === rule.firewallName ) {
            this.setState({selected: '', selectedRule: { } })
        }
        else {
            this.setState({ selected: rule.firewallName, selectedRule:  rule});
        }
    };

    handleChangePage = (event, page) => { this.setState({ page }) };

    showPorts = (key) => { 
        if(this.state.viewPorts === key ) {
            this.setState({ viewPorts: null })
        }
        else {
            this.setState({ viewPorts: key });
        }
    }

    showDestinations = (key) => {
        if(this.state.viewDestinations === key ) {
            this.setState({ viewDestinations: null })
        }
        else {
            this.setState({ viewDestinations: key });
        }
    }

    showSources = (key) => {
        if(this.state.viewSources === key ) {
            this.setState({ viewSources: null })
        }
        else {
            this.setState({ viewSources: key });
        }
    }

    handleChangeRowsPerPage = event => { this.setState({ rowsPerPage: event.target.value }) };

    isSelected = id => this.state.selected === id

    createSortHandler = property => event => { this.handleRequestSort(event, property) };

    query(e) { this.setState({ query: e.target.value }) }

    render() {
        const { classes } = this.props;
        const { order, orderBy, selected, rowsPerPage, page, firewallRules, numSelected, showAlert, alertMessage, alertSuccess,
            dialogOpen, showLoading, selectedRule, loadingRules, viewPorts, viewSources, viewDestinations } = this.state;

        const emptyRows = rowsPerPage - Math.min(rowsPerPage, firewallRules.length - page * rowsPerPage);

        const rows = [
            { id: 'firewallName', numeric: false, disablePadding: false, label: 'Firewall Rule Name' },
            { id: 'protocols', numeric: false, disablePadding: false, label: 'Protocols' },
            { id: 'ports', numeric: false, disablePadding: false, label: 'Ports' },
            { id: 'sources', numeric: false, disablePadding: false, label: 'Sources' },
            { id: 'destinations', numeric: false, disablePadding: false, label: 'Destinations' },
            { id: 'comment', numeric: false, disablePadding: false, label: 'Comment' }
        ];

        let filteredSearch;
        filteredSearch = firewallRules.filter(
          (item) => {return JSON.stringify(item).toLowerCase().indexOf(this.state.query.toLowerCase()) !== -1;}
        );
          
        return (
            <div>
                <ConfirmDeleteDialog name={this.state.selectedRule ? this.state.selectedRule.firewallName : ''} type='Firewall Rule' open={dialogOpen} handleDialogClose={this.handleDialogClose} loading={showLoading}
                    confirmDelete={this.confirmDeleteRule}
                />
                <Alert showAlert={showAlert} alertSuccess={alertSuccess} alertMessage={alertMessage} handleAlertClose={this.handleAlertClose}/>
                <Paper className={classes.root}>
                    <Toolbar
                        className={classNames(classes.toolbar, {
                            [classes.highlight]: numSelected > 0,
                        })}
                    >
                    <div className={classes.title}>
                        {selected !== '' ? (
                            <Typography color="primary" variant="subtitle1">
                                { selected }
                            </Typography>
                        ) : (
                            <Typography variant="h6" id="tableTitle">
                                Firewall Rules
                            </Typography>
                        )}
                    </div>
                    <div className={classes.spacer} />
                    <div className={classes.actions}>
                        { selected !== '' ? (
                            <div className={classes.tableHead}>
                                <Tooltip title="Edit">
                                <IconButton aria-label="EDIT" color='primary'
                                    component={Link} to={{
                                        pathname: "/FirewallForm",
                                        state: {
                                            isEdit: true,
                                            firewallRule: selectedRule
                                        }
                                    }}>
                                    < Edit />                                        
                                </IconButton>                                      
                                </Tooltip>
                                <Tooltip title="Delete">
                                    <IconButton aria-label="Delete" onClick={() => this.handleDeleteDialog()}>
                                        <DeleteIcon />
                                    </IconButton>
                                </Tooltip>
                            </div>
                        ) : (
                            <div className={classes.headSearch}>
                                <IconButton className={classes.iconButton} aria-label="Search">
                                    <SearchIcon />
                                </IconButton>
                                <InputBase 
                                    className={classes.input} 
                                    onChange={this.query.bind(this)}
                                    placeholder="Search..."
                                />
                                <Tooltip title="Create New Firewall Rule">
                                        <IconButton aria-label="Create" color='primary'
                                            component={Link} to={{
                                                pathname: "/FirewallForm",
                                                state: {
                                                    isEdit: false
                                                }
                                            }}>
                                            <AddIcon />
                                        </IconButton>
                                </Tooltip>
                            </div>

                        )}
                    </div>
                    </Toolbar>
                    <div className={classes.tableWrapper}>
                        <Table className={classes.table} aria-labelledby="tableTitle">
                            { loadingRules && <CircularProgress size={24} className={classes.loadingFirewall} />}
                            <TableHead>
                                <TableRow>
                                    { rows.map((row, index) => 
                                        {
                                            return [
                                                row.id === 'firewallName' || row.id === 'comment' ? 
                                                    <TableCell
                                                    key={row.id}
                                                    align='left'
                                                    padding='default'
                                                    sortDirection={orderBy === row.id ? order : false} >
                                                    <Tooltip
                                                        title="Sort"
                                                        placement='bottom-start'
                                                        enterDelay={300} >
                                                    <TableSortLabel
                                                        active={orderBy === row.id}
                                                        direction={order}
                                                        onClick={this.createSortHandler(row.id)} >
                                                        {row.label}
                                                    </TableSortLabel>
                                                    </Tooltip>
                                                </TableCell>
                                                :
                                                <TableCell
                                                    key={row.id}
                                                    align='left'
                                                    padding='default' >
                                                    {row.label}
                                                </TableCell> 
                                            ]
                                        }  
                                    )}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            { stableSort(filteredSearch, getSorting(order, orderBy))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((n, i) => {
                                    const isSelected = this.isSelected(n.username);
                                    return [
                                        <TableRow
                                            hover
                                            onClick={event => this.handleClick(event, n)}
                                            aria-checked={isSelected}
                                            tabIndex={-1}
                                            key={n.key}
                                            selected={isSelected} >
                                            <TableCell align="left">{n.firewallName}</TableCell>
                                            <TableCell component="th" scope="row" padding='default'>
                                                { 
                                                    n.protocols.tcp === true &&
                                                    <Chip key={i} variant="outlined" label='TCP' className={classes.chip} />
                                                }
                                                {
                                                    n.protocols.udp === true &&
                                                    <Chip key={i} variant="outlined" label='UDP' className={classes.chip} />
                                                }
                                            </TableCell>
                                            <TableCell align="left">
                                                {
                                                    n.ports.slice(0, viewPorts ? n.ports.length : 2).map((value, index) => (
                                                        <Chip key={index} value={value} variant="outlined" label={value} className={classes.chip} />
                                                    ))                                                    
                                                }
                                                { 
                                                    n.ports.length > 2 ? 
                                                        <Tooltip title={viewPorts ? "View Less" : "View All Ports"}>
                                                            <Chip key={'viewAll'} color='primary' className={classes.chip} label=''
                                                                icon={
                                                                    <IconButton onClick={() => this.showPorts(n.key)} style={{marginLeft:15}}>
                                                                        <SvgIcon>
                                                                            {
                                                                                !viewPorts ? 
                                                                                    <path  d="M18,8H8V18H6V8A2,2 0 0,1 8,6H18V8M14,2H4A2,2 0 0,0 2,4V14H4V4H14V2M22,12V20A2,2 0 0,1 20,22H12A2,2 0 0,1 10,20V12A2,2 0 0,1 12,10H20A2,2 0 0,1 22,12M20,15H17V12H15V15H12V17H15V20H17V17H20V15Z" />
                                                                                :
                                                                                    <path d="M14,4H4V14H2V4A2,2 0 0,1 4,2H14V4M18,6H8A2,2 0 0,0 6,8V18H8V8H18V6M22,12V20A2,2 0 0,1 20,22H12A2,2 0 0,1 10,20V12A2,2 0 0,1 12,10H20A2,2 0 0,1 22,12M20,15H12V17H20V15Z" />
                                                                            }
                                                                        </SvgIcon>
                                                                    </IconButton>
                                                                }
                                                            />
                                                        </Tooltip>
                                                    :
                                                    null
                                                }
                                            </TableCell>
                                            <TableCell align="left">
                                                {
                                                    n.sources.slice(0, viewSources ? n.ports.length : 1).map((value, index) => (
                                                        <Chip key={index} value={value} variant="outlined" label={value} className={classes.chip} />
                                                    ))                                                    
                                                }
                                                { 
                                                    n.sources.length > 1 ? 
                                                        <Tooltip title={viewSources ? "View Less" : "View All Sources"}>
                                                            <Chip key={'viewAll'} color='primary' className={classes.chip} label=''
                                                                icon={
                                                                    <IconButton onClick={() => this.showSources(n.key)} style={{marginLeft:15}}>
                                                                        <SvgIcon>
                                                                            {
                                                                                !viewSources ? 
                                                                                    <path  d="M18,8H8V18H6V8A2,2 0 0,1 8,6H18V8M14,2H4A2,2 0 0,0 2,4V14H4V4H14V2M22,12V20A2,2 0 0,1 20,22H12A2,2 0 0,1 10,20V12A2,2 0 0,1 12,10H20A2,2 0 0,1 22,12M20,15H17V12H15V15H12V17H15V20H17V17H20V15Z" />
                                                                                :
                                                                                    <path d="M14,4H4V14H2V4A2,2 0 0,1 4,2H14V4M18,6H8A2,2 0 0,0 6,8V18H8V8H18V6M22,12V20A2,2 0 0,1 20,22H12A2,2 0 0,1 10,20V12A2,2 0 0,1 12,10H20A2,2 0 0,1 22,12M20,15H12V17H20V15Z" />
                                                                            }
                                                                        </SvgIcon>
                                                                    </IconButton>
                                                                }
                                                            />
                                                        </Tooltip>
                                                    :
                                                    null
                                                }
                                            </TableCell>
                                            <TableCell align="left">
                                                {
                                                    n.destinations.slice(0, viewDestinations ? n.destinations.length : 1).map((value, index) => (
                                                        <Chip key={index} value={value} variant="outlined" label={value} className={classes.chip} />
                                                    ))                                                    
                                                }
                                                { 
                                                    n.destinations.length > 1 ? 
                                                        <Tooltip title={viewDestinations ? "View Less" : "View All Sources"}>
                                                            <Chip key={'viewAll'} color='primary' className={classes.chip} label=''
                                                                icon={
                                                                    <IconButton onClick={() => this.showDestinations(n.key)} style={{marginLeft:15}}>
                                                                        <SvgIcon>
                                                                            {
                                                                                !viewDestinations ? 
                                                                                    <path  d="M18,8H8V18H6V8A2,2 0 0,1 8,6H18V8M14,2H4A2,2 0 0,0 2,4V14H4V4H14V2M22,12V20A2,2 0 0,1 20,22H12A2,2 0 0,1 10,20V12A2,2 0 0,1 12,10H20A2,2 0 0,1 22,12M20,15H17V12H15V15H12V17H15V20H17V17H20V15Z" />
                                                                                :
                                                                                    <path d="M14,4H4V14H2V4A2,2 0 0,1 4,2H14V4M18,6H8A2,2 0 0,0 6,8V18H8V8H18V6M22,12V20A2,2 0 0,1 20,22H12A2,2 0 0,1 10,20V12A2,2 0 0,1 12,10H20A2,2 0 0,1 22,12M20,15H12V17H20V15Z" />
                                                                            }
                                                                        </SvgIcon>
                                                                    </IconButton>
                                                                }
                                                            />
                                                        </Tooltip>
                                                    :
                                                    null
                                                }
                                            </TableCell>
                                            <TableCell align="left">{n.comment}</TableCell>
                                        </TableRow>
                                    ];
                                })
                            }
                            { emptyRows > 0 && (
                                <TableRow style={{ height: 49 * emptyRows }}>
                                <TableCell colSpan={6} />
                                </TableRow>
                            )}
                            </TableBody>
                    </Table>
                    </div>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={firewallRules.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        backIconButtonProps={{
                            'aria-label': 'Previous Page',
                        }}
                        nextIconButtonProps={{
                            'aria-label': 'Next Page',
                        }}
                        onChangePage={this.handleChangePage}
                        onChangeRowsPerPage={this.handleChangeRowsPerPage} />
                </Paper>
            </div>
        );
    }
}

FirewallRules.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(style)(FirewallRules);