import React from 'react';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import {Grow, CircularProgress, Paper, ClickAwayListener, Popper, ButtonGroup, Button, MenuItem, MenuList } from '@material-ui/core';

const options = ['Start', 'Stop', 'Reboot', 'Add Tags', 'Add Profile', 'Create AMI', 'Terminate'];

export default function SplitButton(instance) {
    const os = JSON.parse(instance.instance.info).os
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);
    const [selectedIndex, setSelectedIndex] = React.useState(0);

    function handleClick() {
        if(options[selectedIndex] === 'Add Profile') {
            instance.handleAddProfile()
        }
        else if (options[selectedIndex] === 'Create AMI') {
            instance.handleCreateAMIDialog()
        }
        else if (options[selectedIndex] === 'Add Tags') {
            instance.handleAddTagsDialog()
        }
        else {
            instance.handleFunctions(options[selectedIndex]) 
        }
    }

    function handleMenuItemClick(event, index) {
        setSelectedIndex(index);
        setOpen(false);
    }

    function handleToggle() {
        setOpen(prevOpen => !prevOpen);
    }

    function handleClose(event) {
        if (anchorRef.current && anchorRef.current.contains(event.target))
            return;
        setOpen(false);
    }

    return (
        <div style={{width: '100%'}}>
            <ButtonGroup variant="contained" color="primary" ref={anchorRef} aria-label="split button" style={{width: '100%'}}>
            <Button onClick={handleClick} style={{width: '100%'}} disabled={instance.loadingFunction}>{options[selectedIndex]}</Button>
            <Button
                disabled={instance.loadingFunction}
                color="primary"
                size="small"
                aria-owns={open ? 'menu-list-grow' : undefined}
                aria-haspopup="true"
                onClick={handleToggle} >
                {
                    instance.loadingFunction ? 
                        <CircularProgress size={24} />
                    :
                        <ArrowDropDownIcon />
                    }
            </Button>
            </ButtonGroup>
            <Popper open={open} anchorEl={anchorRef.current} transition disablePortal>
            {({ TransitionProps, placement }) => (
                <Grow
                {...TransitionProps}
                style={{
                    transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                }}
                >
                <Paper id="menu-list-grow">
                    <ClickAwayListener onClickAway={handleClose}>
                    <MenuList>
                        {options.map((option, index) => (
                        <MenuItem
                            key={option}
                            selected={index === selectedIndex}
                            disabled={option === 'Create AMI' && (instance.instance.securestack_managed === 'false' || os === 'na' ) ? true : false }
                            onClick={event => handleMenuItemClick(event, index)}>
                            {option}
                        </MenuItem>
                        ))}
                    </MenuList>
                    </ClickAwayListener>
                </Paper>
                </Grow>
            )}
            </Popper>
        </div>
    );
}