import React, { Component } from 'react';
import Copyright from '../../components/Copyright'
import {Auth} from "aws-amplify";
import {Button, CssBaseline, TextField, Link, Grid, Box, Typography, Container, CircularProgress, FormHelperText } from '@material-ui/core/';
import PropTypes from 'prop-types';
import { withStyles, MuiThemeProvider } from '@material-ui/core/styles';
import style from './Style'
import small_logo from '../../assets/images/securestack_horizontal.png';
import theme from '../../content/formTheme';
import Alert from '../../components/Alert';
import QRCode from 'qrcode.react';
import { NavLink } from 'react-router-dom'

var mixpanel = require('mixpanel-browser');
mixpanel.init("177283cdf44ca45aa1e52db11ea3b292");

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      company: '',
      password: '',
      newPassword: '',
      confirmPassword: '',
      mfa: '',
      qr: 'awfwfaw',
      verify: '',
      newPasswordError: false,
      confirmPasswordError: false,
      showLoading: false, 
      showLogin: true,
      showMFA: false,
      showQR: false,
      showPassword: false,
      showResetPassword: false
    };
  }

  handleLogIn = async (e) => {
    e.preventDefault()
    this.setState({ showLoading: true });
    await Auth.signIn(this.state.company+'.'+this.state.username, this.state.password)
    .then(user => {
      this.setState({ showLoading: false });
      if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        this.setState({ showPassword: true, showLogin: false, auth: user})
      } else {
        this.setState({ showMFA: true, showLogin: false, auth: user })
      }
    })
    .catch(err => {
      console.log(err)
      if(err.code === "PasswordResetRequiredException") {
        this.setState({ showResetPassword: true, showLogin: false, showLoading: false })
      }
      else {
        this.setState({ showAlert: true, alertMessage: err.message, alertSuccess: false, showLoading: false})
      }
    })
  }

  handleMFALogin = async (e) => {
    e.preventDefault()
    this.setState({ showLoading: true });
    await Auth.confirmSignIn(this.state.auth, this.state.mfa, 'SOFTWARE_TOKEN_MFA' )
    .then(async data => {
      this.setState({ showLoading: false });
      this.props.userHasAuthenticated(true);
      mixpanel.track("Authenticated login");
    })
    .catch(err => {
      console.log(err)
      this.setState({ showAlert: true, alertMessage: err.message, alertSuccess: false, showLoading: false})
    })
  }

  handleFirstPassword = async (e) => {
    e.preventDefault()
    if(this.state.newPassword === this.state.confirmPassword) {
      this.setState({ showLoading: true });
      Auth.completeNewPassword( this.state.auth, this.state.newPassword)
      .then(user => {
        this.setState({ auth: user, showQR: true, showLoading: false, showPassword: false });
        Auth.setupTOTP(user)
        .then((code) => { 
          this.setState({ qr: `otpauth://totp/SecureStack@${this.state.company}:${this.state.username}?secret=${code}&issuer=SecureStack-CloudBuilder` });
        });
      })
      .catch(err => {
        console.log(err)
        this.setState({ showAlert: true, alertMessage: err.message, alertSuccess: false, showLoading: false})
      });
    }
    else {
      this.setState({ showAlert: true, alertMessage: 'Passwords must match', alertSuccess: false})
    }
  }

  handleFirstMFA = (e) => {
    e.preventDefault()
    this.setState({ showLoading: true });
    Auth.verifyTotpToken(this.state.auth, this.state.mfa)
    .then(data => {
      this.setState({ showLoading: false });
      mixpanel.track("SignUp Complete - MFA success");
      mixpanel.identify(this.state.username);
      this.props.userHasAuthenticated(true);
    })
    .catch(err => { 
      this.setState({ showAlert: true, alertMessage: err.message, alertSuccess: false, showLoading: false})
    })
  }

  handleResetPassword = (e) => {
    e.preventDefault()
    if (this.state.newPassword === this.state.confirmPassword) {
      this.setState({showLoading: true})
      Auth.forgotPasswordSubmit(this.state.company+'.'+this.state.username, this.state.verify, this.state.newPassword)
      .then(data => {
          this.setState({showLoading: false,  showResetPassword: false, showLogin: true, password: '', showAlert: true, alertMessage: 'Password reset successful - please login with your new password', alertSuccess: true})
      })
      .catch(err => {
        this.setState({ showAlert: true, alertMessage: err.message, alertSuccess: false, showLoading: false})
      });
    }
    else {
      this.setState({ showAlert: true, alertMessage: 'Passwords must match', alertSuccess: false})
    }
  }

  handleUsername = (e) => { this.setState({username: e.target.value}) }

  handlePassword = (e) => { this.setState({password: e.target.value}) }

  handleCompany = (e) => { this.setState({company: e.target.value}) }

  handleMFA = (e) => { this.setState({mfa: e.target.value}) }

  handleVerify = (e) => { this.setState({verify: e.target.value}) }

  handleNewPassword = (e) => { 
    if (e.target.value.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{12,})/)) {
      this.setState({newPasswordError: false});
    }
    else {
      this.setState({newPasswordError: true});
    }
    this.setState({newPassword: e.target.value })
  }

  handleConfirmPassword = (e) => { 
    if (e.target.value.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{12,})/)) {
      this.setState({confirmPasswordError: false});
    }
    else {
      this.setState({confirmPasswordError: true});
    }
    this.setState({confirmPassword: e.target.value })
  }

  handleAlertClose = () => this.setState({showAlert: false })

  render() {
    const { classes } = this.props
    const { username, company, password, mfa, newPassword, confirmPassword, verify, newPasswordError, confirmPasswordError } = this.state
    const { showAlert, alertSuccess, alertMessage, showLoading, showLogin, showMFA, showPassword, showQR, showResetPassword } = this.state
    return (
      <div>
        <Alert showAlert={showAlert} alertSuccess={alertSuccess} alertMessage={alertMessage} handleAlertClose={this.handleAlertClose}/>
        <Container component="main" maxWidth="sm">
          <MuiThemeProvider theme={theme}>
          <CssBaseline />
          <div className={classes.paper}>
            <img src={small_logo} alt="img_logo" style={{width: '20rem'}}/>
            <br/>
            <Typography component="h1" variant="h5" color='textSecondary'>
              Welcome to SecureStack CloudBuilder
            </Typography>
            {
              showLogin &&
              <form className={classes.form} noValidate>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="username"
                  label="Username"
                  name="username"
                  autoComplete="off"
                  value={username}
                  onChange={this.handleUsername}
                  autoFocus />
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="company"
                  label="Company"
                  autoComplete="off"
                  name="company"
                  value={company}
                  onChange={this.handleCompany} />
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  autoComplete="current-password"
                  label="Password"
                  type="password"
                  value={password}
                  onChange={this.handlePassword}
                  id="password" />
                <div className={classes.wrapper}>
                  <Button
                    id='loginBtn'
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={this.handleLogIn}
                    disabled={showLoading}
                    className={classes.submit} >
                    Sign In
                  </Button>
                  {showLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
                </div>
              </form>
            }
            {
              showMFA &&
              <form className={classes.form} noValidate>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="mfa"
                  label="MFA Code"
                  autoComplete="off"
                  name="mfa"
                  value={mfa}
                  onChange={this.handleMFA}
                  autoFocus />
                <div className={classes.wrapper}>
                  <Button
                    fullWidth
                    variant="contained"
                    type="submit"
                    color="primary"
                    onClick={this.handleMFALogin}
                    disabled={showLoading}
                    className={classes.submit} >
                    Sign In
                  </Button>
                  {showLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
                </div>
              </form>
            }
            {
              showPassword &&
              <form className={classes.form} noValidate>
                <FormHelperText error={false}>Password Policy: minimum 12 characters, at least 1 number, at least 1 special character and at least 1 uppercase character</FormHelperText>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="newPassword"
                  label="New Password"
                  type="password"
                  autoComplete="current-password"
                  value={newPassword}
                  onChange={this.handleNewPassword}
                  id="password" />
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="confirmPassword"
                  label="Confirm Password"
                  autoComplete="current-password"
                  type="password"
                  value={confirmPassword}
                  onChange={this.handleConfirmPassword}
                  id="password" />
                <div className={classes.wrapper}>
                  <Button
                    fullWidth
                    type="submit"
                    variant="contained"
                    color="primary"
                    onClick={this.handleFirstPassword}
                    disabled={showLoading}
                    className={classes.submit} >
                    Sign In
                  </Button>
                  {showLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
                </div>
              </form>
            }
            {
              showQR &&
              <form className={classes.form} noValidate>
                <div style={{width: '100%', 'text-align':'center'}}>
                  <QRCode value={this.state.qr} style={{display: 'inline'}} />
                </div>
                <FormHelperText error={false}>Please scan the above QR code and enter its MFA code below</FormHelperText>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="mfa"
                  autoComplete="off"
                  label="MFA Code"
                  name="mfa"
                  value={mfa}
                  onChange={this.handleMFA}
                  autoFocus />
                <div className={classes.wrapper}>
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    type="submit"
                    onClick={this.handleFirstMFA}
                    disabled={showLoading}
                    className={classes.submit} >
                    Sign In
                  </Button>
                  {showLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
                </div>
              </form>
            }
            {
              showResetPassword &&
              <form className={classes.form} noValidate>
                <FormHelperText error={false}>Your Password has been reset please give yourself a new password</FormHelperText>
                <FormHelperText error={false}>Password Policy: minimum 12 characters, at least 1 number, at least 1 special character and at least 1 uppercase character</FormHelperText>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  autoComplete="current-password"
                  fullWidth
                  name="newPassword"
                  label="New Password"
                  type="password"
                  error={newPasswordError}
                  value={newPassword}
                  onChange={this.handleNewPassword}
                  id="password" />
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="confirmPassword"
                  autoComplete="current-password"
                  label="Confirm Password"
                  type="password"
                  error={confirmPasswordError}
                  value={confirmPassword}
                  onChange={this.handleConfirmPassword}
                  id="password" />
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  autoComplete="off"
                  id="verify"
                  label="Verify Code"
                  name="verify"
                  value={verify}
                  onChange={this.handleVerify}
                  />
                <div className={classes.wrapper}>
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    type="submit"
                    onClick={this.handleResetPassword}
                    disabled={showLoading}
                    className={classes.submit} >
                    Sign In
                  </Button>
                  {showLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
                </div>
              </form>
            }
            <Grid container>
              <Grid item xs>
                <Link href="https://securestack.com/index.html#main-footer" variant="body2">
                  Support
                </Link>
              </Grid>
              <Grid item>
                <NavLink to="/SignUp">
                  <Typography color='primary' variant='body2'>Don't have an account? Sign Up</Typography>
                </NavLink>
              </Grid>
            </Grid>
            <Box mt={8}>
              <Copyright />
            </Box>
          </div>
        </MuiThemeProvider>
      </Container>
    </div>
    );
  }
}

Login.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(style)(Login);
