export default { mutation: `
mutation CreateSSHKey (
    $name: String,
    $credential: String,
    $region: [String]
){
    CreateSSHKey(
        SSHKey: {
            name: $name
            credential: $credential
            region: $region
        }
    ){
        result
        payload
    }
}
`
};
