export default { mutation: `
mutation ResetUserPassword(
	  $username: String ) {
      ResetUserPassword(resetPassword: {
      username: $username
  }) {
    result
    payload
  }
}`
};