export default { mutation: `
mutation AddWebsite($website: WebsiteInput) {
  AddWebsite(website: $website) {
	key
	name
	url
	vuln
  }
}`
};
