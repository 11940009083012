export default { query: `
  query GetDomains {
    GetDomains {
      key
      name
      url
      vuln
    }
  }`
};
