import React, { useEffect } from 'react';
import { Card, CardHeader, Tooltip, IconButton, CardContent, Typography, Input, FormHelperText, FormControl, Button,
CircularProgress, InputLabel, Select, MenuItem, Chip  } from '@material-ui/core/';
import { ArrowBack, Add } from '@material-ui/icons';
import { API, graphqlOperation } from "aws-amplify";
import style from './Style'
import Alert from '../Alert';
import ec2Instances from "../../content/ec2Instances";
import AWSRegions from "../../content/regions";
import AzureVMTypes from "../../content/azurevmtypes";
import AzureRegions from "../../content/azureregions";
import GetAccounts from '../../graphql/queries/GetAccounts';
import GetAzureAccounts from '../../graphql/queries/GetAzureAccounts';
import GetSecurityGroups from '../../graphql/queries/GetSecurityGroups';
import GetVPCs from '../../graphql/queries/GetVPCs';
import GetResourceGroups from '../../graphql/queries/GetResourceGroups';
import CreateTarget from '../../graphql/mutations/CreateTarget';
import EditTarget from '../../graphql/mutations/EditTarget';
import GetAzureVirtualNetworks from '../../graphql/queries/GetAzureVirtualNetworks';

const validator = {
  targetName: /^[a-zA-Z0-9.-_.@-]+$/
}

const emptyValue = {
  value: '',
  error: false,
  errorMsg: ''
}

export default function TargetForm(props) {
  const classes = style();
  const target = props.target;
  const isEdit = !props.isCreate;
  const cloud = props.isEdit ? target.cloud : 'aws';

  const [alert, updateAlert] = React.useState({
    alertMessage: '',
    alertSuccess: false,
    showAlert: false
  })

  const [showLoading, setShowLoading] = React.useState(false)

  //Global Form Vars
  const [targetName, setTargetName] = React.useState({ value: isEdit ? target.targetName : '', errorMsg: '', error: false })
  const [provider, setProvider] = React.useState(isEdit ? target.provider.toLowerCase() : cloud)
  const [instanceType, setInstanceType] = React.useState(isEdit ? target.instanceType : '')
  const [region, setRegion] = React.useState(isEdit ? target.region : '')
  const [credential, setCredential] = React.useState(isEdit ? target.credential : '')
  const [accounts, setAccounts] = React.useState({value:[], loading: true})
  const [tags, setTags] = React.useState(isEdit ? target.tags : [])
  const [tag, setTag] = React.useState({name:'', value: ''})
  //AWS Form Vars
  const [securityGroups, setSecurityGroups] = React.useState({value: [], loading: false})
  const [selectedSecurityGroups, setSelectedSecurityGroups] = React.useState(isEdit ? target.securityGroups : [])
  const [vpcs, setVpcs] = React.useState({value: [], loading: false})
  const [vpc, setVpc] = React.useState(isEdit ? target.vpc : '')
  const [subnets, setSubnets] = React.useState([])
  const [subnet, setSubnet] = React.useState(isEdit ? target.subnetId : '')
  //AZURE Form Vars
  const [resourceGroups, setResourceGroups] = React.useState({value: [], loading: false})
  const [resourceGroup, setResourceGroup] = React.useState(isEdit ? target.resourceGroup : '')
  const [virtualNetworks, setVirtualNetworks] = React.useState({value: [], loading: false})
  const [networkInterface, setNetworkInterface] = React.useState(isEdit ? target.networkInterface ? 'NA' : 'NA' : 'NA')

  useEffect( () => {
    if(provider === 'aws') 
      getAwsAccounts()
    else if(provider === 'azure')
      getAzureAccounts()
  },[])

  useEffect(() => {
    if(provider === 'aws' && region !== '' && credential !== '') {
      getSubnets()
    }
    else if(provider === 'azure' && credential !== '' && region !== '') {
      getResourceGroups()
    }
  }, [credential, region]);

  useEffect(() => {
    if(provider === 'aws' && region !== '' && credential !== '' && vpc !== '') {
      getSecurityGroups()
    }
  }, [vpc]);

  useEffect(() => {
    if(provider === 'azure' && credential !== '' && region !== '' && resourceGroup !== '') {
      getVirtualNetworks()
    }
  }, [resourceGroup]);

  const getAzureAccounts = async () => {
    await API.graphql(graphqlOperation(GetAzureAccounts.query))
    .then(result => { 
      setAccounts({value: result.data.GetAzureAccounts, loading: false})
    })
    .catch(e => {
      console.log(e)
      updateAlert({alertMessage: 'Error fetching your azure providers', alertSuccess: false, showAlert: true})
    })
  }

  const getAwsAccounts = async () => {
    await API.graphql(graphqlOperation(GetAccounts.query))
    .then(result => { 
      setAccounts({value: JSON.parse(result.data.GetAccounts.payload), loading: false})
    })
    .catch(e => {
      console.log(e)
      updateAlert({alertMessage: 'Error fetching your aws providers', alertSuccess: false, showAlert: true})
    })
  }

  const handleAlertClose = () => { updateAlert({ alertMessage: '', alertSuccess: false, showAlert: false }) }


  const clearForm = () => { 
    setRegion(''); 
    setCredential(''); 
    setInstanceType(''); 
    setTargetName(emptyValue);
    setSubnets([]);
    setSubnet('');
    setVpcs({value: [], loading: false});
    setSecurityGroups({value: [], loading: false});
    setSelectedSecurityGroups([]);
    setResourceGroups({value: [], loading: false})
    setResourceGroup('')
    setVirtualNetworks({value: [], loading: false})
    setNetworkInterface('')
    setTags([])
    setTag({name: '', value: ''})
  }

  const handleTargetName = (e) => {
    if(e.target.value.match(validator.targetName)) 
      setTargetName({ value: e.target.value, errorMsg: '', error: false })    
    else 
      setTargetName({ value: e.target.value, errorMsg: 'Invalid target name', error: true })
  }

  const handleProvider = (e) => { 
    if(e.target.value !== provider) {
      setProvider(e.target.value)
      setAccounts({value:[], loading: true})
      clearForm()  
      if(e.target.value === 'aws')
        getAwsAccounts()
      else if(e.target.value === 'azure')
        getAzureAccounts()
    }
  }

  const handleCredential = (e) => { 
    if(credential !== e.target.value) {
      setCredential(e.target.value)
      clearAWSNetworkValues()
    } 
  }

  const handleInstanceType = (e) => { setInstanceType(e.target.value) }

  const handleRegion = (e) => { 
    if(region !== e.target.value) {
      setRegion(e.target.value)
      clearAWSNetworkValues()
    } 
  }

  const clearAWSNetworkValues = () => {
    setSubnets([]);
    setSubnet('');
    setVpc('')
    setVpcs({value: [], loading: false});
    setSecurityGroups({value: [], loading: false});
    setSelectedSecurityGroups([]);  
  }

  const handleTagName = (e) => {setTag({name: e.target.value, value: tag.value})}

  const handleTagValue = (e) => {setTag({name: tag.name, value: e.target.value })}

  const handleTag = () => {
    if(tag.name !== '' && tag.value !== '') {
      setTags(tags.concat(tag))
      setTag({name: '', value: ''})
    }
  }

  const handleDeleteTag = (tag) => { setTags(tags.filter(item => item.name !== tag.name)) }

  const handleResourceGroup = (e) => {
    setResourceGroup(e.target.value)
  }

  const handleNetworkInterface = (e) => {
    setNetworkInterface(e.target.value)
  }

  const handleSecurityGroup = (event, info, value) => {
    var sg = info.props.value
    var index = selectedSecurityGroups.findIndex((emp) => emp.id === sg.id);
    if(index.toString() === '-1') { 
        setSelectedSecurityGroups(selectedSecurityGroups.concat(sg))        
    }
    else {
      setSelectedSecurityGroups(selectedSecurityGroups.filter(item => item.id !== sg.id))     
    }
  }

  const getSecurityGroups = () => {
    setSecurityGroups({value: [], loading: true})
    API.graphql(graphqlOperation(GetSecurityGroups.query, {accountName: credential, region: region, vpcId: vpc}))
    .then(result => { 
      setSecurityGroups({value: result.data.GetSecurityGroups, loading: false})
    })
    .catch(err => {
      console.log(err)
      updateAlert({alertMessage: 'Error fetching AWS security groups', alertSuccess: false, showAlert: true})
    })
  }

  //TODO - BUG - changing a VPC will remove all subnets
  const handleVpc = (e) => {
    var vpc = e.target.value
    setVpc(vpc)
    setSubnets(subnets.filter(item => item.vpcId === vpc))
    setSubnet('')
  }

  const handleSubnet = (e) => {setSubnet(e.target.value)}

  const getSubnets = () => {
    setVpcs({value: [], loading: true})
    API.graphql(graphqlOperation(GetVPCs.query, {accountName: credential, region: region}))
    .then(result => { 
      const vpcs = [...new Set(result.data.GetVPCs.map(v => v.vpcId))];
      setVpcs({value: vpcs, loading: false})
      setSubnets(result.data.GetVPCs)
    })
    .catch(err => {
      updateAlert({alertMessage: 'Error fetching AWS subnets', alertSuccess: false, showAlert: true})
    })
  }

  var awsTarget = {
    targetName: targetName.value,
    region: region,
    credential: credential,
    provider: provider,
    instanceType: instanceType,
    tags: tags,
    securityGroups: selectedSecurityGroups,
    subnetId: subnet,
    vpc: vpc
  }

  const submitAWSTarget = async () => {
    setShowLoading(true)
    await API.graphql(graphqlOperation(isEdit ? EditTarget.mutation : CreateTarget.mutation,  !isEdit ? {targetInput: awsTarget} : {targetInput: awsTarget, key: target.key}))
    .then(result => {
      setShowLoading(false)
      props.handleTarget(!isEdit ? result.data.CreateTarget : result.data.EditTarget)
      updateAlert({alertMessage: `New Target ${isEdit ? 'updated' : 'created'}`, alertSuccess: true, showAlert: true})
      if(!isEdit)
        clearForm()
    })
    .catch(err => {
      setShowLoading(false)
      console.log(err)
      updateAlert({alertMessage: 'Error creating Target', alertSuccess: false, showAlert: true})
    });
  }

  var azureTarget = {
    targetName: targetName.value,
    region: region,
    credential: credential,
    provider: provider,
    instanceType: instanceType,
    resourceGroup: resourceGroup,
    networkInterface: networkInterface,
    tags: tags
  }

  const submitAzureTarget = async () => {
    setShowLoading(true)
    await API.graphql(graphqlOperation(isEdit ? EditTarget.mutation : CreateTarget.mutation, !isEdit ? {targetInput: azureTarget} : {targetInput: azureTarget, key: target.key} ))
    .then(result => {
      setShowLoading(false)
      updateAlert({alertMessage: `New Target ${isEdit ? 'updated' : 'created'}`, alertSuccess: true, showAlert: true})
      props.handleTarget(!isEdit ? result.data.CreateTarget : result.data.EditTarget)
      if(!isEdit)
        clearForm()
    })
    .catch(err => {
      setShowLoading(false)
      console.log(err)
      updateAlert({alertMessage: 'Error creating Target', alertSuccess: false, showAlert: true})
    });  
  }

  const getResourceGroups = () => {
    setResourceGroups({value:[], loading:true})
    API.graphql(graphqlOperation(GetResourceGroups.query, {accountName: credential, region: region}))
    .then(result => { 
      setResourceGroups({value:result.data.GetResourceGroups, loading:false})
    })
    .catch(err => {
      updateAlert({alertMessage: 'Error fetching azure resource groups', alertSuccess: false, showAlert: true})
    })
  }

  const getVirtualNetworks = () => {
    setVirtualNetworks({value:[], loading:true})
    API.graphql(graphqlOperation(GetAzureVirtualNetworks.query, {accountName: credential, region: region, resourceGroup: resourceGroup}))
    .then(result => { 
      setVirtualNetworks({value:result.data.GetAzureVirtualNetworks, loading:false})
    })
    .catch(err => {
      console.log(err)
      updateAlert({alertMessage: 'Error fetching azure network interfaces', alertSuccess: false, showAlert: true})
    })
  }

  var formComplete =  
    !targetName.error && targetName.value !== '' &&
    region !== '' &&
    credential !== '' &&
    instanceType !== '' 

  return (
    <div>
      <Alert showAlert={alert.showAlert} alertSuccess={alert.alertSuccess} alertMessage={alert.alertMessage} handleAlertClose={handleAlertClose}/>      
      <Card>
        <CardHeader
          title={(!isEdit ? "Create" : "Update" ) + ' Target'}
          subheader={!isEdit ? "Create a new securestack target" : `Update a ${target.targetName} securestack target`}
          action= {
            <Tooltip title="Back to Targets list" aria-label="targets_list">     
                <IconButton aria-label="arrow_back" onClick={props.toggleForm}>
                  <ArrowBack />
                </IconButton> 
            </Tooltip>
          }
        />
        <CardContent>
          <form>
            <FormControl className={classes.formControl} required error={targetName.error}>
              <Typography color="textSecondary" className={classes.typo}>Target Name</Typography>
              <Input id="input-targetName" autoComplete="off" value={targetName.value} onChange={handleTargetName} disabled={isEdit} />
              <FormHelperText error={targetName.error}>{targetName.errorMsg}</FormHelperText>
            </FormControl> 
            <FormControl className={classes.formControl}>
              <InputLabel>Select Provider</InputLabel>
              <Select
                value={provider}
                onChange={handleProvider} >
                <MenuItem value='aws'>Amazon Web Services</MenuItem>
                <MenuItem value='azure' disabled>Microsoft Azure</MenuItem>
                <MenuItem value='gcp' disabled>Google GCP</MenuItem>
              </Select>
            </FormControl>
            <FormControl className={classes.formControl}>
              <InputLabel>Provider Credentials</InputLabel>
              <Select
                disabled={accounts.loading}
                value={credential}
                onChange={handleCredential} >
                {
                  accounts.value.map((item, index) =>{
                    return <MenuItem key={index} value={item.name}>{item.name}</MenuItem>
                  })
                }
              </Select>
              { accounts.loading && <CircularProgress size={24} className={classes.buttonProgress} />}
            </FormControl>
            <FormControl className={classes.formControl}>
              <InputLabel>Instance Type</InputLabel>
              <Select
                value={instanceType}
                onChange={handleInstanceType} >
                {
                  provider === 'aws' ? 
                    ec2Instances.map((item, index) =>{
                      return <MenuItem key={index} value={item}>{item}</MenuItem>
                    })
                  : provider === 'azure' ?
                    AzureVMTypes.map((item, index) =>{
                      return <MenuItem key={index} value={item}>{item}</MenuItem>
                    })
                  : null
                }
              </Select>
            </FormControl>
            <FormControl className={classes.formControl}>
              <InputLabel>Region</InputLabel>
              <Select
                value={region}
                onChange={handleRegion}>
                {
                  provider === 'aws' ? 
                    AWSRegions.map((item, index) =>{
                        return <MenuItem key={index} value={item}>{item}</MenuItem>
                    })
                  : provider === 'azure' ?
                    AzureRegions.map((item, index) =>{
                      return <MenuItem key={index} value={item}>{item}</MenuItem>
                    })
                  : null
                }
              </Select>
            </FormControl>  
            {
              provider === 'aws' ?
                <React.Fragment>  
                  <FormControl className={classes.formControl}>
                    <InputLabel>VPC</InputLabel>
                    <Select
                      disabled={vpcs.loading}
                      value={vpc}
                      onChange={handleVpc} >
                      {
                        vpcs.value.map((item, index) =>{
                          return <MenuItem key={index} value={item}>{item}</MenuItem>
                        })
                      }
                    </Select>
                    {vpcs.loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                  </FormControl>   
                  <FormControl className={classes.formControl}>
                    <InputLabel>Subnet Id</InputLabel>
                    <Select
                      value={subnet}
                      onChange={handleSubnet} >
                      {
                        subnets.map((item, index) =>{
                          return <Tooltip key={index} value={item.subnetId} title={item.cidrBlock}>     
                              <MenuItem key={index} value={item.subnetId}>{item.subnetId}</MenuItem>
                            </Tooltip>
                        })
                      }
                    </Select>
                    <FormHelperText error={true}>Select a VPC, Region and Credentials to view the list of available subnets</FormHelperText>
                  </FormControl>   
                  <FormControl className={classes.formControl}>
                    <InputLabel>Select Security Groups</InputLabel>
                    <Select
                      multiple
                      disabled={securityGroups.loading}
                      value={selectedSecurityGroups}
                      onChange={handleSecurityGroup}
                      input={<Input id="select-multiple-chip" />}
                      renderValue={selectedSecurityGroups => (
                        <div className={classes.chips}>
                          { selectedSecurityGroups.map( (value, index) => (
                            <Chip key={index} value={value} label={value.name} className={classes.chip} />
                          ))}
                        </div>
                      )}>
                      {
                        securityGroups.value.map((item, index) =>{
                            return <MenuItem key={index} value={item}>{item.name}</MenuItem>
                        })
                      }
                    </Select>
                    <FormHelperText error={true}>Select a VPC, Region and Credentials to view the list of available security groups</FormHelperText>
                    { securityGroups.loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                  </FormControl>  
                </React.Fragment>
              : provider === 'azure' ?
                <React.Fragment>
                  <FormControl className={classes.formControl}>
                    <InputLabel>Resource Groups</InputLabel>
                    <Select
                      disabled={resourceGroups.loading}
                      value={resourceGroup}
                      onChange={handleResourceGroup} >
                      {
                        resourceGroups.value.map((item, index) =>{
                          return <MenuItem key={index} value={item.name}>{item.name}</MenuItem>
                        })
                      }
                    </Select>
                    {resourceGroups.loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                  </FormControl>
                  <FormControl className={classes.formControl}>
                    <InputLabel>Virtual Network</InputLabel>
                    <Select
                      disabled={virtualNetworks.loading}
                      value={networkInterface}
                      onChange={handleNetworkInterface} >
                      {
                        virtualNetworks.value.map((item, index) =>{
                          return <MenuItem key={index} value={item.name}>{item.name}</MenuItem>
                        })
                      }
                    </Select>
                    {virtualNetworks.loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                  </FormControl>
                </React.Fragment>
              : null
            }
            <FormControl className={classes.formControl}>
              <Typography color="textSecondary" className={classes.typo}>Tags</Typography>
              <div>
                  <Input id="input-TagName"  placeholder="Tag Name" value={tag.name} className={classes.tagInput} onChange={handleTagName} />
                  <Input id="input-TagValue" placeholder="Tag Value" value={tag.value} className={classes.tagInput} onChange={handleTagValue} />
                  <Button variant="outlined" color='primary' size="small" onClick={handleTag}>
                  <Add />
                      Add Tag
                  </Button>
              </div>
              <div className={classes.taglist}>
                {
                  tags.map(((tag, index) => {
                      return <Chip
                        key={index}
                        color="primary"
                        label={tag.name+": " +tag.value}
                        onDelete={() => handleDeleteTag(tag)}
                        className={classes.chip}
                        variant="outlined"
                      />
                  }))
                }
              </div>
            </FormControl>
            <div className={classes.wrapper}>
              <Button
                variant="outlined"
                color="primary"
                className={classes.button}
                disabled={showLoading || !formComplete} 
                onClick={ () =>
                    provider === 'azure' ?
                      submitAzureTarget()
                    : provider === 'aws' ? 
                      submitAWSTarget()
                    : null
                }>
                {isEdit ? "Update" : "Create"} Target
              </Button>
              { showLoading && <CircularProgress size={24} className={classes.buttonProgress} /> }
            </div> 
          </form>
        </CardContent>
      </Card>
    </div>
  );
}

