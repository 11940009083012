export default { mutation: `
  mutation SetUserPassword(
    $username: String
    $password: String 
  ) 
  {
    SetUserPassword(
      setPassword: {
        username: $username
        password: $password
      }
    ) 
  {
    result
    payload
  }
}`
};