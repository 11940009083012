import React, {useEffect} from 'react';
import { useTheme } from '@material-ui/core/styles';
import { AppBar, Paper, Tabs, Tab, CircularProgress } from '@material-ui/core/';
import { API, graphqlOperation } from "aws-amplify";
import UpdateUserSettings from '../../graphql/mutations/UpdateUserSettings';
import style from './Style'
import ProvidersList from '../../components/ProvidersList'
import GetAccounts from '../../graphql/queries/GetAccounts';
import GetAzureAccounts from '../../graphql/queries/GetAzureAccounts';
import DeleteAmazonAccount from '../../graphql/mutations/DeleteAmazonAccount';
import DeleteAzureAccount from '../../graphql/mutations/DeleteAzureAccount';
import Alert from '../../components/Alert';
import ConfirmDeleteDialog from '../../components/ConfirmDeleteDialog';
import TabPanel from '../../components/TabPanel'

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

function not(a, b) { return a.filter(value => value.name !== b) }

export default function Providers(props) {
  const classes = style();
  const theme = useTheme();
  const [provider, setProvider] = React.useState(props.userSettings.provider === 'aws' ? 0 : props.userSettings.provider === 'azure' ? 1 : 0);
  const [awsProviders, setAWSProviders] = React.useState([]);
  const [azureProviders, setAzureProviders] = React.useState([]);
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [accountName, setAccountName] = React.useState('');
  const [DeleteLoading, setDeleteLoading] = React.useState(false);
  const [loadingAzure, setloadingAzure] = React.useState(true);
  const [loadingAws, setloadingAws] = React.useState(true);

  const [alert, updateAlert] = React.useState({
    alertMessage: '',
    alertSuccess: false,
    showAlert: false
  })

  useEffect(() => {
    //AWS
    API.graphql(graphqlOperation(GetAccounts.query))
    .then(result => { 
      setAWSProviders(JSON.parse(result.data.GetAccounts.payload))
      setloadingAws(false)
    })
    //Azure
    API.graphql(graphqlOperation(GetAzureAccounts.query))
    .then(result => { 
      setAzureProviders(result.data.GetAzureAccounts)
      setloadingAzure(false)
    })
  }, []);

  const handleAlertClose = () => { updateAlert({ alertMessage: '', alertSuccess: false, showAlert: false }) }

  const handleChange = (event, newValue) => {
    setProvider(newValue);
    API.graphql(graphqlOperation(UpdateUserSettings.mutation, {provider: newValue === 0 ? 'aws' : newValue === 1 ? 'azure' : 'aws'}))
  };

  const handleDialogOpen = (accountName) => {
    setAccountName(accountName)
    setDialogOpen(true)
  }

  const handleDialogClose = () => {
    setDialogOpen(false)
    setAccountName('')
  }

  const deleteAzureAccount = () => {
    setDeleteLoading(true)
    API.graphql(graphqlOperation(DeleteAzureAccount.mutation, {name: accountName}))
    .then(result => { 
      setDeleteLoading(false)
      setAzureProviders(not(azureProviders, accountName));
      updateAlert({ alertMessage: `The Azure account ${accountName} has been removed`, alertSuccess: true, showAlert: true })
      handleDialogClose()  
    })
    .catch(err => {
      setDeleteLoading(false)
      updateAlert({ alertMessage: `Error removing the Azure account ${accountName}`, alertSuccess: false, showAlert: true })
      console.log(err)
    })
  }

  const deleteAWSAccount = () => {
    setDeleteLoading(true)
    API.graphql(graphqlOperation(DeleteAmazonAccount.mutation, {name: accountName}))
    .then(result => { 
      setDeleteLoading(false)
      setAWSProviders(not(awsProviders, accountName));
      updateAlert({ alertMessage: `The AWS account ${accountName} has been removed`, alertSuccess: true, showAlert: true })
      handleDialogClose()  
    })
    .catch(err => {
      setDeleteLoading(false)
      updateAlert({ alertMessage: `Error removing the AWS account ${accountName}`, alertSuccess: false, showAlert: true })
      console.log(err)
    })
  }

  const awsRows = [
    { id: 'name', numeric: false, disablePadding: false, label: 'Account Name' },
    { id: 'accountNumber', numeric: false, disablePadding: false, label: 'Account Number' },
    { id: 'clientId', numeric: false, disablePadding: false, label: 'Client Id' },
    { id: 'personal', numeric: false, disablePadding: false, label: 'Personal' },
    { id: 'actions', numeric: false, disablePadding: false, label: 'Actions' },
  ];

  const azureRows = [
    { id: 'name', numeric: false, disablePadding: false, label: 'Account Name' },
    { id: 'clientId', numeric: false, disablePadding: false, label: 'Client Id' },
    { id: 'tenantId', numeric: false, disablePadding: false, label: 'Tenant Id' },
    { id: 'subscriptionId', numeric: false, disablePadding: false, label: 'Subscription Id' },
    { id: 'personal', numeric: false, disablePadding: false, label: 'Personal' },
    { id: 'actions', numeric: false, disablePadding: false, label: 'Actions' },
  ]

  return (
    <Paper >
      <Alert showAlert={alert.showAlert} alertSuccess={alert.alertSuccess} alertMessage={alert.alertMessage} handleAlertClose={handleAlertClose}/>
      <ConfirmDeleteDialog name={accountName} type='Provider' open={dialogOpen} handleDialogClose={handleDialogClose} loading={DeleteLoading}
      confirmDelete={
        provider === 0 ? 
          deleteAWSAccount :
        provider === 1 ?
          deleteAzureAccount :
        deleteAWSAccount
      }/>
      <AppBar position="static" color="default">
        <Tabs
          value={provider}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth">
          <Tab label="AWS" {...a11yProps(0)} />
          <Tab label="Azure" {...a11yProps(1)}/>
          <Tab label="GCP" {...a11yProps(2)} disabled />
          <Tab label="VMware" {...a11yProps(3)} disabled />
        </Tabs>
      </AppBar>
        <TabPanel value={provider} index={0} dir={theme.direction}>
          { loadingAws === true ?
              <div className={classes.progressWrapper} >
                <CircularProgress size={24} className={classes.providersProgress} /> 
              </div>
            :
              <ProvidersList rows={awsRows} cloud='aws' data={awsProviders} deleteAccount={handleDialogOpen} />
          }
        </TabPanel>
        <TabPanel value={provider} index={1} dir={theme.direction}>
          {
            loadingAzure === true ? 
              <div className={classes.progressWrapper} >  
                <CircularProgress size={24} className={classes.providersProgress} /> 
              </div>
            :
              <ProvidersList rows={azureRows} cloud='azure' data={azureProviders} deleteAccount={handleDialogOpen} />
          }
        </TabPanel>
        <TabPanel value={provider} index={2} dir={theme.direction}>
          GCP
        </TabPanel>
        <TabPanel value={provider} index={3} dir={theme.direction}>
          VMware
        </TabPanel>
    </Paper>
  );
}
