import React, { Component } from 'react';
import "tabler-react/dist/Tabler.css";
import { DefaultErrorPage } from "tabler-react";

class ErrorDefault extends Component {
  render() {
    return (
      <DefaultErrorPage/>
    );
  }
}

export default ErrorDefault;
