import React from 'react';
import {Input, InputLabel, MenuItem, FormControl, Select, CardHeader, IconButton, Card, Typography, CardContent,
    FormHelperText, CircularProgress, Button , Snackbar, FormControlLabel, Checkbox } from '@material-ui/core';
import PropTypes from 'prop-types';
import useStyles from './Style'
import { withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import { API, graphqlOperation  } from "aws-amplify";
import GetProducts from '../../graphql/queries/GetProducts';
import GetSSHKeys from '../../graphql/queries/GetSSHKeys';
import DeployEc2 from '../../graphql/mutations/DeployEc2';
import GetCustomAMIs from '../../graphql/queries/GetCustomAMIs';
import base_amis from '../../content/baseAMIs';

var mixpanel = require('mixpanel-browser');

class Deploy extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showLoading: false,
            loadingSSH: false,
            keys: [],
            products: [],
            product: '',
            sshKey: '',
            hostName: '',
            enablePublicIP: false,
            disablePublicIP: true,
            baseAMIs: base_amis,
            amis: []
        };
    } 

    async componentDidMount() {
        //Get Products
        await API.graphql(graphqlOperation(GetProducts.query))
        .then(result => {
            if(result) {
                this.setState({products: result.data.GetProducts})
            }
        })
        .catch(err => {
            console.log(err)
            this.setState({ showAlert: true, alertMessage: 'Error Fetching Products', alertSuccess: false})
        });        
    }

    setSSHKeys = (product) => {
        this.setState({loadingSSH: true})
        let params = {
            accountName: product.target.credential,
        }
        API.graphql(graphqlOperation(GetSSHKeys.query, params)).then(result => {
            if(result.data.GetSSHKeys) {
                var keys = JSON.parse(result.data.GetSSHKeys.payload).filter(k => k.region === product.target.region)
                this.setState({keys: keys, loadingSSH: false})
            }
        })
    }

    getCustomAMIs = (target) => {
        let params = {
            accountName: target.credential,
            region: target.region
        }
        API.graphql(graphqlOperation(GetCustomAMIs.query, params))
        .then(result => {
            this.setState({amis: result.data.GetCustomAMIs, loadingAMIs: false})
        })
        .catch(err => {
            this.setState({ showAlert: true, alertMessage: err.errors[0].message, alertSuccess: false, loadingAMIs: false})
        })
    }

    handleHostName = (e) => { this.setState({hostName: e.target.value}) } 

    handleAlertClose = () => { this.setState({showAlert: false, alertMessage: '', instance: {}}) }

    handleProduct = (e) => {
        var product = this.state.products[e.target.value]
        this.setState({product: e.target.value, sshKey: '', keys: [], selectedProduct: product}, () => {
            this.setSSHKeys(product)
            this.getCustomAMIs(product.target)
        })
        //Set the public IP checkbox
        if(product.profile.enablePublicIP === true) {
            this.setState({enablePublicIP: true, disablePublicIP: false})
        }
        else {
            this.setState({enablePublicIP: false, disablePublicIP: true})
        }
    }

    handleChangeCheckBox = (e) => { this.setState({enablePublicIP: !this.state.enablePublicIP}) }

    handleSSHKey = (e) => { this.setState({sshKey: e.target.value}) }

    submitForm = async () => {
        this.setState({showLoading: true})
        if(this.state.hostName && this.state.sshKey && this.state.selectedProduct) {
            delete this.state.selectedProduct.key; 
            var deployEc2Input = {
                hostName: this.state.hostName,
                product: this.state.selectedProduct,
                sshKey: this.state.sshKey,
                enablePublicIP: this.state.enablePublicIP,
                customAMI: this.state.baseAMIs.find(i => i.imageAMI === this.state.selectedProduct.image) ? false : true,
                OS: this.state.baseAMIs.find(i => i.imageAMI === this.state.selectedProduct.image) ? this.state.baseAMIs.find(i => i.imageAMI === this.state.selectedProduct.image).OS : this.state.amis.find(i => i.imageAMI === this.state.selectedProduct.image).os
            }  
            await API.graphql(graphqlOperation(DeployEc2.mutation, deployEc2Input))
            .then(result => {
                mixpanel.track("Deploy Instance Success");
                this.setState({ showAlert: true, instance: result.data.DeployEc2, alertMessage: 'New Instance Successfully Created:', 
                    alertSuccess: true, hostName: '', sshKey: '', selectedProduct: '', 
                    product: '', showLoading: false, enablePublicIP: false, disablePublicIP: true})
            })
            .catch(err => {
                console.log(err)
                mixpanel.track("Deploy Instance Failed");
                this.setState({ showAlert: true, alertMessage: err.errors[0].message, 
                    alertSuccess: false, hostName: '', sshKey: '', selectedProduct: '', product: '', showLoading: false,  enablePublicIP: false, disablePublicIP: true})
            })
        }
        else {
            this.setState({ showAlert: true, alertMessage: 'Error Missing Parameters', alertSuccess: false, showLoading: false})
        }
    }
   
    render() {
        const { classes } = this.props;
        const { showLoading, loadingSSH, instance, hostName, hostNameError, sshKey, keys, hostNameErrMsg, enablePublicIP,
            showAlert, alertSuccess, alertMessage, loadingProducts, product, products, disablePublicIP } = this.state;
    return ( <div>
        <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={showAlert}
            onClose={this.handleAlertClose}
            ContentProps={{
                'aria-describedby': 'message-id',
                classes: {
                    root: alertSuccess ? classes.notificationSuccess : classes.notificationError
                }
            }}
            message={
                alertSuccess ?
                    <span id="message-id">
                        <b>{alertMessage}</b>
                        <br/><br/>
                        <p>Instance Id: {instance.instanceId}</p>
                        <p>Instance Type: {instance.instanceType}</p>
                        <p>Key Name: {instance.keyName}</p>
                        <p>Private Dns Name: {instance.privateDnsName}</p>
                        <p>Private Ip Address: {instance.privateIpAddress}</p>
                        <p>Subnet Id: {instance.subnetId}</p>
                        <p>VPC Id: {instance.vpcId}</p>
                    </span>
                : <span id="message-id"><b>{alertMessage}</b></span>}
            action={[
                <IconButton
                    key="close"
                    aria-label="Close"
                    color="inherit"
                    className={classes.close}
                    onClick={this.handleAlertClose}>
                    <CloseIcon />
                </IconButton>,
            ]}
        />
        <Card>
            <CardHeader
                title={'Deploy CloudBuilder Product: '}
                subheader={'Deploy an SecureStack image into your cloud'} />
            <CardContent>
                <FormControl className={classes.formControl} required error={hostNameError}>
                    <Typography color="textSecondary" className={classes.typo}>Host Name</Typography>
                    <Input id="input-hostName" value={hostName} onChange={this.handleHostName} />
                    <FormHelperText error={hostNameError}>{hostNameErrMsg}</FormHelperText>
                </FormControl>    
                <FormControl className={classes.formControl}>
                    <InputLabel>Select Product</InputLabel>
                    <Select
                        disabled={loadingProducts}
                        value={product}
                        onChange={this.handleProduct} >
                        {
                            products.map((item, index) =>{
                                return <MenuItem key={index} value={index}>{item.productName}</MenuItem>
                            })
                        }
                    </Select>
                    {loadingProducts && <CircularProgress size={24} className={classes.buttonProgress} />}
                </FormControl>
                <FormControl className={classes.formControl}>
                    <InputLabel>Select SSH Key</InputLabel>
                    <Select
                        disabled={loadingSSH}
                        value={sshKey}
                        onChange={this.handleSSHKey} >
                        {
                            keys.map((item, index) =>{
                                return <MenuItem key={index} value={item.name}>{item.name}</MenuItem>
                            })
                        }
                    </Select>
                    {loadingSSH && <CircularProgress size={24} className={classes.buttonProgress} />}
                </FormControl> 
                { !disablePublicIP &&
                    <FormControl className={classes.formControl}>
                        <FormControlLabel
                            control={
                                <Checkbox 
                                    checked={enablePublicIP} 
                                    onChange={this.handleChangeCheckBox} 
                                    value={enablePublicIP}  />
                                }
                                    label="Enable Public IP"
                            />  
                    </FormControl>
                }
                <div className={classes.wrapper}>
                    <Button
                        variant="outlined"
                        color="primary"
                        className={classes.button}
                        disabled={showLoading}
                        onClick={this.submitForm} >
                        Deploy 
                    </Button>
                    {showLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
                </div>
            </CardContent> 
        </Card>
    </div>)
    }
}


Deploy.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(useStyles)(Deploy);
