import React from 'react';
import Template from '../components/Template';
import { Page } from "tabler-react";

export default function Loading (props) {
    return (
        <Template>
            <Page.Content title="Loading..."/>
        </Template>
    );
};

