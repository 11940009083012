export default { query: `
  query GetAzureAccounts {
    GetAzureAccounts {
      name
      clientId 
      tenantId
      subscriptionId
      personal
    }
  }`
};