import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Dialog, DialogActions, DialogContent, Button, FormControl, Input, FormHelperText, DialogContentText, DialogTitle,
  Typography, CircularProgress } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    formControl: {
        width: '100%'
    },
    progress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12
    },
    wrapper: {
        position: 'relative'
    }
}));


export default function ConfirmDeleteDialog(props) {
    const classes = useStyles();

    var dialogOpen = props.open 

    const [form, setForm ] = React.useState({
        name: '',
        nameErrorMsg: '',
        error: false
    })

    const handleName = (e) => {
        if(e.target.value !== props.name) {
            setForm({
                name: e.target.value,
                nameErrorMsg: `${props.type} name does not match`,
                error: true
            })
        }
        else {
            setForm({
                name: e.target.value,
                nameErrorMsg: '',
                error: false
            })
        }
    }

    return (
        <Dialog open={dialogOpen} onClose={props.handleDialogClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Delete {props.type}: {props.name}</DialogTitle>
            <form>
                <DialogContent>
                    <DialogContentText>
                        To Delete this {props.type} please enter it's name below
                    </DialogContentText> 
                    <FormControl className={classes.formControl} required error={form.error}>
                        <Typography>{props.type} Name</Typography>
                        <Input id="input-name" value={form.name} onChange={handleName} />
                        <FormHelperText error={form.error}>{form.nameErrorMsg}</FormHelperText>
                    </FormControl> 
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.handleDialogClose} color="primary">
                        Cancel
                    </Button>
                    <div className={classes.wrapper}>
                        <Button onClick={props.confirmDelete} color="primary" disabled={form.error || form.name === ''}>
                            Delete
                        </Button>
                        { props.loading &&
                            <CircularProgress size={24} className={classes.progress} />
                        }
                    </div>
                </DialogActions>
            </form>
        </Dialog>
    )
}