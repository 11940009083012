const useStyles = theme => ({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    input: {
        width: '80%'
    },
    grid: {
        margin: 'auto',
    },
    paper: {
        width: 400,
        height: 330,
        overflow: 'auto',
    },
    gridButton: {
        margin: theme.spacing(0.5, 0),
    },
    gridHead: {
        marginBottom: 10
    },
    formControl: {
        width: '90%',
        margin: 20 
    },
    button: {
        width: '100%',
        marginTop: 20,
        marginBottom: 20
    },
    buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    icon: {
        'vertical-align': 'middle',
        marginRight: theme.spacing(0.5),
        width: 20,
        height: 20,
    },
    bread: {
        marginBottom: 20,
        padding: 15
    }
});

export default useStyles;