export default { mutation: 
  `mutation CreateBlueprint(
    $filename: String, 
    $file: String,
    $createdBy: String,
    $reviewedBy: String,
    $reviewed: Boolean,
    $applicationServers: AWSJSON,
    $operatingSystems: AWSJSON,
    $cyberRange: AWSJSON ) {
    CreateBlueprint(blueprint: {
      filename: $filename, 
      createdBy: $createdBy, 
      reviewedBy: $reviewedBy,
      reviewed: $reviewed,
      applicationServers: $applicationServers,
      cyberRange: $cyberRange
      operatingSystems: $operatingSystems
      },
      file: $file
      ) {
      result
      payload
    }
  }`
};