const newsList = [
   {
        title: "Largest data breach ever.  1.2 billion records leaked",
        url: "https://www.dataviper.io/blog/2019/pdl-data-exposure-billion-people/"
   },
   {
        title: "ANU detailed account of latest data breach",
        url: "https://www.anu.edu.au/news/all-news/anu-releases-detailed-account-of-data-breach"
   },
   {
        title: "SecureStack pitch at RSA Singapore",
        url: "https://www.youtube.com/watch?v=FDdeRA_nrFU"
   },
   {
        title: "Australian Encryption law gets re-examined",
        url: "https://www.innovationaus.com/2019/07/Home-Affairs-to-clarify-encryption-laws"
   },
   {
        title: "Capital One hack leverages AWS IAM complexity",
        url: "https://krebsonsecurity.com/tag/capital-one-breach/"
   },
   {
        title: "SecureStack hits the global stage",
        url: "https://www.innovationaus.com/2019/07/Cyber-startups-hit-the-world-stage"
   },
   {
        title: "SecureStack finalist for Australian Technology Competition 2019",
        url: "https://docs.wixstatic.com/ugd/cf5777_a936e7cb8cb04d8ebd9f0da8cc542fbd.pdf"
    },
    {
        title: "We're pitching on stage at the RSA Launchpad",
        url: "https://www.rsaconference.com/events/ap19/agenda/rsac-launch-pad"
   },
   {
        title: "SecureStack exhibiting at RSA APJ Singapore 2019<",
        url: "https://www.rsaconference.com/events/ap19/expo-sponsors/early-stage-expo"
    },
    {
        title: "Demo Day pitch Sydney May 7th, 2019",
        url: "https://www.youtube.com/watch?v=06x7e7RF7xU"
   },
   {
        title: "North Korean malware \"Electricfish\" alert",
        url: "https://www.cso.com.au/article/661466/fbi-outs-north-korean-hidden-cobra-malware-electricfish/"
    },
    {
        title: "SecureStack one of the hottest startups at RSA",
        url: "https://www.csoonline.com/article/3342162/12-of-the-hottest-startups-at-the-rsa-conference-2019.html"
    },
    {
        title: "MySecurity podcast with SecureStack",
        url: "https://www.blubrry.com/mysecurity/42356314/episode-140-devops-and-the-journey-to-devsecops-with-ozcyberinusa2019-interview-with-paul-mccarty-of-securestack/"
    },
    {
        title: "LandMark White predicts $6m loss from data breach",
        url: "https://www.smh.com.au/business/companies/landmark-white-predicts-up-to-6m-loss-from-data-hack-20190506-p51kkd.html"
    }
];

export default newsList;
