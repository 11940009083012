import React, {Component} from 'react';
import style from './Style'
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Checkbox, List, ListItem, ListItemText, Collapse, ListItemIcon } from '@material-ui/core';
import { ExpandLess, ExpandMore} from '@material-ui/icons';

class ReviewProfile extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    } 

    handleSecurityGroups = (e) => {
        this.setState({securityGroupsOpen: !this.state.securityGroupsOpen})
    }

    handleClustering = (e) => {
        this.setState({clusteringOpen: !this.state.clusteringOpen})
    }

    handleApplicationServers = (e) => {
        this.setState({applicationServersOpen: !this.state.applicationServersOpen})
    }

    handleCloudSecurity = (e) => {
        this.setState({cloudSecurityOpen: !this.state.cloudSecurityOpen})
    }

    handleHostSecurity =(e) => {
        this.setState({hostSecurityOpen: !this.state.hostSecurityOpen})
    }

    handleFirewallRules = () => {
        this.setState({firewallOpen: !this.state.firewallOpen})
    }

    handleIAM = (e) => {
        this.setState({iamOpen: !this.state.iamOpen})
    }

    render() {
        const {selectedProfile, dense} = this.props
        const { classes } = this.props;
        const {hostSecurityOpen, cloudSecurityOpen, securityGroupsOpen, iamOpen} = this.state;
        return (
            <div className={classes.list}>
                <List dense={dense}>
                    <ListItem>
                        <ListItemText
                        primary="Profile Name:"
                        secondary={selectedProfile ? selectedProfile.profileName : ''}
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemText
                        primary="SIIP Server IP:"
                        secondary={selectedProfile ? selectedProfile.siipServer.ipAddress : ''}
                        />
                    </ListItem>
                    <ListItem onClick={this.handleHostSecurity}>
                        <ListItemText
                        primary="Host Security Controls:"
                        />
                        {hostSecurityOpen ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                            <Collapse in={hostSecurityOpen} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding dense={dense}>
                                    <ListItem
                                        className={classes.nestedListItem} >
                                        <ListItemText
                                            primary="Anti-Virus:" />
                                            <ListItemIcon>
                                                <Checkbox
                                                    checked={selectedProfile ? selectedProfile.antiVirus : false}
                                                    disableRipple
                                                />
                                            </ListItemIcon>
                                    </ListItem>
                                    <ListItem
                                        className={classes.nestedListItem} >
                                        <ListItemText
                                            primary="Application Monitoring:" />
                                            <ListItemIcon>
                                                <Checkbox
                                                    checked={selectedProfile ? selectedProfile.waf : false}
                                                    disableRipple
                                                />
                                            </ListItemIcon>
                                    </ListItem>
                                    <ListItem
                                        className={classes.nestedListItem} >
                                        <ListItemText
                                            primary="System Monitoring:" />
                                            <ListItemIcon>
                                                <Checkbox
                                                    checked={selectedProfile ? selectedProfile.systemMonitoring : false}
                                                    disableRipple
                                                />
                                            </ListItemIcon>
                                    </ListItem>
                                    <ListItem
                                        className={classes.nestedListItem} >
                                        <ListItemText
                                            primary="Malware Protection:" />
                                            <ListItemIcon>
                                                <Checkbox
                                                    checked={selectedProfile ? selectedProfile.malwareProtection : false}
                                                    disableRipple
                                                />
                                            </ListItemIcon>
                                    </ListItem>
                                    <ListItem
                                        className={classes.nestedListItem} >
                                        <ListItemText
                                            primary="Intrusion Detection:" />
                                            <ListItemIcon>
                                                <Checkbox
                                                    checked={selectedProfile ? selectedProfile.intrusionPrevention : false}
                                                    disableRipple
                                                />
                                            </ListItemIcon>
                                    </ListItem>

                                </List>
                            </Collapse>
                        <ListItem onClick={this.handleCloudSecurity}>
                            <ListItemText
                            primary="Cloud Security:"
                            />
                            {cloudSecurityOpen ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>
                            <Collapse in={cloudSecurityOpen} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding dense={dense}>
                                    <ListItem
                                        className={classes.nestedListItem} >
                                        <ListItemText
                                            primary="Multi-Factor Authentication:" />
                                            <ListItemIcon>
                                                <Checkbox
                                                    checked={selectedProfile ? selectedProfile.mfa : false}
                                                    disableRipple
                                                />
                                            </ListItemIcon>
                                    </ListItem>
                                    <ListItem
                                        className={classes.nestedListItem} >
                                        <ListItemText
                                            primary="Termination Protection:" />
                                            <ListItemIcon>
                                                <Checkbox
                                                    checked={selectedProfile ? selectedProfile.terminationProtection : false}
                                                    disableRipple
                                                />
                                            </ListItemIcon>
                                    </ListItem>
                                    <ListItem
                                        className={classes.nestedListItem} >
                                        <ListItemText
                                            primary="Encrypt Boot Disk:" />
                                            <ListItemIcon>
                                                <Checkbox
                                                    checked={selectedProfile ? selectedProfile.encryptBootdisk : false}
                                                    disableRipple
                                                />
                                            </ListItemIcon>
                                    </ListItem>
                                    <ListItem
                                        className={classes.nestedListItem} >
                                        <ListItemText
                                            primary="Enable Public IP:" />
                                            <ListItemIcon>
                                                <Checkbox
                                                    checked={selectedProfile ? selectedProfile.enablePublicIP : false}
                                                    disableRipple
                                                />
                                            </ListItemIcon>
                                    </ListItem>
                                </List>
                            </Collapse>
                            { selectedProfile.automaticScanning &&
                                <ListItem >
                                    <ListItemText
                                    primary="Vulnerability Scanning:"
                                    secondary={selectedProfile ? selectedProfile.automaticScanningSchedule : 'OFF'}
                                    />
                                    </ListItem>
                            }
                            { selectedProfile.automaticPatching &&
                                <ListItem >
                                    <ListItemText
                                    primary="Automatic Patching:"
                                    secondary={selectedProfile ? selectedProfile.automaticPatchingSchedule : 'OFF'}
                                    />
                                    </ListItem>
                            }
                            { selectedProfile.automaticBackups &&
                                <ListItem >
                                    <ListItemText
                                    primary="Automatic Backups:"
                                    secondary={selectedProfile ? selectedProfile.automaticBackupsSchedule : 'OFF'}
                                    />
                                    </ListItem>
                            }
                        { /*
                        <ListItem onClick={this.handleFirewallRules}>
                            <ListItemText
                            primary="Firewall Rules:"
                            />
                            {firewallOpen ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>
                        <Collapse in={firewallOpen} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding dense={dense}>
                                {   selectedProfile ? 
                                        selectedProfile.firewallRules.map((item) =>{
                                            var firewall = JSON.parse(item);
                                            return (<ListItem className={classes.nestedListItem}>
                                            <ListItemText
                                                primary={firewall.firewallName}
                                                secondary={`Source: ${firewall.source} - Desination: ${firewall.destination} - Protocol: ${firewall.protocol} - Port: ${firewall.port} `}
                                            />
                                            </ListItem>)
                                        })
                                    : <div/>
                                }
                            </List>
                        </Collapse> 
                        */ }  
                        <ListItem onClick={this.handleSecurityGroups}>
                            <ListItemText
                            primary="Security Groups:"
                            />
                            {securityGroupsOpen ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>
                            <Collapse in={securityGroupsOpen} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding dense={dense}>
                                    {   selectedProfile ? 
                                            selectedProfile.securityGroups.map((item, index) =>{
                                                return (<ListItem className={classes.nestedListItem}>
                                                <ListItemText
                                                    primary={item}
                                                />
                                                </ListItem>)
                                            })
                                        : <div/>
                                    }
                                </List>
                            </Collapse>  
                        <ListItem onClick={this.handleIAM}>
                            <ListItemText
                            primary="IAM Roles:"
                            />
                            {iamOpen ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>
                            <Collapse in={iamOpen} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding dense={dense}>
                                    {   selectedProfile ? 
                                            selectedProfile.iamRoles.map((item, index) =>{
                                                return (<ListItem className={classes.nestedListItem}>
                                                <ListItemText
                                                    primary={item}
                                                />
                                                </ListItem>)
                                            })
                                        : <div/>
                                    }
                                </List>
                            </Collapse>  
                    </List>
                </div>)
    }
}

ReviewProfile.propTypes = {
    classes: PropTypes.object.isRequired
};
  
export default withStyles(style)(ReviewProfile);