export default { mutation: 
  `mutation EditBlueprint(
    $key: String,
    $filename: String, 
    $file: String,
    $createdBy: String,
    $reviewedBy: String,
    $reviewed: Boolean,
    $operatingSystems: AWSJSON,
    $applicationServers: AWSJSON,
    $cyberRange: AWSJSON ) {
    EditBlueprint(
      key: $key,
      blueprint: {
        filename: $filename, 
        createdBy: $createdBy, 
        reviewedBy: $reviewedBy,
        reviewed: $reviewed,
        applicationServers: $applicationServers,
        cyberRange: $cyberRange
        operatingSystems: $operatingSystems
      },
      file: $file
      ) {
      result
      payload
    }
  }`
};