import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
    // For Underline Color After Click
    palette: {
      primary: { main: '#0B96D8'},
      secondary: {main: '#0B96D8'}
    },
    // For Underline Hover Color
    overrides: {
      MuiInput: {
        underline: {
          '&:hover:not($disabled):not($error):not($focused):before': {
            borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
          },
        },
      }
    }
});

export default theme;