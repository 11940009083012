import React from 'react';
import "tabler-react/dist/Tabler.css";
import { Button } from "tabler-react";

export default class WelcomeMessage extends React.Component {
    render() {
    const { username, companyId  } = this.props;
    return ( <div>
        <div style={{ display: 'flex',
            alignItems: 'center',
            justifyContent: 'center' }}>
            <img src={'/images/logo-648x675.png'} alt="logo" style={{height: '20%', width: '20%'}}/>
        </div>
        <hr style={{backgroundColor: '#2393D2'}}/>
        <div style={{margin: 20}}>
            <b>Welcome to SecureStack!</b> If you have any questions about CloudBuilder please email us at info@securestack.com.  If you have any support issues, feel free to create a ticket at https://support.securestack.com or email us at support@securestack.com.
            <div style={{marginTop: 10}}>
                <p>Your Username is: <b>{username}</b></p>
                <p>Company ID: <b>{companyId}</b></p>
                <p><a target="_blank" href="./Home">Get Started</a></p>
            </div>
            <div style={{ display: 'flex',
                alignItems: 'center',
                justifyContent: 'center' }}>
                <Button pill color='primary'  RootComponent="a" target="_blank" href="./Home">
                    Get Started
                </Button>
            </div>
        </div>
    </div>)
    }
}
