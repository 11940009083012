const useStyles = theme => ({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    formControl: {
        width: '100%',
        marginTop: 20,
        marginBottom: 20
    },
    button: {
        width: '100%',
        marginTop: 20,
        marginBottom: 20
    },
    buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    icon: {
        'vertical-align': 'middle',
        marginRight: theme.spacing(0.5),
        width: 20,
        height: 20,
    },
    bread: {
        marginBottom: 20,
        padding: 15
    },
    chip: {
        marginLeft: 10,
        marginTop: 10
    },
    chiplist: {
        marginTop: 10,
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
    },
});

export default useStyles;