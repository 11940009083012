export default { query: `
  query GetBatchInventory {
    GetBatchInventory {
        resourceId
        status
        serverName
        registered
        score
        cloud
        location
        securestack_managed
        activity
        notifications
        instanceType
        createdBy
        info
      }
  }`
};
