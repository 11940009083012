export default { subscription: `
  subscription Notifications($businessId: String!) {
    Notifications(businessId: $businessId) {
      businessId
      Notifications {
        index
        topic
        timestamp
        message
      }
    }
  }`
};