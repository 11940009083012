const useStyles = theme => ({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    formControl: {
        width: '100%',
        marginTop: 20,
        marginBottom: 20
    },
    chips: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    chip: {
      margin: 2,
    },
    noLabel: {
      marginTop: 5,
    },
    button: {
        width: '100%',
        marginTop: 20,
        marginBottom: 20
    },
    tagButton: {
        
    },
    wrapper: {
        position: 'relative',
    },
    buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    container:{
        marginTop: 5,
        display: 'flex',
        flexWrap: 'wrap',
    },
    taglist: {
        marginTop: 10,
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
    },
    tagInput: {
        marginRight: 20,
        flex: 1
    },
    notificationSuccess: {
        backgroundColor: 'rgba(0, 130, 200, 0.7)',
    },
    notificationError: {
        backgroundColor: 'rgba(217, 30, 24, 0.7)',
    },
});

export default useStyles;