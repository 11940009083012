import React from 'react';
import { Button, DialogActions, Dialog, DialogTitle, DialogContent, DialogContentText, CircularProgress, Radio, RadioGroup, FormControlLabel } from '@material-ui/core/'

export default function DialogUpdateTarget(props) {
    const classes = props.classes
    return (
        <Dialog onClose={props.handleChangeTargetClose} aria-labelledby="form-dialog-title" open={props.changeTargetOpen}>
            <DialogTitle id="form-dialog-title">Change Target</DialogTitle>
            <DialogContent>
            <DialogContentText>
                Select the Target from the list below and select 'Change Target' to update the Product
            </DialogContentText>
            <RadioGroup
                aria-label="Target"
                name="target"
                value={props.targetKey}
                onChange={props.handleTargetChange}>
                { 
                    props.selectedProduct ?
                        props.targets.map((target, index) => (
                            <FormControlLabel value={target.key} key={index} control={<Radio />} label={target.targetName} />
                        ))
                    :
                    null
                }
            </RadioGroup>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.handleChangeTargetClose} disabled={props.updatingProducts} color="primary">
                    Cancel
                </Button>
                <Button onClick={props.handleChangeTarget} disabled={props.updatingProducts} color="primary">
                    Change Target
                </Button>
                {  props.updatingProducts && <CircularProgress size={24} className={classes.productsProgress} /> }
            </DialogActions>
        </Dialog>
    )
}