export default {
    query: `
        query GetBlueprints {
            GetBlueprints {
                filename
                active
                file
                modified
                key
                cyberRange
                applicationServers
                operatingSystems
                createdBy
                reviewedBy
                reviewed
            }
        }
    `,
};