import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, List, Card, CardHeader, ListItem, ListItemText, ListItemIcon, Checkbox, Button, Divider } from '@material-ui/core/';
import { ArrowUpward, ArrowDownward } from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
  root: {
    margin: 'auto',
  },
  cardHeader: {
    padding: theme.spacing(1, 2),
  },
  list: {
    width: '100%',
    minHeight: 230,
    backgroundColor: theme.palette.background.paper,
    overflow: 'auto',
  },
  button: {
    margin: theme.spacing(0.5, 0),
  },
}));

export default function BlueprintOrder(props) {
  const classes = useStyles();
  const [ blueprints, setBlueprints ] = useState(props.blueprints)

  const [checked, setChecked] = React.useState(null);

  const handleToggle = (value) => () => { setChecked(value.filename) };

  useEffect( () => {
    props.setBlueprints(blueprints)
  }, [ blueprints ] )

  const handleMoveBlueprint = (e) => {
    const selectedBlueprintIndex = blueprints.findIndex(blueprint => blueprint.filename === checked);
    const selectedBlueprint = blueprints[selectedBlueprintIndex];
    const blueprintsFiltered = blueprints.filter(( value ) => value.filename !== checked);
    if(e === 'down' && selectedBlueprintIndex !== (blueprints.length -1)) {
      blueprintsFiltered.splice((selectedBlueprintIndex + 1), 0, selectedBlueprint)
      setBlueprints(blueprintsFiltered)
    } 
    else if(e === 'up' && selectedBlueprintIndex < blueprints.length) {
      blueprintsFiltered.splice((selectedBlueprintIndex - 1), 0, selectedBlueprint)
      setBlueprints(blueprintsFiltered)
    }
  }

  const customList = (title) => (
    <Card>
      <CardHeader
        className={classes.cardHeader}
        subheader={`Update order blueprints will executed on a new image`}
      />
      <Divider />
      <List className={classes.list} dense component="div" role="list">
        { blueprints.map((blueprint, index) => {
            return (
              <ListItem key={blueprint.filename} role="listitem" button onClick={handleToggle(blueprint, index)}>
                <ListItemIcon>
                  <Checkbox 
                    checked={checked === blueprint.filename}
                  />
                </ListItemIcon>
                <ListItemText id={blueprint.filename} primary={`${blueprint.filename}`} />
              </ListItem>
            );
          })
        }
      </List>
    </Card>
  );

  return (
    <Grid container spacing={2} justify="center" alignItems="center" className={classes.root}>
      <Grid item>{customList('Blueprints Order')}</Grid>
      <Grid item>
        <Grid container direction="column" alignItems="center">
          <Button
            variant="outlined"
            size="small"
            name="up-icon"
            className={classes.button}
            onClick={() =>handleMoveBlueprint('up')}
            disabled={blueprints.length === 0} >
            <ArrowUpward />
          </Button>
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            name="down-icon"
            onClick={() =>handleMoveBlueprint('down')}
            disabled={blueprints.length === 0} >
            <ArrowDownward/>
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}