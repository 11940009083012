export default { mutation: `
mutation EditInventoryTags (
        $tags: [TagInput], 
        $instanceId: String, 
        $location: String,
        $accountName: String
    ) {
      EditInventoryTags(
        accountName: $accountName,
        data: {
            tags: $tags
            instanceId: $instanceId
            location: $location
    }) {
    result
    payload
  }
}`
};
