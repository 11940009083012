export default {
    query: `
        query GetUserProfile {
            GetUserProfile {
                firstName
                lastName
                company
                username
                email
                isAdmin
                phoneNumber
                key
            }
        }
    `,
};