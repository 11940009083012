import React, {Component} from 'react';
import PropTypes from 'prop-types';
import useStyles from './Style'
import { withStyles } from '@material-ui/core/styles';
import { Card, CardHeader, CardContent, FormControl, Typography, Input, FormHelperText, Button, CircularProgress,
    Paper, List, ListItem, IconButton, Grid, ListItemText, ListItemAvatar, Avatar, InputBase, Breadcrumbs
} from '@material-ui/core';
import GetUserProfiles from "../../graphql/queries/GetUserProfiles";
import CreateGroup from "../../graphql/mutations/CreateGroup";
import EditGroup from "../../graphql/mutations/EditGroup";
import {Search, Home, People, Edit} from '@material-ui/icons';
import Alert from '../../components/Alert';
import { API, graphqlOperation } from "aws-amplify";
import update from 'immutability-helper';
import { Link } from 'react-router-dom';

class GroupForm extends Component {
    constructor(props) {
        super(props);
        const { isEdit, group, users} = this.props.location.state ? this.props.location.state : {isEdit: false, group: {}, users: []};
        this.state = {
            isEdit: isEdit,
            group: group,
            users: isEdit ? users.filter((o) => !group.users.includes(o.key)) : users,
            allUsers: users,
            groupUsers: isEdit ? users.filter((o) => group.users.includes(o.key)) : [],
            loadingUsers: false,
            selectedUserIndex: 'disabled',
            selectedGroupUserIndex: 'disabled',
            queryGroupUser: '',
            queryUser: '',
            groupName: isEdit ? group.groupName : ''
        };
    } 

    async componentDidMount() {
        if(this.state.users.length === 0 && !this.state.isEdit) {
            this.setState({loadingUsers: true})
            await API.graphql(graphqlOperation(GetUserProfiles.query))
            .then(result => {
                this.setState({users: result.data.GetUserProfiles, allUsers: result.data.GetUserProfiles, loadingUsers: false})
            });
        }
    }

    handleAlertClose = () => { this.setState({showAlert: false, alertMessage: ''})}

    handleGroupName = (e) => {
        this.setState({groupName: e.target.value});
        if (e.target.value.match(/^[a-zA-Z0-9,.!?]*$/)) {
            this.setState({groupNameErrMsg: '', groupNameError: false});
        } 
        else {
            this.setState({groupNameErrMsg: 'Invalid Name', groupNameError: true});
        }
    }

    updateGroup = async () => {
        var group = {
            groupName: this.state.groupName,
            users: JSON.stringify(this.state.groupUsers.reduce((res, obj) => { 
                var key = obj.username; 
                res[key] = obj.key;
                return res;
            }, {}))
        }
        this.setState({showLoading: true});
        await API.graphql(graphqlOperation(EditGroup.mutation, {group: group, key: this.state.group.key}))
        .then(async result => {             
            this.setState({
                showLoading: false,
                alertSuccess: true,
                showAlert: true,
                alertMessage: `Group updated ${group.groupName}`,
            })
        })
        .catch(e => {
            this.setState({
                showLoading: false,
                alertSuccess: false,
                showAlert: true,
                alertMessage: 'Error updating group'
            })
        })
    }

    createGroup = async () => {
        var group = {
            groupName: this.state.groupName,
            users: JSON.stringify(this.state.groupUsers.reduce((res, obj) => { 
                var key = obj.username; 
                res[key] = obj.key;
                return res;
            }, {}))
        }        
        this.setState({showLoading: true});
        await API.graphql(graphqlOperation(CreateGroup.mutation, group))
        .then(async result => { 
            this.resetForm()
            this.setState({
                showLoading: false,
                alertSuccess: true,
                showAlert: true,
                alertMessage: 'New Group created ' + group.groupName ,
            })
        })
        .catch(e => {
            this.setState({
                showLoading: false,
                alertSuccess: false,
                showAlert: true,
                alertMessage: 'Error creating new group'
            })
        })
        
    }

    resetForm = () => { this.setState({ users: this.state.allUsers, groupUsers: [], groupName: '' }) }

    handleUser = (value) => { this.setState({selectedUserIndex:  this.state.users.findIndex(x => x.key === value.key)}) }

    handleGroupUser = (value) => { this.setState({selectedGroupUserIndex:  this.state.groupUsers.findIndex(x => x.key === value.key)}) }

    handleCheckedRight = () => {
        let newState = update(this.state, {
            groupUsers: {  $push: [this.state.users[this.state.selectedUserIndex]] },
            users: { $splice: [[this.state.selectedUserIndex, 1]] },
            selectedUserIndex: {  $set: 'disabled' } 
        })
        this.setState(newState);
    }

    handleCheckedLeft = () => {
        let newState = update(this.state, {
            users: {  $push: [this.state.groupUsers[this.state.selectedGroupUserIndex]] },
            groupUsers: { $splice: [[this.state.selectedGroupUserIndex, 1]] },
            selectedGroupUserIndex: {  $set: 'disabled' } 
        })
        this.setState(newState);
    }

    handleAllRight = () => { this.setState({users: [], groupUsers: this.state.allUsers}) }

    handleAllLeft = () => { this.setState({groupUsers: [], users: this.state.allUsers}) }

    queryUser(e) { this.setState({ queryUser: e.target.value }) }

    queryGroupUser(e) { this.setState({ queryGroupUser: e.target.value }) }

    render() {
        const { classes } = this.props;
        const { isEdit, group, showLoading, showAlert, alertSuccess, alertMessage, groupName, groupNameErrMsg, groupNameError,
            users, groupUsers, loadingUsers, selectedUserIndex, selectedGroupUserIndex, queryGroupUser, queryUser, allUsers } = this.state
        
        let filteredUsers;
        filteredUsers = this.state.users.filter(
            (item) => {return JSON.stringify(item).toLowerCase().indexOf(queryUser.toLowerCase()) !== -1;}
        );

        let filteredGroupUsers;
        filteredGroupUsers = this.state.groupUsers.filter(
            (item) => {return JSON.stringify(item).toLowerCase().indexOf(queryGroupUser.toLowerCase()) !== -1;}
        );

        const customList = group => (
            <Paper className={classes.paper}>
                <div className={classes.headSearch}>
                    <IconButton className={classes.iconButton} aria-label="Search">
                        <Search />
                    </IconButton>
                    <InputBase 
                        className={classes.input} 
                        onChange={group === 'users' ? this.queryUser.bind(this) : this.queryGroupUser.bind(this)}
                        placeholder="Search..."
                    />
                </div>
                
                <List dense component="div" role="list">
                    { loadingUsers && <CircularProgress size={24} className={classes.buttonProgress} />}
                    { group === 'users' ?
                        filteredUsers.map((value, index) => {
                            return (
                                <ListItem key={index} role="listitem" button onClick={() => this.handleUser(value)} selected={selectedUserIndex === index}>
                                    <ListItemAvatar>
                                        <Avatar alt={value.firstName} src={value.profilePic} />
                                    </ListItemAvatar>
                                    <ListItemText primary={`${value.firstName} ${value.lastName}`} />
                                </ListItem>
                            )
                        })
                    :
                        filteredGroupUsers.map((value, index) => {
                            return (
                                <ListItem key={index} role="listitem" button onClick={() => this.handleGroupUser(value)} selected={selectedGroupUserIndex === index}>
                                    <ListItemAvatar>
                                        <Avatar alt={value.firstName} src={value.profilePic} />
                                    </ListItemAvatar>
                                    <ListItemText primary={`${value.firstName} ${value.lastName}`} />
                                </ListItem>
                            )
                        })
                    }
                    <ListItem />
                </List>
            </Paper>
        );

        return ( 
            <div>
                <Paper className={classes.bread}>
                    <Breadcrumbs aria-label="Breadcrumb" >
                        <Link color='primary' to='/'>
                            <Home className={classes.icon}/>
                            Home
                        </Link>
                        <Link color='primary' to={{
                            pathname: "/Teams",
                            state: {
                                users: allUsers,
                                tab: 1
                            }
                        }}>  
                            <People className={classes.icon} />
                            Teams
                        </Link>
                        <Typography color='primary' className={classes.link}>
                            <Edit className={classes.icon} />
                            Manage Group
                        </Typography>
                    </Breadcrumbs>
                </Paper>
                <Alert showAlert={showAlert} alertSuccess={alertSuccess} alertMessage={alertMessage} handleAlertClose={this.handleAlertClose}/>
                <Card>
                    <CardHeader
                        title={isEdit ?  'Edit Group': 'Create Group' }
                        subheader={isEdit ? `Update Group ${group.groupName}` : 'Create a New Group'}
                    />
                    <CardContent>
                        <FormControl className={classes.formControl} required error={groupNameError}>
                            <Typography color="textSecondary" >Group Name</Typography>
                            <Input id="input-groupName" value={groupName} onChange={this.handleGroupName} />
                            <FormHelperText error={groupNameError}>{groupNameErrMsg}</FormHelperText>
                        </FormControl>    
                        <Grid container spacing={2} justify="center" alignItems="center" className={classes.grid}>
                        <Grid item>
                            <Typography variant='h5' color='primary' className={classes.gridHead}>Users</Typography>
                            {customList('users')}
                        </Grid>
                        <Grid item>
                            <Grid container direction="column" alignItems="center">
                            <Button
                                variant="outlined"
                                size="small"
                                className={classes.gridButton}
                                onClick={this.handleAllRight}
                                disabled={users.length === 0}
                                aria-label="move all right"
                            >
                                ≫
                            </Button>
                            <Button
                                variant="outlined"
                                size="small"
                                className={classes.gridButton}
                                onClick={this.handleCheckedRight}
                                disabled={users.length === 0 || selectedUserIndex === 'disabled'}
                                aria-label="move selected right"
                            >
                                &gt;
                            </Button>
                            <Button
                                variant="outlined"
                                size="small"
                                className={classes.gridButton}
                                onClick={this.handleCheckedLeft}
                                disabled={groupUsers.length === 0 || selectedGroupUserIndex === 'disabled' }
                                aria-label="move selected left"
                            >
                                &lt;
                            </Button>
                            <Button
                                variant="outlined"
                                size="small"
                                className={classes.gridButton}
                                onClick={this.handleAllLeft}
                                disabled={groupUsers.length === 0 }
                                aria-label="move all left"
                            >
                                ≪
                            </Button>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Typography variant='h5' color='primary' className={classes.gridHead}>Users in group</Typography>
                            {customList('groupUsers')}
                        </Grid>
                        </Grid>
                        <div className={classes.wrapper}>
                            <Button
                                variant="outlined"
                                color="primary"
                                className={classes.button}
                                disabled={showLoading}
                                onClick={isEdit ? this.updateGroup : this.createGroup} >
                            {isEdit ? "Update" : "Create"} Group
                            </Button>
                            { showLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
                        </div>    
                    </CardContent>
                </Card>
            </div>
        )
    }
}

GroupForm.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(useStyles)(GroupForm);