export default { mutation: `
    mutation UpdateDomain($key: String, $domain: DomainInput ) 
    {
    UpdateDomain(key: $key, domain: $domain) 
    {
        key
        name
        url
        vuln
    }
    }`
};