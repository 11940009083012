import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {Table, TableBody, TableCell, TableHead, TablePagination, TableRow, TableSortLabel, Toolbar, Typography,
    Paper, InputBase, IconButton, Tooltip, CircularProgress, Chip, Avatar } from '@material-ui/core/'
import { Search as SearchIcon, Add as AddIcon, Delete as DeleteIcon } from '@material-ui/icons/';
import style from './Style'
import GetAllProducts from '../../graphql/queries/GetAllProducts';
import GetUserProfiles from '../../graphql/queries/GetUserProfiles';
import GetTargets from '../../graphql/queries/GetTargets';
import GetProfiles from '../../graphql/queries/GetProfiles';
import GetBlueprints from '../../graphql/queries/GetBlueprints';
import GetGroups from '../../graphql/queries/GetGroups';
import DeleteProduct from '../../graphql/mutations/DeleteProduct';
import EditProduct from '../../graphql/mutations/EditProduct';
import ReviewProduct from '../../components/ReviewProducts/ReviewProduct';
import {Link} from 'react-router-dom';
import { API, graphqlOperation } from "aws-amplify";
import Alert from '../../components/Alert';
import update from 'immutability-helper';
import base_amis from '../../content/baseAMIs';
import GetCustomAMIs from '../../graphql/queries/GetCustomAMIs';
import ConfirmDeleteDialog from '../../components/ConfirmDeleteDialog';
import CheckSovereign from '../../functions/CheckSovereign';
import DialogUpdateBlueprints from './DialogUpdateBlueprints';
import DialogUpdateUser from './DialogUpdateUsers';
import DialogUpdateGroups from './DialogUpdateGroups';
import DialogUpdateTarget from './DialogUpdateTarget';
import DialogUpdateProfile from './DialogUpdateProfile';

var mixpanel = require('mixpanel-browser');

function desc(a, b, orderBy) {
    if(orderBy === 'productName') {
        if (b[orderBy].toLowerCase() < a[orderBy].toLowerCase()) {
            return -1;
        }
        if (b[orderBy].toLowerCase() > a[orderBy].toLowerCase()) {
            return 1;
        }
        return 0;
    }
    else if (orderBy === 'profile') {
        if (b[orderBy].profileName.toLowerCase() < a[orderBy].profileName.toLowerCase()) {
            return -1;
        }
        if (b[orderBy].profileName.toLowerCase() > a[orderBy].profileName.toLowerCase()) {
            return 1;
        }
        return 0;
    }
    else if(orderBy === 'target' ) {
        if (b[orderBy].targetName.toLowerCase() < a[orderBy].targetName.toLowerCase()) {
            return -1;
        }
        if (b[orderBy].targetName.toLowerCase() > a[orderBy].targetName.toLowerCase()) {
            return 1;
        }
        return 0;
    }
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

class Products extends React.Component {
    state = {
        products: [],
        selected: '',
        query: "",
        page: 0,
        users: [],
        groups: [],
        rowsPerPage: 10,
        dialogOpen: false,
        updateBlueprint: { visible: false, type: null },
        addGroupsOpen: false,
        changeTargetOpen: false,
        addUsersOpen: false,
        changeProfileOpen: false,
        loadingProducts: true,
        updatingProducts: false,
        usersChecked: [],
        groupsChecked: [],
        blueprintsChecked: [],
        targets: [],
        profiles: [],
        blueprints: [],
        amis: [],
        baseAMIs: base_amis,
        loadingDelete: false
    };

    componentDidMount = async () => {
        await API.graphql(graphqlOperation(GetAllProducts.query))
        .then(async result => {
            if(result){
                this.setState({products: result.data.GetAllProducts})
                await API.graphql(graphqlOperation(GetUserProfiles.query))
                .then(result => {
                    this.setState({users: result.data.GetUserProfiles})
                });
                await API.graphql(graphqlOperation(GetGroups.query))
                .then(result => {
                    this.setState({groups: result.data.GetGroups})
                });
                await API.graphql(graphqlOperation(GetTargets.query))
                .then(result => {
                    this.setState({targets: result.data.GetTargets})
                });
                await API.graphql(graphqlOperation(GetProfiles.query))
                .then(result => {
                    this.setState({profiles: result.data.GetProfiles})
                });
                await API.graphql(graphqlOperation(GetBlueprints.query))
                .then(result => {
                    this.setState({blueprints: result.data.GetBlueprints, loadingProducts: false })
                });
            }
        })
        .catch(err => {
            console.log(err)
            this.setState({
                showAlert: true, 
                alertMessage: `Something went wrong fetching your products`,
                alertSuccess: false,
            })
        });
    }

    setProduct = (data, field) => {
        var product = {
            productName: this.state.selectedProduct.productName,
            target: field === 'changeTarget' ? data : this.state.selectedProduct.target.key,
            profile: field === 'changeProfile' ? data : this.state.selectedProduct.profile.key,
            blueprints: (field === 'addBlueprints') || (field === 'reorderBlueprints') ? data : this.state.selectedProduct.blueprints,
            image: this.state.selectedProduct.image,
            costCentre: this.state.selectedProduct.costCentre === 'none' ? null : this.state.selectedProduct.costCentre,
            users : field === 'addUser' ? data : JSON.stringify(this.state.users.filter((o) => this.state.selectedProduct.users.includes(o.key)).reduce((res, obj) => { 
                var key = obj.username; 
                res[key] = obj.key;
                return res;
            }, {})),
            groups: field === 'addGroup' ? data : this.state.selectedProduct.groups
        };
        this.updateProduct(product)
    }

    updateProduct = async (product) => {
        await API.graphql(graphqlOperation(EditProduct.mutation, {key: this.state.selectedProduct.key, product: product}))
        .then(result => { 
            this.setState({
                products: result.data.EditProduct, 
                updatingProducts: false, 
                showAlert: true, 
                alertMessage: `The product ${this.state.selectedProduct.productName} has been successfully updated`,
                alertSuccess: true,
                addGroupsOpen: false,
                selected: '', 
                selectedProduct: null
            })
        })
        .catch(e => {
            this.setState({
                updatingProducts: false,
                showAlert: true, 
                alertMessage: `Something went wrong adding groups to the product please try again`,
                alertSuccess: false
            })
        })
    }

    handleAlertClose = () => {
        this.setState({showAlert: false, alertMessage: ''})
    }

    confirmDeleteProduct = async () => {
        this.setState({loadingDelete: true})
        await API.graphql(graphqlOperation(DeleteProduct.mutation, {key: this.state.selectedProduct.key}))
        .then(result => {
        if(result){
            this.setState({
                products: result.data.DeleteProduct, 
                showAlert: true, 
                alertMessage: `The product ${this.state.selectedProduct.productName} has been successfully deleted`,
                alertSuccess: true,
                dialogOpen: false,
                productName: '',
                productNameError: false,
                productNameMsg: '',
                selected: '', 
                selectedProduct: null,
                loadingDelete: false
            })
            mixpanel.track("Product Deleted");
        }
        })
        .catch(err => {
            console.log(err)
            this.setState({
                loadingDelete: false,
                showAlert: true, 
                alertMessage: `Something went wrong deleting the product ${this.state.selectedProduct.productName}`,
                alertSuccess: false,
            })
        });    
    }

    handleDeleteUser = (users, key) => {
        let newState = update(this.state, {
            products: {
                [this.state.products.findIndex(prod => prod.key === key)]: {
                    users: { $set: users.map((user) => user.key) }
                }
            },
            selectedProduct: {
                users: { $set: users.map((user) => user.key) }
            }
        })
        this.setState(newState)
    }

    handleDeleteGroup = (groups, key) => {
        let newState = update(this.state, {
            products: {
                [this.state.products.findIndex(prod => prod.key === key)]: {
                    groups: { $set: groups.map((group) => group.key) }
                }
            },
            selectedProduct: {
                groups: { $set: groups.map((group) => group.key) }
            }
        })
        this.setState(newState)
    }

    handleDeleteDialog = () => { this.setState({dialogOpen: true}) }

    handleDialogClose = () => { this.setState({dialogOpen: false}) }

    handleRequestSort = (event, property) => {
        const orderBy = property;
        let order = 'desc';
        if (this.state.orderBy === property && this.state.order === 'desc') {
            order = 'asc';
        }
        this.setState({ order, orderBy });
    };

    handleClick = (event, product) => {
        if(this.state.selected === product.productName ) {
            this.setState({selected: '', selectedProduct: null})
        }
        else {
            this.setState({ selected: product.productName, selectedProduct: product, targetKey: product.target.key, profileKey: product.profile.key});
            this.getCustomAMIs(product.target)
        }
    };

    handleAddUsersOpen = () => { this.setState({addUsersOpen: true}) }

    handleAddUsersClose = () => { this.setState({addUsersOpen: false, usersChecked: []}) }

    handleCheckUser = value => () => {
        const newChecked = [...this.state.usersChecked];
        this.state.usersChecked.indexOf(value) === -1 ? newChecked.push(value) : newChecked.splice(this.state.usersChecked.indexOf(value), 1)
        this.setState({usersChecked: newChecked});
    };

    handleAddUser = async () => {
        this.setState({updatingProducts: true})
        const newUsers = this.state.usersChecked.reduce((res, obj) => {
            var key = obj.username; 
            res[key] = obj.key;
            return res;
        }, {});
        const currentUsers = this.state.users.filter((o) => this.state.selectedProduct.users.includes(o.key)).reduce((res, obj) => { 
            var key = obj.username; 
            res[key] = obj.key;
            return res;
        }, {})
        this.setProduct(JSON.stringify({...newUsers, ...currentUsers }), 'addUser')
        this.setState({addUsersOpen: false})
    }

    handleAddGroupsOpen = () => { this.setState({addGroupsOpen: true}) }

    handleAddGroupsClose = () => { this.setState({addGroupsOpen: false, groupsChecked: []}) }

    handleCheckGroup = value => () => {
        const newChecked = [...this.state.groupsChecked];
        this.state.groupsChecked.indexOf(value) === -1 ? newChecked.push(value) : newChecked.splice(this.state.groupsChecked.indexOf(value), 1)
        this.setState({groupsChecked: newChecked});
    };

    handleCheckBlueprint = value => () => {
        const newChecked = [...this.state.blueprintsChecked];
        this.state.blueprintsChecked.indexOf(value) === -1 ? newChecked.push(value) : newChecked.splice(this.state.blueprintsChecked.indexOf(value), 1)
        this.setState({blueprintsChecked: newChecked});
    };


    handleAddGroups = async () => {
        this.setState({updatingProducts: true})
        const newGroups = this.state.groupsChecked.map(res => res.key)
        var groups = this.state.selectedProduct.groups.concat(newGroups)
        this.setProduct(groups, 'addGroup')
        this.setState({addGroupsOpen: false})
    }

    handleChangeTargetOpen = () => { this.setState({ changeTargetOpen: true }) }

    handleChangeTargetClose = () => { this.setState({ changeTargetOpen: false }) }

    handleTargetChange = async (e) => { 
        const targetKey = e.target.value
        const target = this.state.targets.filter(target => { return target.key === targetKey })[0]
        const isSovereign = await CheckSovereign(target.region, this.state.selectedProduct.profile.sovereign)
        if(isSovereign) {
            this.setState({ targetKey: targetKey }) 
        }
        else {
            this.setState({ showAlert: true, alertMessage: 'Unable to use Target due to Profile Sovereignity', alertSuccess: false})
        }
    }

    handleChangeTarget = async () => {
        this.setState({updatingProducts: true, changeTargetOpen: false})
        var target = this.state.targetKey
        this.setProduct(target, 'changeTarget')
    }

    handleChangeProfileOpen = () => { this.setState({ changeProfileOpen: true }) }
    
    handleChangeProfileClose = () => { this.setState({ changeProfileOpen: false }) }

    handleProfileChange = async (e) => { 
        const profileKey = e.target.value
        const profile = this.state.profiles.filter(profile => { return profile.key === e.target.value})[0]
        const isSovereign = await CheckSovereign(this.state.selectedProduct.target.region, profile.sovereign)
        if(isSovereign) {
            this.setState({ profileKey: profileKey }) 
        }
        else {
            this.setState({ showAlert: true, alertMessage: 'Unable to use Profile due to its Sovereignity', alertSuccess: false})
        }
    }

    handleChangeProfile = async () => {
        this.setState({updatingProducts: true, changeProfileOpen: false})
        var profile = this.state.profileKey
        this.setProduct(profile, 'changeProfile')
    }

    handleAddBlueprints = () => {
        this.setState({updatingProducts: true, updateBlueprint: { visible: false, type: null }})
        const newBlueprints = this.state.blueprintsChecked.map(res => res.key)
        var blueprints = this.state.selectedProduct.blueprints.concat(newBlueprints) 
        this.setProduct(blueprints, 'addBlueprints')
    }

    handleReorderBlueprints = (reordered) => {
        this.setState({updatingProducts: true, updateBlueprint: { visible: false, type: null }})
        var blueprints = reordered.map(res => res.key)
        this.setProduct(blueprints, 'reorderBlueprints')
    }

    getCustomAMIs = (target) => {
        this.setState({loadingAMIs: true})
        let params = {
            accountName: target.credential,
            region: target.region
        }
        API.graphql(graphqlOperation(GetCustomAMIs.query, params))
        .then(result => {
            this.setState({amis: result.data.GetCustomAMIs}, () => this.setState({loadingAMIs: false}))
        })
        .catch(err => {
            this.setState({ showAlert: true, alertMessage: err.errors[0].message, alertSuccess: false, loadingAMIs: false})
        })
    }

    handleChangeTargetOpen = () => { this.setState({ changeTargetOpen: true }) }

    handleAddBlueprintsOpen = (type) => { this.setState({ updateBlueprint: { visible: true, type: type } }) }

    handleAddBlueprintsClose = () => { this.setState({ updateBlueprint: false }) }

    handleChangePage = (event, page) => { this.setState({ page }) };

    handleChangeRowsPerPage = event => { this.setState({ rowsPerPage: event.target.value }) };

    isSelected = id => this.state.selected === id

    createSortHandler = property => event => { this.handleRequestSort(event, property) };

    query(e) {
        this.setState({
            query: e.target.value,
        });
    }

    render() {
        const { classes } = this.props;
        const { loadingDelete, order, orderBy, selected, rowsPerPage, page, products, numSelected, showAlert, alertMessage, alertSuccess, loadingProducts, loadingAMIs,
            dialogOpen, users, groups, addUsersOpen, updatingProducts, addGroupsOpen, usersChecked,
            changeTargetOpen, targets, targetKey, changeProfileOpen, profiles, profileKey, blueprints, blueprintsChecked, updateBlueprint } = this.state;

        const emptyRows = rowsPerPage - Math.min(rowsPerPage, products.length - page * rowsPerPage);

        const rows = [
            { id: 'productName', numeric: false, disablePadding: true, label: 'Prodcut Name' },
            { id: 'target', numeric: false, disablePadding: false, label: 'Target' },
            { id: 'profile', numeric: false, disablePadding: false, label: 'Profile' },
            { id: 'image', numeric: false, disablePadding: false, label: 'Image' },
            { id: 'blueprint', numeric: false, disablePadding: false, label: 'Blueprints' },
            { id: 'costCentre', numeric: false, disablePadding: false, label: 'Cost Centre' },
            { id: 'groups', numeric: false, disablePadding: false, label: 'Groups' },
            { id: 'users', numeric: false, disablePadding: false, label: 'Users' },
        ];

        let filteredSearch;
        filteredSearch = this.state.products.filter(
          (item) => {return JSON.stringify(item).toLowerCase().indexOf(this.state.query.toLowerCase()) !== -1;}
        );
          
        return (
            <div>
                <ConfirmDeleteDialog name={this.state.selectedProduct ? this.state.selectedProduct.productName : ''} type='Product' open={dialogOpen} handleDialogClose={this.handleDialogClose} loading={loadingDelete}
                    confirmDelete={this.confirmDeleteProduct}/>
                { /* TODO - move some logic to Dialog components */ }
                <DialogUpdateBlueprints 
                    handleAddBlueprintsClose={this.handleAddBlueprintsClose} 
                    open={ updateBlueprint.visible }
                    type={ updateBlueprint.type }
                    selectedProduct={this.state.selectedProduct}
                    loadingAMIs={loadingAMIs}
                    blueprints={blueprints}
                    baseAMIs={this.state.baseAMIs}
                    amis={this.state.amis}
                    blueprintsChecked={blueprintsChecked}
                    handleCheckBlueprint={this.handleCheckBlueprint}
                    updatingProducts={updatingProducts}
                    handleAddBlueprints={this.handleAddBlueprints}
                    handleReorderBlueprints={this.handleReorderBlueprints}
                    classes={classes}
                />
                <DialogUpdateUser
                    handleAddUsersClose={this.handleAddUsersClose}
                    addUsersOpen={addUsersOpen}
                    selectedProduct={this.state.selectedProduct}
                    users={users}
                    handleCheckUser={this.handleCheckUser}
                    usersChecked={usersChecked}
                    updatingProducts={updatingProducts}
                    handleAddUser={this.handleAddUser}
                    classes={classes}
                />
                <DialogUpdateGroups
                    handleAddGroupsClose={this.handleAddGroupsClose}
                    addGroupsOpen={addGroupsOpen}
                    selectedProduct={this.state.selectedProduct}
                    groups={groups}
                    handleCheckGroup={this.handleCheckGroup}
                    groupsChecked={this.state.groupsChecked}
                    updatingProducts={updatingProducts}
                    handleAddGroups={this.handleAddGroups}
                    classes={classes}
                />
                <DialogUpdateTarget 
                    handleChangeTargetClose={this.handleChangeTargetClose}
                    changeTargetOpen={changeTargetOpen}
                    targetKey={targetKey}
                    handleTargetChange={this.handleTargetChange}
                    targets={targets}
                    updatingProducts={updatingProducts}
                    handleChangeTarget={this.handleChangeTarget}
                    selectedProduct={this.state.selectedProduct}
                    classes={classes}
                />
                <DialogUpdateProfile 
                    handleChangeProfileClose={this.handleChangeProfileClose}
                    changeProfileOpen={changeProfileOpen}
                    profileKey={profileKey}
                    handleProfileChange={this.handleProfileChange}
                    profiles={profiles}
                    updatingProducts={updatingProducts}
                    handleChangeProfile={this.handleChangeProfile}
                    classes={classes}
                />
                <Alert showAlert={showAlert} alertSuccess={alertSuccess} alertMessage={alertMessage} handleAlertClose={this.handleAlertClose}/>
                <Paper className={classes.root}>
                    <Toolbar
                        className={classNames(classes.toolbar, {
                            [classes.highlight]: numSelected > 0,
                        })}
                    >
                    <div className={classes.title}>
                        {selected !== '' ? (
                            <Typography color="primary" variant="subtitle1">
                                { selected }
                            </Typography>
                        ) : (
                            <Typography variant="h6" id="tableTitle">
                                Products
                            </Typography>
                        )}
                    </div>
                    <div className={classes.spacer} />
                    <div className={classes.actions}>
                        { selected !== '' ? (
                            <Tooltip title="Delete">
                                <IconButton aria-label="Delete" onClick={() => this.handleDeleteDialog()}>
                                    <DeleteIcon />
                                </IconButton>
                            </Tooltip>
                        ) : (
                            <div className={classes.headSearch}>
                                <IconButton className={classes.iconButton} aria-label="Search">
                                    <SearchIcon />
                                </IconButton>
                                <InputBase 
                                    className={classes.input} 
                                    onChange={this.query.bind(this)}
                                    placeholder="Search..."
                                />
                                <Tooltip title="Create New Product">
                                    <Link
                                         to={{
                                            pathname: "/CreateProduct",
                                            state: {
                                                allUsers: users,
                                                allGroups: groups
                                            }
                                        }}>
                                        <IconButton aria-label="Create" color='primary'>
                                            <AddIcon />
                                        </IconButton>
                                    </Link>
                                </Tooltip>
                            </div>

                        )}
                    </div>
                    </Toolbar>
                    <div className={classes.tableWrapper}>
                        { loadingProducts && <CircularProgress size={24} className={classes.productsProgress} /> }
                        <Table className={classes.table} aria-labelledby="tableTitle">
                            <TableHead>
                                <TableRow>
                                { rows.map((row, index) => 
                                    {
                                        return [
                                            row.id === 'productName' || row.id === 'target' || row.id === 'profile' || row.id === 'costCentre' ?
                                                <TableCell
                                                    key={row.id}
                                                    align='left'
                                                    padding='default'
                                                    sortDirection={orderBy === row.id ? order : false} >
                                                <Tooltip
                                                    title="Sort"
                                                    placement='bottom-start'
                                                    enterDelay={300} >
                                                <TableSortLabel
                                                    active={orderBy === row.id}
                                                    direction={order}
                                                    onClick={this.createSortHandler(row.id)} >
                                                    {row.label}
                                                </TableSortLabel>
                                                </Tooltip>
                                            </TableCell>
                                            :
                                            <TableCell
                                                key={row.id}
                                                align='left'
                                                padding='default' >
                                                {row.label}
                                            </TableCell> 
                                        ]
                                    }  
                                )}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            { !loadingProducts &&
                                stableSort(filteredSearch, getSorting(order, orderBy))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map(n => {
                                    const isSelected = this.isSelected(n.productName);
                                    if(isSelected) {
                                        var selectedProduct = {
                                            productName: n.productName,
                                            target: n.target,
                                            profile: n.profile,
                                            costCentre: n.costCentre,
                                            image: n.image,
                                            blueprints: n.blueprints.map((x) => { return blueprints.find((o) => o.key === x ) }),
                                            users:  users.filter((o) => n.users.includes(o.key)),
                                            groups: groups.filter((o) => n.groups.includes(o.key)),
                                            key: n.key
                                        }
                                        return [
                                            <TableRow
                                                hover
                                                onClick={event => this.handleClick(event, n)}
                                                aria-checked={isSelected}
                                                tabIndex={-1}
                                                key={n.key}
                                                selected={isSelected} >
                                                <TableCell component="th" scope="row" padding='default'>{n.productName}</TableCell>
                                                <TableCell align="left">{n.target.targetName}</TableCell>
                                                <TableCell align="left">{n.profile.profileName}</TableCell>
                                                <TableCell align="left">{n.image}</TableCell>
                                                <TableCell align="left">
                                                    {
                                                        blueprints.filter((o) => n.blueprints.slice(0,2).includes(o.key)).map((value, index) => (
                                                            <Chip key={index} value={value} variant="outlined" label={value.filename} className={classes.chip} />
                                                        ))  
                                                    }
                                                </TableCell>
                                                <TableCell align="left">{n.costCentre === 'none' ? '' : n.costCentre }</TableCell>
                                                <TableCell align="left"> 
                                                    {
                                                        groups.filter((o) => n.groups.slice(0,2).includes(o.key)).map((value, index) => (
                                                            <Chip key={index} value={value} variant="outlined" label={value.groupName} className={classes.chip} />
                                                        ))  
                                                    }
                                                </TableCell>
                                                <TableCell align="left"> 
                                                    {
                                                        users.filter((o) => n.users.slice(0,2).includes(o.key)).map((value, index) => (
                                                            <Chip key={index} avatar={<Avatar alt={value.firstName} src={value.profilePic} />}
                                                                value={value} variant="outlined" label={value.firstName + ' ' + value.lastName} className={classes.chip} />
                                                        ))  
                                                    }
                                                </TableCell>
                                            </TableRow>,
                                            <TableRow
                                                selected={isSelected}>
                                                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
                                                    <ReviewProduct product={selectedProduct} elevation={0} handleAddUsersOpen={this.handleAddUsersOpen}
                                                        handleAddGroupsOpen={this.handleAddGroupsOpen} handleDeleteUser={this.handleDeleteUser} handleDeleteGroup={this.handleDeleteGroup}
                                                        isEdit={true} handleChangeTargetOpen={this.handleChangeTargetOpen} handleChangeProfileOpen={this.handleChangeProfileOpen} handleAddBlueprintsOpen={this.handleAddBlueprintsOpen} updateProduct={this.updateProduct}/>
                                                </TableCell>
                                            </TableRow>
                                        ];
                                    }
                                    else {
                                        return (
                                            <TableRow
                                                hover
                                                onClick={event => this.handleClick(event, n)}
                                                aria-checked={isSelected}
                                                tabIndex={-1}
                                                key={n.key}
                                                selected={isSelected} >
                                                <TableCell component="th" scope="row" padding='default'>{n.productName}</TableCell>
                                                <TableCell align="left">{n.target.targetName}</TableCell>
                                                <TableCell align="left">{n.profile.profileName}</TableCell>
                                                <TableCell align="left">{n.image}</TableCell>
                                                <TableCell align="left">
                                                    {
                                                        blueprints.filter((o) => n.blueprints.slice(0,2).includes(o.key)).map((value, index) => (
                                                            <Chip key={index} value={value} variant="outlined" label={value.filename} className={classes.chip} />
                                                        ))  
                                                    }
                                                </TableCell>
                                                <TableCell align="left">{n.costCentre === 'none' ? '' : n.costCentre }</TableCell>
                                                <TableCell align="left"> 
                                                    {
                                                        groups.filter((o) => n.groups.slice(0,2).includes(o.key)).map((value, index) => (
                                                            <Chip key={index} value={value} variant="outlined" label={value.groupName} className={classes.chip} />
                                                        ))  
                                                    }
                                                </TableCell>
                                                <TableCell align="left"> 
                                                    {
                                                        users.filter((o) => n.users.slice(0,2).includes(o.key)).map((value, index) => (
                                                            <Chip key={index} avatar={<Avatar alt={value.firstName} src={value.profilePic} />}
                                                                value={value} variant="outlined" label={value.firstName + ' ' + value.lastName} className={classes.chip} />
                                                        ))  
                                                    }
                                                </TableCell>
                                            </TableRow>
                                        )
                                    }
                                })
                            }
                            { emptyRows > 0 && (
                                <TableRow style={{ height: 49 * emptyRows }}>
                                <TableCell colSpan={8} />
                                </TableRow>
                            )}
                            </TableBody>
                    </Table>
                    </div>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={products.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        backIconButtonProps={{
                            'aria-label': 'Previous Page',
                        }}
                        nextIconButtonProps={{
                            'aria-label': 'Next Page',
                        }}
                        onChangePage={this.handleChangePage}
                        onChangeRowsPerPage={this.handleChangeRowsPerPage} />
                </Paper>
            </div>
        );
    }
}

Products.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(style)(Products);
