import React, {Component} from 'react';
import "tabler-react/dist/Tabler.css";
import { Button, Form, Grid, Card, Icon, Dimmer } from "tabler-react";
import countryList from "../content/countryList";
import { CardElement, injectStripe } from 'react-stripe-elements';
import EditBillingInfo from '../graphql/mutations/EditBillingInfo';
import { graphqlOperation, API } from "aws-amplify";


class BillingDetailsInput extends Component {
    constructor(props) {
        super(props);
        this.handleCountry = this.handleCountry.bind(this);
        this.handleConfirmation = this.handleConfirmation.bind(this);
        const {billingDetails, customerID } = this.props
        this.state = {
            customerID: customerID,
            firstName: billingDetails ? billingDetails.firstName : '',
            lastName: billingDetails ? billingDetails.lastName: '',
            address: billingDetails ? billingDetails.address: '',
            city: billingDetails ? billingDetails.city: '',
            region: billingDetails ? billingDetails.region: '',
            postal: billingDetails ? billingDetails.postal: '',
            company: billingDetails ? billingDetails.company: '',
            email: billingDetails ? billingDetails.email: '',
            phone: billingDetails ? billingDetails.phone: '',
            country: billingDetails ? billingDetails.country: '',
            cardNo: billingDetails ? billingDetails.cardNo: '',
            cardExpM: billingDetails ? billingDetails.cardExpM: '',
            cardExpY: billingDetails ? billingDetails.cardExpY: '',
        };
    }

    componentDidMount() {
        //Set Phone number flag display if edit
        if(this.props.isEdit) {
            let obj = countryList.find(o => o[1] === this.state.country.toLowerCase());
            this.setState({phoneCode: obj[2], countryCode: obj[1], phone: this.state.phone.substr(obj[2].length + 1), country: obj[0]})
        }
    }

    handleConfirmation(event) {
        switch (event.target.name) {
            case 'firstName-text-input':
                this.setState({firstName: event.target.value})
                if (event.target.value.match(/^(?=.{1,30}$)[a-zA-Z]+(?:['_.\s][a-zA-Z]+)*$/)) {
                    this.setState({firstNameComplete: true, firstNameError: false});
                } 
                else {
                    this.setState({firstNameComplete: false, firstNameError: true});
                }
                break;
            case 'lastName-text-input': 
                this.setState({lastName: event.target.value})
                if (event.target.value.match(/^(?=.{1,30}$)[a-zA-Z]+(?:['_.\s][a-zA-Z]+)*$/)) {
                    this.setState({lastNameComplete: true, lastNameError: false});
                } 
                else {
                    this.setState({lastNameComplete: false, lastNameError: true});
                }
                break;
            case 'address-text-input':
                if (event.target.value === ""){
                    this.setState({addressComplete: false})
                }
                else {
                    this.setState({addressComplete: true})
                }
                this.setState({address: event.target.value})
                break;
            case 'zip-text-input':
                if (event.target.value === ""){
                    this.setState({postalComplete: false})
                }
                else {
                    this.setState({postalComplete: true})
                }
                this.setState({postal: event.target.value})
                break;
            case 'city-text-input':
                this.setState({city: event.target.value})
                if (event.target.value.match(/^(?=.{1,50}$)[a-zA-Z]+(?:['_.\s][a-zA-Z]+)*$/)) {
                    this.setState({cityComplete: true, cityError: false});
                } 
                else {
                    this.setState({cityComplete: false, cityError: true});
                }
                break;
            case 'region-text-input':
                this.setState({region: event.target.value})
                if (event.target.value.match(/^(?=.{1,50}$)[a-zA-Z]+(?:['_.\s][a-zA-Z]+)*$/)) {
                    this.setState({regionComplete: true, regionError: false});
                } 
                else {
                    this.setState({regionComplete: false, regionError: true});
                }
                break;
            case 'companyName-text-input':
                this.setState({company: event.target.value})
                if (event.target.value.match(/^(?=.{1,50}$)[a-zA-Z]+(?:['_.\s][a-zA-Z]+)*$/)) {
                    this.setState({companyComplete: true, companyError: false});
                } 
                else {
                    this.setState({companyComplete: false, companyError: true});
                }
                break;
            case 'email-text-input':
                this.setState({email: event.target.value.toLowerCase()})
                if (event.target.value.match(/[a-z0-9._%+!$&*=^|~#%'`?{}/-]+@([a-z0-9-]+.){1,}([a-z]{2,16})/)) {
                    this.setState({emailComplete: true, emailError: false});
                } 
                else {
                    this.setState({emailComplete: false, emailError: true});
                }
                break;
            case 'phone-text-input':
                this.setState({phone: event.target.value.toLowerCase()})
                if (event.target.value.match(/^((?!(0))[0-9]{5,15})$/)) {
                    this.setState({phoneComplete: true, phoneError: false});
                } 
                else {
                    this.setState({phoneComplete: false, phoneError: true});
                }
                break;
            default:  
                break;
        }
    }

    handleCountry(event) {
        var country = event.target.value.split(',');
        this.setState({
            country: country[0],
            countryCode: country[1],
            phoneCode: country[2]
        })
    }

    checkInputs() {
        var {regionComplete, firstNameComplete, lastNameComplete, phoneComplete, emailComplete, companyComplete, addressComplete} = this.state
        console.log({regionComplete, firstNameComplete, lastNameComplete, phoneComplete, emailComplete, companyComplete, addressComplete})
        if(regionComplete && firstNameComplete && lastNameComplete && phoneComplete && emailComplete && companyComplete && addressComplete) {
            return true;
        }
        else {
            return false;
        }
    }

    async updateCustomer() {
        if(this.props.isEditCreditCard) {
            await this.props.stripe.createToken({name: this.state.company}).then(async token => {
                if (token.error) {
                    console.log(token.error)
                }
                else {
                    var customerInput = {
                        customerID: this.state.customerID,
                        firstName: this.state.firstName,
                        lastName: this.state.lastName,
                        address: this.state.address,
                        city: this.state.city,
                        region: this.state.region,
                        postal: this.state.postal,
                        email: this.state.email,
                        phone: "+"+this.state.phoneCode+this.state.phone,
                        country: this.state.countryCode,
                        token: token.token.id,
                    }
                    await API.graphql(graphqlOperation(EditBillingInfo.mutation, customerInput))
                    .then((result) => {
                        console.log(result)
                    })
                    .catch(err => {
                    console.log(err)
                    });
                }
            })
        }
        else {
            var customerInput = {
                customerID: this.state.customerID,
                firstName: this.state.firstName,
                lastName: this.state.lastName,
                address: this.state.address,
                city: this.state.city,
                region: this.state.region,
                postal: this.state.postal,
                email: this.state.email,
                phone: "+"+this.state.phoneCode+this.state.phone,
                country: this.state.countryCode,
                token: this.props.billingDetails.token
            }
            await API.graphql(graphqlOperation(EditBillingInfo.mutation, customerInput))
            .then((result) => {
                console.log(result)
            })
            .catch(e => console.log(e))
        }
    }

    async nextStep() {
        this.setState({loading: true});
        var completeInputs = this.checkInputs();
        console.log('nxt step')
        if(this.state.countryCode && this.state.phoneCode && completeInputs) {
            await this.props.stripe.createToken({name: this.state.company}).then(token => {
                if (token.error) {
                    this.setState({loading: false});
                    this.props.showAlert('Credit Card Error','there was an error processing your credit card','danger')
                }
                else {
                    var billingDetails = {
                        firstName: this.state.firstName,
                        lastName: this.state.lastName,
                        address: this.state.address,
                        city: this.state.city,
                        region: this.state.region,
                        postal: this.state.postal,
                        company: this.state.company,
                        email: this.state.email,
                        phone: "+"+this.state.phoneCode+this.state.phone,
                        token: token.token.id,
                        country: this.state.countryCode
                    }
                    this.setState({loading: false});
                    this.props.nextStep(billingDetails, ( this.state.countryCode + ',' + this.state.phoneCode ) )
                }
            }).catch(err => {
                this.setState({loading: false});
                this.props.showAlert('Credit Card Error','there was an error processing your credit card','danger')
            });
        }
        else {
            this.setState({loading: false});
            this.props.showAlert('Invalid Parameter','one or more of your inputs are invalid please check your billing details and try again','danger')
        }
    }

    render() {
        const {countryCode, country } = this.state;
        const { cardNo, cardExpY, cardExpM } = this.state;
        const {firstNameComplete, firstNameError, firstName } = this.state;
        const {lastNameComplete, lastNameError, lastName } = this.state;
        const {addressComplete, address } = this.state;
        const {cityComplete, cityError, city } = this.state;
        const {regionComplete, regionError, region } = this.state;
        const {postal, postalComplete } = this.state;
        const {companyComplete, companyError, company } = this.state;
        const {emailComplete, emailError, email } = this.state;
        const {phoneComplete, phoneError, phone } = this.state;
        const { isEdit, isEditCreditCard } = this.props;
        const { loading } = this.state;
        return (
            <div>
            <Card statusColor="blue" >
                { !isEdit &&
                    <Card.Header>
                        <Card.Title>Billing details</Card.Title>
                    </Card.Header>
                }
                <Dimmer active={false} loader>
                    <div style={{ margin: 20 }}>
                        <Form>
                            <Grid.Row>
                                <Grid.Col width={6}>
                                    <Form.Group
                                        isRequired
                                        label="First Name">
                                        <Form.Input 
                                            name="firstName-text-input"
                                            tick={firstNameComplete}
                                            cross={firstNameError}
                                            value={firstName || ''} 
                                            onChange={this.handleConfirmation} />
                                    </Form.Group>
                                </Grid.Col>
                                <Grid.Col  width={6}>
                                    <Form.Group isRequired
                                        className="mb-0"
                                        label="Last Name">
                                        <Form.Input 
                                            name="lastName-text-input"
                                            tick={lastNameComplete}
                                            cross={lastNameError}
                                            value={lastName || ''} 
                                            onChange={this.handleConfirmation} />
                                    </Form.Group>
                                </Grid.Col>
                            </Grid.Row>
                            <Form.Group
                                label="Address">
                                <Form.Input 
                                    name="address-text-input"
                                    tick={addressComplete}
                                    value={address || ''} 
                                    onChange={this.handleConfirmation}
                                />
                            </Form.Group>
                            <Grid.Row>
                                <Grid.Col width={6}>
                                    <Form.Group
                                        isRequired
                                        label="City">
                                        <Form.Input 
                                            name="city-text-input" 
                                            tick={cityComplete}
                                            cross={cityError}
                                            value={city || ''} 
                                            onChange={this.handleConfirmation}
                                        />
                                    </Form.Group>
                                </Grid.Col>
                                <Grid.Col  width={6}>
                                    <Form.Group isRequired
                                        className="mb-0"
                                        label="State/Provice/Region">
                                        <Form.Input 
                                            name="region-text-input" 
                                            tick={regionComplete}
                                            cross={regionError}
                                            value={region || ''} 
                                            onChange={this.handleConfirmation}/>
                                    </Form.Group>
                                </Grid.Col>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Col width={4}>
                                    <Form.Group
                                        label="ZIP/Postal Code">
                                        <Form.Input 
                                            name="zip-text-input"
                                            tick={postalComplete}
                                            value={postal || ''} 
                                            onChange={this.handleConfirmation}
                                             />
                                    </Form.Group>
                                </Grid.Col>
                                <Grid.Col  width={8}>
                                    <Form.Group isRequired
                                        className="mb-0"
                                        label="Country">
                                        <Form.Select
                                            name="country"
                                            type="text"
                                            onChange={this.handleCountry}>
                                            <option>
                                                { isEdit ? country : 'Country...'}
                                            </option>
                                        {
                                        countryList.map(function (country) {
                                            return <option
                                                key={country[0]}
                                                value={country[0]+","+country[1]+","+country[2]}
                                                name={country[0]+'w'}>
                                                {country[0]}
                                            </option>
                                        })}
                                        </Form.Select>
                                    </Form.Group>
                                </Grid.Col>
                            </Grid.Row>
                            { !isEdit &&
                                <Form.Group
                                isRequired
                                label="Company Name">
                                    <Form.Input 
                                        name="companyName-text-input" 
                                        tick={companyComplete}
                                        cross={companyError}
                                        value={company || ''} 
                                        onChange={this.handleConfirmation}/>
                                </Form.Group>
                            }
                            <Form.Group
                                isRequired
                                label="Email address">
                                <Form.Input 
                                    name="email-text-input"
                                    tick={emailComplete}
                                    cross={emailError}
                                    value={email || ''} 
                                    onChange={this.handleConfirmation} />
                            </Form.Group>
                            <Form.Group
                                isRequired
                                label="Phone Number">
                                <Form.InputGroup>
                                    <Form.InputGroupPrepend>
                                        { countryCode &&
                                            <div style={{backgroundColor: '#FBFBFD', margin: 'auto'}}>
                                                <span>
                                                    <Icon prefix="flag" name={this.state.countryCode} />
                                                    &nbsp;
                                                    + {this.state.phoneCode} 
                                                    &nbsp; 
                                                </span>          
                                            </div>
                                        }
                                    </Form.InputGroupPrepend>
                                    <Form.Input 
                                        name="phone-text-input"
                                        tick={phoneComplete}
                                        cross={phoneError}
                                        value={phone || ''} 
                                        onChange={this.handleConfirmation} />
                                </Form.InputGroup>     
                            </Form.Group> 
                            
                            <Form.Group
                                isRequired
                                label="Credit Card">
                                {
                                    isEdit && !isEditCreditCard &&
                                    <div
                                        onClick={() => {this.props.showAlert()}} >
                                        <Form.InputGroup>
                                        <Form.Input 
                                            name="creditcard-display"
                                            disabled={true}
                                            placeholder={'************'+cardNo} />
                                            <Form.InputGroupAppend >
                                                <Form.Input
                                                    disabled={true}
                                                    placeholder={"Exp: " + cardExpM+' / '+cardExpY }>
                                                </Form.Input>
                                            </Form.InputGroupAppend>
                                        </Form.InputGroup>
                                    </div>
                                }
                                {
                                    ! isEdit &&
                                    <div style={{
                                        border: '1px solid rgba(0, 40, 100, 0.12)',
                                        borderRadius: '3px',
                                        transition: 'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out',
                                        display: 'block',
                                        padding: '0.375rem 0.75rem',
                                    }}>
                                        <div style={{margin: 4}}>
                                            <CardElement hidePostalCode={true} placeholder="1213"
                                                style={{
                                                    padding: '0.375rem 0.75rem',
                                                    base: {
                                                        width: '100%',
                                                        color: '#495057',
                                                        fontSize: '14px',
                                                        fontFamily: '"Open Sans", sans-serif',
                                                        '::placeholder': {
                                                            color: '#adb5bd'
                                                        }
                                                    }
                                                }}
                                            />
                                        </div>
                                    </div>
                                }       
                            </Form.Group>
                        </Form>
                    </div>
                </Dimmer>
                <Button color="primary" 
                    loading={loading}
                    onClick={() => isEdit ? this.updateCustomer() : this.nextStep()} >
                        {isEdit ? 'Update' : 'Next'}
                </Button>

            </Card>
            </div>
        );
    }
}

export default injectStripe(BillingDetailsInput)
