import React, { Component } from 'react';
import "tabler-react/dist/Tabler.css";
import { Page, Alert, Header, Button } from "tabler-react";
import BillingDetailsInput from '../components/BillingDetailsInput'
import { Elements, StripeProvider} from 'react-stripe-elements';
import { API, graphqlOperation, Auth  } from "aws-amplify";
import GetCustomer from '../graphql/queries/GetCustomer';
import stripe_config from "../stripe_config";

class BillingInformation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      billingInfo: {},
      isEditCreditCard: false,
      showAlert: false
    };
    this.showAlert = this.showAlert.bind(this);
  }

  async componentDidMount() {
    Auth.currentAuthenticatedUser()
    .then(async user => {
      this.setState({customerID: user.attributes['custom:stripeId']})
      await API.graphql(graphqlOperation(GetCustomer.query, {customerID: user.attributes['custom:stripeId']}))
      .then(result => {
        this.setState({billingInfo: result.data.GetCustomer, isLoading: false})
      })
      .catch(err => {
        console.log(err);
     });
    });
  }

  showAlert() {
    this.setState({showAlert: true})
  }

  render() {
    const {billingInfo, isLoading, customerID, isEditCreditCard, showAlert} = this.state;
    return (
        <Page.Content title="Billing Details">
        {
          showAlert &&
          <Alert type="warning" isDismissible>
            <Header.H4>Update Credit Card</Header.H4>
            <p>
              Are you sure you want to update the credit card linked to this account?
            </p>
            <Button.List>
              <Button color="info" RootComponent="button" onClick={() => this.setState({isEditCreditCard: true, showAlert: false})}>
                Yes, im sure
              </Button>
              <Button color="secondary" RootComponent="button" onClick={() => this.setState({showAlert: false})}>
                No, thanks
              </Button>
            </Button.List>
          </Alert>
        }
        { !isLoading &&
          <StripeProvider apiKey={stripe_config.apiKey}>
            <Elements fonts={[
              {cssSrc: 'https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,500,600'}
            ]}>
              <BillingDetailsInput billingDetails={billingInfo} isEdit={true} isEditCreditCard={isEditCreditCard} showAlert={this.showAlert} customerID={customerID}/>
            </Elements>
          </StripeProvider>     
        }
        </Page.Content>
    );
  }
}

export default BillingInformation;
