import React from 'react';
import ReactDOM from 'react-dom';
import './content/index.css';
import App from './App';
import aws_config from './aws_config'
import Amplify from "aws-amplify";
import { BrowserRouter } from "react-router-dom";
import * as serviceWorker from './serviceWorker';

Amplify.configure(aws_config);

/*
Amplify.Analytics.autoTrack('pageView', {
  enable: false,
  eventName: 'pageView',
  type: 'SPA',
});
*/

ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();