import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {Table, AppBar, Tab, Tabs, TableBody, TableCell, TableHead, TablePagination, TableRow, TableSortLabel, Toolbar, Typography, FormControl, Button, DialogActions,
    Paper, InputBase, IconButton, Tooltip, Dialog, DialogTitle, DialogContent, DialogContentText, CircularProgress, Input, FormHelperText, Avatar } from '@material-ui/core/'
import {Edit, Search as SearchIcon, Add as AddIcon, Delete as DeleteIcon, Group, Person} from '@material-ui/icons/';
import style from './Style'
import GetUserProfiles from "../../graphql/queries/GetUserProfiles";
import DeleteUserFromGroup from "../../graphql/mutations/DeleteUserFromGroup";
import DeleteUserFromProduct from "../../graphql/mutations/DeleteUserFromProduct";
import DeleteUserAccount from '../../graphql/mutations/DeleteUserAccount';
import {Link} from 'react-router-dom';
import { API, graphqlOperation } from "aws-amplify";
import Alert from '../../components/Alert';
import Groups from '../../components/Groups/Groups'

function desc(a, b, orderBy) {
    if(orderBy === 'username') {
        if (b.username.toLowerCase() < a.username.toLowerCase()) {
            return -1;
        }
        if (b.username.toLowerCase() > a.username.toLowerCase()) {
            return 1;
        }
        return 0;
    }
    else if (orderBy === 'fullName') {
        if (b.firstName.toLowerCase() < a.firstName.toLowerCase()) {
            return -1;
        }
        if (b.firstName.toLowerCase() > a.firstName.toLowerCase()) {
            return 1;
        }
        return 0;
    }
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

class Teams extends React.Component {
    state = {
        users: this.props.location.state ? this.props.location.state.users : [],
        selected: '',
        query: "",
        page: 0,
        rowsPerPage: 10,
        dialogOpen: false,
        tabValue: this.props.location.state ? this.props.location.state.tab : 0,
        username: '',
        loadingUsers: false
    };

    componentDidMount = async () => {
        if(this.state.users.length <= 0) {
            this.setState({loadingUsers: true})
            await API.graphql(graphqlOperation(GetUserProfiles.query))
            .then(result => {
                this.setState({users: result.data.GetUserProfiles, loadingUsers: false})
            });
        }
    }

    handleAlertClose = () => {
        this.setState({showAlert: false, alertMessage: '', selectedUser: {}})
    }

    confirmDeleteUser = async () => {
        if (this.state.username === this.state.selectedUser.username.substring(this.state.selectedUser.username.indexOf('.') + 1) ) {
            //Delete user account
            await API.graphql(graphqlOperation(DeleteUserAccount.mutation, {username: this.state.selectedUser.username, key: this.state.selectedUser.key, groups: this.state.selectedUser.groups, products: this.state.selectedUser.products}))
            .then(async result => {
                if (result.data.DeleteUserAccount.result) { 
                    //Delete user from groups
                    await this.state.selectedUser.groups.map( async (group) => {
                        await API.graphql(graphqlOperation(DeleteUserFromGroup.mutation, { username: this.state.selectedUser.username, group: group }))
                        .catch((e) => {
                            this.setState({
                                showAlert: true, 
                                alertMessage: `Something went wrong deleting the user from groups - please contact SecureStack support`,
                                alertSuccess: false,
                            })
                        })
                    })
                    //Delete user from products
                    await this.state.selectedUser.products.map( async (product) => {
                        await API.graphql(graphqlOperation(DeleteUserFromProduct.mutation, { username: this.state.selectedUser.username, product: product }))
                        .catch((e) => {
                            this.setState({
                                showAlert: true, 
                                alertMessage: `Something went wrong deleting the user from products - please contact SecureStack support`,
                                alertSuccess: false,
                            })
                        })
                    })
                }
                else {
                    this.setState({
                        showAlert: true, 
                        alertMessage: `Something went wrong deleting the user ${this.state.username}`,
                        alertSuccess: false,
                    })
                } 
            })
            .then(() => {
                const users = this.state.users.filter((user) => { return user.username !== this.state.selectedUser.username });
                this.setState({
                    users: users, 
                    showAlert: true, 
                    alertMessage: `The User ${this.state.username} has been successfully deleted`,
                    alertSuccess: true,
                    dialogOpen: false,
                    username: '',
                    usernameError: false,
                    usernameMsg: '', 
                    selectedUser: {},
                    selected: ''
                })
            })
            .catch(err => {
                this.setState({
                    showAlert: true, 
                    alertMessage: `Something went wrong deleting the user ${this.state.username}`,
                    alertSuccess: false,
                })
            });
        }
        else {
            this.setState({
                usernameError: true, 
                usernameMsg: `Your input value did not match the username: ${this.state.selectedUser.username}`
            })
        }
    }

    handleDeleteDialog = () => { this.setState({dialogOpen: true}) }

    handleDialogClose = () => { this.setState({dialogOpen: false}) }

    handleUsername = (e) => {this.setState({ username: e.target.value })}

    handleRequestSort = (event, property) => {
        const orderBy = property;
        let order = 'desc';
        if (this.state.orderBy === property && this.state.order === 'desc') {
            order = 'asc';
        }
        this.setState({ order, orderBy });
    };

    handleClick = (event, user) => {
        //Select the user
        if(this.state.selected === user.username ) {
            this.setState({selected: '', selectedUser: user})
        }
        else {
            this.setState({ selected: user.username, selectedUser:  user});
        }
    };

    handleChangePage = (event, page) => { this.setState({ page }) };

    handleChangeRowsPerPage = event => { this.setState({ rowsPerPage: event.target.value }) };

    isSelected = id => this.state.selected === id

    createSortHandler = property => event => { this.handleRequestSort(event, property) };

    query(e) {
        this.setState({
            query: e.target.value,
        });
    }

    handleTabChange = (e) => {
        this.setState({tabValue: this.state.tabValue === 0 ? 1 : 0 }
    )}

    render() {
        const { classes } = this.props;
        const { tabValue, order, orderBy, selected, rowsPerPage, page, users, numSelected, showAlert, alertMessage, alertSuccess,
            dialogOpen, usernameError, username, usernameMsg, selectedUser, loadingUsers } = this.state;

        const emptyRows = rowsPerPage - Math.min(rowsPerPage, users.length - page * rowsPerPage);

        const rows = [
            { id: 'avatar', numeric: false, disablePadding: false, label: '' },
            { id: 'username', numeric: false, disablePadding: false, label: 'Username' },
            { id: 'fullName', numeric: false, disablePadding: false, label: 'Full Name' },
            { id: 'role', numeric: false, disablePadding: false, label: 'Role' },
            { id: 'phone', numeric: false, disablePadding: false, label: 'Phone No' },
            { id: 'email', numeric: false, disablePadding: false, label: 'Email' }
        ];

        let filteredSearch;
        filteredSearch = this.state.users.filter(
          (item) => {return JSON.stringify(item).toLowerCase().indexOf(this.state.query.toLowerCase()) !== -1;}
        );
          
        return (
            <div>
                <Dialog open={dialogOpen} onClose={this.handleDialogClose} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">Delete User: {selected.substring(selected.indexOf('.') + 1)}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            To Delete this user please enter their username below
                        </DialogContentText>
                        <FormControl className={classes.formControl} required error={usernameError}>
                            <Typography>Username</Typography>
                            <Input id="input-username" value={username} onChange={this.handleUsername} />
                            <FormHelperText error={usernameError}>{usernameMsg}</FormHelperText>
                        </FormControl> 
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleDialogClose} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={this.confirmDeleteUser} color="primary">
                            Delete
                        </Button>
                    </DialogActions>
                </Dialog>
                <Alert showAlert={showAlert} alertSuccess={alertSuccess} alertMessage={alertMessage} handleAlertClose={this.handleAlertClose}/>
                <AppBar position="static" color="default">
                    <Tabs
                    value={tabValue}
                    onChange={this.handleTabChange}
                    variant="fullWidth"
                    scrollButtons="on"
                    indicatorColor="primary"
                    textColor="primary">
                        <Tab label="Users" icon={<Person />} />
                        <Tab label="User Groups" icon={<Group />} />
                    </Tabs>
                </AppBar>
                {
                    tabValue === 0 ? (
                    <Paper className={classes.root}>
                        <Toolbar
                            className={classNames(classes.toolbar, {
                                [classes.highlight]: numSelected > 0,
                            })}
                        >
                        <div className={classes.title}>
                            {selected !== '' ? (
                                <Typography color="primary" variant="subtitle1">
                                    { selected.substring(selected.indexOf('.') + 1) }
                                </Typography>
                            ) : (
                                <Typography variant="h6" id="tableTitle">
                                    User's
                                </Typography>
                            )}
                        </div>
                        <div className={classes.spacer} />
                        <div className={classes.actions}>
                            { selected !== '' ? (
                                <div className={classes.tableHead}>
                                    <Tooltip title="Edit">
                                    <IconButton aria-label="EDIT" color='primary'
                                        component={Link} to={{
                                            pathname: "/UserForm",
                                            state: {
                                                isEdit: true,
                                                user: selectedUser
                                            }
                                        }}>
                                        < Edit />                                        
                                    </IconButton>                                      
                                    </Tooltip>
                                    <Tooltip title="Delete">
                                        <IconButton aria-label="Delete" onClick={() => this.handleDeleteDialog()}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                            ) : (
                                <div className={classes.headSearch}>
                                    <IconButton className={classes.iconButton} aria-label="Search">
                                        <SearchIcon />
                                    </IconButton>
                                    <InputBase 
                                        className={classes.input} 
                                        onChange={this.query.bind(this)}
                                        placeholder="Search..."
                                    />
                                    <Tooltip title="Create New User">
                                            <IconButton aria-label="Create" color='primary'
                                                component={Link} to={{
                                                    pathname: "/UserForm",
                                                    state: {
                                                        isEdit: false
                                                    }
                                                }}>
                                                <AddIcon />
                                            </IconButton>
                                    </Tooltip>
                                </div>

                            )}
                        </div>
                        </Toolbar>
                        <div className={classes.tableWrapper}>
                            <Table className={classes.table} aria-labelledby="tableTitle">
                                { loadingUsers && <CircularProgress size={24} className={classes.loadingUsers} />}
                                <TableHead>
                                    <TableRow>
                                        { rows.map((row, index) => 
                                            {
                                                return [
                                                    row.id === 'username' || row.id === 'fullName' ? 
                                                        <TableCell
                                                        key={row.id}
                                                        align='left'
                                                        padding='default'
                                                        sortDirection={orderBy === row.id ? order : false} >
                                                        <Tooltip
                                                            title="Sort"
                                                            placement='bottom-start'
                                                            enterDelay={300} >
                                                        <TableSortLabel
                                                            active={orderBy === row.id}
                                                            direction={order}
                                                            onClick={this.createSortHandler(row.id)} >
                                                            {row.label}
                                                        </TableSortLabel>
                                                        </Tooltip>
                                                    </TableCell>
                                                    :
                                                    <TableCell
                                                        key={row.id}
                                                        align='left'
                                                        padding='default' >
                                                        {row.label}
                                                    </TableCell> 
                                                ]
                                            }  
                                        )}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                { stableSort(filteredSearch, getSorting(order, orderBy))
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((n, i) => {
                                    const isSelected = this.isSelected(n.username);
                                    if(isSelected) {
                                        return [
                                            <TableRow
                                                hover
                                                onClick={event => this.handleClick(event, n)}
                                                aria-checked={isSelected}
                                                tabIndex={-1}
                                                key={n.key+i}
                                                selected={isSelected} >
                                                <TableCell align="left"><Avatar alt="pic" src={n.profilePic}/></TableCell>
                                                <TableCell component="th" scope="row" padding='default'>{n.username.substring(n.username.indexOf('.') + 1)}</TableCell>
                                                <TableCell align="left">{n.firstName} {n.lastName}</TableCell>
                                                <TableCell align="left">{n.isAdmin ? 'Administrator' : 'User'}</TableCell>
                                                <TableCell align="left">{n.phoneNumber}</TableCell>
                                                <TableCell align="left">{ n.email}</TableCell>
                                            </TableRow>,
                                            <TableRow
                                                selected={isSelected}>
                                                <TableCell key={n.key+i} style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={4}></TableCell>
                                            </TableRow>
                                        ];
                                    }
                                    else {
                                        return (
                                            <TableRow
                                                hover
                                                onClick={event => this.handleClick(event, n)}
                                                aria-checked={isSelected}
                                                tabIndex={-1}
                                                key={n.key+i}
                                                selected={isSelected} >
                                                <TableCell align="left"><Avatar alt="pic" src={n.profilePic}/></TableCell>
                                                <TableCell component="th" scope="row" padding='default'>{n.username.substring(n.username.indexOf('.') + 1)}</TableCell>
                                                <TableCell align="left">{n.firstName} {n.lastName}</TableCell>
                                                <TableCell align="left">{n.isAdmin ? 'Administrator' : 'User'}</TableCell>
                                                <TableCell align="left">{n.phoneNumber}</TableCell>
                                                <TableCell align="left">{ n.email}</TableCell>
                                            </TableRow>
                                        )
                                    }
                                    })
                                }
                                { emptyRows > 0 && (
                                    <TableRow style={{ height: 49 * emptyRows }}>
                                    <TableCell colSpan={6} />
                                    </TableRow>
                                )}
                                </TableBody>
                        </Table>
                        </div>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={users.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            backIconButtonProps={{
                                'aria-label': 'Previous Page',
                            }}
                            nextIconButtonProps={{
                                'aria-label': 'Next Page',
                            }}
                            onChangePage={this.handleChangePage}
                            onChangeRowsPerPage={this.handleChangeRowsPerPage} />
                    </Paper>)
                : (
                    <Groups users={users} />
                )
                }
            </div>
        );
    }
}

Teams.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(style)(Teams);