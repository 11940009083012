export default { mutation: `
  mutation 
    CreateFirewallRule(
      $firewallName: String
      $sources: [String]
      $destinations: [String]
      $protocols: ProtocolInput
      $ports: [String]
      $comment: String 
    ) {
    CreateFirewallRule(firewallRule: {
      firewallName: $firewallName
      sources: $sources
      destinations: $destinations
      protocols: $protocols
      ports: $ports
      comment: $comment
    }) 
    {
      firewallName
      sources
      destinations
      protocols {
        tcp
        udp
      }
      ports
      comment
      key
    }
  }`
};
