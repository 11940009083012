const useStyles = theme => ({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    formControl: {
        width: '100%',
        marginTop: 20,
        marginBottom: 20
    },
    button: {
        width: '100%',
        marginTop: 20,
        marginBottom: 20
    },
    buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    icon: {
        'vertical-align': 'middle',
        marginRight: theme.spacing(0.5),
        width: 20,
        height: 20,
    },
    bread: {
        marginBottom: 20,
        padding: 15
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: '33.33%',
        flexShrink: 0,
      },
      secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
      },
});

export default useStyles;