export default { query: `
  query GetInvoices($customerID: String) {
    GetInvoices(customerID: $customerID) {
      id
      pdf
      status
      from
      to
      amount
      paid
      }
  }`
};
