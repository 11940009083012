import React from 'react';
import ReactGA from 'react-ga';
import { API, graphqlOperation } from "aws-amplify";
import GetTargets from '../../graphql/queries/GetTargets';
import TargetForm from "../../components/TargetForm/TargetFrom"
import TargetCard from '../../components/TargetCard/TargetCard';
import DeleteTarget from '../../graphql/mutations/DeleteTarget';
import { Card, Grid, Tooltip, CardHeader, IconButton, CardContent, Toolbar, InputBase } from '@material-ui/core';
import { Add, Search } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import style from './Style'
import PropTypes from 'prop-types';
import Alert from '../../components/Alert';
import ConfirmDeleteDialog from '../../components/ConfirmDeleteDialog';

var mixpanel = require('mixpanel-browser');

class Targets extends React.Component {
  constructor(props) {
    super(props);
    this.editTarget = this.editTarget.bind(this);
    this.deleteTarget = this.deleteTarget.bind(this);
    this.updateTargetsList = this.updateTargetsList.bind(this);
    this.state = {
      props: props,
      targets: [],
      showLoading: true,
      isEdit: false,
      showList: true,
      showForm: false,
      query: '',
      dialogOpen: false
    };
  }

  async componentDidMount() {
    //Add Google tracking
    ReactGA.initialize('UA-141370158-1');
    ReactGA.pageview('/Targets');

    //Pull Data From DB
    await API.graphql(graphqlOperation(GetTargets.query))
    .then(result => {
      this.setState({
        targets: result.data.GetTargets,
        showLoading: false,
        target: result.data.GetTargets[0] 
      })
    })
    .catch(err => {
      this.setState({showAlert: true, alertMessage: 'Oh No! something went wrong pulling your targets - Please try refreshing the page', alertSuccess: false})
    });
  }

  updateTargetsList(result) {
    this.setState({targets: result})
  }

  editTarget(target, key) {
    this.setState({target: target, keyValue: key, isEdit: true, showForm: true})
  }

  toggleForm(editing) {
    this.setState({
      showForm: !this.state.showForm,
      isEdit: editing,
    });
  }

  query(e) {
    this.setState({
      query: e.target.value,
    });
  }

  handleAlertClose = () => {
    this.setState({showAlert: false, alertMessage: ''})
  }

  deleteTarget = (target) => {
    if(target.active) {
      this.setState({showAlert: true, alertMessage: 'You are unable to delete this Target as it is currently active in a Product', alertSuccess: false})
    }
    else {
      this.setState({dialogOpen: true, target: target, confirmTargetName: target.targetName, cardLoading: true});
    }
  }

  confirmDeleteTarget = async () => {
    this.setState({DeleteLoading: true})
    var name = this.state.targetName;
    var targetInput = {
      "key": this.state.target.key
    };
    await API.graphql(graphqlOperation(DeleteTarget.mutation, targetInput))
    .then(result => {
        this.updateTargetsList(result.data.DeleteTarget)
        //Reset Page Values and Show Dialog
        this.setState({targetNameMsg: '', targetNameError: false, dialogOpen: false, 
          cardLoading: false, target: {}, targetName: '', confirmTargetName: '', 
          showAlert: true, alertMessage: ('Successfully Deleted Target: ' + name), alertSuccess: true,
          DeleteLoading: false
        });
        mixpanel.track("Target Deleted");
    })
    .catch((e) => {
      this.setState({targetNameMsg: '', targetNameError: false, dialogOpen: false, 
        cardLoading: false, target: {}, targetName: '', confirmTargetName: '', 
        showAlert: true, alertMessage: ('Error Deleting Target: ' + name), alertSuccess: false,
        DeleteLoading: false
      });
    })
  }

  handleClose = () => {
    this.setState({dialogOpen: false, cardLoading: false})
  }

  render() {
    const { classes } = this.props;
    const {alertMessage, showAlert, alertSuccess, dialogOpen, DeleteLoading, cardLoading} = this.state;

    let filteredSearch;
    filteredSearch = this.state.targets.filter(
      (item) => {return JSON.stringify(item).toLowerCase().indexOf(this.state.query.toLowerCase()) !== -1;}
    );
    return (<div>
        <ConfirmDeleteDialog name={this.state.target ? this.state.target.targetName : ''} type='Target' open={dialogOpen} handleDialogClose={this.handleClose} loading={DeleteLoading}
          confirmDelete={this.confirmDeleteTarget }/>
        <Alert showAlert={showAlert} alertSuccess={alertSuccess} alertMessage={alertMessage} handleAlertClose={this.handleAlertClose}/>
        {
          this.state.showForm ?
          (
            <div>
              {
                this.state.isEdit ?
                (
                  <TargetForm 
                    isCreate={false} 
                    target={this.state.target} 
                    handleTarget={this.updateTargetsList}
                    toggleForm={() => this.toggleForm(false)}/>
                ):(
                  <TargetForm 
                    isCreate={true} 
                    handleTarget={this.updateTargetsList} 
                    toggleForm={() => this.toggleForm(false)}/>
                )
              }
            </div>
          ):(
            <Card>
              <CardHeader
                title='Targets'
                subheader={'Available Targets'}
                action= {
                    <Toolbar>
                    <div className={classes.search}>
                      <div className={classes.searchIcon}>
                        <Search />
                      </div>
                      <InputBase
                        onChange={this.query.bind(this)}
                        placeholder="Search..."
                        classes={{
                          root: classes.inputRoot,
                          input: classes.inputInput,
                        }}
                        inputProps={{ 'aria-label': 'Search' }}
                      />
                    </div>
                    <Tooltip title="Create a new Target" aria-label="Create a new Target">     
                        <IconButton aria-label="arrow_back" disabled={this.state.currentUserAdmin ? true : false} onClick={()=>this.toggleForm(false)}>
                            <Add />
                        </IconButton> 
                    </Tooltip>
                    </Toolbar>
                }
              />
              <CardContent>
              <Grid container spacing={3}>
                  {
                    filteredSearch.map((target, key) => 
                    <Grid item xs key={key}>
                      <TargetCard 
                        key={key} 
                        keyValue={key} 
                        target={target} 
                        cardLoading={cardLoading}
                        deleteTarget={this.deleteTarget}
                        editTarget={this.editTarget} />
                    </Grid>
                    )
                  }
              </Grid>
              </CardContent>
            </Card>
          )
        }
        </div>
    );
  }
}

Targets.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(style)(Targets);
