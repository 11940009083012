export default {
    query: `
        query GetProfiles {
            GetProfiles {
                profileName
                antiVirus
                key
                applicationMonitoring
                intrusionPrevention
                systemMonitoring
                malwareProtection
                mfa
                waf
                iamRoles
                securityGroups
                awsTags {
                    name
                    value
                }
                firewallRules
                automaticScanning
                automaticScanningSchedule
                automaticPatching
                automaticPatchingSchedule
                automaticBackups
                automaticBackupsSchedule
                tags {
                    name
                    value
                }
                encryptBootdisk
                terminationProtection
                enablePublicIP
                siipServer {
                    ipAddress
	                authDKey
                }
                active
                complianceFrameworks {
                    cis
                    nist
                    disa_stig
                    pci_dss
                    hipaa
                    australian_ism
                }
                sovereign {
                    sovereignAU
                    sovereignUSA
                    sovereignCanada
                    awsGovcloud
                    auCloud
                }
            }
        }
    `,
};
