import React from 'react';
import { Card, CardHeader, Tooltip, IconButton, CardContent, Typography, Input, FormHelperText, FormControl, Button,
CircularProgress, Checkbox, makeStyles  } from '@material-ui/core/';
import { ArrowBack } from '@material-ui/icons';
import { API, graphqlOperation } from "aws-amplify";
import style from './Style'
import Alert from '../../components/Alert';
import { Link } from 'react-router-dom';
import EncryptAmazonAccount from '../../graphql/mutations/EncryptAmazonAccount';
import EncryptAzureAccount from '../../graphql/mutations/EncryptAzureAccount';

const validator = {
  name: /^[a-zA-Z0-9.-_@-]+$/,
  accountNumber: /^[0-9]+$/,
}

const emptyValue = {
  value: '',
  error: false,
  errorMsg: ''
}

export default function ProvidersForm(props) {
  const classes = makeStyles(style);
  const isEdit = props.location.state ? props.location.state.isEdit : false;
  const cloud = props.location.state ? props.location.state.cloud : 'aws';
  const provider = props.location.state ? props.location.state.provider : {};
  const names = props.location.state ? props.location.state.names : [];

  const [alert, updateAlert] = React.useState({
    alertMessage: '',
    alertSuccess: false,
    showAlert: false
  })

  const [showLoading, setShowLoading] = React.useState(false)

  //Global Form Vars
  const [name, setName] = React.useState({ value: isEdit  ? provider.name : '', errorMsg: '', error: false })
  const [personal, setPersonal] = React.useState(isEdit ? provider.personal : false)
  const [clientId, setClientId] = React.useState({ value: isEdit ? provider.clientId : '', errorMsg: '', error: false })

  //AWS Form Vars
  const [accountNumber, setAccountNumber] = React.useState({ value: isEdit && cloud === 'aws' ? provider.accountNumber : '', errorMsg: '', error: false })
  const [secretKey, setSecretKey] = React.useState({ value: '', errorMsg: '', error: false })

  //AZURE Form Vars
  const [clientSecret, setClientSecret] = React.useState({ value: '', errorMsg: '', error: false })
  const [tenantId, setTenantId] = React.useState({ value: isEdit && cloud === 'azure' ? provider.tenantId : '', errorMsg: '', error: false })
  const [subscriptionId, setSubscriptionId] = React.useState({ value: isEdit && cloud === 'azure' ? provider.subscriptionId : '', errorMsg: '', error: false })

  const handleAlertClose = () => { updateAlert({ alertMessage: '', alertSuccess: false, showAlert: false }) }

  const clearForm = () => { setName(emptyValue); setAccountNumber(emptyValue); setClientId(emptyValue); setSecretKey(emptyValue); setSubscriptionId(emptyValue); setTenantId(emptyValue); setClientSecret(emptyValue); setPersonal(false) }

  const handleName = (e) => {
    if(e.target.value.match(validator.name)) {
      if(names.includes(e.target.value)) 
        setName({ value: e.target.value, errorMsg: 'Account name must be unique', error: true })
      else
        setName({ value: e.target.value, errorMsg: '', error: false })
    }     
    else 
      setName({ value: e.target.value, errorMsg: 'Invalid Name', error: true })
  }

  const handleClientId = (e) => { setClientId({ value: e.target.value, errorMsg: '', error: false }) }

  const hanldeSubscriptionId = (e) => { setSubscriptionId({ value: e.target.value, errorMsg: '', error: false }) }

  const handleAccountNumber = (e) => {
    if(e.target.value.match(validator.accountNumber)) 
      setAccountNumber({ value: e.target.value, errorMsg: '', error: false })
    else 
      setAccountNumber({ value: e.target.value, errorMsg: 'Invalid Account Number', error: true })
  }

  const handleSecretKey = (e) => { setSecretKey({ value: e.target.value, errorMsg: '', error: false }) }

  const handleTenantId = (e) => { setTenantId({ value: e.target.value, errorMsg: '', error: false }) }

  const handleClientSecret = (e) => { setClientSecret({ value: e.target.value, errorMsg: '', error: false }) }

  const handlePersonal = () => { setPersonal(!personal) }

  var amazonAccount = {
    name: name.value,
    subId: props.user.sub,
    accountNumber: accountNumber.value,
    clientId: clientId.value,
    secretKey: secretKey.value,
    master: false,
    personal: personal,
  }

  const createAWSAccount = async () => {
    setShowLoading(true)
    await API.graphql(graphqlOperation(EncryptAmazonAccount.mutation, {amazonAccount: amazonAccount}))
    .then(async result => {
        setShowLoading(false)
        updateAlert({ alertMessage: `A new AWS account called ${name.value} has been created`, alertSuccess: true, showAlert: true })
        clearForm()
    })
    .catch(err => {
      console.log(err)
      setShowLoading(false)
      updateAlert({ alertMessage: `Error creating the AWS account called ${name.value}`, alertSuccess: false, showAlert: true })
    });
  }

  const updateAWSAccount = async () => {
    setShowLoading(true)
    await API.graphql(graphqlOperation(EncryptAmazonAccount.mutation, {amazonAccount: amazonAccount}))
    .then(async result => {
        setShowLoading(false)
        updateAlert({ alertMessage: `A new AWS account called ${name.value} has been updated`, alertSuccess: true, showAlert: true })
    })
    .catch(err => {
      console.log(err)
      setShowLoading(false)
      updateAlert({ alertMessage: `Error updating the AWS account called ${name.value}`, alertSuccess: false, showAlert: true })
    });
  }

  var azureAccount = {
    name: name.value,
    subId: props.user.sub,
    clientId: clientId.value,
    clientSecret: clientSecret.value,
    tenantId: tenantId.value,
    subscriptionId: subscriptionId.value,
    master: false,
    personal: personal
  }


  const createAzureAccount = async () => {
    setShowLoading(true)
    await API.graphql(graphqlOperation(EncryptAzureAccount.mutation, {azureAccount: azureAccount}))
    .then(async result => { 
      setShowLoading(false)
      updateAlert({ alertMessage: `A new Azure account called ${name.value} has been created`, alertSuccess: true, showAlert: true })
      clearForm()
    })
    .catch(err => {
      setShowLoading(false)
      updateAlert({ alertMessage: `Error creating the Azure account called ${name.value}`, alertSuccess: false, showAlert: true })
      console.log(err)
    })
  }

  const updateAzureAccount = async () => {
    setShowLoading(true)
    await API.graphql(graphqlOperation(EncryptAzureAccount.mutation, {azureAccount: azureAccount}))
    .then(async result => { 
      setShowLoading(false)
      updateAlert({ alertMessage: `The Azure account ${name.value} has been updated`, alertSuccess: true, showAlert: true })
    })
    .catch(err => {
      setShowLoading(false)
      updateAlert({ alertMessage: `Error updating the Azure account called ${name.value}`, alertSuccess: false, showAlert: true })
      console.log(err)
    })
  }

  var formComplete =  
    cloud === 'aws' ? 
      !name.error && name.value !== '' &&
      !clientId.error && clientId.value !== '' &&
      !accountNumber.error && accountNumber.value !== '' &&
      !secretKey.error && secretKey.value !== ''
    :
    cloud === 'azure' ? 
      !name.error && name.value !== '' &&
      !clientId.error && clientId.value !== '' &&
      !clientSecret.error && clientSecret.value !== '' &&
      !tenantId.error && tenantId.value !== '' &&
      !subscriptionId.error && subscriptionId.value !== ''
    :
    false
      
  return (
    <div>
      <Alert showAlert={alert.showAlert} alertSuccess={alert.alertSuccess} alertMessage={alert.alertMessage} handleAlertClose={handleAlertClose}/>
      <Card>
        <CardHeader
          title={(!isEdit ? "Create" : "Update" ) + ' Provider'}
          subheader={!isEdit ? "Create a new securestack provider" : `Update ${cloud.toUpperCase()} provider`}
          action= {
            <Tooltip title="Back to Providers list" aria-label="provider_list">     
                <IconButton aria-label="arrow_back" component={Link} to={{
                  pathname: "/Providers"
                }}>
                  <ArrowBack />
                </IconButton> 
            </Tooltip>
          }
        />
        <CardContent>
          <form>
            <FormControl className={classes.formControl} required error={name.error}>
              <Typography color="textSecondary" className={classes.typo}>Provider Name</Typography>
              <Input id="input-name" autoComplete="off" value={name.value} onChange={handleName} disabled={isEdit} />
              <FormHelperText error={name.error}>{name.errorMsg}</FormHelperText>
            </FormControl> 
            <FormControl className={classes.formControl} required error={clientId.error}>
              <Typography color="textSecondary" className={classes.typo}>Client Id</Typography>
              <Input id="input-clientId" autoComplete="off" value={clientId.value} onChange={handleClientId} />
              <FormHelperText error={clientId.error}>{clientId.errorMsg}</FormHelperText>
            </FormControl>
            {
              cloud === 'aws' ?
                <React.Fragment>
                  <FormControl className={classes.formControl} required error={accountNumber.error}>
                    <Typography color="textSecondary" className={classes.typo}>Account Number</Typography>
                    <Input id="input-accountNumber" autoComplete="off" value={accountNumber.value} onChange={handleAccountNumber} />
                    <FormHelperText error={accountNumber.error}>{accountNumber.errorMsg}</FormHelperText>
                  </FormControl>
                  <FormControl className={classes.formControl} required error={secretKey.error}>
                    <Typography color="textSecondary" className={classes.typo}>Secret Key</Typography>
                    <Input id="input-secretKey" autoComplete="off" value={secretKey.value} onChange={handleSecretKey} />
                    <FormHelperText error={secretKey.error}>{secretKey.errorMsg}</FormHelperText>
                  </FormControl>
                </React.Fragment>
              : cloud === 'azure' ?
                <React.Fragment>
                  <FormControl className={classes.formControl} required error={tenantId.error}>
                    <Typography color="textSecondary" className={classes.typo}>Tenant Id</Typography>
                    <Input id="input-tenantId" autoComplete="off" value={tenantId.value} onChange={handleTenantId} />
                    <FormHelperText error={tenantId.error}>{tenantId.errorMsg}</FormHelperText>
                  </FormControl>
                  <FormControl className={classes.formControl} required error={clientSecret.error}>
                    <Typography color="textSecondary" className={classes.typo}>Client Secret</Typography>
                    <Input id="input-clientSecret" autoComplete="off" value={clientSecret.value} onChange={handleClientSecret} />
                    <FormHelperText error={clientSecret.error}>{clientSecret.errorMsg}</FormHelperText>
                  </FormControl>
                  <FormControl className={classes.formControl} required error={subscriptionId.error}>
                    <Typography color="textSecondary" className={classes.typo}>Subscription Id</Typography>
                    <Input id="input-subscriptionId" autoComplete="off" value={subscriptionId.value} onChange={hanldeSubscriptionId} />
                    <FormHelperText error={subscriptionId.error}>{subscriptionId.errorMsg}</FormHelperText>
                  </FormControl>
                </React.Fragment>
              : null
            }
            <Typography color="textSecondary" className={classes.typo}>Personal</Typography>
            <Checkbox checked={personal} onChange={handlePersonal} />
            <div className={classes.wrapper}>
              <Button
                  variant="outlined"
                  color="primary"
                  className={classes.button}
                  disabled={showLoading || !formComplete}
                  onClick={
                    isEdit ? 
                      cloud === 'aws' ? 
                        updateAWSAccount
                      : cloud === 'azure' ?
                        updateAzureAccount
                      : null
                    :
                      cloud === 'aws' ? 
                        createAWSAccount
                      : cloud === 'azure' ?
                        createAzureAccount
                      : null
                  } >
              {isEdit ? "Update" : "Create"} Provider
              </Button>
              {showLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
            </div> 
          </form>
        </CardContent>
      </Card>
    </div>
  );
}

