import { API, graphqlOperation  } from "aws-amplify";

const query = `
    query GetFirewallRules {
        GetFirewallRules {
            firewallName
            sources
            destinations
            protocols {
                tcp
                udp
            }
            ports
            comment
            key
        }
    }
`;

const operation = () => graphqlOperation(query);
export default {
    query,
    operation,
    get: () => API.graphql(operation()),
    getNames: () => {
        return API.graphql(graphqlOperation(`
            query GetFirewallRulesNames {
                GetLaunchConfig {
                    firewallRules {
                        firewallName
                    }
                }
            }
        `));
    },
};

