import GetUserProfiles from "../graphql/queries/GetUserProfiles";
import CreateNotification from '../graphql/mutations/CreateNotification';
import { API, graphqlOperation  } from "aws-amplify";

export default async function PushNotification(topic, message, businessId) {
  const users = {};
  await API.graphql(graphqlOperation(GetUserProfiles.query))
    .then(async result => {
      await Promise.all(result.data.GetUserProfiles.map(async (user) => {
        users[user.key] = {dismissed:false};
      })).then(async () => {
        const notification = {
          topic: topic,
          timestamp: new Date().toISOString(),
          message: message,
          users: JSON.stringify(users),
        };
        await API.graphql(graphqlOperation(CreateNotification.mutation, {businessId: businessId, notification: notification}))
          .then(result => {
            return result
          })
          .catch(err => {
            console.log(err)
            return err
          });
      });
    })
  .catch(err => {
    return err;
  });
}