export default { mutation: `mutation DeleteAmazonAccount (
    $name: String
){
    DeleteAmazonAccount (
        AmazonAccount: {
            name: $name
        }
    ){
        result
        payload
    }
}`
};

