import { makeStyles } from '@material-ui/core/styles';

const style = makeStyles(theme => ({
    formControl: {
        width: '100%',
        marginTop: 20,
        marginBottom: 20
    },
    buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    wrapper: {
        position: 'relative',
    },
    button: {
        width: '100%',
        marginTop: 20,
        marginBottom: 20
    },
    taglist: {
        marginTop: 10,
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
    },
    tagInput: {
        marginRight: 20,
        flex: 1
    },
}))

export default style;