const style = theme => ({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    formControl: {
        width: '100%',
        marginTop: 20
    },
    inputRoot: {
        color: 'inherit',
    },
    button: {
        width: '90%',
        margin: 20 
    },
    wrapper: {
        position: 'relative',
    },
    inputInput: {
        marginLeft: 20,
        padding: 7,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: 120,
            '&:focus': {
            width: 200,
            },
        },
    },
    stepperButton: {
        marginRight: 1,
    },
    completed: {
        display: 'inline-block',
    },
    instructions: {
        marginTop: 1,
        marginBottom: 1,
    },
    list: {
        marginTop: 20,
    },
    nestedListItem: {
        paddingLeft: 60
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: '33.33%',
        flexShrink: 0,
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
    icon: {
        'vertical-align': 'middle',
        marginRight: theme.spacing(0.5),
        width: 20,
        height: 20,
    },
    bread: {
        marginBottom: 20,
        padding: 15
    },
    chip: {
        marginLeft: 5
    },
    taglist: {
        marginTop: 10,
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
    }
});

export default style;