export default { mutation: `
mutation ImportSSHKey (
    $name: String,
    $credential: String,
    $region: [String],
    $key: String
){
    ImportSSHKey (
        SSHKeyImport: {
            name: $name
            credential: $credential
            region: $region
            key: $key
        }
    ){
        result
        payload
    }
}
`
};
