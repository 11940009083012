export default  { mutation: `mutation CreateNotification(
    $businessId: String!
    $notification: NotificationInput) {
    CreateNotification(businessId: $businessId, notification: $notification) {
      businessId
	  	Notifications {
        index
        topic
        timestamp
        message
      }
    }
  }`
};
