import React, { Component } from 'react';
import "tabler-react/dist/Tabler.css";
import { Page, StatsCard, Grid, Tab, TabbedCard, Button, Header, Alert } from "tabler-react";
import SiteWrapper from '../components/SiteWrapper/SiteWrapper';

export default class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      props: props
    };
  }

  componentDidMount() {

  }

  render() {
    return (
      <SiteWrapper data={this.state.props}>
        <Page.Content title="Cost">
                 <TabbedCard initialTab="Daily Spend">
                        <Tab title="Daily Spend">

        <Grid.Row cards deck>
          <Grid.Col md={2}>
            <StatsCard layout={1} movement={0} total="0" label="Critical Alerts" />
          </Grid.Col>
          <Grid.Col md={2}>
            <StatsCard layout={1} movement={-75} total="2" label="Warnings Alerts" />
          </Grid.Col>
        </Grid.Row>

<Alert type="primary" icon="bell" isDismissible>
  <Header.H4>2 warnings from your cloud providers</Header.H4>
  <p>
    There are two alerts from your cloud providers.  AWS has sent you an alert because a new IAM group was created.
    GCP has sent you an alert that a ssh key is expired. 
    Do you want to address these alerts now?
  </p>
  <Button.List>
    <Button color="success" RootComponent="button">
      Okay
    </Button>
    <Button color="secondary" RootComponent="button">
      Remind me later
    </Button>
  </Button.List>
</Alert>
	</Tab>

        <Tab title="Monthly Spend">
        <Grid.Row>
          <Grid.Col md={2}>
            <StatsCard layout={1} movement={-75} total="0" label="Instance Fatal" />
          </Grid.Col>
          <Grid.Col md={2}>
            <StatsCard layout={1} movement={+33} total="3" label="Instance Warnings" />
          </Grid.Col>
        </Grid.Row>
	</Tab>
        </TabbedCard>


        </Page.Content>
        </SiteWrapper>
    );
  }
}

