import { fade } from '@material-ui/core/styles/colorManipulator';
import { lighten } from '@material-ui/core/styles/colorManipulator';

const style = theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing(3),
    },
    table: {
        minWidth: 1020,
    },
    tableWrapper: {
        overflowX: 'auto',
    }, 
    toolbar: {
        paddingRight: 1,
    },
    tableHead: {
        width: 150,
    },
    headSearch: {
        width: 500,
        backgroundColor: fade('rgba(228, 233, 237, 50)', 0.15),
        '&:hover': {
            backgroundColor: fade('rgba(228, 233, 237, 1)', 0.25),
        },
        flexDirection: 'row'
    },
    highlight:
        theme.palette.type === 'light'
        ? {
            color: theme.palette.secondary.main,
            backgroundColor: lighten(theme.palette.secondary.light, 0.85),
            }
        : {
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.secondary.dark,
        },
    spacer: {
        flex: '1 1 100%',
    },
    actions: {
        color: theme.palette.text.secondary,
    },
    title: {
        flex: '0 0 auto',
    },
    input: {
        width: '80%'
    },
    formControl: {
        width: '90%',
        margin: 20 
    },
    loadingUsers: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
});

export default style;