import React, { Component } from 'react';
import "tabler-react/dist/Tabler.css";
import { Form, Grid, Avatar, Alert, Card, Button, Header, Media, List, Dropdown, Loader } from "tabler-react";
import { Storage } from "aws-amplify";
import { API, Auth, graphqlOperation  } from "aws-amplify";
import QRCode from 'qrcode.react';
import GetSSHKeys from '../graphql/queries/GetSSHKeys';
import CreateSShKey from '../graphql/mutations/CreateSSHKey';
import ImportSSHKey from '../graphql/mutations/ImportSSHKey';
import DeleteSSHKey from '../graphql/mutations/DeleteSSHKey';
import WriteFederatedIdentity from '../graphql/mutations/WriteFederatedIdentity';
import AWSRegions from "../content/regions";
import AzureRegions from "../content/azureregions";
import GetAccounts from '../graphql/queries/GetAccounts';
import update from 'immutability-helper';

class UserProfileForm extends Component {
    constructor(props) {
        super(props);
        this.handleConfirmation = this.handleConfirmation.bind(this);
        this.resetMFA = this.resetMFA.bind(this);
        this.resetPassword = this.resetPassword.bind(this);
        this.setQRCode = this.setQRCode.bind(this);
        this.state = {
            resetLoading: false,
            imageError: true,
            qr: 'https://www.securestack.com.au/',
            mfaUpdate: false,
            resetPasswordColor: 'info',
            resetPasswordMessage: 'Password Policy: minimum 12 characters, at least 1 number, at least 1 special character and at least 1 uppercase character  ',
            loadingKeys: true,
            keys: [],
            editKeys: false,
            values: [{}, {}, {}, {}],
            provider: "",
            accounts: [],
            importKeys: false,
            type: 0, // Type 0 = Create , 1 = import
            ssherror: false,
            ssherrortype: "",
            ssherrormessage: "",
            addingKey: false
        };
        this.getS3(this.props.user.username+'.png')
    }

    async componentDidMount() {
        // Get credentials to populate SSH form
        await API.graphql(graphqlOperation(GetAccounts.query))
        .then(async result => {
            var data = JSON.parse(result.data.GetAccounts.payload);
            //populate drop down options for create/edit keys
            data.map((key) =>  { return { name: key.name, provider: key.provider } })
            this.setState({ accounts: data });

            //Get Keys
            const keys = data.map(async (item) => {
                let params = {
                    accountName: item.name,
                }
                await API.graphql(graphqlOperation(GetSSHKeys.query, params)).then(result => {
                    if(result.data.GetSSHKeys) {
                        var keys = JSON.parse(result.data.GetSSHKeys.payload)
                        let currentKeys = this.state.keys;
                        var newKeys = keys.concat(currentKeys)
                        this.setState({keys: newKeys})
                    }
                })
            });

            await Promise.all(keys).then(() => {
                this.setState({loadingKeys: false})
            })
        })
        .catch(err => {
            console.log(err);
            this.setState({
                ssherror: true,
                ssherrortype: "warning",
                ssherrormessage: "Error Fetching SSH Keys"
            });
        });
    }

    resetValues = () => {
        this.setState({
            values: [{}, {}, {}, {}]    
        });
    }

    handleValidate(field, regexp, e) {
        var regex = RegExp(regexp);
        let temp = this.state.values;
        temp[field].value = e.target.value;
        if (e.target.value.match(regex)) {
            temp[field].valid = true;
            this.setState({
                values: temp,
            });
        } else {
            temp[field].valid = false;
            this.setState({
                values: temp,
            });
        }
    }

    toggleSSHform = (type) => {
        if (this.state.editKeys) {
            // form is open
            this.setState({
                editKeys: false,
                type: 0,
            });
        } else {
            // form is closed
            this.setState({
                editKeys: true,
                type: type,
            });
        }
        this.resetValues();
    }

    importSSHKey = () => {
        console.log('import')
        // Importing
        // Check for errors and unused values
        var errors = false;
        this.state.values.forEach(item => {
            if(!item.valid) {
                errors = true;
            }
        });
        // Import the key
        if (errors) {
            // Errors, display message
            this.setState({
                ssherror: true,
                ssherrortype: "danger",
                ssherrormessage: "Error while adding the SSH Key."
            });
        } else {
            // No errors, import the key
            // Add the key
            this.setState({addingKey: true})
            var regions = []
            // Add the key
            if (this.state.values[2].value === 'all') {
                regions = AWSRegions
            }
            else {
                regions.push(this.state.values[2].value)
            }

            let SSHKeyImportInput = {
                name: this.props.user.username + "_" + this.state.values[0].value,
                credential: this.state.values[1].value,
                region: regions,
                key: this.state.values[3].value,
            }
            API.graphql(graphqlOperation(ImportSSHKey.mutation, SSHKeyImportInput))
            .then(result => {
                regions.forEach((region) => {
                    let newState = update(this.state, {
                        keys: {  $push: [{
                            name: this.state.values[0].value,
                            credential: this.state.values[1].value,
                            region: region
                        }]}  
                    })
                    this.setState(newState);
                })
                this.setState({
                    ssherror: true,
                    ssherrortype: "success",
                    ssherrormessage: "Key imported successfully.",
                    downloadLink: false,
                    addingKey: false
                });
                this.toggleSSHform(0);
            })
            .catch(error => {
                this.setState({
                    ssherror: true,
                    ssherrortype: "danger",
                    ssherrormessage: "Error while adding the SSH Key.",
                    downloadLink: false
                });
            });
        }
    }

    
    addSSHKey = () => {
        // Creating
        // Revome last value
        let tmp = this.state.values;
        tmp.pop();
        this.setState({
            values: tmp,
        });
        
        // Check for errors and unused values
        var errors = false;
        this.state.values.forEach(item => {
            if(!item.valid) {
                console.log(item);
                errors = true;
            }
        });

        // Add the key
        if (errors) {
            // There are errors, display error message
            this.setState({
                ssherror: true,
                ssherrortype: "danger",
                ssherrormessage: "Error while adding the SSH Key."
            });
        } else {
            // No errors, add the key
                this.setState({addingKey: true})
                var regions = []
                // Add the key
                if (this.state.values[2].value === 'all') {
                    regions = AWSRegions
                }
                else {
                    regions.push(this.state.values[2].value)
                }
                let SSHKeyInput = {
                    name: this.props.user.username + "_" + this.state.values[0].value,
                    credential: this.state.values[1].value,
                    region: regions
                }
                API.graphql(graphqlOperation(CreateSShKey.mutation, SSHKeyInput))
                .then(result => {
                    if(result.data.CreateSSHKey) {            
                        regions.forEach((region) => {
                            let newState = update(this.state, {
                                keys: {  $push: [{
                                    name: this.state.values[0].value,
                                    credential: this.state.values[1].value,
                                    region: region
                                }]}  
                            })
                            this.setState(newState);
                        })

                        this.setState({
                            ssherror: true,
                            ssherrortype: "success",
                            ssherrormessage: "Key added successfully.   ",
                            downloadLink: true,
                            addingKey: false
                        }, () =>{
                            var element = document.getElementById('downloadLink');
                            element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(JSON.parse(result.data.CreateSSHKey.payload)));
                            element.setAttribute('download', this.state.values[0].value+'.pem');
                        });
                        this.toggleSSHform(0);
                    }
                    else {
                        this.setState({
                            addingKey: false,
                            ssherror: true,
                            ssherrortype: "danger",
                            ssherrormessage: "Error while adding the SSH Key."
                        }); 
                    }
                })
                .catch(error => {
                    this.setState({
                        addingKey: false,
                        ssherror: true,
                        ssherrortype: "danger",
                        ssherrormessage: "Error while adding the SSH Key."
                    });
                });
        }
    }

    deleteSSHKey = (key, index) => {
        // Add the key
        let newState = update(this.state, {
            keys: {
                [index]: {
                    loading: { $set: true  },
                }
            }
        })
        this.setState(newState);
        var region = [key.region];
        let SSHKeyInput = {
            name: key.name,
            credential: key.credential,
            region: region
        }
        API.graphql(graphqlOperation(DeleteSSHKey.mutation, SSHKeyInput))
        .then(result => {
            let newState = update(this.state, {
                keys: arr => arr.filter(k => k.region+k.name !== key.region+key.name),
            })
            this.setState(newState); 
        })
        .catch(error => {
            console.log(error);
        });
    }

    getS3 = (key) => {
        Storage.get(key, {level: 'private'})
        .then(result => {
            fetch(result).then(res => {
                if(res.status !== 403)            
                    this.setState({profilePic: result})
                else
                    this.setState({profilePic: '/images/profilePic.png'})
            })
        })
        .catch(err => {
            this.setState({profilePic: '/images/profilePic.png'})
        });
    }
    
    updateS3 = (image) => {
        this.setState({showLoading: true});
        const file = image.target.files[0];
        Storage.put(this.props.user.username+'.png', file, {
            level: 'private', 
            contentType: 'image'
        })
        .then (result => {
            this.getS3(result.key)
            this.setState({showUpdated: true, showLoading: false})
            API.graphql(graphqlOperation(WriteFederatedIdentity.mutation))
        })
        .catch(err => console.log(err));
    }
    hover = () => {
        this.setState({hover: !this.state.hover})
    }

    resetPassword() {
        if (this.state.currentPasswordComplete && this.state.newPasswordComplete && this.state.repeatPasswordComplete) {
            if(this.state.newPassword !== this.state.repeatPassword) {
                this.setState({resetPasswordColor: 'warning', resetPasswordMessage: 'New Passwords do not match'}) 
            }
            else {
                this.setState({resetLoading: true});
                Auth.currentAuthenticatedUser()
                .then(user => {
                    return Auth.changePassword(user, this.state.currentPassword, this.state.newPassword);
                })
                .then(data => {
                    this.setState({resetLoading: false, resetPasswordColor: 'success', resetPasswordMessage: 'Password reset successful.'})
                })
                .catch(err => {
                    this.setState({resetLoading: false, resetPasswordColor: 'danger', resetPasswordMessage: 'Password reset error please check the password constraints.'})
                }); 
            }
        }
        else {
            this.setState({resetPasswordColor: 'warning', resetPasswordMessage: 'Please check the passwords meet the password policy constraints.'})
        }      
    }

    handleConfirmation(event) {
        switch (event.target.name) {
            case 'currentPassword-text-input':
                this.setState({currentPassword: event.target.value});
                if (event.target.value.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{12,})/)) {
                    this.setState({currentPasswordComplete: true, currentPasswordError: false});
                } 
                else {
                    this.setState({currentPasswordComplete: false, currentPasswordError: true});
                }
                break;
            case 'newPassword-text-input': 
                this.setState({newPassword: event.target.value});
                if (event.target.value.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{12,})/)) {
                    this.setState({newPasswordComplete: true, newPasswordError: false});
                }
                else {
                    this.setState({newPasswordComplete: false, newPasswordError: true});
                }
                break;
            case 'repeatPassword-text-input': 
                this.setState({repeatPassword: event.target.value});
                if (event.target.value.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{12,})/)) {
                    this.setState({repeatPasswordComplete: true, repeatPasswordError: false});
                }
                else {
                    this.setState({repeatPasswordComplete: false, repeatPasswordError: true});
                }
                break;
            case 'mfaToken-text-input': 
                this.setState({mfaToken: event.target.value});
                if (event.target.value.match(/^[0-9]{6,}$/)) {
                    this.setState({mfaTokenComplete: true, mfaTokenError: false});
                }
                else {
                    this.setState({mfaTokenComplete: false, mfaTokenError: true});
                }
                break;
            default:
                break;
        }
    }

    setQRCode() {
        Auth.currentAuthenticatedUser()
        .then(user => {
            Auth.setupTOTP(user).then((code) => {
                this.setState({
                    qr: "otpauth://totp/AWSCognito:"+ user.username + "?secret=" + code + "&issuer=securestack",
                    mfaUpdate: true
                });
            });
        });
    }

    resetMFA() {
        if( this.state.mfaTokenComplete ) {
            this.setState({mfaLoading: true});
            Auth.currentAuthenticatedUser()
            .then(user => {
                Auth.verifyTotpToken(user, this.state.mfaToken)
                .then(result => {
                    this.setState({mfaAlertColor: 'success', mfaAlertMessage: 'MFA Token Updated', mfaLoading: false})
                    Auth.setPreferredMFA(user, 'TOTP');
                })
                .catch((e) => {
                    this.setState({mfaAlertColor: 'danger', mfaAlertMessage: 'Incorrect MFA token supplied', mfaLoading: false})
                })
            })
        }
        else {
            this.setState({mfaAlertColor: 'danger', mfaAlertMessage: 'Incorrect MFA token supplied'})
        }
    }
    
    render() {
    const { user } = this.props;
    const { resetPasswordColor, resetPasswordMessage, resetLoading} = this.state
    const { currentPassword, currentPasswordComplete, currentPasswordError } = this.state;
    const { newPassword, newPasswordComplete, newPasswordError } = this.state;
    const { repeatPassword, repeatPasswordComplete, repeatPasswordError } = this.state;
    const { mfaToken, mfaTokenComplete, mfaTokenError, mfaAlertColor, mfaAlertMessage, mfaUpdate, mfaLoading } = this.state;
    const { loadingKeys, addingKey, ssherror, ssherrormessage, ssherrortype, type, downloadLink } = this.state;
    return (
      <div>         
        <Grid.Row>
            <Grid.Col sm={6} md={6}>
                <Card title='My Details'>
                    <Card.Body>
                        <Media>
                            <div  style={{marginRight: 30}}>
                            <label htmlFor="updateProfilePic" onMouseEnter={this.hover} onMouseLeave={this.hover}>
                                <Avatar style={this.state.hover ? {opacity: 0.5} : {opacity: 1} } size="xxl" imageURL={this.state.profilePic}>
                                    <p style={this.state.hover ? {color: '#FFFFFF', fontSize: 15, opacity: 10} : {opacity: 0} }>Update</p>
                                </Avatar>
                            </label>
                            <input
                            id="updateProfilePic"
                            type="file" 
                            accept='image/*'
                            onChange={(image) => this.updateS3(image)}
                            onClick={(event)=> {event.target.value = null}}
                            style={{opacity: 0, width: '1px', height: '1px'}} />
                            </div>
                            <Media.Body>
                                <div style={{marginTop: 15}}>
                                    <Header.H5>{user.firstName + ' ' + user.lastName}</Header.H5>
                                    <address class="text-muted small">
                                        {user.username.substring(user.username.indexOf('.') + 1)}
                                    </address>   
                                </div>
                            </Media.Body>
                        </Media>
                        <Grid.Row>
                            <Grid.Col width={6} key={1}>
                                <Header.H6>First Name</Header.H6>
                                <p>{user.firstName}</p>
                            </Grid.Col>
                            <Grid.Col width={6} key={2}>
                                <Header.H6>Last Name</Header.H6>
                                <p>{user.lastName}</p>
                            </Grid.Col>
                            <Grid.Col width={6} key={1}>
                                <Header.H6>Company</Header.H6>
                                <p>{user.company}</p>
                            </Grid.Col>
                            <Grid.Col width={6} key={1}>
                                <Header.H6>Username</Header.H6>
                                <p>{user.username.substring(user.username.indexOf('.') + 1)}</p>
                            </Grid.Col>
                            <Grid.Col width={6} key={2}>
                                <Header.H6>Email</Header.H6>
                                <p>{user.email}</p>
                            </Grid.Col>
                            <Grid.Col width={6} key={2}>
                                <Header.H6>Phone number</Header.H6>
                                <p>{user.phoneNumber}</p>
                            </Grid.Col>
                        </Grid.Row>
                    </Card.Body>
                </Card>
                <Card>
                    {
                        ssherror &&
                        <Card.Alert
                            color={ssherrortype}>
                                {ssherrormessage}
                                {
                                    downloadLink &&
                                    <a id="downloadLink" href="https://www.securestack.com.au/" >Click here to download PEM File</a>
                                }
                        </Card.Alert>
                    }
                    <Card.Header>
                    <Card.Title>SSH Keys</Card.Title>
                        <Card.Options>
                            { loadingKeys && 
                                <div  style={{marginRight: 10}}>
                                    <Loader/>
                                </div>
                            }
                            {
                                !this.state.editKeys ?
                                (
                                    <Button.Dropdown
                                        icon="plus"
                                        color="primary"
                                    >
                                        <Dropdown.Item onClick={() => {
                                            this.toggleSSHform(0);
                                        }}>
                                        Create
                                        </Dropdown.Item>
                                        <Dropdown.Item onClick={() => {
                                            this.toggleSSHform(1);
                                        }}>
                                        Import
                                        </Dropdown.Item>
                                    </Button.Dropdown>
                                ):(
                                    <Button
                                        color="secondary"
                                        onClick={this.toggleSSHform}
                                    >Back</Button>
                                )
                            }
                        </Card.Options>
                    </Card.Header>
                    <Card.Body>
                        {
                            !this.state.editKeys ?
                             // Display the keys
                                <List.Group>
                                    {
                                        this.state.keys.map((item, index) => 
                                            <List.GroupItem>
                                                <p>Name: {
                                                    item.name
                                                }</p>
                                                <p>Credential: {item.credential}</p>
                                                <p>Region: {item.region}</p>
                                                <div style={{
                                                    float: "right"
                                                }}>
                                                    <Button
                                                        loading={item.loading}
                                                        icon="trash"
                                                        color="red"
                                                        onClick={() => this.deleteSSHKey(item, index)}
                                                    ></Button>
                                                </div>
                                            </List.GroupItem>
                                        )
                                    }
                                </List.Group>
                            : // Display either the create form or import form
                                <div>
                                    <Form>
                                        <Form.Input
                                            tick={this.state.values[0].valid}
                                            cross={!this.state.values[0].valid && true}
                                            name="name"
                                            label="Name"
                                            value={this.state.values[0].value}
                                            onChange={(e)=> {
                                                this.handleValidate(0, new RegExp(/^[A-Za-z.\s_-]+$/i), e);
                                            }}
                                        ></Form.Input>
                                        {
                                            this.state.editKeys && this.state.type === 1 &&
                                            (
                                                <Form.Input
                                                    tick={this.state.values[3].valid}
                                                    cross={!this.state.values[3].valid && true}
                                                    name="key"
                                                    label="Key"
                                                    value={this.state.values[3].value}
                                                    onChange={(e)=> {
                                                        this.handleValidate(3, new RegExp(/^(.*?)/i), e);
                                                    }}
                                                ></Form.Input>
                                            )
                                        }
                                        <Form.Select
                                            label="Provider"
                                            onChange={(e) => {
                                                this.setState({
                                                    provider: e.target.value,
                                                });
                                            }}
                                        >
                                        <option>Select provider ...</option>
                                        <option>AWS</option>
                                        <option disabled>Azure</option>
                                        <option disabled>GCP</option>
                                        </Form.Select>
                                        <Form.Select
                                            label="Credentials"
                                            onChange={(e)=> {
                                                this.handleValidate(1, new RegExp(/^(.*?)/i), e);
                                            }}
                                        >
                                        <option selected="selected" value="Select a credential ...">Select a credential ...</option>
                                        {
                                            this.state.provider === "AWS" ?
                                            (
                                                this.state.accounts.map(item => item.provider === 'AWS' ? <option>{item.name}</option> : null )
                                            ): this.state.provider === "Azure" ? 
                                            (
                                                AzureRegions.forEach(item => 
                                                    <option>{item}</option>
                                                )
                                            ): this.state.provider === "GCP" ? 
                                            (
                                                <option>GCP</option>
                                            ):(
                                                <option>Other</option>
                                            )
                                        }
                                        </Form.Select>
                                        <Form.Select
                                            label="Regions"
                                            onChange={(e)=> {
                                                this.handleValidate(2, new RegExp(/^(.*?)/i), e);
                                            }}
                                        >
                                        <option selected="selected" value="Select a region ...">Select a region ...</option>
                                        {   this.state.type === 1 &&
                                            <option>all</option>
                                        }
                                        {
                                            this.state.provider === "AWS" ?
                                            (   
                                                AWSRegions.map(item => 
                                                    <option>{item}</option>
                                                )
                                            ): this.state.provider === "Azure" ? 
                                            (
                                                AzureRegions.map(item => 
                                                    <option>{item}</option>
                                                )
                                            ): this.state.provider === "GCP" ? 
                                            (
                                                <option>GCP</option>
                                            ):(
                                                <option>Other</option>
                                            )
                                        }
                                        </Form.Select>
                                    </Form>
                                    <Button 
                                        loading={addingKey}
                                        block 
                                        color="primary"
                                        onClick={type === 1 ? this.importSSHKey : this.addSSHKey}
                                    >
                                        Add
                                    </Button>
                                </div>
                            
                        }
                    </Card.Body>
                </Card>
            </Grid.Col>
            <Grid.Col sm={6} md={6}>
                <Card title='Reset Password'>
                    <Card.Alert
                    color={resetPasswordColor}>
                        {resetPasswordMessage}
                    </Card.Alert>
                    <Card.Body>
                        <Form>
                            <Form.Group isRequired
                                className="mb-0"
                                label="Current Password">
                                <Form.Input 
                                    name="currentPassword-text-input"
                                    type='password'
                                    tick={currentPasswordComplete}
                                    cross={currentPasswordError}
                                    value={currentPassword || ''} 
                                    onChange={this.handleConfirmation} />
                            </Form.Group>
                            <Form.Group isRequired
                                className="mb-0"
                                label="New Password">
                                <Form.Input 
                                    type='password'
                                    name="newPassword-text-input"
                                    tick={newPasswordComplete}
                                    cross={newPasswordError}
                                    value={newPassword || ''} 
                                    onChange={this.handleConfirmation} />
                            </Form.Group>
                            <Form.Group isRequired
                                className="mb-0"
                                label="Repeat New Password">
                                <Form.Input 
                                    name="repeatPassword-text-input"
                                    type='password'
                                    tick={repeatPasswordComplete}
                                    cross={repeatPasswordError}
                                    value={repeatPassword || ''} 
                                    onChange={this.handleConfirmation} />
                            </Form.Group>         
                        </Form>    
                    </Card.Body>
                    <Button block loading={resetLoading} color="primary" onClick={this.resetPassword}>Reset Password</Button>
                </Card>
                <Card title='Update MFA'>
                    { mfaAlertColor &&
                        <Card.Alert color={mfaAlertColor}>
                        {mfaAlertMessage}
                        </Card.Alert>
                    }
                    { !mfaUpdate &&
                        <Alert type="primary">
                        <Header.H4>MFA Warning</Header.H4>
                          If you select to update your MFA you <strong>WILL</strong> need to set up the new QR code 
                          provided in order to log into SecureStack 
                        <Button.List>
                          <Button color="danger" onClick={this.setQRCode}>
                            Update MFA
                          </Button>
                        </Button.List>
                      </Alert>
                    }
                    <Card.Body>
                        <QRCode value={this.state.qr}/>
                        <Form.Input
                            disabled={!mfaUpdate} 
                            name="mfaToken-text-input"
                            placeholder="MFA Token"
                            tick={mfaTokenComplete}
                            cross={mfaTokenError}
                            value={mfaToken || ''} 
                            onChange={this.handleConfirmation} />
                    </Card.Body>
                    <Button block disabled={!mfaUpdate} loading={mfaLoading} color="primary" onClick={this.resetMFA}>Update MFA</Button>
                </Card>
            </Grid.Col>
    </Grid.Row>    
        </div>
    );
  }
}
export default UserProfileForm;

