import React, { Component } from "react";
import { Page, Alert, Card, Dimmer } from "tabler-react";
import StartUp from "../components/StartUp";
import Template from "../components/Template";
import BillingDetailsInput from "../components/BillingDetailsInput";
import AdminAccountInput from "../components/AdminAccountInput";
import { API }  from "aws-amplify";
import { Elements, StripeProvider} from 'react-stripe-elements';
import WelcomeMessage from "../components/WelcomeMessage";
import CompleteSignUp from "../components/CompleteSignUp";

import stripe_config from "../stripe_config";

export default class StartUpBundle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      subscriptionSelection: null,
      selectSubscription: true,
      BillingDetailsInput: false,
      AdminAccountInput: false,
      CompleteSignUp: false,
      WelcomeMessage: false,
      alertVisable: false,
      creatingAccount: false,
      alert: {
        type: '',
        message: '',
        view: ''
      },
    };
    this.selectSubscription = this.selectSubscription.bind(this);
    this.showAlert = this.showAlert.bind(this);
    this.gotoBillingInput = this.gotoBillingInput.bind(this);
    this.gotoAccountInput = this.gotoAccountInput.bind(this);
    this.gotoWelcomeMessage = this.gotoWelcomeMessage.bind(this);
    this.gotoFinish = this.gotoFinish.bind(this);
  }

  componentDidMount() {
    this.setState({subscriptionSelection: stripe_config.startUp.name, planId: stripe_config.startUp.planId})
  }

  selectSubscription(subscription, product, planId) {
    this.setState({subscriptionSelection: subscription, productId: product, planId: planId})
  }

  gotoBillingInput() {
    if( this.state.subscriptionSelection == null ) {
      this.showAlert('Invalid Subscription', 'Please select a plan from the options below', 'warning')
    }
    else {
      this.setState({selectSubscription: false, alertVisable: false, BillingDetailsInput: true})
    }
  }

  gotoAccountInput(billingDetails, phoneCode) {
    this.setState({BillingDetailsInput: false, billingDetails: billingDetails, phoneCode: phoneCode, alertVisable: false, AdminAccountInput: true})
  }

  gotoFinish(accountDetails) {
    this.setState({CompleteSignUp: true, accountDetails: accountDetails, AdminAccountInput: false, alertVisable: false},
    () => {
      this.signUp()
    })
  }

  gotoWelcomeMessage() {
      this.setState({CompleteSignUp: false, alertVisable: false, WelcomeMessage: true})
  }

  signUp() {
    this.setState({creatingAccount: true})
    var request = {
      body: {
        billingDetails: this.state.billingDetails,
        subscription: this.state.subscriptionSelection,
        planId: this.state.planId,
        account: this.state.accountDetails
      }
    }
    API.post("SubscribeToSecureStack", "subscribe-to-securestack", request).then(response => {
      if(response.statusCode !== 200 ) {
        this.apiError(response);
      }
      else {
        this.setState({creatingAccount: false, creatingError: false, tempPassword: response.body.password})
      }
    }).catch(error => {
      this.apiError(error)
    });
  }

  apiError(error) {
    this.setState({creatingAccount: false, BillingDetailsInput: true, billingDetails: '', accountDetails: '', CompleteSignUp: false, activeStep: 1})
    this.showAlert('Oh no Something went wrong', 'Please re-enter your details and try again', 'warning')
  }

  showAlert(topic, message, type){
    var alert = {
      topic: topic,
      message: message,
      view: type
    }
    this.setState({alert: alert, alertVisable: true})
  }

  render() {
    const { alert, subscriptionSelection, phoneCode, billingDetails, creatingAccount, accountDetails, creatingError } = this.state;
    return (
        <Template>
        <Page.Content>
          { this.state.alertVisable &&
            <Alert type={alert.view}>
              <strong>{alert.topic} </strong>
              {alert.message}
            </Alert>
          }
          { this.state.selectSubscription &&
            <StartUp selectSubscription={this.selectSubscription} nextStep={this.gotoBillingInput} subscriptionSelection={subscriptionSelection}/>
          }
          { this.state.BillingDetailsInput &&
            <StripeProvider apiKey={stripe_config.apiKey}>
              <Elements fonts={[
                {cssSrc: 'https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,500,600'}
              ]}>
                <BillingDetailsInput selectSubscription={this.selectSubscription} billingDetails={billingDetails} isEdit={false} nextStep={this.gotoAccountInput} showAlert={this.showAlert}/>
              </Elements>
            </StripeProvider>
          }
          { this.state.AdminAccountInput &&
            <AdminAccountInput selectSubscription={this.selectSubscription} phoneCode={phoneCode} nextStep={this.gotoFinish} showAlert={this.showAlert}/>
          }
          { this.state.CompleteSignUp &&
             <div>
              <Card>
                  <Card.Body>
                    <Dimmer active={creatingAccount} loader>
                      { !creatingError &&
                        <CompleteSignUp username={accountDetails.user} password={this.state.tempPassword} companyId={accountDetails.companyId} showAlert={this.showAlert} nextStep={this.gotoWelcomeMessage}/>
                      }
                    </Dimmer>
                  </Card.Body>
                </Card>
              </div>
          }
           { this.state.WelcomeMessage &&
             <div>
              <Card>
                  <Card.Body>
                    <Dimmer active={creatingAccount} loader>
                        <WelcomeMessage username={accountDetails.user} companyId={accountDetails.companyId}/>
                    </Dimmer>
                  </Card.Body>
                </Card>
              </div>
          }
        </Page.Content>
        </Template>
    );
  }
}
