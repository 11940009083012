export default { mutation: `
mutation DeployEc2(
    $hostName: String, 
    $product: Ec2ProductInput, 
    $sshKey: String,
    $enablePublicIP: Boolean, 
    $customAMI: Boolean,
    $OS: String
  ) {
    DeployEc2(
      deployEc2Input: {
        hostName: $hostName
        product: $product
        sshKey: $sshKey
        enablePublicIP: $enablePublicIP
        customAMI: $customAMI
        OS: $OS
      }
    ) 
    {
      instanceId
      instanceType
      keyName
      launchTime
      privateDnsName
      privateIpAddress
      subnetId
      vpcId
      securityGroups
    }
  }`
};
