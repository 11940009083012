export default { mutation: `
mutation EditUserProfile(
    $key: String
    $firstName: String
  	$lastName: String
  	$username: String
  	$company: String
    $email: String
    $phoneNumber: String
    $isAdmin: Boolean
    ) {
    EditUserProfile(key: $key, userProfile: {
    firstName: $firstName
    lastName: $lastName
    username: $username
    company: $company
    email: $email
    phoneNumber: $phoneNumber
    isAdmin: $isAdmin
  }) {
    firstName
  	lastName
  	username
    company
    isAdmin
    email
    phoneNumber
    key
  }
}`
};
