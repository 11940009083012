import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { Button, Fade, Toolbar, List, Tooltip, Typography, Divider, Drawer, AppBar, IconButton, ListItem, ListItemIcon, ListItemText, MenuItem, Badge, Menu, Avatar, ListItemSecondaryAction } from '@material-ui/core/';
import {ExitToApp, Cloud, People, Settings, 
  //VpnLock, 
  Home, Lock, MoreVert, AccountCircle, ExpandLess, ExpandMore, MonetizationOn, CreditCard, Receipt, Accessibility, Launch, CloudCircle, ControlPoint, CloudUpload, Security, Devices, LockOpen, Done, Chat, InsertDriveFile } from '@material-ui/icons/';
import NotificationsIcon from '@material-ui/icons/Notifications';
import img_logo from '../../assets/images/logo.png';
import small_logo from '../../assets/images/profilePic.png';
import { NavLink } from 'react-router-dom'
import Collapse from '@material-ui/core/Collapse';
import { Storage,Auth } from 'aws-amplify';
import styles from './Style';
import { API, graphqlOperation  } from "aws-amplify";
import Notifications from '../../graphql/subscriptions/Notifications';
import ReadNotification from '../../graphql/mutations/ReadNotification';
import GetNotifications from '../../graphql/queries/GetNotifications';
import update from 'immutability-helper';
import newsList from '../../content/newsList';
import HubSpotMsg from '../HubSpotMsg';

class SiteWrapper extends React.Component {
  state = {
    user: this.props.children.props.children.props.childProps.user,
    userGroup: this.props.children.props.children.props.childProps.user.groupName,
    open: false,
    mobileMoreAnchorEl: null,
    anchorEl: null,
    notificationAnchorEl: null,
    manageSecureCloud: false,
    profilePic: '',
    notificationCount: 0,
    notifications: [],
    newsLink: newsList[0].url,
    newsTitle: newsList[0].title,
    fade: true,
    viewMsg: false
  };

  async componentDidMount() {
    Storage.get(this.state.user['cognito:username']+'.png', {level: 'private'})
    .then(result => this.setState({profilePic: result}))
    .catch(err => console.log(err));

    // Notifications
    await API.graphql(graphqlOperation(GetNotifications.query))
    .then(result => {
      if(result.data.GetNotifications.length > 0) 
        this.setState({notificationCount: result.data.GetNotifications.length, notifications: result.data.GetNotifications}); 
    })

    // Subscribe for real time notifications
    await API.graphql(graphqlOperation(Notifications.subscription, {businessId: this.state.user.businessId})).subscribe({
      next: (result) => {
          this.setState({notificationCount: result.value.data.Notifications.Notifications.length, notifications: result.value.data.Notifications.Notifications}); 
      }
    });

    //News Feed
   setInterval(() => {
      var num = Math.floor(Math.random() * (+newsList.length - +0)) + +0; 
      this.setState({
        fade: false,
        newsTitle: newsList[num].title,
        newsLink: newsList[num].url
      }, () =>
        setTimeout(() => {
          this.setState({fade: true})
        }, 1500)
      )
    }, 30000);
  }

  handleDrawerOpen = () => {
    this.setState({ open: true });
  };

  handleDrawerClose = () => {
    this.setState({ open: false });
  };

  handleProfileMenuOpen = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleMenuClose = () => {
    this.setState({ anchorEl: null });
    this.handleMobileMenuClose();
  };

  handleMobileMenuOpen = event => {
    this.setState({ mobileMoreAnchorEl: event.currentTarget });
  };

  handleMobileMenuClose = () => {
    this.setState({ mobileMoreAnchorEl: null });
  };

  handleViewNotifications = event => {
    this.setState({ notificationAnchorEl: event.currentTarget });
  };

  handleNotificationMenuClose = e => {
    this.setState({notificationAnchorEl: null})
  }

  handleReadNotification = (key) => {
    API.graphql(graphqlOperation(ReadNotification.mutation, {key: key})).then(() => {
      let newState = update(this.state, {
        notifications: arr => arr.filter(item => item.index !== key),
        notificationCount: {$set: this.state.notificationCount -1}
      });
      this.setState(newState);  
    })
  }

  handleLogOut= () => {
    Auth.signOut()
    .then(data => {
      window.location='/';
    })
  }

  handleManageSecureCloudClick = () => {
    this.setState(state => ({ manageSecureCloud: !state.manageSecureCloud }));
  };

  handleBillingClick = () => {
    this.setState(state => ({ manageBilling: !state.manageBilling }));
  };

  handleMyCloudClick = () => {
    this.setState(state => ({ viewMyCloud: !state.viewMyCloud }));
  };

  handleCloudBuilderClick = () => {
    this.setState(state => ({ cloudBuilder: !state.cloudBuilder }));
  };

  handleOpenMsg = () => { this.setState({viewMsg: !this.state.viewMsg}) }

  render() {
    const { classes } = this.props;
    const { anchorEl, mobileMoreAnchorEl, profilePic, userGroup, notificationCount, notificationAnchorEl, notifications, newsTitle, newsLink, fade, viewMsg } = this.state;
    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
    const isNotificationMenuOpen = Boolean(notificationAnchorEl);
    
    const ForwardNavLink = React.forwardRef((props, ref) => (
      <NavLink {...props} innerRef={ref} />
    ));


    const renderMenu = (
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={isMenuOpen}
        onClose={this.handleMenuClose}
      >
        <MenuItem onClick={this.handleMenuClose} component={ForwardNavLink} exact to="/UserProfile">My Profile</MenuItem>
        <MenuItem onClick={() => this.handleLogOut()}>Sign Out</MenuItem>
      </Menu>
    );

    const renderMobileMenu = (
      <Menu
        anchorEl={mobileMoreAnchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={isMobileMenuOpen}
        onClose={this.handleMobileMenuClose}
      >
        <MenuItem onClick={this.handleProfileMenuOpen}>
          <IconButton>
            <AccountCircle />
          </IconButton>
          <p>Profile</p>
        </MenuItem>
      </Menu>
    );

    const renderNotificationMenu = (
      <Menu
        anchorEl={notificationAnchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={isNotificationMenuOpen}
        onClose={this.handleNotificationMenuClose}
      >
        {
          notifications.map((n, i) => {
            return <List key={i} style={{width: 400}}>
              <ListItem>
                <ListItemText
                  primary={n.topic}
                  secondary={n.message}
                />
                <ListItemSecondaryAction>
                  <Tooltip title="View Notification" aria-label={"view"+i}>
                    <IconButton onClick={() => this.handleReadNotification(n.index)}>
                      <Done/>
                    </IconButton>
                  </Tooltip>
                </ListItemSecondaryAction>
              </ListItem>
            </List>
          })
        }
      </Menu>
    );

    return (
      <div className={classes.root}>
        <AppBar
          position="fixed"
          className={classNames(classes.appBar, {
            [classes.appBarShift]: this.state.open,
          })}
        >
          <Toolbar disableGutters={!this.state.open}>
            <Tooltip title="Expand Menu" aria-label="Expand Menu">     
              <IconButton
                id='logoDrawerOpen'
                aria-label="Open drawer"
                onClick={this.handleDrawerOpen}
                className={classNames(classes.menuButton, {
                  [classes.hide]: this.state.open,
                })}
              >
                <img src={small_logo} alt="img_logo" style={{width: '2rem'}}/>
              </IconButton>
            </Tooltip>
            <Typography variant="h6" className={classes.header} noWrap>
              We Secure Your Cloud
            </Typography>
            <div className={classes.grow} />
              <div className={classes.sectionDesktop}>
              <Fade in={fade}>
                <Tooltip title="View Article" aria-label="ViewArticle">     
                  <Button className={classes.newsButton} onClick={() => {window.open(newsLink, "_blank")}}>
                    <Typography color='primary' noWrap ><b>News:</b> {newsTitle}</Typography>
                  </Button>
                </Tooltip>
              </Fade>
              <Tooltip title="Open Support Chat">
                <IconButton onClick={this.handleOpenMsg}>
                  <Chat />
                </IconButton>
              </Tooltip>
              <IconButton onClick={this.handleViewNotifications}>
                <Badge badgeContent={notificationCount} color="secondary">
                  <NotificationsIcon />
                </Badge>
              </IconButton>
              <IconButton
                aria-owns={isMenuOpen ? 'material-appbar' : undefined}
                aria-haspopup="true"
                onClick={this.handleProfileMenuOpen} >
                <Avatar src={profilePic} />
              </IconButton>
            </div>
            <div className={classes.sectionMobile}>
              <IconButton aria-haspopup="true" onClick={this.handleMobileMenuOpen} >
                <MoreVert />
              </IconButton>
            </div>
          </Toolbar>
        </AppBar>
        {renderMenu}
        {renderMobileMenu}
        {renderNotificationMenu}
        <Drawer
          variant="permanent"
          className={classNames(classes.drawer, {
            [classes.drawerOpen]: this.state.open,
            [classes.drawerClose]: !this.state.open,
          })}
          classes={{
            paper: classNames({
              [classes.drawerOpen]: this.state.open,
              [classes.drawerClose]: !this.state.open,
            }),
          }}
          open={this.state.open}
        >
          <div className={classes.toolbar}>
          <ListItem onClick={this.handleDrawerClose} id='logoDrawerClose'>
              <img src={img_logo} alt="img_logo" style={{width: '100%'}}/>
            </ListItem>
          </div>
          <Divider />
          <List dense={true}>
            <ListItem key={"Home"} button component={ForwardNavLink} activeClassName={classes.active} exact to="/">
              <ListItemIcon>
                <Home />
              </ListItemIcon>
              <ListItemText primary={"Home"} />
            </ListItem>
            <ListItem button key={"MyCloud"} onClick={this.handleMyCloudClick}>
              <ListItemIcon>
                <CloudCircle />
              </ListItemIcon>
              <ListItemText primary={"My Cloud"} />
              {this.state.viewMyCloud ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={this.state.viewMyCloud} timeout="auto" unmountOnExit>
              <List disablePadding dense={true}>
                <ListItem key={"deploy"} button component={ForwardNavLink} className={classes.nested} exact to="/Deploy" activeClassName={classes.active}>
                  <ListItemIcon>
                    <CloudUpload />
                  </ListItemIcon>
                  <ListItemText primary="Deploy" />
                </ListItem>                
                <ListItem key={"inventory"} button component={ForwardNavLink} exact to="/Inventory" activeClassName={classes.active} className={classes.nested}>
                  <ListItemIcon>
                    <Devices />
                  </ListItemIcon>
                  <ListItemText primary="Inventory" />
                </ListItem>
              </List>
            </Collapse>
            { userGroup === 'Administrators' &&
            <div>
            <ListItem button key={"CloudBuilder"} onClick={this.handleCloudBuilderClick}>
              <ListItemIcon>
                <Cloud />
              </ListItemIcon>
              <ListItemText primary={"CloudBuilder"} />
              {this.state.cloudBuilder ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={this.state.cloudBuilder} timeout="auto" unmountOnExit>
              <List disablePadding dense={true}>
                <ListItem key={"blueprints"} button component={ForwardNavLink} exact to="/Blueprints" activeClassName={classes.active} className={classes.nested}>
                  <ListItemIcon>
                    <InsertDriveFile />
                  </ListItemIcon>
                  <ListItemText primary="Blueprints" />
                </ListItem>
                <ListItem key={"products"} button component={ForwardNavLink} exact to="/Products" activeClassName={classes.active} className={classes.nested}>
                  <ListItemIcon>
                    <Accessibility />
                  </ListItemIcon>
                  <ListItemText primary="Products" />
                </ListItem>
                <ListItem key={"profiles"} button component={ForwardNavLink} exact to="/Profiles" activeClassName={classes.active}className={classes.nested}>
                  <ListItemIcon>
                    <Security />
                  </ListItemIcon>
                  <ListItemText primary="Profiles" />
                </ListItem>
                <ListItem key={"providers"} button component={ForwardNavLink} exact to="/Providers" activeClassName={classes.active} className={classes.nested}>
                  <ListItemIcon>
                    <LockOpen />
                  </ListItemIcon>
                  <ListItemText primary="Providers" />
                </ListItem>
                <ListItem key={"targets"} button component={ForwardNavLink} exact to="/Targets" activeClassName={classes.active}className={classes.nested}>
                  <ListItemIcon>
                    <ControlPoint />
                  </ListItemIcon>
                  <ListItemText primary="Targets" />
                </ListItem>
                <ListItem key={"teams"} button component={ForwardNavLink} exact to="/Teams" activeClassName={classes.active} className={classes.nested}>
                  <ListItemIcon>
                    <People />
                  </ListItemIcon>
                  <ListItemText primary="Teams" />
                </ListItem>
              </List>
            </Collapse>
            <ListItem button key={"securecloud"} onClick={this.handleManageSecureCloudClick}>
              <ListItemIcon>
                <Lock />
              </ListItemIcon>
              <ListItemText primary={"CloudAdmins"} />
              {this.state.manageSecureCloud ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={this.state.manageSecureCloud} timeout="auto" unmountOnExit>
              <List disablePadding dense={true}>
                <ListItem key={"assets"} button component={ForwardNavLink} exact to="/Assets" activeClassName={classes.active} className={classes.nested}>
                  <ListItemIcon>
                    <Devices />
                  </ListItemIcon>
                  <ListItemText primary="Assets" />
                </ListItem>
                <ListItem key={"components"} button component={ForwardNavLink} exact to="/Components" activeClassName={classes.active}className={classes.nested}>
                  <ListItemIcon>
                    <Settings />
                  </ListItemIcon>
                  <ListItemText primary="Components" />
                </ListItem>
                {
                  /*
                    <ListItem key={"firewall"} button component={ForwardNavLink} exact to="/FirewallRules" activeClassName={classes.active} className={classes.nested}>
                      <ListItemIcon>
                        <VpnLock />
                      </ListItemIcon>
                      <ListItemText primary="Firewall" />
                    </ListItem>
                  */
                }
                <ListItem key={"Services"} button component={ForwardNavLink} exact to="/Launch" activeClassName={classes.active} className={classes.nested}>
                  <ListItemIcon>
                    <Launch />
                  </ListItemIcon>
                  <ListItemText primary="Services" />
                </ListItem>
              </List>
            </Collapse>
            <ListItem button key={"billing"} onClick={this.handleBillingClick}>
              <ListItemIcon>
                <MonetizationOn />
              </ListItemIcon>
              <ListItemText primary={"Billing"} />
              {this.state.managBilling ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={this.state.manageBilling} timeout="auto" unmountOnExit>
              <List disablePadding dense={true}>
                <ListItem key={"billingInformation"} button component={ForwardNavLink} exact to="/BillingInformation" activeClassName={classes.active} className={classes.nested}>
                  <ListItemIcon>
                    <CreditCard />
                  </ListItemIcon>
                  <ListItemText primary="Billing Information" />
                </ListItem>
                <ListItem key={"invoices"} button component={ForwardNavLink} exact to="/Invoices" activeClassName={classes.active} className={classes.nested}>
                  <ListItemIcon>
                    <Receipt />
                  </ListItemIcon>
                  <ListItemText primary="Invoices"/>
                </ListItem>
              </List>
            </Collapse>
            </div>
          }
          </List>
          <Divider />
          <List dense={true}>
            <ListItem button key={"logout"} onClick={() => this.handleLogOut()}>
              <ListItemIcon>
                <ExitToApp />
              </ListItemIcon>
              <ListItemText primary={"Logout"} />
            </ListItem>
          </List>
        </Drawer>
        <main className={classes.content}>
          <div className={classes.toolbar} />
            {this.props.children}
        </main>
          {
            viewMsg &&
              <HubSpotMsg/>
          }
      </div>
    );
  }
}

SiteWrapper.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(SiteWrapper);
