import React, { Component } from 'react';
import "tabler-react/dist/Tabler.css";
import { Page, Table, Button, Badge, Tag, Card } from "tabler-react";
import { API, graphqlOperation, Auth  } from "aws-amplify";
import GetInvoices from '../graphql/queries/GetInvoices';

class Invoices extends Component {
  constructor(props) {
    super(props);
    this.state = {
      invoices: [],
    };
}
  async componentDidMount() {
    Auth.currentAuthenticatedUser()
    .then(async user => {
      await API.graphql(graphqlOperation(GetInvoices.query, {customerID: user.attributes['custom:stripeId']}))
      .then(result => {
        this.setState({invoices:result.data.GetInvoices})
      })
      .catch(err => {
        console.log(err);
     });
    });
  }

  render() {
    const {invoices} = this.state;
    return (
        <Page.Content>
        <Card>
          <Card.Header>
            <Card.Title>Invoices</Card.Title>
          </Card.Header>
          <Table>
            <Table.Header>
              <Table.ColHeader>Id</Table.ColHeader>
              <Table.ColHeader>From</Table.ColHeader>
              <Table.ColHeader>To</Table.ColHeader>
              <Table.ColHeader>Amount</Table.ColHeader>
              <Table.ColHeader>Paid on</Table.ColHeader>
              <Table.ColHeader>Status</Table.ColHeader>
              <Table.ColHeader>Download</Table.ColHeader>
            </Table.Header>
            <Table.Body>
            { invoices.map((invoice, index) =>                   
              <Table.Row key={index}>
                <Table.Col>{invoice.id}</Table.Col>
                <Table.Col><Tag>{new Date(invoice.from*1000).toString().slice(0, 15)}</Tag></Table.Col>
                <Table.Col><Tag>{new Date(invoice.to*1000).toString().slice(0, 15)}</Tag></Table.Col>
                <Table.Col>${(invoice.amount/100).toLocaleString()}</Table.Col>
                <Table.Col><Tag>{new Date(invoice.paid*1000).toString().slice(0, 21)}</Tag></Table.Col>
                <Table.Col><Badge color={invoice.status ? 'success' : 'danger'} className="mr-1">{invoice.status ? 'paid' : 'un-paid'}</Badge></Table.Col>
                <Table.Col><Button RootComponent="a" href={invoice.pdf} color="primary" icon="download"></Button></Table.Col>
              </Table.Row>
            )}
            </Table.Body>
          </Table>
        </Card>
        </Page.Content>
    );
  }
}

export default Invoices;
