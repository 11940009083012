export default { query: `
    query GetAzureInventory {
        GetAzureInventory {
            resourceId
            status
            serverName
            registered
            score
            cloud
            location
            securestack_managed
            activity
            notifications
            instanceType
            createdBy
            info
        }
    }`
};