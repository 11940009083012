import React, { Component } from 'react';
import PropTypes from 'prop-types';
import useStyles from './Style'
import { withStyles } from '@material-ui/core/styles';
import { Add, VpnLock, Home, Edit } from '@material-ui/icons';
import { Card, InputAdornment, CardHeader, CardContent, FormControl, Typography, Input, FormHelperText, Button, 
    IconButton, Tooltip, Chip, CircularProgress, Checkbox, FormControlLabel, Paper, Breadcrumbs, Grid } from '@material-ui/core';
import Alert from '../../components/Alert';
import { Link } from 'react-router-dom';
import update from 'immutability-helper';
import { API, graphqlOperation  } from "aws-amplify";
import CreateFirewallRule from '../../graphql/mutations/CreateFirewallRule';
import EditFirewallRule from '../../graphql/mutations/EditFirewallRule';

class FirewallForm extends Component {
    constructor(props) {
        super(props);
        const { isEdit, firewallRule } = this.props.location.state ? this.props.location.state : { isEdit: false, firewallRule: {} };
        this.state = {
            isEdit: isEdit,
            firewallRule: firewallRule,
            showLoading: false,
            firewallName: isEdit ? firewallRule.firewallName : null,
            ports: isEdit ? firewallRule.ports : [],
            sources: isEdit ? firewallRule.sources : [],
            destinations: isEdit ? firewallRule.destinations : [],
            comment: isEdit ? firewallRule.comment : null,
            tcp: isEdit ? firewallRule.protocols.tcp : false,
            udp: isEdit ? firewallRule.protocols.udp : false,
            key: isEdit ? firewallRule.key : null,
        };
    } 

    handleAlertClose = () => { this.setState({showAlert: false, alertMessage: ''})}

    handleFirewallName = (e) => {
        this.setState({firewallName: e.target.value})
        if (e.target.value.match(/^[a-zA-Z0-9.-_@-]+$/)) {
            this.setState({firewallNameErrMsg: '', firewallNameError: false});
        } 
        else {
            this.setState({firewallNameErrMsg: 'Invalid firewall rule name', firewallNameError: true});
        }
    }

    handlePort = (e) => {
        this.setState({port: e.target.value})
        if (e.target.value.match(/^([0-9]{1,4}|[1-5][0-9]{4}|6[0-4][0-9]{3}|65[0-4][0-9]{2}|655[0-2][0-9]|6553[0-5])$/)) {
            this.setState({portErrMsg: '', portError: false});
        } 
        else {
            this.setState({portErrMsg: 'Invalid port', portError: true});
        }
    }

    handleAddPort = () => {
        var port = this.state.port
        if( port && !this.state.portError ) {
            let newState = update(this.state, {
                ports: {  $push: [ port ]}  
            })
            this.setState(newState, () => {
                this.setState({port:''})
            }); 
        }
    }

    handleDeletePort = (port) => {
        let newState = update(this.state, {
            ports: arr => arr.filter(value => value !== port)
        });
        this.setState(newState);    
    }

    handleSource = (e) => {
        this.setState({source: e.target.value})
        if (e.target.value.match(/^((\d){1,3}\.){3}(\d){1,3}\/(\d){1,3}/)) {
            this.setState({sourceErrMsg: '', sourceError: false});
        } 
        else {
            this.setState({sourceErrMsg: 'Invalid source ip', sourceError: true});
        }
    }

    handleAddSource = () => {
        var source = this.state.source
        if( source && !this.state.sourceError ) {
            let newState = update(this.state, {
                sources: {  $push: [ source ]}  
            })
            this.setState(newState, () => {
                this.setState({source:''})
            }); 
        }
    }

    handleDestination = (e) => {
        this.setState({destination: e.target.value})
        if (e.target.value.match(/^((\d){1,3}\.){3}(\d){1,3}\/(\d){1,3}/)) {
            this.setState({destinationErrMsg: '', destinationError: false});
        } 
        else {
            this.setState({destinationErrMsg: 'Invalid destination ip', destinationError: true});
        }
    }

    handleDeleteSource = (ip) => {
        let newState = update(this.state, {
            sources: arr => arr.filter(value => value !== ip)
        });
        this.setState(newState);    
    }

    handleAddDestination= () => {
        var destination = this.state.destination
        if( destination && !this.state.destinationError ) {
            let newState = update(this.state, {
                destinations: {  $push: [ destination ]}  
            })
            this.setState(newState, () => {
                this.setState({destination:''})
            }); 
        }
    }

    handleDeleteDestination = (ip) => {
        let newState = update(this.state, {
            destinations: arr => arr.filter(value => value !== ip)
        });
        this.setState(newState);    
    }

    handleComment = (e) => {
        this.setState({comment: e.target.value})
        if (e.target.value.match(/^[a-zA-Z0-9.-_@-]+$/)) {
            this.setState({commentErrMsg: '', commentError: false});
        } 
        else {
            this.setState({firewallNameErrMsg: 'Invalid comment', commentError: true});
        }
    }

    handleChangeTcp = () => { this.setState({ tcp: !this.state.tcp })}

    handleChangeUdp = () => { this.setState({ udp: !this.state.udp })}

    clearForm = () => { 
        this.setState({ 
            firewallName: '',
            tcp: false,
            ude: false,
            sources: [],
            source: '',
            destination: '',
            destinations: [],
            port: '',
            ports: [],
            comment: ''
        }) 
    }

    createFirewall = async () => {
        if( this.state.firewallName && this.state.ports.length && this.state.sources.length && this.state.destinations.length && !this.state.firewallNameError && !this.state.commentError ) {
            this.setState({showLoading: true})
            const firewallRule = {
                firewallName: this.state.firewallName,
                protocols: {
                    tcp: this.state.tcp,
                    udp: this.state.udp
                },
                comment: this.state.comment,
                sources: this.state.sources,
                destinations: this.state.destinations,
                ports: this.state.ports 
            }
            await API.graphql(graphqlOperation(CreateFirewallRule.mutation, firewallRule))
            .then(result => {
                this.clearForm();
                this.setState({showLoading: false, showAlert: true, alertMessage: 'New firewall rule created.', alertSuccess: true})
            })
            .catch(err => {
                this.setState({ showLoading: false, showAlert: true, alertMessage: "Error while creating the firewall rule.", alertSuccess: false });
            });
        }
        else {
            this.setState({ showAlert: true, alertMessage: "Invalid values please check you have added your port, source or destination and your firewall rule name is valid", alertSuccess: false });
        }
    } 

    updateFirewall = async() => {
        if( this.state.firewallName && this.state.ports.length && this.state.sources.length && this.state.destinations.length && !this.state.firewallNameError && !this.state.commentError ) {
            this.setState({showLoading: true})
            const firewallRule = {
                key: this.state.key,
                firewallName: this.state.firewallName,
                protocols: {
                    tcp: this.state.tcp,
                    udp: this.state.udp
                },
                comment: this.state.comment,
                sources: this.state.sources,
                destinations: this.state.destinations,
                ports: this.state.ports 
            }
            await API.graphql(graphqlOperation(EditFirewallRule.mutation, firewallRule))
            .then(result => {
                this.setState({showLoading: false, showAlert: true, alertMessage: 'Firewall rule updated.', alertSuccess: true})
            })
            .catch(err => {
                this.setState({ showLoading: false, showAlert: true, alertMessage: "Error while updating the firewall rule.", alertSuccess: false });
            });
        }
        else {
            this.setState({ showAlert: true, alertMessage: "Invalid values please check you have added your port, source or destination and your firewall rule name is valid", alertSuccess: false });
        }
    }

    render() {
        const { classes } = this.props;
        const { isEdit, firewallRule, showLoading, showAlert, alertSuccess, alertMessage, firewallName, firewallNameErrMsg, firewallNameError,  
        port, portErrMsg, portError, source, sourceErrMsg, sourceError, destination, destinationErrMsg, destinationError, comment, commentErrMsg, commentError,
        sources, destinations, ports, tcp, udp} = this.state;
        return ( 
            <div>
                <Alert showAlert={showAlert} alertSuccess={alertSuccess} alertMessage={alertMessage} handleAlertClose={this.handleAlertClose}/>
                <Paper className={classes.bread}>
                    <Breadcrumbs aria-label="Breadcrumb" >
                        <Link color='primary' to='/'>
                            <Home className={classes.icon}/>
                            Home
                        </Link>
                        <Link color='primary' to="/FirewallRules">  
                            <VpnLock className={classes.icon} />
                            Firewall Rules
                        </Link>
                        <Typography color='primary' className={classes.link}>
                            <Edit className={classes.icon} />
                            Firewall Rule Form
                        </Typography>
                    </Breadcrumbs>
                </Paper>
                <Card>
                    <CardHeader
                        title={isEdit ?  'Edit Firewall Rule': 'Create Firewall Rule' }
                        subheader={isEdit ? `Update User ${firewallRule.firewallName}` : 'Create a New Firewall Rule'}
                    />
                    <CardContent>
                        <FormControl className={classes.formControl} required error={firewallNameError}>
                            <Typography color="textSecondary" >Firewall Rule Name</Typography>
                            <Input id="input-firewallName" value={firewallName} onChange={this.handleFirewallName} />
                            <FormHelperText error={firewallNameError}>{firewallNameErrMsg}</FormHelperText>
                        </FormControl> 
                        <FormControl component="fieldset" className={classes.formControl}>
                            <Typography color="textSecondary">Protocols</Typography>
                            <Grid container spacing={3}>
                                <Grid item xs >
                                    <FormControlLabel
                                        control={<Checkbox checked={tcp} onChange={this.handleChangeTcp} value="tcp" />}
                                        label="TCP"
                                    />
                                </Grid>
                                <Grid item xs >
                                    <FormControlLabel
                                        control={<Checkbox checked={udp} onChange={this.handleChangeUdp} value="ubp" />}
                                        label="UDP"
                                    />
                                </Grid>
                            </Grid>
                        </FormControl>
                        <FormControl className={classes.formControl} required error={portError}>
                            <Typography color="textSecondary" >Ports</Typography>
                            <Input id="input-port" value={port} onChange={this.handlePort} endAdornment={
                                <InputAdornment position="end">
                                    <Tooltip title="Add Port">
                                        <IconButton
                                            aria-label="add_port"
                                            onClick={this.handleAddPort} >
                                            <Add color='primary' />
                                        </IconButton>
                                    </Tooltip>
                                </InputAdornment>}
                            />
                            <FormHelperText error={portError}>{portErrMsg}</FormHelperText>
                            <div className={classes.chiplist}>
                                {
                                    ports.map(((port, index) => {
                                        return <Chip
                                            key={index}
                                            color="primary"
                                            label={port}
                                            onDelete={() => this.handleDeletePort(port)}
                                            className={classes.chip}
                                            variant="outlined"
                                        />
                                    }))
                                }
                            </div>
                        </FormControl>  
                        <FormControl className={classes.formControl} required error={sourceError}>
                            <Typography color="textSecondary" >Source IPs</Typography>
                            <Input id="input-source" value={source} onChange={this.handleSource} endAdornment={
                                <InputAdornment position="end">
                                    <Tooltip title="Add Source IP">
                                        <IconButton
                                            aria-label="add_source"
                                            onClick={this.handleAddSource} >
                                            <Add color='primary' />
                                        </IconButton>
                                    </Tooltip>
                                </InputAdornment>}
                            />
                            <div className={classes.chiplist}>
                                {
                                    sources.map(((ip, index) => {
                                        return <Chip
                                            key={index}
                                            color="primary"
                                            label={ip}
                                            onDelete={() => this.handleDeleteSource(ip)}
                                            className={classes.chip}
                                            variant="outlined"
                                        />
                                    }))
                                }
                            </div>
                            <FormHelperText error={sourceError}>{sourceErrMsg}</FormHelperText>
                        </FormControl>  
                        <FormControl className={classes.formControl} required error={destinationError}>
                            <Typography color="textSecondary" >Destination IPs</Typography>
                            <Input id="input-destination" value={destination} onChange={this.handleDestination} endAdornment={
                                <InputAdornment position="end">
                                    <Tooltip title="Add Destination IP">
                                        <IconButton
                                            aria-label="add_destination"
                                            onClick={this.handleAddDestination} >
                                            <Add color='primary' />
                                        </IconButton>
                                    </Tooltip>
                                </InputAdornment>}
                            />
                            <div className={classes.chiplist}>
                                {
                                    destinations.map(((ip, index) => {
                                        return <Chip
                                            key={index}
                                            color="primary"
                                            label={ip}
                                            onDelete={() => this.handleDeleteDestination(ip)}
                                            className={classes.chip}
                                            variant="outlined"
                                        />
                                    }))
                                }
                            </div>
                            <FormHelperText error={destinationError}>{destinationErrMsg}</FormHelperText>
                        </FormControl>
                        <FormControl className={classes.formControl} required error={commentError}>
                            <Typography color="textSecondary" >Comment</Typography>
                            <Input id="input-comment" value={comment} onChange={this.handleComment} />
                            <FormHelperText error={commentError}>{commentErrMsg}</FormHelperText>
                        </FormControl> 
                        <div className={classes.wrapper}>
                            <Button
                                variant="outlined"
                                color="primary"
                                className={classes.button}
                                disabled={showLoading}
                                onClick={isEdit ? this.updateFirewall : this.createFirewall} >
                            {isEdit ? "Update" : "Create"} Firewall Rule
                            </Button>
                            { showLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
                        </div>   
                    </CardContent>
                </Card>
            </div>
        )
    }
}

FirewallForm.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(useStyles)(FirewallForm);