import React from 'react';
import { API, graphqlOperation  } from "aws-amplify";
import PropTypes from 'prop-types';
import useStyles from './Style'
import { withStyles, MuiThemeProvider } from '@material-ui/core/styles';
import {ArrowBack, ExpandMore, Add } from '@material-ui/icons';
import {Card, Tooltip, CardContent, IconButton, FormLabel, Snackbar, Checkbox, FormControl, Input, Typography, FormHelperText, CardHeader, FormGroup, Button, Chip, FormControlLabel, Dialog, Select, Grid, CircularProgress, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, DialogTitle, DialogContent, InputLabel, DialogActions} from '@material-ui/core';
//import { intruder, incidentresponse, kali, pentest } from '@material-ui/core';
import theme from '../../content/formTheme';
import CloseIcon from '@material-ui/icons/Close';
import GetFirewallRules from '../../graphql/queries/GetFirewallRules';
import CreateProfile from '../../graphql/mutations/CreateProfile';
import EditProfile from '../../graphql/mutations/EditProfile';
import update from 'immutability-helper';

var mixpanel = require('mixpanel-browser');

class ProfileForm extends React.Component {
    constructor(props) {
        super(props);
        const isCreate = this.props.isCreate;
        const profile = this.props.profile;
        this.state = {
            showLoading: false,
            showLoadingAccounts: false,
            showLoadingVPCs: false,
            firewallRuleNames: [],
            //Initial Form Values
            key: isCreate ? '' : profile.key,
            name: isCreate ? '' : profile.profileName,
            tags: isCreate ? [] : profile.tags.length > 0 ? profile.tags : [],
            firewallRules: isCreate ? [] : profile.firewallRules.length > 0 ? profile.firewallRules : [],
            securityGroups: isCreate ? [""] : profile.securityGroups.length > 0 ? profile.securityGroups : [""],
            securityControls: isCreate ? '' : profile.securityControls,
            scanSchedule: isCreate ? 'none' : profile.scanSchedule,
            awsTags: isCreate ? [{}] : profile.awsTags.length > 0 ? profile.awsTags : [{}],
            iamRoles: isCreate ? [""] : profile.iamRoles.length > 0 ? profile.iamRoles : [""],
            antiVirus: isCreate ? false : profile.antiVirus,
            applicationMonitoring: isCreate ? false : profile.applicationMonitoring,
            intrusionPrevention: isCreate ? false : profile.intrusionPrevention,
            systemMonitoring: isCreate ? false : profile.systemMonitoring,
            malwareProtection: isCreate ? false : profile.malwareProtection,
            mfa: isCreate ? false : profile.mfa,
            waf: isCreate ? false : profile.waf,
            encryptBootdisk: isCreate ? false : profile.encryptBootdisk,
            terminationProtection: isCreate ? false : profile.terminationProtection,
            enablePublicIP: isCreate ? false : profile.enablePublicIP,
            enableDisposable: isCreate ? false : profile.enableDisposable,
            automaticScanning: isCreate ? false : profile.automaticScanning,
            automaticScanningSchedule: isCreate ? 'none' : profile.automaticScanningSchedule,
            automaticPatching: isCreate ? false : profile.automaticPatching,
            automaticPatchingSchedule: isCreate ? 'none' : profile.automaticPatchingSchedule,
            automaticBackups: isCreate ? false : profile.automaticBackups,
            automaticBackupsSchedule: isCreate ? 'none' : profile.automaticBackupsSchedule,
            siipIP: isCreate ? ' ' : profile.siipServer ? profile.siipServer.ipAddress : '',
            siipKey: isCreate ? ' ' : profile.siipServer ? profile.siipServer.authDKey : '',
            //Sovereignty
            sovereignAU: isCreate ? false : profile.sovereign.sovereignAU,
            sovereignUSA: isCreate ? false : profile.sovereign.sovereignUSA,
            sovereignCanada: isCreate ? false : profile.sovereign.sovereignCanada,
            awsGovcloud: isCreate ? false : profile.sovereign.awsGovcloud,
            auCloud: isCreate ? false : profile.sovereign.auCloud,
            //Compliance Frameworks
            cis: isCreate ? false : profile.complianceFrameworks.cis,
            nist:  isCreate ? false : profile.complianceFrameworks.nist,
            disa_stig:  isCreate ? false : profile.complianceFrameworks.disa_stig,
            pci_dss:  isCreate ? false : profile.complianceFrameworks.pci_dss,
            hipaa:  isCreate ? false : profile.complianceFrameworks.hipaa,
            australian_ism:  isCreate ? false : profile.complianceFrameworks.australian_ism
        };
    } 

    async componentDidMount() {
        await API.graphql(graphqlOperation(GetFirewallRules.query))
        .then(result => {
            this.setState({
                firewallRuleNames: result.data.GetFirewallRules,
            }); 
            mixpanel.track("New Profile Created");
        })
        .catch(err => {
            this.setState({showAlert: true, alertSuccess: false, alertMessage: 'Error fetching firewall rules - please try refreshing the page'})
        });
    }

    handleAlertClose = () => {
        this.setState({showAlert: false})
    }

    handleChangeCheckBox = (e) => {
        var value = e.target.value
        this.setState({[e.target.value]: e.target.checked}, () => {
            if(this.state.automaticBackups === true && value === 'automaticBackups') {
                this.setState({automaticBackupsDialog: true, dialogOpen: true})
            }
            else if(this.state.automaticPatching === true && value === 'automaticPatching') {
                this.setState({automaticPatchingDialog: true, dialogOpen: true})
            }
            else if(this.state.automaticScanning === true && value === 'automaticScanning') {
                this.setState({automaticScanningDialog: true, dialogOpen: true})
            }
        })
    }

    handleDialogClose = () => {
        this.setState({automaticBackupsDialog: false, dialogOpen: false, automaticScanningDialog: false, automaticPatchingDialog: false})
    }

    handleAutomaticBackups = (e) => {
        this.setState({automaticBackupsSchedule: e.target.value})
    }

    handleAutomaticScanning = (e) => {
        this.setState({automaticScanningSchedule: e.target.value})
    }

    handleAutomaticPatching = (e) => {
        this.setState({automaticPatchingSchedule: e.target.value})
    }

    handleFirewallRule = (e) => {
        if (e.target.checked) {
            let newState = update(this.state, {
                firewallRules:  {$push: [e.target.value]}
            });
            this.setState(newState);
        } else {
            let newState = update(this.state, {
                firewallRules:  {$splice: [[e.target.name, 1]]}
            });
            this.setState(newState);
        }
    }

    handleTag = () => {
        var tagName = this.state.tagName
        var tagValue = this.state.tagValue
        if(tagName && tagValue) {
            var newTag = {name: tagName, value: tagValue}
            let newState = update(this.state, {
                tags: {  $push: [ newTag ]}  
            })
            this.setState(newState, () => {
                this.setState({tagName:'', tagValue: ''})
            }); 
        }
    }

    handleDeleteTag = (tag) => {
        let newState = update(this.state, {
            tags: arr => arr.filter(item => item.name !== tag.name)
        });
        this.setState(newState);    
    }

    handleTagName = (event) => {
        this.setState({tagName: event.target.value})
    }

    handleTagValue = (event) => {
        this.setState({tagValue: event.target.value})
    }

    handleSIIPIp = (event) => {
        if(!event.target.value.match(/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/)) {
            this.setState({siipIPErrorMsg: 'Invalid IP address', siipIP: event.target.value, siipIPError: true});
        }   
        else {
            this.setState({siipIPErrorMsg: '', siipIP: event.target.value, siipIPError: false});
        } 
    }

    handleSIIPKey = (event) => {
        this.setState({siipKey: event.target.value})
    }

    handleProfileName = (event) => {
        if(!event.target.value.match(/^[a-zA-Z0-9-_.@-]+$/)) {
            this.setState({profileNameErrMsg: 'Profile Name does not meet requirments', name: event.target.value, profileNameError: true});
        }   
        else {
            this.setState({name: event.target.value, profileNameError: false, profileNameErrMsg: ''})
        } 
    }

    handleDeleteSecurityGroups = (securityGroup) => {
        let newState = update(this.state, {
            securityGroups: arr => arr.filter(item => item !== securityGroup)
        });
        this.setState(newState);  
    }

    handleSecurityGroupName = (event) => {
        this.setState({securityGroup: event.target.value})
    }

    handleSecurityGroup = () => {
        var securityGroup = this.state.securityGroup;
        if(securityGroup) {
            let newState = update(this.state, {
                securityGroups: {  $push: [ securityGroup ]}  
            })
            this.setState(newState, () => {
                this.setState({securityGroup:''})
            }); 
        }
    }

    handleDeleteIAMRole = (iamRole) => {
        let newState = update(this.state, {
            iamRoles: arr => arr.filter(item => item !== iamRole)
        });
        this.setState(newState);  
    }

    handleIAMRoleName = (event) => {
        this.setState({iamRole: event.target.value})
    }

    handleIAMRole = () => {
        var iamRole = this.state.iamRole;
        if(iamRole) {
            let newState = update(this.state, {
                iamRoles: {  $push: [ iamRole ]}  
            })
            this.setState(newState, () => {
                this.setState({iamRole:''})
            }); 
        }
    }

    handleAWSTag = () => {
        var tagName = this.state.awsTagName
        var tagValue = this.state.awsTagValue
        if(tagName && tagValue) {
            var newTag = {name: tagName, value: tagValue}
            let newState = update(this.state, {
                awsTags: {  $push: [ newTag ]}  
            })
            this.setState(newState, () => {
                this.setState({awsTagName:'', awsTagValue: ''})
            }); 
        }
    }

    handleDeleteAWSTag = (tag) => {
        let newState = update(this.state, {
            awsTags: arr => arr.filter(item => item.name !== tag.name)
        });
        this.setState(newState);    
    }

    handleAWSTagName = (event) => {
        this.setState({awsTagName: event.target.value})
    }

    handleAWSTagValue = (event) => {
        this.setState({awsTagValue: event.target.value})
    }

    handleSubmit = async () => {
        this.setState({showLoading: true});
        const values = this.state;
        const profile = {
            profileName: values.name,
            antiVirus: values.antiVirus,
            applicationMonitoring: values.applicationMonitoring,
            intrusionPrevention: values.intrusionPrevention,
            systemMonitoring: values.systemMonitoring,
            malwareProtection: values.malwareProtection,
            mfa: values.mfa,
            waf: values.waf,
            terminationProtection: values.terminationProtection,
            encryptBootdisk: values.encryptBootdisk,
            enablePublicIP: values.enablePublicIP,
            enableDisposable: values.enableDisposable,
            securityGroups: values.securityGroups.filter(value => Object.keys(value).length !== 0),
            iamRoles: values.iamRoles.filter(value => Object.keys(value).length !== 0),
            awsTags: values.awsTags.filter(value => Object.keys(value).length !== 0),
            firewallRules: values.firewallRules.filter(value => Object.keys(value).length !== 0),
            automaticScanning: values.automaticScanning,
            automaticScanningSchedule: values.automaticScanningSchedule,
            automaticPatching: values.automaticPatching,
            automaticPatchingSchedule: values.automaticPatchingSchedule,
            automaticBackups: values.automaticBackups,
            automaticBackupsSchedule: values.automaticBackupsSchedule,
            tags: values.tags.filter(value => Object.keys(value).length !== 0),
            siipServer: {
                ipAddress: values.siipIP,
                authDKey: values.siipKey
            },
            complianceFrameworks: {
                cis: values.cis,
                nist: values.nist,
                disa_stig: values.disa_stig,
                pci_dss: values.pci_dss,
                hipaa: values.hipaa,
                australian_ism: values.australian_ism
            },
            sovereign: {
                sovereignAU: values.sovereignAU,
                sovereignUSA: values.sovereignUSA,
                sovereignCanada: values.sovereignCanada,
                awsGovcloud: values.awsGovcloud,
                auCloud: values.auCloud,
            }
        };
        if (this.props.isCreate === true)
            delete profile.key
        await API.graphql(graphqlOperation(this.props.isCreate ? CreateProfile.mutation : EditProfile.mutation, this.props.isCreate ? { profile: profile } : { profile: profile, key: this.state.key }))
        .then(result => {
            this.setState({showLoading: false});
            if(this.props.isCreate) {
                this.setState({isCreated: true, createdProfile: values.name});
            }
            this.props.handleProfile();
            this.props.toggleForm();
        }).catch(e => {
            this.setState({showAlert: true, alertSuccess: false, alertMessage: 'Error creating the profile: ' + profile.profileName})
            console.log(e)
        });
    }
  
    render() {
        const { classes, isCreate } = this.props;
        const { intrusionPrevention, antiVirus, applicationMonitoring, waf, systemMonitoring, malwareProtection, osmfa } = this.state;
        const { mfa, terminationProtection, encryptBootdisk, enablePublicIP } = this.state;
        const { cis, nist, pci_dss } = this.state;
        const { sovereignAU, sovereignUSA, sovereignCanada, awsGovcloud } = this.state;
        const { securityGroups, securityGroup, iamRoles, iamRole, awsTags, awsTagName, awsTagValue } = this.state;
        const {siipIP, siipIPError, siipIPErrorMsg, siipKey, siipKeyError, siipKeyErrorMsg} = this.state;
        const {  automaticBackupsSchedule, automaticPatchingSchedule, automaticScanningSchedule, automaticBackupsDialog, dialogOpen, automaticPatchingDialog, automaticScanningDialog } = this.state;
        const { name, profileNameErrMsg, profileNameError, tags, tagName, tagValue, showLoading } = this.state;
        const { showAlert, alertSuccess, alertMessage } = this.state;

    return ( <div>
        <MuiThemeProvider theme={theme}>
        <Dialog disableBackdropClick disableEscapeKeyDown open={dialogOpen} onClose={this.handleClose}>
            <DialogTitle>{ automaticBackupsDialog ? 'Automatic Backups Schedule' 
                            : automaticPatchingDialog ?  'Automatic Patching Schedule'
                            : automaticScanningDialog ? 'Automatic Scanning Schedule'
                            : ''}</DialogTitle>
            <DialogContent>
                <form className={classes.container}>
                    <FormControl className={classes.formControl}>
                    <InputLabel htmlFor="automaticSchedule">
                        { automaticBackupsDialog ? 'Automatic Backups Schedule' 
                            : automaticPatchingDialog ?  'Automatic Patching Schedule'
                            : automaticScanningDialog ? 'Automatic Scanning Schedule'
                            : ''}
                    </InputLabel>
                    <Select
                        native
                        value={
                            automaticBackupsDialog ? automaticBackupsSchedule
                            : automaticPatchingDialog ?  automaticPatchingSchedule
                            : automaticScanningDialog ?  automaticScanningSchedule
                            : ''
                        }
                        onChange={
                            automaticBackupsDialog ? this.handleAutomaticBackups
                            : automaticPatchingDialog ?  this.handleAutomaticPatching
                            : automaticScanningDialog ?  this.handleAutomaticScanning
                            : this.handleDialogClose
                        }
                        input={<Input id="automaticSchedule" />}
                    >
                        <option value="none" name="none" >None</option>
                        <option value="daily" name="daily" >Daily</option>
                        <option value="weekly" name="weekly">Weekly</option>
                        <option value="monthly" name="monthly">Monthly</option>
                    </Select>
                    </FormControl>
                </form>
            </DialogContent>
            <DialogActions>
                <Button onClick={this.handleDialogClose} color="primary">
                    Ok
                </Button>
            </DialogActions>
        </Dialog>
        <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={showAlert}
            onClose={this.handleAlertClose}
            ContentProps={{
                'aria-describedby': 'message-id',
                classes: {
                    root: alertSuccess ? classes.notificationSuccess : classes.notificationError
                }
            }}
            message={<span id="message-id"><b>{alertMessage}</b></span>}
            action={[
                <IconButton
                    key="close"
                    aria-label="Close"
                    color="inherit"
                    className={classes.close}
                    onClick={this.handleAlertClose}>
                    <CloseIcon />
                </IconButton>,
            ]}
        />
        <Card>
            <CardHeader
                title={(isCreate ? "Create" : "Update")+' Profile'}
                subheader={(isCreate ? "Create" : "Update") + ' a profile for your securestack instances'}
                action= {
                    <Tooltip title="Back to Profiles" aria-label="Back to Profiles">     
                        <IconButton aria-label="arrow_back" onClick={this.props.toggleForm}>
                            <ArrowBack />
                        </IconButton> 
                    </Tooltip>
                }
            />
            <CardContent>
                <form>
                <FormControl className={classes.formControl} required error={profileNameError}>
                    <Typography color="textSecondary" className={classes.typo}>Profile Name</Typography>
                    <Input id="input-profileName" value={name} onChange={this.handleProfileName} />
                    <FormHelperText error={profileNameError}>{profileNameErrMsg}</FormHelperText>
                </FormControl> 
                <Grid container spacing={3}>
                    <Grid item xs >
                        <FormControl component="fieldset" className={classes.formControl}>
                            <FormLabel component="legend">Host Security</FormLabel>
                            <FormGroup>
                            <FormControlLabel
                                control={<Checkbox checked={antiVirus} onChange={this.handleChangeCheckBox} value="antiVirus" />}
                                label="Anti-Virus"
                            />
                            <FormControlLabel
                                control={<Checkbox checked={applicationMonitoring} onChange={this.handleChangeCheckBox} value="applicationMonitoring" />}
                                label="Application Monitoring"
                            />
                            <FormControlLabel
                                control={
                                <Checkbox checked={waf} onChange={this.handleChangeCheckBox} value="waf" />}
                                label="Web Application Firewall"
                            />
                            <FormControlLabel
                                control={
                                <Checkbox checked={systemMonitoring} onChange={this.handleChangeCheckBox} value="systemMonitoring" />}
                                label="System Monitoring"
                            />
                            <FormControlLabel
                                control={
                                <Checkbox checked={malwareProtection} onChange={this.handleChangeCheckBox} value="malwareProtection" />}
                                label="Malware Protection"
                            />
                            <FormControlLabel
                                control={
                                <Checkbox checked={intrusionPrevention} checked={true} onChange={this.handleChangeCheckBox} value="intrusionPrevention" />}
                                label="Intrusion Detection"
                            />
                            <FormControlLabel
                                control={
                                <Checkbox checked={osmfa} checked={true} value="osmfa" />}
                                label="Multi-Factor Authentication"
                            />
                            </FormGroup>
                        </FormControl>
                    </Grid>
                    <Grid item xs >
                        <FormControl component="fieldset" className={classes.formControl}>
                            <FormLabel component="legend">Cloud Security</FormLabel>
                            <FormGroup>
                            <FormControlLabel
                                control={
                                <Checkbox checked={mfa} onChange={this.handleChangeCheckBox} value="mfa" />}
                                label="Multi-Factor Authentication"
                            />
                            <FormControlLabel
                                control={<Checkbox checked={terminationProtection} onChange={this.handleChangeCheckBox} value="terminationProtection" />}
                                label="Termination Protection"
                            />
                            <FormControlLabel
                                control={
                                <Checkbox checked={encryptBootdisk} onChange={this.handleChangeCheckBox} value="encryptBootdisk" />}
                                label="Encrypt Boot Disk"
                            />
                            <FormControlLabel
                                control={
                                <Checkbox checked={enablePublicIP} onChange={this.handleChangeCheckBox} value="enablePublicIP" />}
                                label="Allow Public IP"
                            />
			    { /*
                            <FormControlLabel
                                control={
                                <Checkbox checked={enableDisposable} onChange={this.handleChangeCheckBox} value="enableDisposable" />}
                                label="Disposable Environment"
                            />
			    */ }
                            </FormGroup>
                        </FormControl>
                    </Grid>
                    <Grid item xs >
                        <FormControl component="fieldset" className={classes.formControl}>
                            <FormLabel component="legend">Sovereignty</FormLabel>
                            <FormGroup>
                            <FormControlLabel
                                control={
                                <Checkbox checked={sovereignAU} onChange={this.handleChangeCheckBox} value="sovereignAU" />}
                                label="Australia"
                            />
                            <FormControlLabel
                                control={
                                <Checkbox checked={sovereignCanada} onChange={this.handleChangeCheckBox} value="sovereignCanada" />}
                                label="Canada"
                            />
                            <FormControlLabel
                                control={
                                <Checkbox checked={sovereignUSA} onChange={this.handleChangeCheckBox} value="sovereignUSA" />}
                                label="United States"
                            />
                            <FormControlLabel
                                control={
                                <Checkbox checked={awsGovcloud} onChange={this.handleChangeCheckBox} value="awsGovcloud" />}
                                label="AWS GovCloud"
                            />
                            </FormGroup>
                        </FormControl>
                    </Grid>
                    <Grid item xs >
                        <FormControl component="fieldset" className={classes.formControl}>
                            <FormLabel component="legend">Compliance Frameworks</FormLabel>
                            <FormGroup>
                            <FormControlLabel
                                control={
                                <Checkbox checked={cis} checked={true} onChange={this.handleChangeCheckBox} value="cis" />}
                                label="CIS"
                            />
                            <FormControlLabel
                                control={<Checkbox checked={nist} onChange={this.handleChangeCheckBox} value="nist" />}
                                label="NIST"
                            />
                            <FormControlLabel
                                control={
                                <Checkbox checked={pci_dss} onChange={this.handleChangeCheckBox} value="pci_dss" />}
                                label="PCI DSS"
                            />
			    {
			    /*
                            <FormControlLabel
                                control={
                                <Checkbox checked={disa_stig} onChange={this.handleChangeCheckBox} value="disa_stig" />}
                                label="DISA STIG"
                            />
                            <FormControlLabel
                                control={
                                <Checkbox checked={hipaa} onChange={this.handleChangeCheckBox} value="hipaa" />}
                                label="HIPAA"
                            />
                            <FormControlLabel
                                control={
                                <Checkbox checked={australian_ism} onChange={this.handleChangeCheckBox} value="australian_ism" />}
                                label="Australian ISM"
                            />
			    */
			    }
                            </FormGroup>
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
		    {
                    /*
                    <Grid item xs >
                        <FormControl component="fieldset" className={classes.formControl}>
                            <FormLabel component="legend">Enable Vulnerability Scanning </FormLabel>
                            <FormGroup>
                            <FormControlLabel
                                control={<Checkbox checked={automaticScanning} onChange={this.handleChangeCheckBox} value="automaticScanning" />}
                                label="Enabled"
                            />
                            { automaticScanning === true &&
                               <FormHelperText>Scanning Schedule: {automaticScanningSchedule}</FormHelperText>
                            }
                            </FormGroup>
                        </FormControl>
                    </Grid>
                    <Grid item xs >
                        <FormControl component="fieldset" className={classes.formControl}>
                            <FormLabel component="legend">Enable Patching</FormLabel>
                            <FormGroup>
                            <FormControlLabel
                                control={<Checkbox checked={automaticPatching} onChange={this.handleChangeCheckBox} value="automaticPatching" />}
                                label="Enabled"
                            />
                            { automaticPatching === true &&
                               <FormHelperText>Patching Schedule: {automaticPatchingSchedule}</FormHelperText>
                            }
                            </FormGroup>
                        </FormControl>
                    </Grid>
		    */
		    }
                    {
                    /*
                        <Grid item xs >
                            <FormControl component="fieldset" className={classes.formControl}>
                                <FormLabel component="legend">Enable Backups</FormLabel>
                                <FormGroup>
                                <FormControlLabel
                                    control={<Checkbox checked={automaticBackups} onChange={this.handleChangeCheckBox} value="automaticBackups" />}
                                    label="Enable"
                                />
                                { automaticBackups === true &&
                                <FormHelperText>Backups Schedule: {automaticBackupsSchedule}</FormHelperText>
                                }
                                </FormGroup>
                            </FormControl>
                        </Grid>
                    */
                    }
                </Grid>
                {
                    /*
                    <Grid container spacing={3}>
                        <FormControl component="fieldset" className={classes.formControl}>
                        <FormLabel component="legend">Firewall Rules</FormLabel>
                        { firewallRuleNames.map((v,k) => (
                            <Grid item xs key={`${v.firewallName}-${k}`} >
                                <FormControlLabel
                                    control={<Checkbox 
                                        name={k}
                                        checked={firewallRules.indexOf(JSON.stringify(v)) > -1}
                                        onChange={this.handleFirewallRule}
                                        value={JSON.stringify(v)} />}
                                    label={v.firewallName}
                                />
                            </Grid> 
                        ))}
                        </FormControl>
                    </Grid>
                    */
                }
                <FormControl className={classes.formControl} required error={siipIPError}>
                    <Typography color="textSecondary" className={classes.typo}>SIIP Server IP</Typography>
                    <Input id="input-siipServer" value={siipIP} onChange={this.handleSIIPIp} />
                    <FormHelperText error={siipIPError}>{siipIPErrorMsg}</FormHelperText>
                </FormControl>
                <FormControl className={classes.formControl} required error={siipKeyError}>
                    <Typography color="textSecondary" className={classes.typo}>SIIP Server Key</Typography>
                    <Input id="input-siiopKey" type='text' value={siipKey} onChange={this.handleSIIPKey} />
                    <FormHelperText error={siipKeyError}>{siipKeyErrorMsg}</FormHelperText>
                </FormControl>
                <FormControl className={classes.formControl}>
                    <Typography color="textSecondary" className={classes.typo}>Tags</Typography>
                    <div className={classes.container}>
                        <Input id="input-TagName"  placeholder="Tag Name" value={tagName} className={classes.tagInput} onChange={this.handleTagName} />
                        <Input id="input-TagValue" placeholder="Tag Value" value={tagValue} className={classes.tagInput} onChange={this.handleTagValue} />
                        <Button variant="outlined" color='primary' size="small" className={classes.tagButton} onClick={this.handleTag}>
                        <Add />
                            Add Tag
                        </Button>
                    </div>
                    <div className={classes.taglist}>
                        {
                            tags.map(((tag, index) => {
                                return <Chip
                                    key={index}
                                    color="primary"
                                    label={tag.name+": " +tag.value}
                                    onDelete={() => this.handleDeleteTag(tag)}
                                    className={classes.chip}
                                    variant="outlined"
                                />
                            }))
                        }
                    </div>
                </FormControl>
                <div className={classes.formControl}>
                    <ExpansionPanel>
                        <ExpansionPanelSummary
                            expandIcon={<ExpandMore />}
                            aria-controls="panel1a-content"
                            id="panel1a-header">
                            <FormLabel component="legend">AWS Specific Items</FormLabel>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <FormControl className={classes.formControl}>
                                <Typography color="textSecondary" className={classes.typo}>Security Groups</Typography>
                                <div className={classes.container}>
                                    <Input id="input-SecurityGroup" value={securityGroup} className={classes.tagInput} onChange={this.handleSecurityGroupName} />
                                    <Button variant="outlined" color='primary' size="small" className={classes.tagButton} onClick={this.handleSecurityGroup}>
                                    <Add />
                                        Add Security Group
                                    </Button>
                                </div>
                                <div className={classes.taglist}>
                                    {
                                        securityGroups.map(((sg, index) => {
                                            return <Chip
                                                key={index}
                                                color="primary"
                                                label={sg}
                                                onDelete={() => this.handleDeleteSecurityGroups(sg)}
                                                className={classes.chip}
                                                variant="outlined"
                                            />
                                        }))
                                    }
                                </div>
                            </FormControl>
                            </ExpansionPanelDetails>
                            <ExpansionPanelDetails>
                                <FormControl className={classes.formControl}>
                                    <Typography color="textSecondary" className={classes.typo}>IAM Roles</Typography>
                                    <div className={classes.container}>
                                        <Input id="input-IAMRole" value={iamRole} className={classes.tagInput} onChange={this.handleIAMRoleName} />
                                        <Button variant="outlined" color='primary' size="small" className={classes.tagButton} onClick={this.handleIAMRole}>
                                        <Add />
                                            Add IAM Role
                                        </Button>
                                    </div>
                                    <div className={classes.taglist}>
                                        {
                                            iamRoles.map(((iam, index) => {
                                                return <Chip
                                                    key={index}
                                                    color="primary"
                                                    label={iam}
                                                    onDelete={() => this.handleDeleteSecurityGroups(iam)}
                                                    className={classes.chip}
                                                    variant="outlined"
                                                />
                                            }))
                                        }
                                    </div>
                                </FormControl>
                            </ExpansionPanelDetails>
                            <ExpansionPanelDetails>
                                <FormControl className={classes.formControl}>
                                    <Typography color="textSecondary" className={classes.typo}>AWS Tags</Typography>
                                    <div className={classes.container}>
                                        <Input id="input-AWSTagName"  placeholder="Tag Name" value={awsTagName} className={classes.tagInput} onChange={this.handleAWSTagName} />
                                        <Input id="input-AWSTagValue" placeholder="Tag Value" value={awsTagValue} className={classes.tagInput} onChange={this.handleAWSTagValue} />
                                        <Button variant="outlined" color='primary' size="small" className={classes.tagButton} onClick={this.handleAWSTag}>
                                        <Add />
                                            Add Tag
                                        </Button>
                                    </div>
                                    <div className={classes.taglist}>
                                        {
                                            awsTags.map(((tag, index) => {
                                                return <Chip
                                                    key={index}
                                                    color="primary"
                                                    label={tag.name+": " +tag.value}
                                                    onDelete={() => this.handleDeleteAWSTag(tag)}
                                                    className={classes.chip}
                                                    variant="outlined"
                                                />
                                            }))
                                        }
                                    </div>
                                </FormControl>
                            </ExpansionPanelDetails>
                    </ExpansionPanel>
                    <ExpansionPanel>
                        <ExpansionPanelSummary
                            expandIcon={<ExpandMore />}
                            aria-controls="panel1a-content"
                            id="panel1a-header">
                            <FormLabel component="legend">Google GCP Specific Items</FormLabel>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <Typography>
                                GCP
                            </Typography>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                    <ExpansionPanel>
                        <ExpansionPanelSummary
                            expandIcon={<ExpandMore />}
                            aria-controls="panel1a-content"
                            id="panel1a-header">
                            <FormLabel component="legend">Azure Specific Items</FormLabel>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <Typography>
                            Azure
                            </Typography>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                </div>
                <div className={classes.wrapper}>
                    <Button
                        variant="outlined"
                        color="primary"
                        className={classes.button}
                        disabled={showLoading}
                        onClick={this.handleSubmit} >
                    {isCreate ? "Create" : "Update"} Profile
                    </Button>
                    {showLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
                </div>
                </form>
            </CardContent> 
        </Card>
        </MuiThemeProvider>
    </div>)
    }
}


ProfileForm.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(useStyles)(ProfileForm);
