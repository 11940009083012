export default { query: `
  query GetTargets {
    GetTargets {
      targetName
      provider
      credential
      instanceType
      region
      key
      tags {
        name
        value
      }
      vpc
      securityGroups {
        name
        id
      } 
      resourceGroup
      networkInterface
      subnetId
      active
    }
  }`
};
