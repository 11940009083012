import React from 'react';
import "tabler-react/dist/Tabler.css";
import { Button, Header, Card, PricingCard, Grid } from "tabler-react";
import stripe_config from "../stripe_config";

export default class EarlyAccess extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div>
            <Card statusColor="blue">
                <Card.Body>
                    <Header.H1 className="display-1 text-muted mb-5"><center><b>SecureCloud</b></center></Header.H1>
                    <Header.H3 className="display-2 text-muted mb-6"><center>Free 30 Day Trial</center></Header.H3>
                    <Header.H4 className="text-muted font-weight-normal">
                        SecureCloud let's you orchestrate security controls across your multiple environments in AWS, GCP and Azure from one solution.  Manage the lifecycle of your inventory and optimize for security and cost automatically. Enable security controls and applictions like intrustion detection (IDS) and prevention (IPS), anti-virus, rootkit detection, host based MFA, file integrity monitoring, system and cloud auditing, system monitoring, dynamic host-based firewalls, and more. 
                    </Header.H4>
                    <Header.H4 className="text-muted font-weight-normal">
                        SecureCloud empowers your teams to build what they need while enforcing security using products you build in SecureCloud.
                    </Header.H4>
                    <Header.H4 className="text-muted font-weight-normal">
                        Pricing is for <b>monthly subscriptions </b>billed automatically each month.
                    </Header.H4>
                    <div style={{display: 'flex',  justifyContent:'center', alignItems:'center'}}>  
                    <Grid>    
                        <Grid.Col sm={6} lg={3}>
                        <PricingCard>
                            <PricingCard.Category>{"SecureCloud StartUp"}</PricingCard.Category>
                            <PricingCard.Price>{"$400"} </PricingCard.Price>
                            <PricingCard.AttributeList>
                                <PricingCard.AttributeItem>
                                    {"1 User & 10 Instances"}
                                </PricingCard.AttributeItem>
                                <PricingCard.AttributeItem hasIcon available>
                                    {"AWS Cloud Security"}
                                </PricingCard.AttributeItem>
                                <PricingCard.AttributeItem hasIcon available>
                                    {"Host Based Security"}
                                </PricingCard.AttributeItem>
                                <PricingCard.AttributeItem hasIcon available>
                                    {"SIEM"}
                                </PricingCard.AttributeItem>
                            </PricingCard.AttributeList>
                            <Button color="primary" onClick={() => this.props.nextStep(stripe_config.startUp.name, stripe_config.startUp.planId)} >Try Now</Button>
                        </PricingCard>
                        </Grid.Col>

                        <Grid.Col sm={6} lg={3}>            
                        <PricingCard>
                            <PricingCard.Category>{"SecureCloud Small"}</PricingCard.Category>
                            <PricingCard.Price>{"$2000"} </PricingCard.Price>
                            <PricingCard.AttributeList>
                                <PricingCard.AttributeItem>
                                    {"2 Users & 50 Instances"}
                                </PricingCard.AttributeItem>
                                <PricingCard.AttributeItem hasIcon available>
                                    {"Cloud Security"}
                                </PricingCard.AttributeItem>
                                <PricingCard.AttributeItem hasIcon available>
                                    {"Host Based Security"}
                                </PricingCard.AttributeItem>
                                <PricingCard.AttributeItem hasIcon available>
                                    {"SIEM"}
                                </PricingCard.AttributeItem>
                                <PricingCard.AttributeItem hasIcon available>
                                    {"Security Scores"}
                                </PricingCard.AttributeItem>
                                </PricingCard.AttributeList>
                            <Button color="primary" onClick={() => this.props.nextStep(stripe_config.startUp.name, stripe_config.startUp.planId)} >Try Now</Button>
                        </PricingCard>
                        </Grid.Col>
                        <Grid.Col sm={6} lg={3}>            
                        <PricingCard>
                            <PricingCard.Category>{"Securecloud Medium"}</PricingCard.Category>
                            <PricingCard.Price>{"$10,000"} </PricingCard.Price>
                            <PricingCard.AttributeList>
                                <PricingCard.AttributeItem>
                                    {"5 Users & 500 Instances"}
                                </PricingCard.AttributeItem>
				<PricingCard.AttributeItem hasIcon available>
                                    {"Cloud Security"}
                                </PricingCard.AttributeItem>
                                <PricingCard.AttributeItem hasIcon available>
                                    {"Host Based Security"}
                                </PricingCard.AttributeItem>
                                <PricingCard.AttributeItem hasIcon available>
                                    {"Multi-Cloud Ingestion"}
                                </PricingCard.AttributeItem>
                                <PricingCard.AttributeItem hasIcon available>
                                    {"SIEM"}
                                </PricingCard.AttributeItem>
                                <PricingCard.AttributeItem hasIcon available>
                                    {"Security Scores"}
                                </PricingCard.AttributeItem>
                            </PricingCard.AttributeList>
                            <Button color="primary" disabled onClick={() => this.props.nextStep(stripe_config.earlyAccess.name, stripe_config.earlyAccess.planId)} >Try Now</Button>
                        </PricingCard>
                        </Grid.Col>
                    </Grid>
                    </div>
                </Card.Body>
            </Card>
            </div>
        );
    }
}

