export default { mutation: `
mutation CreateAMI(
    $image: String,
    $region: String,
    $credential: String,
    $amiName: String,
    $amiDesc: String,
    $os: String
  ) {
  CreateAMI(
    createAMI : {
      image: $image,
      region: $region,
      credential: $credential,
      amiName: $amiName,
      amiDesc: $amiDesc,
      os: $os
    }
  ) {
    result
    payload
  }
}`
};