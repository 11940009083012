import { fade } from '@material-ui/core/styles/colorManipulator';

const style = theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
    },
    gridList: {
        flex:1
    },
    icon: {
        color: 'rgba(255, 255, 255, 0.54)',
    },
    fileImage: {
        width:120,
        height:120, 
        display: 'block', 
        margin: 'auto'
    },
    headSearch: {
        width: 500,
        backgroundColor: fade('rgba(228, 233, 237, 50)', 0.15),
        '&:hover': {
            backgroundColor: fade('rgba(228, 233, 237, 1)', 0.25),
        },
        flexDirection: 'row'
    },
    input: {
        width: '80%'
    },
    tileActions: {
        width: 100,
    },
    buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
});

export default style;