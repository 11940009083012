import React, { useState } from 'react';
import { Button, DialogActions, Dialog, DialogTitle, DialogContent, DialogContentText, CircularProgress, 
List, ListItem, ListItemText, ListItemSecondaryAction, Checkbox } from '@material-ui/core/'
import base_amis from '../../content/baseAMIs';
import BlueprintOrder from '../../components/BlueprintOrder'

export default function DialogUpdateBlueprint(props) {
    const classes = props.classes
    const [ reorderedBlueprints, setReorderedBlueprints] = useState(null)

    return (
        <Dialog onClose={props.handleAddBlueprintsClose} aria-labelledby="form-dialog-title" open={props.open}>
            <DialogTitle id="form-dialog-title">{props.type === 'orderBlueprints' ?  "Re-Order Blueprints" : "Add Blueprints"}</DialogTitle>
            {
                props.type === 'orderBlueprints' ? 
                    <DialogContent>
                        <BlueprintOrder 
                            blueprints={props.selectedProduct.blueprints.map((x) => {
                                return props.blueprints.find((o) => o.key === x )
                            })}
                            setBlueprints={(blueprints)=> setReorderedBlueprints(blueprints)}
                        />
                    </DialogContent>
                :
                props.type === 'editBlueprints' ? 
                    <DialogContent>
                        <DialogContentText>
                            Select the blueprints from the list below
                        </DialogContentText>
                        <List>
                        {
                            props.selectedProduct && !props.loadingAMIs &&
                                props.blueprints
                                .filter((o) => !props.selectedProduct.blueprints
                                .includes(o.key))
                                .filter(blueprint => {
                                    //block incompatible operating systems
                                    const image = props.selectedProduct.image
                                    const os = base_amis.find(i => i.imageAMI === image) ? base_amis.find(i => i.imageAMI === image).OS : props.amis.find(i => i.imageAMI === image).os
                                    return JSON.parse(blueprint.operatingSystems)[os]
                                })
                                .map((blueprint , key) => {
                                    return <ListItem button key={key}>
                                        <ListItemText primary={blueprint.filename}/>
                                        <ListItemSecondaryAction>
                                            <Checkbox
                                                edge="end"
                                                onChange={props.handleCheckBlueprint(blueprint)}
                                                checked={props.blueprintsChecked.indexOf(blueprint) !== -1} />
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                })
                        }
                        </List>                    
                    </DialogContent>
                    :
                    null
            }
            <DialogActions>
            <Button onClick={props.handleAddBlueprintsClose} disabled={props.updatingProducts} color="primary">
                Cancel
            </Button>
            <Button onClick={() => props.type === 'orderBlueprints' ?  props.handleReorderBlueprints(reorderedBlueprints) : props.handleAddBlueprints()} disabled={props.updatingProducts} color="primary">
                Update Blueprint
            </Button>
            {  props.updatingProducts && <CircularProgress size={24} className={classes.productsProgress} /> }
        </DialogActions>
    </Dialog>
    )
}