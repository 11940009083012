export default { mutation: `
mutation EditProduct(
  $product: ProductInput,
  $key: String
  ) 
  {
    EditProduct(key: $key, product: $product ) 
  {
    productName
    key
    costCentre
    blueprints
    image
    target {
      key
      targetName
      provider
      credential
      instanceType
      region
      tags {
        name
        value
      }
      vpc
      securityGroups {
        name
        id
      } 
      subnetId
    }
    profile {
      key
      profileName
      antiVirus
      applicationMonitoring
      intrusionPrevention
      systemMonitoring
      malwareProtection
      mfa
      waf
      iamRoles
      securityGroups
      awsTags {
          name
          value
      }
      firewallRules
      automaticScanning
      automaticScanningSchedule
      automaticPatching
      automaticPatchingSchedule
      automaticBackups
      automaticBackupsSchedule
      enablePublicIP
      terminationProtection
      encryptBootdisk
      tags {
          name
          value
      }
      siipServer {
          ipAddress
        authDKey
      }
      sovereign {
        sovereignAU
        sovereignUSA
        sovereignCanada
        awsGovcloud
        auCloud
      }
    }
    users
    groups
  }
}`
};
