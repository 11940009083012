export default { mutation: `mutation DeleteProduct($key: String) {
    DeleteProduct( key: $key ) 
    {
      productName
      key
      costCentre
      blueprints
      image
      target {
        key
        targetName
        provider
        credential
        instanceType
        region
        tags {
          name
          value
        }
        vpc
        securityGroups {
          name
          id
        } 
        subnetId
      }
      profile {
        profileName
        antiVirus
        key
        applicationMonitoring
        intrusionPrevention
        systemMonitoring
        malwareProtection
        mfa
        waf
        iamRoles
        securityGroups
        awsTags {
            name
            value
        }
        firewallRules
        automaticScanning
        automaticScanningSchedule
        automaticPatching
        automaticPatchingSchedule
        automaticBackups
        automaticBackupsSchedule
        enablePublicIP
        terminationProtection
        encryptBootdisk
        tags {
            name
            value
        }
        siipServer {
            ipAddress
          authDKey
        }
        sovereign {
          sovereignAU
          sovereignUSA
          sovereignCanada
          awsGovcloud
          auCloud
        }
      }
      users 
      groups
    }
  }`
  };