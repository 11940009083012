import React, { Component } from 'react';
import PropTypes from 'prop-types';
import useStyles from './Style'
import { withStyles } from '@material-ui/core/styles';
import { InsertDriveFile, Home, Edit, ExpandMore } from '@material-ui/icons';
import { Card, CardHeader, CardContent, FormControl, Typography, Input, FormHelperText, Button, ExpansionPanelDetails, ExpansionPanelSummary,
    ExpansionPanel, CircularProgress, Paper, Breadcrumbs, Divider, Checkbox, FormControlLabel, FormGroup } from '@material-ui/core';
import Alert from '../../components/Alert';
import { Link } from 'react-router-dom';
import { API, graphqlOperation  } from "aws-amplify";
import CreateBlueprint from '../../graphql/mutations/CreateBlueprint';
import EditBlueprint from '../../graphql/mutations/EditBlueprint';

class BlueprintsForm extends Component {
    constructor(props) {
        super(props);
        const { isEdit, blueprint } = this.props.location.state ? this.props.location.state : { isEdit: false, blueprint: {} };
        var { applicationServers, cyberRange, operatingSystems } = {};
        if(isEdit) {
            applicationServers = JSON.parse(blueprint.applicationServers)
            cyberRange = JSON.parse(blueprint.cyberRange)
            operatingSystems  = JSON.parse(blueprint.operatingSystems)
        }
        this.state = {
            isEdit: isEdit,
            blueprint: blueprint,
            showLoading: false,
            fileName: isEdit ? blueprint.filename : '',
            fileContent: isEdit ? JSON.parse(blueprint.file) : '',
            //Cyber templates
            honeypot: isEdit && cyberRange.honeypot ? cyberRange.honeypot : false,
            deceptionops: isEdit && cyberRange.deceptionops ? cyberRange.deceptionops : false,
            intruder: isEdit && cyberRange.intruder ? cyberRange.intruder : false,
            siem: isEdit && cyberRange.siem ? cyberRange.siem : false,
            incidentresponse: isEdit && cyberRange.incidentresponse ? cyberRange.incidentresponse : false,
            kali: isEdit && cyberRange.kali ? cyberRange.kali : false,
            pentest: isEdit && cyberRange.pentest ? cyberRange.pentest : false,
            //Application templates
            apache: isEdit && applicationServers.apache ? applicationServers.apache : false,
            nginx: isEdit && applicationServers.nginx ? applicationServers.nginx : false,
            mySQL: isEdit && applicationServers.mySQL ? applicationServers.mySQL : false,
            elasticsearch:isEdit && applicationServers.elasticsearch ? applicationServers.elasticsearch : false,
            wordpress: isEdit && applicationServers.wordpress ? applicationServers.wordpress : false,
            docker: isEdit && applicationServers.docker ? applicationServers.docker : false,
            jenkins: isEdit && applicationServers.jenkins ? applicationServers.jenkins : false,
            IIS: isEdit && applicationServers.IIS ? applicationServers.IIS : false,
            AWX: isEdit && applicationServers.AWX ? applicationServers.AWX : false,

            //Operating Systems
            windows: isEdit && operatingSystems.windows ? operatingSystems.windows : false,
            ubuntu: isEdit && operatingSystems.ubuntu ? operatingSystems.ubuntu : false,
            centOS: isEdit && operatingSystems.centOS ? operatingSystems.centOS : false,
            redHat: isEdit && operatingSystems.redHat ? operatingSystems.redHat : false
        };
    } 

    handlePanel = (panel) => {
        if(panel === this.state.expanded) {
            this.setState({expanded: ''})
        }
        else {
            this.setState({expanded: panel})
        }
    }

    handleChangeCheckBox = (e) => { this.setState({[e.target.value]: e.target.checked})}

    componentDidMount = () => {
        if(this.state.isEdit) {
            var enc = new TextDecoder("utf-8");
            const content = enc.decode(new Uint8Array(JSON.parse(this.state.fileContent).data))
            this.setState({fileContent: content})
        }
    }

    handleAlertClose = () => { this.setState({showAlert: false, alertMessage: ''})}

    handleFileName = (e) => {
        this.setState({fileName: e.target.value})
        if (e.target.value.match(/^[a-zA-Z0-9.-_@-]+$/)) {
            this.setState({fileNameErrMsg: '', fileNameError: false});
        } 
        else {
            this.setState({fileNameErrMsg: 'Invalid file name', fileNameError: true});
        }
    }

    handleFileContent = (e) => {
        this.setState({fileContent: e.target.value}) 
    }

    clearForm = () => { 
        this.setState({ 
            fileName: '',
            fileContent: '',
            //Cyber
            honeypot: false,
            deceptionops: false,
            intruder: false,
            siem: false,
            incidentresponse: false,
            kali: false,
            pentest: false,
            //Application templates
            apache: false,
            nginx: false,
            mySQL: false,
            elasticsearch: false,
            wordpress: false,
            docker: false,
            jenkins: false,
            IIS: false,
            AWX: false

        }) 
    }

    createBlueprint = async () => {
        if( !this.state.fileNameError ) {
            this.setState({showLoading: true})
            const blueprint = {
                key: this.state.isEdit ? this.state.blueprint.key : null,
                filename: this.state.fileName,
                file: this.state.fileContent,
                createdBy: this.props.user['cognito:username'],
                reviewedBy: this.props.user['cognito:username'],
                reviewed: true,
                applicationServers: JSON.stringify({
                    apache: this.state.apache,
                    nginx: this.state.nginx,
                    mySQL: this.state.mySQL,
                    elasticsearch: this.state.elasticsearch,
                    wordpress: this.state.wordpress,
                    docker: this.state.docker,
                    jenkins: this.state.jenkins,
                    IIS: this.state.IIS,
                    AWX: this.state.AWX


                }),
                cyberRange: JSON.stringify({
                    honeypot: this.state.honeypot,
                    deceptionops: this.state.deceptionops,
                    intruder: this.state.intruder,
                    siem: this.state.siem,
                    incidentresponse: this.state.incidentresponse,
                    kali: this.state.kali,
                    pentest: this.state.pentest
                }),
                operatingSystems: JSON.stringify({
                    windows: this.state.windows,
                    ubuntu: this.state.ubuntu,
                    redHat: this.state.redHat,
                    centOS: this.state.centOS
                })
            }

            await API.graphql(graphqlOperation(this.state.isEdit ? EditBlueprint.mutation : CreateBlueprint.mutation, blueprint))
            .then(result => {
                const alertMsg = `${this.state.isEdit ? `The Blueprint ${blueprint.filename} has been updated.` : `New Blueprint ${blueprint.filename} created.`}`
                this.setState({showLoading: false, showAlert: true, alertMessage: alertMsg, alertSuccess: true})
            })
            .catch(err => {
                this.setState({ showLoading: false, showAlert: true, alertMessage: "Error while updating the Blueprint.", alertSuccess: false });
            });
        }
        else {
            this.setState({ showAlert: true, alertMessage: "Invalid values please check you have no errors on the form", alertSuccess: false });
        }
    } 

    render() {
        const { classes } = this.props;
        const { isEdit, blueprint, showLoading, showAlert, alertSuccess, alertMessage, fileName, fileNameErrMsg, fileNameError, fileContent, expanded } = this.state;
        const { apache, nginx, mySQL, elasticsearch, wordpress, docker, jenkins, IIS, AWX } = this.state; //Application Services
        const { whs, foss } = this.state;
        const { honeypot, deceptionops, intruder, siem, incidentresponse, kali, pentest } = this.state;
        const { windows, centOS, redHat, ubuntu } = this.state;
        
        return ( 
            <div>
                <Alert showAlert={showAlert} alertSuccess={alertSuccess} alertMessage={alertMessage} handleAlertClose={this.handleAlertClose}/>
                <Paper className={classes.bread}>
                    <Breadcrumbs aria-label="Breadcrumb" >
                        <Link color='primary' to='/'>
                            <Home className={classes.icon}/>
                            Home
                        </Link>
                        <Link color='primary' to="/Blueprints">  
                            <InsertDriveFile className={classes.icon} />
                            Blueprints
                        </Link>
                        <Typography color='primary' className={classes.link}>
                            <Edit className={classes.icon} />
                            Blueprint Form
                        </Typography>
                    </Breadcrumbs>
                </Paper>
                <Card>
                    <CardHeader
                        title={isEdit ?  'Edit Blueprints': 'Create Blueprint' }
                        subheader={isEdit ? `Update Blueprint ${blueprint.filename}` : 'Create a New Blueprint'}
                    />
                    <CardContent>
                        <FormControl className={classes.formControl} required error={fileNameError}>
                            <Typography color="textSecondary" >Blueprint Name</Typography>
                            <Input id="input-filename" disabled={isEdit} value={fileName} onChange={this.handleFileName} />
                            <FormHelperText error={fileNameError}>{fileNameErrMsg}</FormHelperText>
                        </FormControl> 
                        <FormControl className={classes.formControl} required error={fileNameError}>
                            <Typography color="textSecondary" >Operating  Systems</Typography>
                            <FormGroup row>
                                <FormControlLabel
                                    control={<Checkbox checked={windows} onChange={this.handleChangeCheckBox} value="windows" disabled={centOS || ubuntu || redHat ? true : false} />}
                                    label="Windows"
                                />
                                <FormControlLabel
                                    control={<Checkbox checked={centOS} onChange={this.handleChangeCheckBox} value="centOS" disabled={windows ? true : false} /> }
                                    label="CentOS"
                                />
                                <FormControlLabel
                                    control={
                                    <Checkbox checked={ubuntu} onChange={this.handleChangeCheckBox} value="ubuntu" disabled={windows ? true : false} />}
                                    label="Ubuntu"
                                />
                                <FormControlLabel
                                    control={
                                    <Checkbox checked={redHat} onChange={this.handleChangeCheckBox} value="redHat" disabled={windows ? true : false} /> }
                                    label="Red Hat"
                                />
                            </FormGroup>
                        </FormControl> 
                        <FormControl className={classes.formControl}>
                            <Typography color="textSecondary" >Services</Typography>
                            <br/>
                            <Divider/>
                            {
                                /*
                                <ExpansionPanel expanded={expanded === 'applicationPanel'} onChange={() => this.handlePanel('applicationPanel')} elevation={0}>
                                <ExpansionPanelSummary
                                    expandIcon={<ExpandMore />}
                                    aria-controls="panel3bh-content"
                                    id="panelapplication-header" >
                                    <Typography className={classes.heading}>Security Scanning: </Typography>
                                    <Typography className={classes.secondaryHeading}>
                                        DAST/SAST/Vulnerability Scanning
                                    </Typography>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails>                        
                                    <FormGroup row>
                                        <FormControlLabel
                                            control={<Checkbox checked={whs} onChange={this.handleChangeCheckBox} value="whs" />}
                                            label="WhiteHat Security Sentinel"
                                        />
                                        <FormControlLabel
                                            control={<Checkbox checked={foss} onChange={this.handleChangeCheckBox} value="foss" />}
                                            label="FOSS"
                                        />
                                    </FormGroup>
                                </ExpansionPanelDetails>
                                </ExpansionPanel>
                                */
                            }   
                            <ExpansionPanel expanded={expanded === 'cyberPanel'} onChange={() => this.handlePanel('cyberPanel')} elevation={0}>
                            <ExpansionPanelSummary
                                expandIcon={<ExpandMore />}
                                aria-controls="panel3bh-content"
                                id="panelcontrol-header" >
                                <Typography className={classes.heading}>Cyber Range: </Typography>
                                <Typography className={classes.secondaryHeading}>
                                    Pre-built Cyber range services
                                </Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <FormGroup row>
                                    <FormControlLabel
                                        control={<Checkbox checked={honeypot} onChange={this.handleChangeCheckBox} value="honeypot" />}
                                        label="Honeypot"
                                    />
                                    <FormControlLabel
                                        control={<Checkbox checked={deceptionops} onChange={this.handleChangeCheckBox} value="deceptionops" />}
                                        label="DeceptionOps"
                                    />
                                    <FormControlLabel
                                        control={
                                        <Checkbox checked={intruder} onChange={this.handleChangeCheckBox} value="intruder" />}
                                        label="Active Intruder"
                                    />
                                    <FormControlLabel
                                        control={
                                        <Checkbox checked={siem} onChange={this.handleChangeCheckBox} value="siem" />}
                                        label="SIEM"
                                    />
                                    <FormControlLabel
                                        control={
                                        <Checkbox checked={incidentresponse} onChange={this.handleChangeCheckBox} value="incidentresponse" />}
                                        label="Incident Response"
                                    />
                                    <FormControlLabel
                                        control={
                                        <Checkbox checked={kali} onChange={this.handleChangeCheckBox} value="kali" />}
                                        label="Kali Linux"
                                    />
                                    <FormControlLabel
                                        control={
                                        <Checkbox checked={pentest} onChange={this.handleChangeCheckBox} value="pentest" />}
                                        label="Pentesting Env"
                                    />
                                </FormGroup>
                            </ExpansionPanelDetails>
                            </ExpansionPanel>
                            <ExpansionPanel expanded={expanded === 'applicationPanel'} onChange={() => this.handlePanel('applicationPanel')} elevation={0}>
                            <ExpansionPanelSummary
                                expandIcon={<ExpandMore />}
                                aria-controls="panel3bh-content"
                                id="panelapplication-header" >
                                <Typography className={classes.heading}>Application Servers: </Typography>
                                <Typography className={classes.secondaryHeading}>
                                    Pre-built application servers
                                </Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>                        
                                <FormGroup row>
                                    <FormControlLabel
                                        control={<Checkbox checked={apache} onChange={this.handleChangeCheckBox} value="apache" />}
                                        label="Apache"
                                    />
                                    <FormControlLabel
                                        control={<Checkbox checked={nginx} onChange={this.handleChangeCheckBox} value="nginx" />}
                                        label="Nginx"
                                    />
                                    <FormControlLabel
                                        control={
                                        <Checkbox checked={mySQL} onChange={this.handleChangeCheckBox} value="mySQL" />}
                                        label="MySQL"
                                    />
                                    <FormControlLabel
                                        control={
                                        <Checkbox checked={elasticsearch} onChange={this.handleChangeCheckBox} value="elasticsearch" />}
                                        label="Elasticsearch"
                                    />
                                    <FormControlLabel
                                        control={
                                        <Checkbox checked={wordpress} onChange={this.handleChangeCheckBox} value="wordpress" />}
                                        label="Wordpress"
                                    />
                                    <FormControlLabel
                                        control={
                                        <Checkbox checked={docker} onChange={this.handleChangeCheckBox} value="docker" />}
                                        label="Docker"
                                    />
                                    <FormControlLabel
                                        control={
                                        <Checkbox checked={jenkins} onChange={this.handleChangeCheckBox} value="jenkins" />}
                                        label="Jenkins"
                                    />
                                     <FormControlLabel
                                        control={
                                        <Checkbox checked={IIS} onChange={this.handleChangeCheckBox} value="IIS" />}
                                        label="IIS"
                                    />
                                    <FormControlLabel
                                        control={
                                        <Checkbox checked={AWX} onChange={this.handleChangeCheckBox} value="AWX" />}
                                        label="AWX"
                                    />
                                </FormGroup>
                            </ExpansionPanelDetails>
                            </ExpansionPanel>
                            <Divider/>
                        </FormControl>
                        <FormControl className={classes.formControl} required>
                            <Typography color="textSecondary">File Content</Typography>
                            <Input id="input-filecontent" value={fileContent} onChange={this.handleFileContent} multiline
                                rows="20" />
                            <FormHelperText error={false}>Paste your commands here</FormHelperText>
                        </FormControl> 
                        <div className={classes.wrapper}>
                            <Button
                                variant="outlined"
                                color="primary"
                                className={classes.button}
                                disabled={showLoading}
                                onClick={this.createBlueprint}>
                            {isEdit ? "Update" : "Create"} Blueprint
                            </Button>
                            { showLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
                        </div>   
                    </CardContent>
                </Card>
            </div>
        )
    }
}

BlueprintsForm.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(useStyles)(BlueprintsForm);
