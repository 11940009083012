export default {
    query: `
        query GetGroups {
            GetGroups {
                groupName
                key
                users
                active
            }
        }
    `,
};
