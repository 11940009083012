export default { mutation: `mutation EditTarget($key: String, $targetInput: TargetInput ) 
    {
    EditTarget(key: $key, 
        target: $targetInput
    ) 
    {
        targetName
        provider
        credential
        instanceType
        region
        key
        tags {
			name
			value
        }
        vpc
        securityGroups {
            name
            id
        } 
        resourceGroup
        networkInterface
        subnetId
        active
    }
    }`
};