import ReactGA from 'react-ga';
import React, { Component } from "react";
import { Auth } from "aws-amplify";
import { withRouter } from "react-router-dom";
import Routes from "./Routes";
import "./content/App.css";
import './content/alert.css';
import Loading from './pages/Loading';
import SiteWrapper from "./components/SiteWrapper/SiteWrapper";
import theme from './content/formTheme';
import { MuiThemeProvider } from '@material-ui/core/styles';
import GetUserSettings from './graphql/queries/GetUserSettings';
import { API, graphqlOperation } from "aws-amplify";
//import mixpanel from 'mixpanel-browser';
//import { MixpanelProvider, MixpanelConsumer } from 'react-mixpanel';
class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAuthenticated: false,
      isAuthenticating: true,
      cognitoData: ""
    };
  }
  async componentDidMount() {      
    ReactGA.initialize('UA-141370158-2');
    try {
      await Auth.currentSession().then(result => {
        this.userHasAuthenticated(true);
      })
    }
    catch(e) {
      if (e !== 'No current user') {
        alert(e);
      }
    }
    this.setState({ isAuthenticating: false });
  }
   userHasAuthenticated = async (authenticated) => {
    await Auth.currentSession().then(async result => {
      await API.graphql(graphqlOperation(GetUserSettings.query))
      .then(settings => {
        this.setState({userSettings: settings.data.GetUserSettings, isAuthenticated: authenticated, user: result.idToken.payload }) 
      })
    })
  }
  render() {
    var { isAuthenticated } = this.state;
      if (this.state.isAuthenticating) {
          return ( <Loading/> );
      }
    var mixpanel = require('mixpanel-browser');
    mixpanel.init("860820c0c2d1c51884c6e15d6b6f86f4");
    const childProps = {
      isAuthenticated: this.state.isAuthenticated,
      userHasAuthenticated: this.userHasAuthenticated,
      user: this.state.user,
      userSettings: this.state.userSettings,
      handleLogout: this.props.handleLogout
    };
    return (
      <div>
      {
        isAuthenticated &&
          <SiteWrapper {...this.props}>
            <MuiThemeProvider theme={theme}>
              <Routes childProps={childProps}></Routes>
            </MuiThemeProvider>
          </SiteWrapper>
      }
      {
        !isAuthenticated &&
        <Routes childProps={childProps}></Routes>
      }
      </div>
    );
  }
}
export default withRouter(App);
