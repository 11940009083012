export default async function CheckSovereign(region, sovereignRegions) {
  const allFalse = Object.keys(sovereignRegions).every((k) => !sovereignRegions[k])
  if(allFalse === true) {
    return true
  }
  else {
    return Object.keys(sovereignRegions).filter(item => sovereignRegions[item] === true)
    .map(sovereignRegion => {
      return sovereignityRegionsList[sovereignRegion].includes(region)
    })
    .includes(true)
  }
}

const sovereignityRegionsList = {
  sovereignAU: [
    "ap-southeast-2"
  ], 
  sovereignUSA: [
    "us-east-1",
    "us-east-2",
    "us-west-1",
    "us-west-2"
  ], 
  sovereignCanada: [
    "ca-central-1"
  ], 
  awsGovcloud: [
    "us-gov-west-1"
  ], 
  auCloud: []
}