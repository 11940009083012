import React from 'react';
import {Input, InputLabel, MenuItem, FormControl, Select, CardHeader, IconButton, Card, Typography, CardContent,
    FormHelperText, CircularProgress, Button , Snackbar, FormControlLabel, Checkbox } from '@material-ui/core';
import PropTypes from 'prop-types';
import useStyles from './Style'
import { withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import { API, graphqlOperation  } from "aws-amplify";
import GetProfiles from '../../graphql/queries/GetProfiles';
import GetTargets from '../../graphql/queries/GetTargets';
import GetSSHKeys from '../../graphql/queries/GetSSHKeys';
import LaunchEc2 from '../../graphql/mutations/LaunchEc2';
import LaunchVM from '../../graphql/mutations/LaunchVM';
import GetCustomAMIs from '../../graphql/queries/GetCustomAMIs';
import base_amis from '../../content/baseAMIs';

var mixpanel = require('mixpanel-browser');

class Launch extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showLoading: false,
            loadingSSH: false,
            serviceImage: true,
            keys: [],
            profiles: [],
            targets: [],
            amis: [],
            loadingAMIs: false,
            loadingProfiles: true,
            sshKey: '',
            hostName: '',
            serverImage: 'securestack_base_centos7',
            enablePublicIP: false,
            disablePublicIP: true,
            baseAMIs: base_amis,
            provider: ''
        };
    } 

    async componentDidMount() {
        //Get Profiles
        await API.graphql(graphqlOperation(GetProfiles.query)).then(result => {
            this.setState({profiles: result.data.GetProfiles, loadingProfiles: false}) 
        })
        .catch(err => {
            this.setState({ showAlert: true, alertMessage: 'Error fetching Profiles - please check your network connection and try again', alertSuccess: false, loadingTargets: false})
        });
        //Get Targets
        await API.graphql(graphqlOperation(GetTargets.query)).then(result => {
            this.setState({targets: result.data.GetTargets, loadingProfiles: false}) 
        })
        .catch(err => {
            this.setState({ showAlert: true, alertMessage: 'Error fetching Targets - please check your network connection and try again', alertSuccess: false, loadingTargets: false})
        });
    }

    setSSHKeys = (target) => {
        this.setState({loadingSSH: true})
        let params = {
            accountName: target.credential,
        }
        API.graphql(graphqlOperation(GetSSHKeys.query, params)).then(result => {
            if(result.data.GetSSHKeys) {
                var keys = JSON.parse(result.data.GetSSHKeys.payload).filter(k => k.region === target.region)
                this.setState({keys: keys, loadingSSH: false})
            }
        })
    }

    getCustomAMIs = (target) => {
        this.setState({loadingAMIs: true})
        let params = {
            accountName: target.credential,
            region: target.region
        }
        API.graphql(graphqlOperation(GetCustomAMIs.query, params))
        .then(result => {
            this.setState({amis: result.data.GetCustomAMIs, loadingAMIs: false})
        })
        .catch(err => {
            this.setState({ showAlert: true, alertMessage: err.errors[0].message, alertSuccess: false, loadingAMIs: false})
        })
    }

    handleHostName = (e) => { this.setState({hostName: e.target.value}) } 

    handleAlertClose = () => { this.setState({showAlert: false, alertMessage: '', instance: {}}) }

    handleImage = (e) => {  this.setState({serverImage: e.target.value}) }
    
    handleProfile = (e) => { 
        const profile = this.state.profiles[e.target.value]
        this.setState({profile: e.target.value, selectedProfile: profile }) 
        //Set the public IP checkbox
        profile.enablePublicIP === true ? this.setState({enablePublicIP: true, disablePublicIP: false}) : this.setState({enablePublicIP: false, disablePublicIP: true})
    }

    handleTarget = (e) => {
        var target = this.state.targets[e.target.value]
        //check provider
        if(target.provider === 'aws') {
            this.setState({target: e.target.value, sshKey: '', keys: [], selectedTarget: target, provider: target.provider}, () => { 
                this.setSSHKeys(target)
                this.getCustomAMIs(target)
            })
        }
        else {
            this.setState({target: e.target.value, sshKey: '', keys: [], selectedTarget: target, provider: target.provider})
        }
    }

    handleChangeCheckBox = (e) => { this.setState({enablePublicIP: !this.state.enablePublicIP}) }

    handleServiceImageCheckBox = (e) => { this.setState({serviceImage: !this.state.serviceImage}) }

    handleSSHKey = (e) => { this.setState({sshKey: e.target.value}) }

    submitFormAzure = async () => {
        this.setState({showLoading: true})  
        var launchVMInput = {
            hostName: this.state.hostName,
            serverImage: this.state.serverImage,
            target: JSON.stringify(this.state.selectedTarget),
            profile: JSON.stringify(this.state.selectedProfile),
        }
        await API.graphql(graphqlOperation(LaunchVM.mutation, {launchVMInput: launchVMInput}))
        .then(result => {
            this.setState({ showAlert: true, instance: result.data.LaunchEc2, alertMessage: 'New Instance Successfully Created:', 
                alertSuccess: true, hostName: '', serverImage: 'securestack_base_centos7', sshKey: '', selectedProduct: '', 
                product: '', showLoading: false, enablePublicIP: false, disablePublicIP: true})             
        })
        .catch(err => {
            console.log(err)
            this.setState({ showAlert: true, alertMessage: err.errors[0].message, 
                alertSuccess: false, hostName: '', serverImage: null, sshKey: '', selectedProduct: '', product: '', showLoading: false,  enablePublicIP: false, disablePublicIP: true})
        })
    }

    submitFormAWS = async () => {
        this.setState({showLoading: true})  
        if(this.state.hostName && this.state.sshKey && this.state.selectedProfile && this.state.selectedTarget) {
            var launchEc2Input = {
                hostName: this.state.hostName,
                serverImage: this.state.serverImage,
                target: JSON.stringify(this.state.selectedTarget),
                profile: JSON.stringify(this.state.selectedProfile),
                sshKey: this.state.sshKey,
                enablePublicIP: this.state.enablePublicIP,
                os:  this.state.baseAMIs.find(i => i.imageAMI === this.state.serverImage) ? this.state.baseAMIs.find(i => i.imageAMI === this.state.serverImage).OS : this.state.amis.find(i => i.imageAMI === this.state.serverImage) ? this.state.amis.find(i => i.imageAMI === this.state.serverImage).os : 'centOS',
                customAMI: this.state.baseAMIs.find(i => i.imageAMI === this.state.serverImage) || this.state.serverImage === 'securestack_siipserver'  ? false : true
            }
            await API.graphql(graphqlOperation(LaunchEc2.mutation, launchEc2Input))
            .then(result => {
                mixpanel.track("Launch Instance Success");
                this.setState({ showAlert: true, instance: result.data.LaunchEc2, alertMessage: 'New Instance Successfully Created:', 
                    alertSuccess: true, hostName: '', serverImage: 'securestack_base_centos7', sshKey: '', selectedProduct: '', 
                    product: '', showLoading: false, enablePublicIP: false, disablePublicIP: true})             
            })
            .catch(err => {
                mixpanel.track("Launch Instance Failed");
                this.setState({ showAlert: true, alertMessage: err.errors[0].message, 
                    alertSuccess: false, hostName: '', serverImage: null, sshKey: '', selectedProduct: '', product: '', showLoading: false,  enablePublicIP: false, disablePublicIP: true})
            })
        }
        else {
            this.setState({ showAlert: true, alertMessage: 'Error Missing Parameters', alertSuccess: false, showLoading: false})
        }
    }
   
    render() {
        const { classes } = this.props;
        const { serverImage, showLoading, loadingSSH, instance, hostName, hostNameError, sshKey, keys, hostNameErrMsg, enablePublicIP, serviceImage, baseAMIs,
            showAlert, alertSuccess, alertMessage, disablePublicIP, profiles, loadingProfiles, profile, provider, targets, loadingTargets, target, amis, loadingAMIs } = this.state;
    return ( <div>
        <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={showAlert}
            onClose={this.handleAlertClose}
            ContentProps={{
                'aria-describedby': 'message-id',
                classes: {
                    root: alertSuccess ? classes.notificationSuccess : classes.notificationError
                }
            }}
            message={
                alertSuccess ?
                    <span id="message-id">
                        <b>{alertMessage}</b>
                        <br/><br/>
                        <p>Instance Id: {instance.instanceId}</p>
                        <p>Instance Type: {instance.instanceType}</p>
                        <p>Key Name: {instance.keyName}</p>
                        <p>Private Dns Name: {instance.privateDnsName}</p>
                        <p>Private Ip Address: {instance.privateIpAddress}</p>
                        <p>Subnet Id: {instance.subnetId}</p>
                        <p>VPC Id: {instance.vpcId}</p>
                    </span>
                : <span id="message-id"><b>{alertMessage}</b></span>}
            action={[
                <IconButton
                    key="close"
                    aria-label="Close"
                    color="inherit"
                    className={classes.close}
                    onClick={this.handleAlertClose}>
                    <CloseIcon />
                </IconButton>,
            ]}
        />
        <Card>
            <CardHeader
                title={serviceImage ? 'Deploy CloudBuilder Service: ' : 'Deploy CloudBuilder Image: '}
                subheader={serviceImage ? 'Deploy an SecureStack CloudBuilder service into your cloud' : 'Deploy an SecureStack CloudBuilder image into your cloud'} 
                action={
                    <FormControlLabel
                        control={
                        <Checkbox
                            checked={serviceImage}
                            onChange={this.handleServiceImageCheckBox}
                            value={serviceImage}
                            color="primary"
                        />
                        }
                        label="SecureStack Service Image"
                    />
                  }
                />
            <CardContent>
                <FormControl className={classes.formControl} required error={hostNameError}>
                    <Typography color="textSecondary" className={classes.typo}>Host Name</Typography>
                    <Input id="input-hostName" value={hostName} onChange={this.handleHostName} />
                    <FormHelperText error={hostNameError}>{hostNameErrMsg}</FormHelperText>
                </FormControl>    
                <FormControl className={classes.formControl}>
                    <InputLabel>Select Profile</InputLabel>
                    <Select
                        disabled={loadingProfiles}
                        value={profile}
                        onChange={this.handleProfile} >
                        {
                            profiles.map((item, index) =>{
                                return <MenuItem key={item.key} value={index}>{item.profileName}</MenuItem>
                            })
                        }
                    </Select>
                    {loadingProfiles && <CircularProgress size={24} className={classes.buttonProgress} />}
                </FormControl> 
                <FormControl className={classes.formControl}>
                    <InputLabel>Select Target</InputLabel>
                    <Select
                        disabled={loadingTargets}
                        value={target}
                        onChange={this.handleTarget} >
                        {
                            targets.map((item, index) =>{
                                return <MenuItem key={item.key} value={index}>{item.targetName}</MenuItem>
                            })
                        }
                    </Select>
                    {
                        serviceImage && 
                            <FormHelperText error={false}>Target must be an m4.large or larger</FormHelperText>
                    }
                    {loadingTargets && <CircularProgress size={24} className={classes.buttonProgress} />}
                </FormControl> 
                <FormControl className={classes.formControl}>
                    <InputLabel>Select Server Image</InputLabel>
                        {
                            serviceImage ? 
                                <Select
                                    value={serverImage}
                                    onChange={this.handleImage} >
                                    <MenuItem key='1' value='securestack_siipserver'>SecureCloud SIIPServer</MenuItem>
                                    <MenuItem key='2' value='securestack_orcserver' disabled>SecureCloud Orcestration Engine</MenuItem>
                                </Select>
                            : 
                                <Select
                                    value={serverImage}
                                    onChange={this.handleImage} >
                                    {
                                        baseAMIs.map((item, index) =>{
                                            return <MenuItem key={item.imageAMI} value={item.imageAMI}>{item.imageName}</MenuItem>
                                        })
                                    }
                                    {
                                        amis.map((item, index) =>{
                                            return <MenuItem key={item.imageAMI} value={item.imageAMI}>{item.imageName}</MenuItem>
                                        })
                                    }
                                </Select>
                        }
                        { loadingAMIs && !serviceImage && <CircularProgress size={24} className={classes.buttonProgress} /> }
                </FormControl>
                {
                    provider !== 'azure' &&
                    <FormControl className={classes.formControl}>
                        <InputLabel>Select SSH Key</InputLabel>
                        <Select
                            disabled={loadingSSH}
                            value={sshKey}
                            onChange={this.handleSSHKey} >
                            {
                                keys.map((item, index) =>{
                                    return <MenuItem key={index} value={item.name}>{item.name}</MenuItem>
                                })
                            }
                        </Select>
                        {loadingSSH && <CircularProgress size={24} className={classes.buttonProgress} />}
                    </FormControl> 
                }
                { !disablePublicIP &&
                    <FormControl className={classes.formControl}>
                        <FormControlLabel
                            control={
                                <Checkbox 
                                    checked={enablePublicIP} 
                                    onChange={this.handleChangeCheckBox} 
                                    value={enablePublicIP}  />
                                }
                                    label="Enable Public IP"
                            />  
                    </FormControl>
                }
                <div className={classes.wrapper}>
                    <Button
                        variant="outlined"
                        color="primary"
                        className={classes.button}
                        disabled={showLoading}
                        onClick={ provider === 'aws' ? this.submitFormAWS : this.submitFormAzure } >
                        Deploy SecureStack Service
                    </Button>
                    {showLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
                </div>
            </CardContent> 
        </Card>
    </div>)
    }
}

Launch.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(useStyles)(Launch);
