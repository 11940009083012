import React, {Component} from 'react';
import style from './Style'
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, List, ListItem, ListItemText, ListItemAvatar, 
    Avatar, Typography, ListItemSecondaryAction, IconButton, CircularProgress, ExpansionPanelActions, Button, Divider,
    FormControl, Input, MenuItem, Select, InputLabel } from '@material-ui/core';
import { ExpandMore, Delete } from '@material-ui/icons';
import ReviewTarget from './ReviewTarget';
import ReviewProfile from './ReviewProfile';
import update from 'immutability-helper';
import { API, graphqlOperation } from "aws-amplify";
import GetCustomAMIs from '../../graphql/queries/GetCustomAMIs';
import base_amis from '../../content/baseAMIs';

class ReviewProduct extends Component {
    constructor(props) {
        super(props);
        this.state = {
            expanded: '',
            product: this.props.product,
            baseAMIs: base_amis,
            amis: [],
            loadingAMIs: true
        };
    } 
    
    handlePanel = (panel) => {
        if(panel === this.state.expanded) {
            this.setState({expanded: ''})
        }
        else {
            this.setState({expanded: panel})
        }
    }

    componentDidMount = () => { 
        this.setState({costCentre: this.state.product.costCentre === 'none' ? '' : this.state.product.costCentre }) 
        this.getCustomAMIs(this.state.product.target)
    }

    getCustomAMIs = (target) => {
        let params = {
            accountName: target.credential,
            region: target.region
        }
        API.graphql(graphqlOperation(GetCustomAMIs.query, params))
        .then(result => {
            this.setState({amis: result.data.GetCustomAMIs, loadingAMIs: false})
        })
        .catch(err => {
            this.setState({ showAlert: true, alertMessage: err.errors[0].message, alertSuccess: false, loadingAMIs: false})
        })
    }

    setProduct = (action) => {
        var product = {
            productName: this.state.product.productName,
            target: this.state.product.target.key,
            profile: this.state.product.profile.key,
            image: this.state.product.image,
            blueprints: this.state.product.blueprints.map( (item) => { return item.key } ),
            costCentre: action === 'deleteCostCentre' ? null : action === 'updateCostCentre' ? this.state.costCentre : this.state.product.costCentre === 'none' ? null : this.state.product.costCentre,
            users : JSON.stringify(this.state.product.users.reduce((res, obj) => { 
                var key = obj.username; 
                res[key] = obj.key;
                return res;
            }, {})),
            groups: this.state.product.groups.map( (item) => { return item.key } )
        };
        this.props.updateProduct(product)
    }

    handleDeleteUser = (index) => {
        this.setState({showLoading: true});
        let newState = update(this.state, {
            product: {
                users: { $splice: [[index, 1]] }
            }
        })
        this.setState(newState, async () => {
            this.setProduct('deleteUser')
        });
    }

    handleDeleteBlueprint = (index) => {
        this.setState({showLoading: true});
        let newState = update(this.state, {
            product: {
                blueprints: { $splice: [[index, 1]] }
            }
        })
        this.setState(newState, async () => {
            this.setProduct('deleteBlueprint')
        });
    }

    handleCostCentre = (e) => { this.setState({costCentre: e.target.value}) }

    removeCostCentre = () => { this.setProduct('deleteCostCenter') }

    updateCostCentre = () => { this.setProduct('updateCostCentre')}

    handleImage = (e) => { this.setState({selectedImage: e.target.value}) }

    handleUpdateImage = () => {
        this.setState({showLoading: true});
        let newState = update(this.state, {
            product: {
                image: { $set: this.state.selectedImage }
            }
        })
        this.setState(newState, () => {
            this.setProduct('updateImage')
        });
    }

    handleDeleteGroup = (index) => {
        this.setState({showLoading: true});
        let newState = update(this.state, {
            product: {
                groups: { $splice: [[index, 1]] }
            }
        })
        this.setState(newState, () => {
            this.setProduct('deleteGroup')
        });
    }

    render() {
        const { expanded, product, showLoading, costCentre, costCentreError, loadingAMIs, amis, baseAMIs, selectedImage } = this.state;
        const {classes, elevation, isEdit } = this.props;
        const availOS = product.blueprints.map((blueprint => {
            var operatingSystems = JSON.parse(blueprint.operatingSystems)
            return Object.keys(operatingSystems).filter(item => operatingSystems[item] === true).map((value, index) => {
                return value
            })
        }))

        return (
            <div style={{marginTop: 5, marginBottom: 5}}>
                { product && 
                <div>
                <ExpansionPanel expanded={expanded === 'targetPanel'} onChange={() => this.handlePanel('targetPanel')} elevation={elevation}>
                    <ExpansionPanelSummary
                        expandIcon={<ExpandMore />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header" >
                        <Typography className={classes.heading}>Target: </Typography>
                        <Typography className={classes.secondaryHeading}>{product.target.targetName}</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <ReviewTarget selectedTarget={product.target} dense={true} isEdit={isEdit}/>   
                    </ExpansionPanelDetails>
                    <ExpansionPanelActions>
                        { isEdit &&
                            <Button size="small" color="primary" onClick={this.props.handleChangeTargetOpen}>
                                Change Target
                            </Button>
                        }
                    </ExpansionPanelActions>
                </ExpansionPanel>
                <ExpansionPanel expanded={expanded === 'profilePanel'} onChange={() => this.handlePanel('profilePanel')} elevation={elevation}>
                    <ExpansionPanelSummary
                        expandIcon={<ExpandMore />}
                        aria-controls="panel2bh-content"
                        id="panel2bh-header">
                        <Typography className={classes.heading}>Profile: </Typography>
                        <Typography className={classes.secondaryHeading}>{product.profile.profileName}</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <ReviewProfile selectedProfile={product.profile} classes={classes} dense={true}/>
                    </ExpansionPanelDetails>
                    <ExpansionPanelActions>
                        { isEdit &&
                            <Button size="small" color="primary" onClick={this.props.handleChangeProfileOpen}>
                                Change Profile
                            </Button>
                        }
                    </ExpansionPanelActions>
                </ExpansionPanel>

                <ExpansionPanel expanded={expanded === 'imagePanel'} onChange={() => isEdit ? this.handlePanel('imagePanel') : null} elevation={elevation}>
                    <ExpansionPanelSummary
                        expandIcon={ isEdit ? <ExpandMore /> : null}
                        aria-controls="panel2bh-content"
                        id="panel2bh-imagePanel">
                        <Typography className={classes.heading}>Image: </Typography>
                        <Typography className={classes.secondaryHeading}>{ product.image}</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                            <FormControl className={classes.formControl}>
                                <InputLabel>Select New Server Image</InputLabel>
                                <Select
                                    disabled={loadingAMIs}
                                    value={selectedImage}
                                    onChange={this.handleImage}>
                                    {
                                        baseAMIs.filter(item => availOS.flat(1).find(i => i === item.OS)).map((item, index) => {
                                            return <MenuItem key={item.imageAMI} value={item.imageAMI}>{item.imageName}</MenuItem>
                                        })
                                    }
                                    {
                                        amis.filter(item => availOS.flat(1).find(i => i === item.os)).map((item, index) => {
                                            return <MenuItem key={item.imageAMI} value={item.imageAMI}>{item.imageName}</MenuItem>
                                        })
                                    }
                                </Select>
                                {loadingAMIs && <CircularProgress size={24} className={classes.buttonProgress} />}
                            </FormControl> 
                    </ExpansionPanelDetails>
                    { isEdit &&
                        <ExpansionPanelActions>
                            <Button size="small" color="primary" onClick={this.handleUpdateImage} disabled={loadingAMIs}>
                                Update Image
                            </Button>
                        </ExpansionPanelActions>
                    }
                </ExpansionPanel>
                <ExpansionPanel elevation={elevation}>
                    <ExpansionPanelSummary
                        expandIcon={<ExpandMore />}
                        aria-controls="panel2bh-content"
                        id="panel2bh-blueprint">
                        <Typography className={classes.heading}>Blueprints: </Typography>
                        <Typography className={classes.secondaryHeading}>List of blueprints attached to this product</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                    <List dense className={classes.list}>
                        { 
                            product.blueprints &&
                            product.blueprints.map( (value, index) => (
                                <ListItem key={index}>
                                        <ListItemText
                                            primary={value.filename}
                                        /> 
                                        { isEdit &&
                                            <ListItemSecondaryAction>
                                                <IconButton disabled={showLoading} edge="end" aria-label="delete" onClick={() => this.handleDeleteBlueprint(index)}>
                                                    <Delete />
                                                </IconButton>
                                                {showLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
                                            </ListItemSecondaryAction> 
                                        }     
                                </ListItem>
                            ))
                        }
                    </List>
                    </ExpansionPanelDetails>
                    <Divider />
                    { isEdit &&
                        <ExpansionPanelActions>
                            <Button size="small" color="primary" onClick={() => this.props.handleAddBlueprintsOpen('editBlueprints')}>
                                Add Blueprints
                            </Button>
                            <Button size="small" color="primary" onClick={() => this.props.handleAddBlueprintsOpen('orderBlueprints')}>
                                Re-Order Blueprints
                            </Button>
                        </ExpansionPanelActions>
                    }
                </ExpansionPanel>
                <ExpansionPanel expanded={expanded === 'costPanel'} onChange={() => isEdit ? this.handlePanel('costPanel') : null } elevation={elevation}>
                    <ExpansionPanelSummary
                        expandIcon={ isEdit ? <ExpandMore /> : null}
                        aria-controls="panel2bh-content"
                        id="panel2bh-costcentre">
                        <Typography className={classes.heading}>Cost Centre: </Typography>
                        <Typography className={classes.secondaryHeading}>{ product.costCentre === 'none' ? '' : product.costCentre }</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <FormControl required error={costCentreError}>
                            <Input style={{width: 500}} id="input-costCentre" value={costCentre} onChange={this.handleCostCentre} disabled={!isEdit} />
                        </FormControl> 
                    </ExpansionPanelDetails>
                    { isEdit &&
                        <ExpansionPanelActions>
                            {
                                product.costCentre !== 'none' &&
                                    <Button size="small" color="error" onClick={this.removeCostCentre}>
                                        Remove Cost Centre
                                    </Button>
                            }
                            <Button size="small" color="primary" onClick={this.updateCostCentre}>
                                Update Cost Centre
                            </Button>
                        </ExpansionPanelActions>
                    }
                </ExpansionPanel>
                <ExpansionPanel expanded={expanded === 'userPanel'} onChange={() => this.handlePanel('userPanel')} elevation={elevation}>
                    <ExpansionPanelSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="panel3bh-content"
                    id="panel3bh-header"
                    >
                    <Typography className={classes.heading}>Users: </Typography>
                    <Typography className={classes.secondaryHeading}>
                        Individual Users who have access to this product
                    </Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <List dense className={classes.list}>
                            { product.users.map( (value, index) => (
                                <ListItem key={index}>
                                        <ListItemAvatar>
                                            <Avatar alt={value.firstName} src={value.profilePic} />
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={value.firstName + ' ' + value.lastName}
                                            secondary={value.username.substring(value.username.indexOf('.') + 1)}
                                        /> 
                                        { isEdit &&
                                            <ListItemSecondaryAction>
                                                <IconButton disabled={showLoading} edge="end" aria-label="delete" onClick={() => this.handleDeleteUser(index)}>
                                                    <Delete />
                                                </IconButton>
                                                {showLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
                                            </ListItemSecondaryAction> 
                                        }     
                                </ListItem>
                            ))}
                        </List>
                    </ExpansionPanelDetails>
                    <Divider />
                    <ExpansionPanelActions>
                        { isEdit &&
                            <Button size="small" color="primary" onClick={this.props.handleAddUsersOpen}>
                                Add Users
                            </Button>
                        }
                    </ExpansionPanelActions>
                </ExpansionPanel>
                <ExpansionPanel expanded={expanded === 'groupsPanel'} onChange={() => this.handlePanel('groupsPanel')} elevation={elevation}>
                    <ExpansionPanelSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="panel3bh-content"
                    id="panel3bh-header"
                    >
                    <Typography className={classes.heading}>Groups: </Typography>
                    <Typography className={classes.secondaryHeading}>
                        Groups who have access to this product
                    </Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <List dense className={classes.list}>
                            { product.groups.map( (value, index) => (
                                <ListItem key={index}>
                                    <ListItemText primary={value.groupName} />
                                    { isEdit &&
                                        <ListItemSecondaryAction>
                                            <IconButton disabled={showLoading} edge="end" aria-label="delete" onClick={() => this.handleDeleteGroup(index)}>
                                                <Delete />
                                            </IconButton>
                                            {showLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
                                        </ListItemSecondaryAction> 
                                    } 
                                </ListItem>
                            ))}
                        </List>
                    </ExpansionPanelDetails>
                    <Divider />
                    <ExpansionPanelActions>
                        { isEdit &&
                            <Button size="small" color="primary" onClick={this.props.handleAddGroupsOpen}>
                                Add Groups
                            </Button>
                        }
                    </ExpansionPanelActions>
                </ExpansionPanel>
                </div>
                }
            </div>
        )
    }
}

ReviewProduct.propTypes = {
    classes: PropTypes.object.isRequired
};
  
export default withStyles(style)(ReviewProduct);