export default { mutation: `mutation DeleteUserFromProduct($username: String, $product: String) {
  DeleteUserFromProduct( username: $username, product: $product ) 
    {
      productName
      key
      target {
        targetName
        provider
        credential
        instanceType
        region
        tags {
          name
          value
        }
        vpc
        securityGroups {
          name
          id
        } 
        subnetId
      }
      profile {
        profileName
        antiVirus
        key
        applicationMonitoring
        intrusionPrevention
        systemMonitoring
        malwareProtection
        mfa
        waf
        apache
        nginx
        mySQL
        elasticsearch
        wordpress
        iamRoles
        securityGroups
        awsTags {
            name
            value
        }
        firewallRules
        automaticScanning
        automaticScanningSchedule
        automaticPatching
        automaticPatchingSchedule
        automaticBackups
        automaticBackupsSchedule
        enablePublicIP
        terminationProtection
        encryptBootdisk
        tags {
            name
            value
        }
        siipServer {
            ipAddress
          authDKey
        }
      }
      users 
      groups
    }
  }`
  };