import React, {useEffect}  from 'react';
import { Card, CardHeader, AppBar, Tab, Tabs, CardContent, Typography, Input, FormHelperText, FormControl, Button, Paper,
CircularProgress  } from '@material-ui/core/';
import { API, graphqlOperation } from "aws-amplify";
import style from './Style'
import Alert from '../../components/Alert';
import EncryptSIIPServer from '../../graphql/mutations/EncryptSIIPServer';
import GetSIIPServer from '../../graphql/queries/GetSIIPServer';
import TabPanel from '../../components/TabPanel';
import { useTheme } from '@material-ui/core/styles';

const validator = {
  ipAddress: /^((25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9]?[0-9])\.){3}(25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9]?[0-9])$/
}

const emptyValue = {
  value: '',
  error: false,
  errorMsg: ''
}

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

export default function Components() {
  const classes = style();
  const theme = useTheme();

  const [alert, updateAlert] = React.useState({
    alertMessage: '',
    alertSuccess: false,
    showAlert: false
  })

  const [showLoading, setShowLoading] = React.useState(true)
  const [tab] = React.useState(0);
  const [ipAddress, setIP] = React.useState(emptyValue)
  const [username, setUsername] = React.useState(emptyValue)
  const [password, setPassword] = React.useState(emptyValue)

  useEffect(() => {
    API.graphql(graphqlOperation(GetSIIPServer.query))
    .then(result => { 
      var siip = result.data.GetSIIPServer
      setIP({ value: siip.ipAddress, error: false, errorMsg: '' })
      setUsername({ value: siip.username, error: false, errorMsg: '' })
      setPassword({ value: '********', error: false, errorMsg: '' })
      setShowLoading(false)
    })
    .catch(err => {
      console.log(err)
      setShowLoading(false)
    })
  }, []);

  const handleAlertClose = () => { updateAlert({ alertMessage: '', alertSuccess: false, showAlert: false }) }

  const handleIp = (e) => {
    if(e.target.value.match(validator.ipAddress)) {
      setIP({value: e.target.value, error: false, errorMsg: ''})
    }
    else
      setIP({value: e.target.value, error: true, errorMsg: 'Invalid IP address'})
  }

  const handleUsername = (e) => { setUsername({ value: e.target.value, errorMsg: '', error: false }) }

  const handlePassword = (e) => { setPassword({ value: e.target.value, errorMsg: '', error: false }) }

  const handleTabChange = () => { }

  var siipServer = {
    ipAddress: ipAddress.value,
    username: username.value,
    password: password.value,
  }

  const handleSetSIIPServer = async () => {
    setShowLoading(true)
    await API.graphql(graphqlOperation(EncryptSIIPServer.mutation, {siipServer: siipServer}))
    .then(async result => {
        setShowLoading(false)
        updateAlert({ alertMessage: `SIIP Server Set`, alertSuccess: true, showAlert: true })
    })
    .catch(err => {
      console.log(err)
      setShowLoading(false)
      updateAlert({ alertMessage: `Error setting SIIP Server`, alertSuccess: false, showAlert: true })
    });
  }

  var formComplete =  
      !ipAddress.error && ipAddress.value !== '' &&
      !username.error && username.value !== '' &&
      !password.error && password.value !== '' && password.value !== '********' 
      
  return (
    <Paper>
      <Alert showAlert={alert.showAlert} alertSuccess={alert.alertSuccess} alertMessage={alert.alertMessage} handleAlertClose={handleAlertClose}/>
      <AppBar position="static" color="default">
        <Tabs
          value={tab}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth">
          <Tab label="SIIP Server" {...a11yProps(0)}/>
          <Tab label="Backup" {...a11yProps(1)} disabled/>
          <Tab label="Vulnerability Assessment" {...a11yProps(2)} disabled />
          <Tab label="Cloud Audit" {...a11yProps(3)} disabled />
        </Tabs>
      </AppBar>
        <TabPanel value={tab} index={0} dir={theme.direction}>
        <Card>
          <CardHeader
            title='Set SIIP Server'
          />
          <CardContent>
          <form>
            <FormControl className={classes.formControl} required error={ipAddress.error}>
              <Typography color="textSecondary" className={classes.typo}>IP Address</Typography>
              <Input id="input-ipAddress" autoComplete="off" value={ipAddress.value} onChange={handleIp} disabled={showLoading} />
              <FormHelperText error={ipAddress.error}>{ipAddress.errorMsg}</FormHelperText>
            </FormControl> 
            <FormControl className={classes.formControl} required error={username.error}>
              <Typography color="textSecondary" className={classes.typo}>Username</Typography>
              <Input id="input-username" autoComplete="off" value={username.value} onChange={handleUsername} disabled={showLoading} />
              <FormHelperText error={username.error}>{username.errorMsg}</FormHelperText>
            </FormControl> 
            <FormControl className={classes.formControl} required error={password.error}>
              <Typography color="textSecondary" className={classes.typo}>Password</Typography>
              <Input type='password' id="input-password" autoComplete="off" value={password.value} onChange={handlePassword} disabled={showLoading} />
              <FormHelperText error={password.error}>{password.errorMsg}</FormHelperText>
            </FormControl> 
            <div className={classes.wrapper}>
              <Button
                  variant="outlined"
                  color="primary"
                  className={classes.button}
                  disabled={showLoading || !formComplete}
                  onClick={handleSetSIIPServer} >
                Set SIIP Server
              </Button>
              {showLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
            </div> 
          </form>
        </CardContent>
      </Card>
        </TabPanel>
    </Paper>
  );
}

