import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {Table, TableBody, TableCell, TableHead, TablePagination, TableRow, TableSortLabel, Toolbar, Typography, FormControl, Button, DialogActions, Avatar,
    Paper, InputBase, IconButton, Tooltip, Dialog, DialogTitle, DialogContent, DialogContentText, Input, FormHelperText, Chip, SvgIcon } from '@material-ui/core/'
import {Edit, Search as SearchIcon, Add as AddIcon, Delete as DeleteIcon } from '@material-ui/icons/';
import style from './Style'
import GetGroups from "../../graphql/queries/GetGroups";
import DeleteGroup from "../../graphql/mutations/DeleteGroup";
import {Link} from 'react-router-dom';
import { API, graphqlOperation } from "aws-amplify";
import Alert from '../Alert';

function desc(a, b, orderBy) {
    if(a[orderBy] && b[orderBy]) {
        if (b[orderBy].toLowerCase() < a[orderBy].toLowerCase()) {
            return -1;
        }
        if (b[orderBy].toLowerCase() > a[orderBy].toLowerCase()) {
            return 1;
        }
        return 0;
    }
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

class Groups extends React.Component {
    state = {
        groups: [],
        users: this.props.users,
        query: "",
        page: 0,
        rowsPerPage: 10,
        dialogOpen: false,
        tabValue: 0,
    };

    componentDidMount = async () => {
        await API.graphql(graphqlOperation(GetGroups.query))
        .then(result => {
            this.setState({groups: result.data.GetGroups})
        })
        .catch(e => console.log(e));
    }

    handleAlertClose = () => {
        this.setState({showAlert: false, alertMessage: '', selectedGroup: {}})
    }

    confirmDeleteGroup = async () => {
        if (this.state.groupName === this.state.selectedGroupName) {
            await API.graphql(graphqlOperation(DeleteGroup.mutation, {key: this.state.selectedGroupKey}))
            .then(result => {
                if(result) {
                    this.setState({
                        groups: result.data.DeleteGroup,
                        selectedGroupKey: '', 
                        selectedGroupName: '', 
                        showAlert: true, 
                        alertMessage: `The Group has been successfully deleted`,
                        alertSuccess: true,
                        dialogOpen: false,
                        groupName: '',
                        groupNameError: false,
                        groupNameMsg: ''
                    })
                }
            })
            .catch(err => {
                this.setState({
                    showAlert: true, 
                    alertMessage: `Something went wrong deleting the group ${this.state.group.groupName}`,
                    alertSuccess: false,
                })
            });
        }
        else {
            this.setState({
                groupNameError: true, 
                groupNameErrMsg: `Your input value did not match the group name: ${this.state.selectedGroupName}`
            })
        }
    }

    handleDeleteDialog = (group) => { 
        //Check if group is in a product
        
        if(!group.active) {
            this.setState({dialogOpen: true, selectedGroupName: group.groupName, selectedGroupKey: group.key}) 
        }
        else {
            this.setState({
                showAlert: true, 
                alertMessage: `Unable to delete the group ${group.groupName} as it is in a product please remove it from any products and retry`,
                alertSuccess: false,
            })
        }
    }

    handleDialogClose = () => { this.setState({dialogOpen: false, selectedGroupName: '', selectedGroupKey: ''}) }

    handleGroupName = (e) => {this.setState({ groupName: e.target.value })}

    handleRequestSort = (event, property) => {
        const orderBy = property;
        let order = 'desc';
        if (this.state.orderBy === property && this.state.order === 'desc') {
            order = 'asc';
        }
        this.setState({ order, orderBy });
    };

    handleChangePage = (event, page) => { this.setState({ page }) };

    handleChangeRowsPerPage = event => { this.setState({ rowsPerPage: event.target.value }) };

    createSortHandler = property => event => { this.handleRequestSort(event, property) };

    showUsers = (key) => { 
        if(this.state.isViewAll === key ) {
            this.setState({isViewAll: null})
        }
        else {
            this.setState({ isViewAll: key});
        }
    }

    isViewAll = id => this.state.isViewAll === id

    query(e) {
        this.setState({
            query: e.target.value,
        });
    }

    render() {
        const { classes } = this.props;
        const { order, orderBy, rowsPerPage, page, groups, numSelected, showAlert, alertMessage, alertSuccess,
            dialogOpen, groupNameErrMsg, groupName, groupNameError, users, selectedGroupName } = this.state;

        const emptyRows = rowsPerPage - Math.min(rowsPerPage, groups.length - page * rowsPerPage);

        const rows = [
            { id: 'groupName', numeric: false, disablePadding: false, label: 'Group Name' },
            { id: 'users', numeric: false, disablePadding: false, label: 'Users' },
            { id: 'actions', numeric: false, disablePadding: false, label: 'Actions' },

        ];

        let filteredSearch;
        filteredSearch = this.state.groups.filter(
            (item) => {return JSON.stringify(item).toLowerCase().indexOf(this.state.query.toLowerCase()) !== -1;}
        );
          
        return (
            <div>
                <Dialog open={dialogOpen} onClose={this.handleDialogClose} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">Delete Group: {selectedGroupName}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            To Delete this group please enter their groupname below
                        </DialogContentText>
                        <FormControl className={classes.formControl} required error={groupNameError}>
                            <Typography>Group Name</Typography>
                            <Input id="input-groupName" value={groupName} onChange={this.handleGroupName} />
                            <FormHelperText error={groupNameError}>{groupNameErrMsg}</FormHelperText>
                        </FormControl> 
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleDialogClose} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={this.confirmDeleteGroup} color="primary">
                            Delete
                        </Button>
                    </DialogActions>
                </Dialog>
                <Alert showAlert={showAlert} alertSuccess={alertSuccess} alertMessage={alertMessage} handleAlertClose={this.handleAlertClose}/>
                    <Paper className={classes.root}>
                        <Toolbar
                            className={classNames(classes.toolbar, {
                                [classes.highlight]: numSelected > 0,
                            })}
                        >
                        <div className={classes.title}>
                            <Typography variant="h6" id="tableTitle">
                                Groups
                            </Typography>
                        </div>
                        <div className={classes.spacer} />
                        <div className={classes.actions}>
                            <div className={classes.headSearch}>
                                <IconButton className={classes.iconButton} aria-label="Search">
                                    <SearchIcon />
                                </IconButton>
                                <InputBase 
                                    className={classes.input} 
                                    onChange={this.query.bind(this)}
                                    placeholder="Search..."
                                />
                                <Tooltip title="Create New Group">
                                        <IconButton aria-label="Create" color='primary'
                                            component={Link} to={{
                                                pathname: "/ManageGroup",
                                                state: {
                                                    isEdit: false,
                                                    users: users
                                                }
                                            }}>
                                            <AddIcon />
                                        </IconButton>
                                </Tooltip>
                            </div>
                        </div>
                        </Toolbar>
                        <div className={classes.tableWrapper}>
                            <Table className={classes.table} aria-labelledby="tableTitle">
                                <TableHead>
                                    <TableRow>
                                    { rows.map((row, index) => 
                                        {
                                            return [
                                                row.id === 'groupName' ? 
                                                    <TableCell
                                                    key={row.id}
                                                    align='left'
                                                    padding='default'
                                                    sortDirection={orderBy === row.id ? order : false} >
                                                    <Tooltip
                                                        title="Sort"
                                                        placement='bottom-start'
                                                        enterDelay={300} >
                                                    <TableSortLabel
                                                        active={orderBy === row.id}
                                                        direction={order}
                                                        onClick={this.createSortHandler(row.id)} >
                                                        {row.label}
                                                    </TableSortLabel>
                                                    </Tooltip>
                                                </TableCell>
                                                :
                                                <TableCell
                                                    key={row.id}
                                                    align='left'
                                                    padding='default' >
                                                    {row.label}
                                                </TableCell> 
                                            ]
                                        }  
                                    )}

                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                { stableSort(filteredSearch, getSorting(order, orderBy))
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((n, i) => {
                                        const isViewAll = this.isViewAll(n.key);
                                        return (
                                            <TableRow
                                                hover
                                                tabIndex={-1}
                                                key={i}>
                                                <TableCell component="th" scope="row" padding='default' className={classes.tableCol}>{n.groupName}</TableCell>
                                                <TableCell align="left"> {
                                                    users.filter((o) => n.users.slice(0, isViewAll ? n.users.length : 3).includes(o.key)).map((value, index) => (
                                                        <Chip key={index} avatar={<Avatar alt={value.firstName} src={value.profilePic} />}
                                                            value={value} variant="outlined" label={value.firstName + ' ' + value.lastName} className={classes.chip} />
                                                    ))                                                    
                                                }
                                                { 
                                                    n.users.length > 3 ? 
                                                        <Tooltip title={isViewAll ? "View Less" : "View All"}>
                                                            <Chip key={'viewAll'} color='primary' className={classes.chip} label=''
                                                                icon={
                                                                    <IconButton onClick={() => this.showUsers(n.key)} style={{marginLeft:15}}>
                                                                        <SvgIcon>
                                                                            {
                                                                                !isViewAll ? 
                                                                                    <path  d="M18,8H8V18H6V8A2,2 0 0,1 8,6H18V8M14,2H4A2,2 0 0,0 2,4V14H4V4H14V2M22,12V20A2,2 0 0,1 20,22H12A2,2 0 0,1 10,20V12A2,2 0 0,1 12,10H20A2,2 0 0,1 22,12M20,15H17V12H15V15H12V17H15V20H17V17H20V15Z" />
                                                                                :
                                                                                    <path d="M14,4H4V14H2V4A2,2 0 0,1 4,2H14V4M18,6H8A2,2 0 0,0 6,8V18H8V8H18V6M22,12V20A2,2 0 0,1 20,22H12A2,2 0 0,1 10,20V12A2,2 0 0,1 12,10H20A2,2 0 0,1 22,12M20,15H12V17H20V15Z" />
                                                                            }
                                                                        </SvgIcon>
                                                                    </IconButton>
                                                                }
                                                            />
                                                        </Tooltip>
                                                    :
                                                    null
                                                }
                                                </TableCell>
                                                <TableCell align="right" className={classes.tableCol}>
                                                    <Tooltip title="Edit Group">
                                                        <IconButton color='primary'
                                                            component={Link} to={{
                                                                pathname: "/ManageGroup",
                                                                state: {
                                                                    isEdit: true,
                                                                    users: users,
                                                                    group: n
                                                                }
                                                            }}>
                                                            <Edit />
                                                        </IconButton>
                                                    </Tooltip>
                                                   <Tooltip title="Delete Group">
                                                        <IconButton onClick={() => this.handleDeleteDialog(n)}>
                                                            <DeleteIcon />
                                                        </IconButton>
                                                   </Tooltip>
                                                </TableCell>
                                            </TableRow>
                                        )
                                    })
                                }
                                { emptyRows > 0 && (
                                    <TableRow style={{ height: 49 * emptyRows }}>
                                    <TableCell colSpan={6} />
                                    </TableRow>
                                )}
                                </TableBody>
                        </Table>
                        </div>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={groups.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            backIconButtonProps={{
                                'aria-label': 'Previous Page',
                            }}
                            nextIconButtonProps={{
                                'aria-label': 'Next Page',
                            }}
                            onChangePage={this.handleChangePage}
                            onChangeRowsPerPage={this.handleChangeRowsPerPage} />
                    </Paper>
            </div>
        );
    }
}

Groups.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(style)(Groups);