import React, { useEffect, useState } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { Backdrop, CircularProgress } from '@material-ui/core'
import TableWrapper from '../../components/TableWrapper';
import Alert from '../../components/Alert';
import TableSearch from '../../components/TableSearch';
import style from './Style'
import GetWebsites from '../../graphql/queries/GetWebsites';
import WebsiteTableRow from '../../components/WebsiteTableRow';
import DeleteWebsite from '../../graphql/mutations/DeleteWebsite';
import ConfirmDeleteDialog from '../../components/ConfirmDeleteDialog';

function Websites(props) {
  const classes = style();
  const [ websites, setWebsites ] = useState([])
  const [ selectedWebsite, setSelectedWebsite ] =  useState({})
  const [ dialogOpen, setDialogVisable] = useState(false);
  const [ deleteLoading, setDeleteLoading] = useState(false);
  const [ loading, setLoading ] = useState(false)
  const [ alert, setAlert ] = useState({open: false, messages: '', success: true})
  const [ query, setQuery ] = useState('');

  useEffect(() => {
    setLoading(true)
    API.graphql(graphqlOperation(GetWebsites.query))
    .then(result => { 
      setWebsites(result.data.GetWebsites)
      setLoading(false)
    })
  }, []);

  const handleSearch = (e) => { setQuery(e.target.value) }

  const handleDelete = (selected) => {
    setSelectedWebsite(selected,
        setDialogVisable(true)
    )
  }

  const  deleteWebsite =  () => {
    setDeleteLoading(true)
    API.graphql(graphqlOperation(DeleteWebsite.mutation, { key: selectedWebsite.key }))
    .then(result => { 
      setDeleteLoading(false)
      setWebsites(result.data.DeleteWebsite)
      setAlert({ message: `The website has been removed`, success: true, open: true })
      setSelectedWebsite({})
      setDialogVisable(false)  
    })
    .catch(err => {
      setDeleteLoading(false)
      setAlert({ message: `Error removing the website`, success: false, open: true })
      console.log(err)
    })
  }

  let filteredSearch;
  filteredSearch = websites.filter(
    (item) => {return JSON.stringify(item).toLowerCase().indexOf(query.toLowerCase()) !== -1;}
  );

  return (
      <React.Fragment>
        <Backdrop className={classes.backdrop} open={loading}>
            <CircularProgress color="inherit" />
        </Backdrop>
        <ConfirmDeleteDialog name={selectedWebsite.name} 
            type='Website' 
            open={dialogOpen} 
            handleDialogClose={() => setDialogVisable(false)} 
            loading={deleteLoading}
            confirmDelete={deleteWebsite} />
        <Alert alert={alert} handleClose={() =>setAlert({open: false, message: '', success: true})}/>
        <TableSearch 
            tooltip="Add New Website"
            pathname="/NewWebsite"
            handleSearch={handleSearch}
        />
        <TableWrapper 
            columns={columns}
            tableBody={
            filteredSearch.map(website => {   
                return (
                    <WebsiteTableRow 
                        website={website}
                        handleDelete={handleDelete}
                    />
                )
            })
            }
        />
    </React.Fragment>
  );
}

export default Websites;

const columns = [ 
    { id: 'name', label: 'name' },
    { id: 'domain', label: 'Domain' },
    { id: 'vulnerability', label: 'Vulnerability Score' },
    { id: 'actions', label: 'Actions' },
]