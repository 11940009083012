export default { mutation: `
mutation CreateUserAccount(
	  $username: String
    $email: String
    $temporaryPassword: String,
    $firstName: String
	  $lastName: String
	  $company: String
    $phoneNumber: String
    $isAdmin: Boolean ) {
    CreateUserAccount(userProfile: {
      username: $username
      email: $email
      temporaryPassword: $temporaryPassword
      firstName: $firstName
      lastName: $lastName
      company: $company
      phoneNumber: $phoneNumber
      isAdmin: $isAdmin
  }) {
    result
    payload
  }
}`
};