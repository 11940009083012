import React, { useEffect, useState } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { Backdrop, CircularProgress } from '@material-ui/core'
import TableWrapper from '../../components/TableWrapper';
import Alert from '../../components/Alert';
import TableSearch from '../../components/TableSearch';
import GetBatchInventory from '../../graphql/queries/GetBatchInventory';
import InventoryTableRow from '../../components/InventoryTableRow';
import style from './Style'

function Instances(props) {
  const classes = style();
  const [ instances, setInstances ] = useState([])
  const [ loading, setLoading ] = useState(false)
  const [ alert, setAlert ] = React.useState({open: false, messages: '', success: true})
  const [ query, setQuery ] = React.useState('');

  const handleSearch = (e) => { setQuery(e.target.value) }

  useEffect(() => {
    setLoading(true)
    API.graphql(graphqlOperation(GetBatchInventory.query))
    .then(async result => {
      if(result.data.GetBatchInventory) {
        setInstances(result.data.GetBatchInventory)
        setLoading(false)
      }
      else {
        setAlert({open: true, messages: `Something went wrong fetching your inventory`, success: false})
      }
    })
    .catch(err => {
      setAlert({open: true, messages: `Something went wrong fetching your inventory`, success: false})
      console.log(err)
    });
  }, []);

  let filteredSearch;
  filteredSearch = instances.filter(
    (item) => {return JSON.stringify(item).toLowerCase().indexOf(query.toLowerCase()) !== -1;}
  );

  return (
      <React.Fragment>
        <Backdrop className={classes.backdrop} open={loading}>
          <CircularProgress color="primary" />
        </Backdrop>
        <Alert alert={alert} handleClose={() =>setAlert({open: false, message: '', success: true})}/>
        <TableSearch 
            tooltip="Create New Instance"
            pathname="/Deploy"
            handleSearch={handleSearch}
        />
        <TableWrapper 
            columns={columns}
            tableBody={
              filteredSearch.map(instance => {
                return (
                  <InventoryTableRow server={instance} page='assets' />
                )
              })
            }
        />
    </React.Fragment>
  );
}

export default Instances;

const columns = [ 
  { id: 'instanceType', numeric: false, disablePadding: true, label: '' },
  { id: 'serverName', numeric: false, disablePadding: true, label: 'Server' },
  { id: 'cis', numeric: true, disablePadding: false, label: 'CIS Score' },
  { id: 'vuln', numeric: true, disablePadding: false, label: 'Vulnerability Score' },
  { id: 'location', numeric: false, disablePadding: false, label: 'Location' },
  { id: 'status', numeric: false, disablePadding: false, label: 'Activity' }
]