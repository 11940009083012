export default { mutation: `
mutation LaunchEc2(
  $hostName: String, 
  $profile: AWSJSON, 
  $target: AWSJSON, 
  $serverImage: String,
  $sshKey: String,
  $enablePublicIP: Boolean,
  $customAMI: Boolean,
  $os: String
  ) {
  LaunchEc2(launchEc2Input: {
    hostName: $hostName
    serverImage: $serverImage
    profile: $profile
    target: $target
    enablePublicIP: $enablePublicIP
    sshKey: $sshKey
    customAMI: $customAMI
    os: $os
  }) {
    instanceId
    instanceType
    keyName
    launchTime
    privateDnsName
    privateIpAddress
    subnetId
    vpcId
    securityGroups
  }
}`
};
