export default { mutation: `
mutation CreateProduct(
    $productName: String
	  $target: String
    $profile: String
    $costCentre: String
    $image: String
    $blueprints: [String]
    $users: AWSJSON
    $groups: [String]
    ) 
    {
      CreateProduct(product: {
      productName: $productName
      target: $target
      costCentre: $costCentre
      image: $image
      blueprints: $blueprints
      profile: $profile
      users: $users
      groups: $groups
      }
    ) {
      productName
      key
      costCentre
      blueprints
      target {
        targetName
        provider
        credential
        instanceType
        region
        tags {
          name
          value
        }
        vpc
        securityGroups {
          name
          id
        } 
        key
        subnetId
      }
      profile {
        profileName
        key
        antiVirus
        key
        applicationMonitoring
        intrusionPrevention
        systemMonitoring
        malwareProtection
        mfa
        waf
        iamRoles
        securityGroups
        awsTags {
            name
            value
        }
        firewallRules
        automaticScanning
        automaticScanningSchedule
        automaticPatching
        automaticPatchingSchedule
        automaticBackups
        automaticBackupsSchedule
        enablePublicIP
        terminationProtection
        encryptBootdisk
        tags {
            name
            value
        }
        siipServer {
            ipAddress
          authDKey
        }
        sovereign {
          sovereignAU
          sovereignUSA
          sovereignCanada
          awsGovcloud
          auCloud
        }
      }
      users
      groups
    }
}`
};
