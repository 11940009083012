export default {
    query: `
        query GetUserProfiles {
            GetUserProfiles {
                firstName
                lastName
                company
                username
                email
                isAdmin
                phoneNumber
                key
                profilePic
                products
                groups
            }
        }
    `,
};
