import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {Table, TableBody, TableCell, TableHead, TablePagination, TableRow, TableSortLabel, Toolbar, Typography,
    Paper, InputBase, IconButton, Tooltip, CircularProgress, SwipeableDrawer, List, ListItem, ListItemText, Divider, Dialog,
    DialogActions, DialogContent, DialogTitle, Button, DialogContentText, Checkbox, ListItemSecondaryAction } from '@material-ui/core/'
import { Search as SearchIcon, Add as AddIcon, Refresh } from '@material-ui/icons/';
import style from './Style'
import GetBatchInventory from '../../graphql/queries/GetBatchInventory';
import GetProfiles from '../../graphql/queries/GetProfiles';
import EditInventory from '../../graphql/mutations/EditInventory';
import CreateAMI from '../../graphql/mutations/CreateAMI';
import EditInventoryTags from '../../graphql/mutations/EditInventoryTags';
import CreateAMIDialog from '../../components/CreateAMIDialog';
import AddTagsDialog from '../../components/AddTagsDialog';
import {Link} from 'react-router-dom';
import { API, graphqlOperation } from "aws-amplify";
import Alert from '../../components/Alert';
import InventoryActionButton from '../../components/InventoryActionButton';
import update from 'immutability-helper';
import AddProfileEc2 from '../../graphql/mutations/AddProfileEc2';
import GetAzureInventory from '../../graphql/queries/GetAzureInventory';
import InventoryTableRow from '../../components/InventoryTableRow';

var mixpanel = require('mixpanel-browser');

function desc(a, b, orderBy) {
    if(orderBy === 'cis' || orderBy === 'vuln') {
        a = JSON.parse(a.info)
        b = JSON.parse(b.info)
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }
    else {
        if (b[orderBy].toLowerCase() < a[orderBy].toLowerCase()) {
            return -1;
        }
        if (b[orderBy].toLowerCase() > a[orderBy].toLowerCase()) {
            return 1;
        }
        return 0;
    }
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

class Inventory extends React.Component {
    state = {
        query: "",
        page: 0,
        inventory: [],
        profiles: [],
        profilesChecked: [],
        rowsPerPage: 10,
        loadingInventory: false,
        selectedServer: null,
        orderBy: 'status',
        order: 'asc'
    };

    componentDidMount = () => {
        this.handleRefresh()
        API.graphql(graphqlOperation(GetProfiles.query))
        .then(async result => {
            this.setState({profiles: result.data.GetProfiles})
        })
        .catch(err => {
            this.setState({
                showAlert: true, 
                alertMessage: `Something went wrong fetching profiles list you may not be able to add a profile to an existing instasnce`,
                alertSuccess: false,
            })
        });
    }

    handleRefresh = async () => {
        this.setState({loadingInventory: true, inventory: []})
        //AWS
        await API.graphql(graphqlOperation(GetBatchInventory.query))
        .then(async result => {
            if(result.data.GetBatchInventory) {
                this.setState({inventory: result.data.GetBatchInventory, loadingInventory: false})
            }
            else {
                this.setState({
                    showAlert: true, 
                    alertMessage: `Something went wrong fetching your inventory`,
                    alertSuccess: false,
                    loadingInventory: false
                })
            }
        })
        .catch(err => {
            this.setState({
                showAlert: true, 
                alertMessage: `Something went wrong fetching your inventory`,
                alertSuccess: false,
            })
        });
        //Azure
        await API.graphql(graphqlOperation(GetAzureInventory.query))
        .then(async result => { 
            var azureInventory = result.data.GetAzureInventory
            const x = [...this.state.inventory, ...azureInventory]
            this.setState({inventory: x})
        })
        .catch(err => console.log(err))
    }

    handleAlertClose = () => { this.setState({showAlert: false, alertMessage: ''}) }

    toggleDrawer = (server) => { this.setState({ drawerOpen: !this.state.drawerOpen, selectedServer: server}) };

    handleRequestSort = (event, property) => {
        const orderBy = property;
        let order = 'desc';
        if (this.state.orderBy === property && this.state.order === 'desc') {
            order = 'asc';
        }
        this.setState({ order, orderBy });
    };

    handleChangePage = (event, page) => { this.setState({ page }) };

    handleChangeRowsPerPage = event => { this.setState({ rowsPerPage: event.target.value }) };

    createSortHandler = property => event => { this.handleRequestSort(event, property) };

    query(e) {
        this.setState({
            query: e.target.value,
        });
    }

    handleStatusUpdate = (status, resourceId) => {
        const index = this.state.inventory.findIndex(x => x.resourceId === resourceId);
        let newState = update(this.state, {
            inventory: {  
                [index]: {
                    status:  {  $set: status} 
                }
            }
        })
        this.setState(newState);
    }

    handleFunctions = (action) => {
        this.setState({loadingFunction: true})
        const info = JSON.parse(this.state.selectedServer.info)
        const params = {
            accountName: info.credential,
            instanceId: this.state.selectedServer.resourceId,
            location: this.state.selectedServer.location,
            function: action
        }
        API.graphql(graphqlOperation(EditInventory.mutation, params))
        .then((result) => {
            var data = result.data.EditInventory;
            switch(params.function) {
                case 'Start':
                    if(data.result) {
                        mixpanel.track("Instance Started");
                        this.handleStatusUpdate('running', params.instanceId);
                        this.setState({ showAlert: true, alertMessage: `Instance ${params.instanceId} is starting`, alertSuccess: true, loadingFunction: false})
                    }
                    else 
                        this.setState({ showAlert: true, alertMessage: `Unable to start ${params.instanceId}`, alertSuccess: false, loadingFunction: false})
                break
                case 'Stop':
                    if(data.result) {
                        this.handleStatusUpdate('stopped', params.instanceId);
                        mixpanel.track("Instance Stopped");
                        this.setState({ showAlert: true, alertMessage: `Instance ${params.instanceId} is stopping`, alertSuccess: true, loadingFunction: false})
                    }
                    else 
                        this.setState({ showAlert: true, alertMessage: `Unable to stop ${params.instanceId}`, alertSuccess: false, loadingFunction: false})
                break
                case 'Terminate':    
                    if(data.result) {
                        this.handleStatusUpdate('terminated', params.instanceId);
                        mixpanel.track("Instance Terminated");
                        this.setState({ showAlert: true, alertMessage: `Instance ${params.instanceId} is terminating`, alertSuccess: true, loadingFunction: false})
                    }
                    else 
                        this.setState({ showAlert: true, alertMessage: data.payload, alertSuccess: false, loadingFunction: false})
                break
                case 'Reboot':
                    if(data.result) {
                        this.handleStatusUpdate('running', params.instanceId);
                        mixpanel.track("Instance Reboot");
                        this.setState({ showAlert: true, alertMessage: `Instance ${params.instanceId} is rebooting`, alertSuccess: true, loadingFunction: false})
                    }
                    else 
                        this.setState({ showAlert: true, alertMessage: `Unable to reboot ${params.instanceId}`, alertSuccess: false, loadingFunction: false})
                break
                default:
                    this.setState({ showAlert: true, alertMessage: `Error performing inventory function`, alertSuccess: false, loadingFunction: false})
            }
        })
        .catch(err => {
            this.setState({ showAlert: true, alertMessage: err.errors[0].message, alertSuccess: false, loadingFunction: false})
        })
    }

    handleProfileDialog = () => { this.setState({profileDialogOpen: true})}

    handleProfileDialogClose = () => { this.setState({profileDialogOpen: false, profilesChecked: []})}

    handleCheckProfile = profile => () => {
        const newChecked = [...this.state.profilesChecked];
        this.state.profilesChecked.indexOf(profile) === -1 ? newChecked.push(profile) : newChecked.splice(this.state.profilesChecked.indexOf(profile), 1)
        this.setState({profilesChecked: newChecked});
    }

    handleAddProfile = async () => {
        this.setState({loadingFunction: true})
        const info = JSON.parse(this.state.selectedServer.info)

        //Make new obj taking only the bool values from checked profiles
        const result = this.state.profilesChecked.map(profile => Object.keys(profile)
        .reduce((o, key) => {
            profile[key] === true && (o[key] = profile[key]);
            return o;
        }, {})).reduce(((obj, key) => Object.assign(obj, key)), {});
        
        let params = {
            credential: info.credential,
            image: this.state.selectedServer.resourceId,
            region: this.state.selectedServer.location,
            profile: JSON.stringify(result)
        }
                
        await API.graphql(graphqlOperation(AddProfileEc2.mutation, params))
        .then((result) => {
            if(result.data.AddProfileEc2.result)
                this.setState({ showAlert: true, alertMessage: result.data.AddProfileEc2.payload, alertSuccess: true, loadingFunction: false, profileDialogOpen: false, profilesChecked: []})
            else
                this.setState({ showAlert: true, alertMessage: result.data.AddProfileEc2.payload, alertSuccess: false, loadingFunction: false})
        })
        .catch(err => {
            this.setState({ showAlert: true, alertMessage: `Error adding profile to ${params.instanceId}`, alertSuccess: false, loadingFunction: false})
        })
    }

    handleCreateAMIDialog = () => { this.setState({createAMIDialogOpen: true}) }

    handleCreateAMIDialogClose = () => { this.setState({createAMIDialogOpen: false}) }

    handleAddTagsDialog = () => { this.setState({addTagsDialogOpen: true}) }

    handleAddTagsDialogClose = () => { this.setState({addTagsDialogOpen: false}) }

    handleCreateAMI = async (amiName, amiDesc) => {
        this.setState({loadingFunction: true})
        const info = JSON.parse(this.state.selectedServer.info)
        let params = {
            credential: info.credential,
            image: this.state.selectedServer.resourceId,
            region: this.state.selectedServer.location,
            os: info.os,
            amiName: amiName,
            amiDesc: amiDesc
        }
        await API.graphql(graphqlOperation(CreateAMI.mutation, params))
        .then((result) => {
            this.setState({ showAlert: true, alertMessage: `Creating New AMI - ${result.data.CreateAMI.payload} this may take several minutes to complete `, alertSuccess: true, loadingFunction: false, createAMIDialogOpen: false})
        })
        .catch(err => {
            this.setState({ showAlert: true, alertMessage: err.errors[0].message, alertSuccess: false, loadingFunction: false})
        })
    }

    handleAddTags = async (tags) => {
        this.setState({loadingFunction: true})
        const info = JSON.parse(this.state.selectedServer.info)
        let params = {
            accountName: info.credential,
            instanceId: this.state.selectedServer.resourceId,
            location: this.state.selectedServer.location,
            tags: tags
        }
        await API.graphql(graphqlOperation(EditInventoryTags.mutation, params))
        .then((result) => {
            this.setState({ showAlert: true, alertMessage: `Tags added to Ec2 - ${params.instanceId}`, alertSuccess: true, loadingFunction: false, addTagsDialogOpen: false})
        })
        .catch(err => {
            this.setState({ showAlert: true, alertMessage: err.errors[0].message, alertSuccess: false, loadingFunction: false})
        })
    }

    render() {
        const { classes } = this.props;
        const { order, orderBy, inventory, drawerOpen, rowsPerPage, page, numSelected, showAlert, alertMessage, alertSuccess, loadingInventory,
            selectedServer, loadingFunction, profileDialogOpen, profiles, profilesChecked, createAMIDialogOpen, addTagsDialogOpen } = this.state;

        const emptyRows = rowsPerPage - Math.min(rowsPerPage, inventory.length - page * rowsPerPage);

        const rows = [
            { id: 'instanceType', numeric: false, disablePadding: true, label: '' },
            { id: 'serverName', numeric: false, disablePadding: true, label: 'Server' },
            { id: 'cis', numeric: true, disablePadding: false, label: 'CIS Score' },
            { id: 'vuln', numeric: true, disablePadding: false, label: 'Vulnerability Score' },
            { id: 'location', numeric: false, disablePadding: false, label: 'Location' },
            { id: 'status', numeric: false, disablePadding: false, label: 'Activity' }
        ];

        let filteredSearch;
        filteredSearch = this.state.inventory.filter(
          (item) => {return JSON.stringify(item).toLowerCase().indexOf(this.state.query.toLowerCase()) !== -1;}
        );

        const sideList = (selectedServer) => {
            if(selectedServer) {
                const info = JSON.parse(selectedServer.info)
                return (
                    <div
                        className={classes.list}
                        role="presentation" >
                    <List>
                        <ListItem key='instanceName' >
                            <ListItemText primary={`Name: ${selectedServer.serverName}`} />
                        </ListItem>
                        <Divider />
                        <ListItem key='instanceId' >
                            <ListItemText primary={`Resource Id: ${selectedServer.resourceId}`} />
                        </ListItem>
                        <ListItem key='reg'>
                            <ListItemText primary={`Registered on: ${selectedServer.registered}`} />
                        </ListItem>
                        <ListItem key='cloud'>
                            <ListItemText primary={`Cloud: ${selectedServer.cloud === "amazon" ?
                                "Amazon Web Services" : selectedServer.cloud === "azure" ? 
                                "Microsoft Azure" : "Other"}`} />
                        </ListItem>
                        <ListItem key='region'>
                            <ListItemText primary={`Region: ${selectedServer.location}`} />
                        </ListItem>
                        <ListItem key='instance'>
                            <ListItemText primary={`Instance: ${selectedServer.instanceType}`} />
                        </ListItem>
                        <ListItem key='createdBy'>
                            <ListItemText primary={`Created by: ${selectedServer.createdBy}`} />
                        </ListItem>
                        <ListItem key='target'>
                            <ListItemText primary={`Target: ${info.target}`} />
                        </ListItem>
                        <ListItem key='profile'>
                            <ListItemText primary={`Profile: ${info.profile}`} />
                        </ListItem>
                        <ListItem key='cisScore'>
                            <ListItemText primary={`CIS Score: ${info.cis}`} />
                        </ListItem>
                        <ListItem key='vulnScore'>
                            <ListItemText primary={`Vulnerability Assessment: ${info.vuln}`} />
                        </ListItem>   
                        <ListItem key='privateIp'>
                            <ListItemText primary={`Private IP: ${info.privateIp}`} />
                        </ListItem>      
                        <ListItem key='publicIp'>
                            <ListItemText primary={`Public IP: ${info.publicIp}`} />
                        </ListItem>  
                        <ListItem key='publicDns'>
                            <ListItemText primary={`Private DNS: ${info.publicDns}`} />
                        </ListItem>  
                        <ListItem key='vpc'>
                            <ListItemText primary={`VPC ID: ${info.vpc}`} />
                        </ListItem>  
                        <ListItem key='provider'>
                            <ListItemText primary={`Provider: ${info.credential}`} />
                        </ListItem>  
                    </List>
                    <Divider />
                        <InventoryActionButton instance={selectedServer} handleFunctions={this.handleFunctions} loadingFunction={loadingFunction}
                            handleAddProfile={this.handleProfileDialog} handleCreateAMIDialog={this.handleCreateAMIDialog} handleAddTagsDialog={this.handleAddTagsDialog}/>
                    </div>
                )
            }
        };

        return (
            <div>
                <Alert showAlert={showAlert} alertSuccess={alertSuccess} alertMessage={alertMessage} handleAlertClose={this.handleAlertClose}/>
                <Dialog open={profileDialogOpen} onClose={this.handleProfileDialogClose} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">Add Profile to Image</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Select the profiles you want to add to the image
                        </DialogContentText>
                        <List>
                            {
                                profiles.map((profile, index) => (
                                    <ListItem button key={profile.key}>
                                        <ListItemText primary={profile.profileName}/>
                                        <ListItemSecondaryAction>
                                            <Checkbox
                                                edge="end"
                                                onChange={this.handleCheckProfile(profile)}
                                                checked={profilesChecked.indexOf(profile) !== -1} />
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                ))
                            }   
                        </List>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleProfileDialogClose} color="primary" disabled={loadingFunction}>
                            Cancel
                        </Button>
                        <Button onClick={this.handleAddProfile} color="primary" disabled={loadingFunction}>
                            Add Profile
                        </Button>
                        { loadingFunction &&
                            <CircularProgress size={24} className={classes.inventoryProgress} />
                        }
                    </DialogActions>
                </Dialog>
                <CreateAMIDialog loadingFunction={loadingFunction} createAMIDialogOpen={createAMIDialogOpen} handleCreateAMIDialogClose={this.handleCreateAMIDialogClose} 
                    handleCreateAMI={this.handleCreateAMI} />
                <AddTagsDialog loadingFunction={loadingFunction} addTagsDialogOpen={addTagsDialogOpen} handleAddTagsDialogClose={this.handleAddTagsDialogClose} 
                    handleAddTags={this.handleAddTags} />
                <Paper className={classes.root}>
                    <Toolbar
                        className={classNames(classes.toolbar, {
                            [classes.highlight]: numSelected > 0,
                        })} >
                    <div className={classes.title}>
                        <Typography variant="h6" id="tableTitle">
                            Inventory
                        </Typography>
                    </div>
                    <div className={classes.spacer} />
                    <div className={classes.actions}>
                            <div className={classes.headSearch}>
                                <IconButton className={classes.iconButton} aria-label="Search">
                                    <SearchIcon />
                                </IconButton>
                                <InputBase 
                                    className={classes.input} 
                                    onChange={this.query.bind(this)}
                                    placeholder="Search..."
                                />
                                <Tooltip title="Create New Instance">
                                    <Link
                                        component={Link} to={{
                                            pathname: "/Deploy",
                                        }}>
                                        <IconButton aria-label="Create" color='primary'>
                                            <AddIcon />
                                        </IconButton>
                                    </Link>
                                </Tooltip>
                                <Tooltip title="Refresh Inventory">
                                    <IconButton aria-label="Create" color='primary' onClick={this.handleRefresh}>
                                        <Refresh />
                                    </IconButton>
                                </Tooltip>
                            </div>
                    </div>
                    </Toolbar>
                    <div className={classes.tableWrapper}>
                        <Table className={classes.table} aria-labelledby="tableTitle">
                            <TableHead>
                                <TableRow>
                                { rows.map((row, index) => 
                                    {
                                        return [
                                            <TableCell
                                                key={index}
                                                align={row.id === 'location' ? 'center': 'left'}
                                                padding='default'
                                                sortDirection={orderBy === row.id ? order : false} >
                                                <Tooltip
                                                    title="Sort"
                                                    placement='bottom-start'
                                                    enterDelay={300} >
                                                    <TableSortLabel
                                                        active={orderBy === row.id}
                                                        direction={order}
                                                        onClick={this.createSortHandler(row.id)} >
                                                        {row.label}
                                                    </TableSortLabel>
                                                </Tooltip>
                                            </TableCell>
                                        ]
                                    }  
                                )}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            { loadingInventory &&
                                <CircularProgress size={24} className={classes.inventoryProgress} />
                            }
                            { !loadingInventory &&
                                stableSort(filteredSearch, getSorting(order, orderBy))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map(n => {
                                    return [
                                        <InventoryTableRow 
                                            server={n}
                                            page='inventory'
                                            toggleDrawer={this.toggleDrawer}
                                        />
                                    ];
                                })
                            }
                            { emptyRows > 0 && (
                                <TableRow style={{ height: 49 * emptyRows }}>
                                <TableCell colSpan={6} />
                                </TableRow>
                            )}
                            </TableBody>
                    </Table>
                    </div>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={inventory.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        backIconButtonProps={{
                            'aria-label': 'Previous Page',
                        }}
                        nextIconButtonProps={{
                            'aria-label': 'Next Page',
                        }}
                        onChangePage={this.handleChangePage}
                        onChangeRowsPerPage={this.handleChangeRowsPerPage} />
                </Paper>
                <SwipeableDrawer
                    style={{width: 500}}
                    anchor="right"
                    open={drawerOpen}
                    onClose={() => this.toggleDrawer(null) }
                >
                    {sideList(selectedServer)}
                </SwipeableDrawer>
            </div>
        );
    }
}

Inventory.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(style)(Inventory);
