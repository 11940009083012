export default {
    query: `
        query GetNotifications {
            GetNotifications {
                index
                topic
                timestamp
                message
            }
        }
    `,
}