export default {
    mutation: `
        mutation CreateProfile($profile: ProfileInput
        ) {
            CreateProfile(
                profile: $profile
            ) {
                profileName
                antiVirus
                key
                applicationMonitoring
                intrusionPrevention
                systemMonitoring
                malwareProtection
                mfa
                waf
                iamRoles
                securityGroups
                awsTags {
                    name
                    value
                }
                firewallRules
                automaticScanning
                automaticScanningSchedule
                automaticPatching
                automaticPatchingSchedule
                automaticBackups
                automaticBackupsSchedule
                enablePublicIP
                terminationProtection
                encryptBootdisk
                tags {
                    name
                    value
                }
                siipServer {
                    ipAddress
	                authDKey
                }
                active
                complianceFrameworks {
                    cis
                    nist
                    disa_stig
                    pci_dss
                    hipaa
                    australian_ism
                }
                sovereign {
                    sovereignAU
                    sovereignUSA
                    sovereignCanada
                    awsGovcloud
                    auCloud
                }
            }
        }
    `,
};
