const style = theme => ({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    formControl: {
        width: '100%',
        marginTop: 20
    },
    notificationError: {
        backgroundColor: 'rgba(217, 30, 24, 0.7)',
    },
    search: {
        position: 'relative',
        backgroundColor: 'rgba(8, 147, 219, 0.02)',
        '&:hover': {
          backgroundColor: 'rgba(8, 147, 219, 0.05)',
        },
        width: '100%'
    },
    searchIcon: {
        height: '100%',
        position: 'absolute',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        marginLeft: 20,
        padding: 7,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: 120,
            '&:focus': {
            width: 200,
            },
        },
    }
});

export default style;