import { Auth } from "aws-amplify";

const aws_config = {
  Auth: {
    mandatorySignIn: false,
    region: "ap-southeast-2",
    userPoolId: "ap-southeast-2_tS77K0NCl",
    userPoolWebClientId: "7f65kma6qhu8eifs1mnqct9lt6",
    identityPoolId: 'ap-southeast-2:856c4c2a-4667-43fc-893f-e7e5e631588c',
  },
  'aws_appsync_graphqlEndpoint': 'https://tl2kwp27b5cthphwaefmihvgdi.appsync-api.ap-southeast-2.amazonaws.com/graphql',
  'aws_appsync_region': 'ap-southeast-2',
  'aws_appsync_authenticationType': 'AMAZON_COGNITO_USER_POOLS',
  'graphql_headers':  async () => { return { Authorization: (await Auth.currentSession()).idToken.jwtToken } },
  API: {
    endpoints: [
      {
          name: 'SubscribeToSecureStack',
          endpoint: 'https://4sjfaulgej.execute-api.ap-southeast-2.amazonaws.com/production/',
          region: 'ap-southeast-2'
      }
    ]
  },
  Storage: {
    AWSS3: {
      bucket: 'securestack-saas-bucket-prod',
      region: 'ap-southeast-2',
      identityPoolId: 'ap-southeast-2:856c4c2a-4667-43fc-893f-e7e5e631588c'
    }
  }
};

export default {
  ...aws_config
};
