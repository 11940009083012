import React, {Component} from 'react';
import {Dialog, DialogContent, DialogActions, DialogContentText, DialogTitle, Button, CircularProgress, Input,
    FormControl, Typography, FormHelperText } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const style = theme => ({ 
    inventoryProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12
    },
    formControl: {
        width: '100%',
        marginTop: 20
    },
    dialogActions: {
        marginTop: 20
    }
})

class CreateAMIDialog extends Component {
    state = {
        amiName: '',
        amiDesc: ''
    };

    handleAMIName = (e) => {
        this.setState({amiName: e.target.value})
        if(!e.target.value.match(/^[a-zA-Z0-9-_.@-]+$/)) {
            this.setState({amiNameErrorMsg: 'AMI name does not meet policy requirments', amiNameError: true});
        }   
        else {
            this.setState({amiNameError: false, amiNameErrorMsg: ''})
        } 
    }

    handleAMIDesc = (e) => {
        this.setState({amiDesc: e.target.value})
        if(!e.target.value.match(/^[a-zA-Z0-9-_.@-]+$/)) {
            this.setState({amiDescErrorMsg: 'AMI description does not meet policy requirments', amiDescErr: true});
        }   
        else {
            this.setState({amiDescErr: false, amiDescErrorMsg: ''})
        } 
    }

    handleCreateAMIDialogClose = () => {
        this.setState({amiDesc: '', amiName: ''})
        this.props.handleCreateAMIDialogClose()
    }

    render() {
        const { classes, loadingFunction, createAMIDialogOpen, handleCreateAMI  } = this.props;
        const { amiName, amiNameError, amiNameErrorMsg, amiDesc, amiDescError, amiDescErrorMsg  } = this.state;

        return (
            <Dialog open={createAMIDialogOpen} onClose={this.handleCreateAMIDialogClose} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">Create AMI from SecureStack Image</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Fill in the details below
                        </DialogContentText>
                        <FormControl className={classes.formControl} required error={amiNameError}>
                            <Typography color="textSecondary">AMI Name</Typography>
                            <Input id="input-aminame" value={amiName} onChange={this.handleAMIName} />
                            <FormHelperText error={amiNameError}>{amiNameErrorMsg}</FormHelperText>
                        </FormControl> 
                        <FormControl className={classes.formControl} error={amiDescError}>
                            <Typography color="textSecondary">AMI Description</Typography>
                            <Input id="input-amidesc" value={amiDesc} onChange={this.handleAMIDesc} />
                            <FormHelperText error={amiDescError}>{amiDescErrorMsg}</FormHelperText>
                        </FormControl> 
                    </DialogContent>
                    <DialogActions className={classes.dialogActions}>
                        <Button onClick={this.handleCreateAMIDialogClose} color="primary" disabled={loadingFunction}>
                            Cancel
                        </Button>
                        <Button onClick={() => handleCreateAMI(amiName, amiDesc)} color="primary" disabled={loadingFunction}>
                            Create AMI
                        </Button>
                        { loadingFunction &&
                            <CircularProgress size={24} className={classes.inventoryProgress} />
                        }
                    </DialogActions>
            </Dialog>
        )
    }
}

CreateAMIDialog.propTypes = {
    classes: PropTypes.object.isRequired,
};
  
  export default withStyles(style)(CreateAMIDialog);
