export default {
    query: `
        query GetAllProducts {
            GetAllProducts {
                productName
                key
                costCentre
                blueprints
                image
                target {
                    key
                    targetName
                    provider
                    credential
                    instanceType
                    region
                    tags {
                        name
                        value
                    }
                    vpc
                    securityGroups {
                        name
                        id
                    } 
                    subnetId
                }
                profile {
                    key
                    profileName
                    antiVirus
                    applicationMonitoring
                    intrusionPrevention
                    systemMonitoring
                    malwareProtection
                    mfa
                    waf
                    iamRoles
                    securityGroups
                    awsTags {
                        name
                        value
                    }
                    firewallRules
                    automaticScanning
                    automaticScanningSchedule
                    automaticPatching
                    automaticPatchingSchedule
                    automaticBackups
                    automaticBackupsSchedule
                    enablePublicIP
                    terminationProtection
                    encryptBootdisk
                    tags {
                        name
                        value
                    }
                    siipServer {
                        ipAddress
                        authDKey
                    }
                    complianceFrameworks {
                        cis
                        nist
                        disa_stig
                        pci_dss
                        hipaa
                        australian_ism
                    }
                    sovereign {
                        sovereignAU
                        sovereignUSA
                        sovereignCanada
                        awsGovcloud
                        auCloud
                    }
                }
                groups
                users
            }
        }
    `,
};