import React from 'react';
import { Button, DialogActions, Dialog, DialogTitle, DialogContent, DialogContentText, CircularProgress, Radio, RadioGroup, FormControlLabel } from '@material-ui/core/'

export default function DialogUpdateProfile(props) {
    const classes = props.classes
    return (
        <Dialog onClose={props.handleChangeProfileClose} aria-labelledby="form-dialog-title" open={props.changeProfileOpen}>
            <DialogTitle id="form-dialog-title">Change Profile</DialogTitle>
            <DialogContent>
            <DialogContentText>
                Select a Profile from the list below and select 'Change Profile' to update the Product
            </DialogContentText>
            <RadioGroup
                aria-label="Profile"
                name="profile"
                value={props.profileKey}
                onChange={props.handleProfileChange}>
                { props.profiles.map((profile, index) => (
                    <FormControlLabel value={profile.key} key={index} control={<Radio />} label={profile.profileName} />
                ))}
            </RadioGroup>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.handleChangeProfileClose} disabled={props.updatingProducts} color="primary">
                    Cancel
                </Button>
                <Button onClick={props.handleChangeProfile} disabled={props.updatingProducts} color="primary">
                    Change Profile
                </Button>
                {  props.updatingProducts && <CircularProgress size={24} className={classes.productsProgress} /> }
            </DialogActions>
        </Dialog>
    )
}