export default { mutation: `mutation EditGroup($key: String, $group: GroupInput ) 
    {
        EditGroup( key: $key, group: $group ) 
    {
        groupName
        key
        users
        active
    }
    }`
};