import { Button, CircularProgress } from '@material-ui/core/';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    wrapper: {
        position: 'relative',
    },
    button: {
        width: '100%',
        marginTop: 20,
        marginBottom: 20
    },
}));

export default function FormButton(props) {
    const classes = useStyles()
    const { loading, isEdit, text, formComplete  } = props;

    return (
        <div className={classes.wrapper}>
        <Button
            variant="outlined"
            color="primary"
            className={classes.button}
            disabled={loading || !formComplete}
            onClick={ props.callback } >
            { isEdit ? "Update" : "Create"} {text}
        </Button>
        { loading && <CircularProgress size={24} className={classes.buttonProgress} />}
      </div> 
    );
}