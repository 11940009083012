const style = theme => ({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    card: {
        margin: 10
    },
    chip: {
        marginLeft: 5
    },
    cloudChip: {
        padding: 6,
        margin: 10,
    },
    cloudIcon: {
        marginLeft: 'auto'
    },
});

export default style;