export default { mutation: `mutation DeleteAzureAccount (
    $name: String
){
    DeleteAzureAccount (
        name: $name
    ){
        result
        payload
    }
}`
};
