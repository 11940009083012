export default { mutation: `
mutation CreateTarget($targetInput: TargetInput) {
  CreateTarget(target: $targetInput) {
    targetName
  	provider
  	credential
  	instanceType
	region
	key
  	tags {
			name
			value
		}
	vpc
	securityGroups {
		name
		id
	} 
	resourceGroup
	networkInterface
	subnetId
	active
  }
}`
};
