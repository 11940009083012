import React from 'react';
import {Typography, Link} from '@material-ui/core/';

export default function Copyright () {
    return (
        <React.Fragment>
             <Typography variant="body2" color="textSecondary" align="center">
                {'Copyright © '}
                <Link color="inherit" href="https://www.securestack.com">
                  SecureStack 
                </Link>{' '}
                {new Date().getFullYear()}
                {' All rights reserved.'}
              </Typography>
        </React.Fragment>
    );
};

