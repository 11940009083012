import React, { useEffect } from 'react';
import { ButtonGroup, FormControl, Button, InputLabel, Chip, Grid, Input, Select, MenuItem, CircularProgress, Typography, Divider } from '@material-ui/core';
import { API, graphqlOperation  } from "aws-amplify";
import GetBlueprints from '../../graphql/queries/GetBlueprints';
import { makeStyles } from '@material-ui/core/styles';
import { ArrowUpward, ArrowDownward } from '@material-ui/icons';

const emptyBlueprint = {
    index: '',
    obj: {},
    fileContent: '',
    controls: [{}]
}

const alertError = {
    showAlert: true, 
    alertError: true,
    alertMessage: 'Oh No! something went wrong pulling the blueprints - Please try refreshing the page'
}

export default function SelectBlueprint(props) {
    const classes = style()
    const selectedBlueprints = props.selectedBlueprints
    const [ loadingBlueprints, setLoadingBlueprints ] = React.useState(true)
    const [ blueprints, setBlueprints ] = React.useState([])
    const [ blueprint, setBlueprint ] = React.useState(emptyBlueprint)

    useEffect(() => {
        API.graphql(graphqlOperation(GetBlueprints.query))
        .then(result => {
            if (result && result.data.GetBlueprints) {
                setBlueprints(result.data.GetBlueprints)
                setLoadingBlueprints(false)
            } else {
                setLoadingBlueprints(false)
                props.showAlert(alertError)
            }
        })
        .catch(err => {
            setLoadingBlueprints(false)
            props.showAlert(alertError)
        });
    }, []);

    const handleBlueprint = (e) => {
        var blueprint =  filterBlueprints[e.target.value]
        const controls = {...JSON.parse(blueprint.applicationServers), ...JSON.parse(blueprint.cyberRange)}
        var enc = new TextDecoder("utf-8");
        const content = enc.decode(new Uint8Array(JSON.parse(JSON.parse(blueprint.file)).data))
        setBlueprint({
            index: e.target.value,
            obj: blueprint,
            fileContent: content,
            controls: controls
        })
    }

    const handleAddBlueprint = async () => {
        props.setBlueprints([...selectedBlueprints, blueprint.obj])
        setBlueprint(emptyBlueprint)
    }

    const handleRemoveBlueprint = (key) => {
        props.setBlueprints(selectedBlueprints.filter(item => item.key !== key))
    }

    const handleMoveBlueprint = (e, index) => {
        const selectedBlueprint = selectedBlueprints[index];
        const blueprintsFiltered = selectedBlueprints.filter(( value ) => value.filename !== selectedBlueprint.filename);
        if(e === 'down') {
            blueprintsFiltered.splice((index + 1), 0, selectedBlueprint)
            props.setBlueprints(blueprintsFiltered)
        } 
        else if(e === 'up') {
            blueprintsFiltered.splice((index - 1), 0, selectedBlueprint)
            props.setBlueprints(blueprintsFiltered)
        }
      }

    let filterBlueprints = blueprints.filter(blueprint => {
        let selected = selectedBlueprints.some((b) => {return b.key === blueprint.key })
        return selected === false && JSON.parse(blueprint.operatingSystems)[props.os] === true
    })
    
    return (
        <div>
            <Grid container spacing={8}>
                <Grid item xs={12} sm={6}>
                    <FormControl className={classes.formControl}>
                        <InputLabel>Select Blueprint</InputLabel>
                        <Select
                            value={blueprint.index}
                            onChange={handleBlueprint} >
                            {
                                filterBlueprints
                                .map((item, index) => {
                                    return <MenuItem name={item.filename} value={index}>{item.filename}</MenuItem>
                                })
                            }
                        </Select>
                        {loadingBlueprints && <CircularProgress size={24} className={classes.buttonProgress} />}
                    </FormControl>
                </Grid>   
                <Grid item xs={12} sm={6}>
                    <FormControl className={classes.formControl}>
                        <InputLabel>File Content</InputLabel>
                        <Input id="input-file" value={blueprint.fileContent} multiline disabled={true} />
                    </FormControl> 
                </Grid>   
            </Grid>
            <Grid container spacing={8}>
                <Grid item xs={12} sm={6}>
                    <FormControl className={classes.formControl}>
                        <Typography color="textSecondary" className={classes.typo}>Controls</Typography>
                        <div classes={classes.taglist}>
                            {
                                Object.keys(blueprint.controls).filter(item => blueprint.controls[item] === true).map((value, index) => {
                                    return <Chip key={index} value={value} color='primary' variant="outlined" label={value} className={classes.chip} />
                                })
                            }
                        </div>
                    </FormControl> 
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl className={classes.formControl}>
                        <Button color='primary' onClick={handleAddBlueprint}>Add Blueprint</Button>
                    </FormControl>  
                </Grid>
            </Grid>      
            <br/>
            {
            selectedBlueprints.map((item, index) => { 
                const enc = new TextDecoder("utf-8") 
                const content = enc.decode(new Uint8Array(JSON.parse(JSON.parse(item.file)).data))
                const controls = {...JSON.parse(item.applicationServers), ...JSON.parse(item.cyberRange)}
                return [ <div>   
                <br />
                <Divider />      
                <Grid container spacing={8}>
                    <Grid item xs={12} sm={6}>
                        <FormControl className={classes.formControl}>
                            <Typography>{`${index + 1} - ${item.filename}`}</Typography>
                        </FormControl>
                    </Grid>   
                    <Grid item xs={12} sm={6}>
                        <FormControl className={classes.formControl}>
                            <InputLabel>File Content</InputLabel>
                            <Input disabled value={content} multiline />
                        </FormControl> 
                        <FormControl className={classes.formControl}>
                            <ButtonGroup size="large" color="primary" fullWidth={true}>
                                <Button color='red' onClick={() => handleRemoveBlueprint(item.key)}>Remove</Button>
                                <Button onClick={() => handleMoveBlueprint('up', index)} disabled={index === 0} >
                                    <ArrowUpward />
                                </Button>
                                <Button onClick={() => handleMoveBlueprint('down', index)} disabled={(index+1) === selectedBlueprints.length} >
                                    <ArrowDownward/>
                                </Button>
                            </ButtonGroup>
                        </FormControl> 
                    </Grid>   
                </Grid>
                <FormControl className={classes.formControl}>
                    <Typography color="textSecondary" className={classes.typo}>Controls</Typography>
                    <div classes={classes.taglist}>
                        {
                            Object.keys(controls).filter(item => controls[item] === true).map((value, index) => {
                                return <Chip key={index} value={value} color='primary' variant="outlined" label={value} className={classes.chip} />
                            })
                        }
                    </div>
                </FormControl>  
                </div> 
                ]
            })}                   
        </div>
    )
}

const style = makeStyles(theme => ({
    formControl: {
        width: '100%',
        marginTop: 20
    },
    chip: {
        marginLeft: 5
    },
    taglist: {
        marginTop: 10,
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
    }
}));