import React, {Component} from 'react';
import {Snackbar, IconButton, Typography, } from '@material-ui/core';
import {Close} from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const style = theme => ({ 
    notificationSuccess: {
        backgroundColor: 'rgba(0, 130, 200, 0.7)',
    },
    notificationError: {
        backgroundColor: 'rgba(217, 30, 24, 0.7)',
    },
    message: {
        color: '#FFFFFF'
    }
})

class Alert extends Component {
    render() {
        const { classes } = this.props;
        const {showAlert, alertSuccess, alertMessage } = this.props
        return (
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={showAlert}
                onClose={this.handleAlertClose}
                ContentProps={{
                    'aria-describedby': 'message-id',
                    classes: {
                        root: alertSuccess ? classes.notificationSuccess : classes.notificationError
                    }
                }}
                message={<span id="message-id" className={classes.message}>
                    <Typography>{alertMessage}</Typography></span>}
                action={[
                    <IconButton
                        key="close"
                        aria-label="Close"
                        color="inherit"
                        onClick={this.props.handleAlertClose}>
                        <Close />
                    </IconButton>,
                ]}
            />
        )
    }
}

Alert.propTypes = {
    classes: PropTypes.object.isRequired,
};
  
  export default withStyles(style)(Alert);
