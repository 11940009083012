const style = theme => ({
    title: {
        fontSize: 14,
    },
    progress: {
        margin: theme.spacing(2),
    },
    media: {
        height: 0,
        width: '100%',
        paddingTop: '56.25%', // 16:9
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
          duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
});

export default style;