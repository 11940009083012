export default { mutation: `
mutation CreateUserProfile(
    $firstName: String
	  $lastName: String
	  $username: String
    $company: String
    $isAdmin: Boolean
    $email: String
    $phoneNumber: String
    $sub: String) {
    CreateUserProfile(userProfile: {
    firstName: $firstName
    lastName: $lastName
    username: $username
    company: $company
    isAdmin: $isAdmin
    email: $email
    phoneNumber: $phoneNumber
  }, sub: $sub) {
    firstName
	  lastName
	  username
    company
    isAdmin
    email
    phoneNumber
    key
  }
}`
};
