export default { mutation: `
    mutation EncryptAzureAccount ( $azureAccount: AzureAccountInput ){
        EncryptAzureAccount (
            azureAccount: $azureAccount
        ){
            result
            payload
        }
    }`
};
