export default { query: `
  query GetVPCs($accountName: String, $region: String) {
    GetVPCs(accountName: $accountName, region: $region) {
      availabilityZone
      availableIpAddressCount
      cidrBlock
      defaultForAz
      mapPublicIpOnLaunch
      state
      subnetId
      vpcId
      assignIpv6AddressOnCreation
    }
  }`
};