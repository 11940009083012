import React from 'react';
import "tabler-react/dist/Tabler.css";
import { Button, Header, Card, PricingCard, Grid } from "tabler-react";

export default class StartUp extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div>
            <Card statusColor="blue">
                <Card.Body>
                    <Header.H1 className="display-1 text-muted mb-5">SecureCloud Start Up Bundle</Header.H1>
                    <Header.H4 className="text-muted font-weight-normal">
                        SecureCloud let's you orchestrate security controls across your multiple environments in AWS, GCP and Azure from one solution.  Manage the lifecycle of your inventory and optimize for security and cost automatically.
                    </Header.H4>
                    <Header.H4 className="text-muted font-weight-normal">
                        SecureCloud empowers your teams to build what they need while enforcing security using products you build in SecureCloud.
                    </Header.H4>
                    <div style={{display: 'flex',  justifyContent:'center', alignItems:'center'}}>  
                    <Grid>    
                        <Grid.Col sm={6} lg={3}>            
                        <PricingCard>
                            <PricingCard.Category>{"SecureCloud Start Up Bundle"}</PricingCard.Category>
                            <PricingCard.Price>{"$250"} </PricingCard.Price>
                            <PricingCard.AttributeList>
                                <PricingCard.AttributeItem>
                                    {"1 User & 10 Instances"}
                                </PricingCard.AttributeItem>
                                <PricingCard.AttributeItem hasIcon available>
                                    {"AWS Cloud Security"}
                                </PricingCard.AttributeItem>
                                <PricingCard.AttributeItem hasIcon available>
                                    {"Host Based Security"}
                                </PricingCard.AttributeItem>
                                <PricingCard.AttributeItem hasIcon available>
                                    {"SIEM"}
                                </PricingCard.AttributeItem>
                            </PricingCard.AttributeList>
                        </PricingCard>
                        </Grid.Col>
                    </Grid>
                    </div>
                </Card.Body>
                <Button color="primary" onClick={() => this.props.nextStep()} >Try Now</Button>
            </Card>
            </div>
        );
    }
}

