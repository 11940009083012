export default { mutation: `mutation DeleteTarget($key: String) {
  DeleteTarget(
    key: $key
      ) {
    targetName
      provider
      credential
      instanceType
      region
      key
      tags {
            name
            value
        }
      vpc
      securityGroups {
        name
        id
      } 
      resourceGroup
      networkInterface
      subnetId
      active
  }
}`
};