import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import { AppBar, Paper, Tabs, Tab } from '@material-ui/core/';
//import style from './Style'
import Alert from '../../components/Alert';
import TabPanel from '../../components/TabPanel'
import a11yProps from '../../functions/a11yProps';
import Domains from './Domains';
import Instances from './Instances';
import Websites from './Websites';

export default function Assets(props) {
 // const classes = style();
  const theme = useTheme();
  const [selectedAsset, setSelectedAsset] = React.useState(0);

  const [alert, updateAlert] = React.useState({
    alertMessage: '',
    alertSuccess: false,
    showAlert: false
  })

  const handleAlertClose = () => { updateAlert({ alertMessage: '', alertSuccess: false, showAlert: false }) }

  const handleChange = (event, newValue) => { setSelectedAsset(newValue); };

  return (
    <Paper >
      <Alert showAlert={alert.showAlert} alertSuccess={alert.alertSuccess} alertMessage={alert.alertMessage} handleAlertClose={handleAlertClose}/>
      <AppBar position="static" color="default">
        <Tabs
          value={selectedAsset}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth">
          <Tab label="Instances" {...a11yProps(0)} />
          <Tab label="Domains" {...a11yProps(1)}/>
          <Tab label="Websites" {...a11yProps(2)} />
        </Tabs>
      </AppBar>
        <TabPanel value={selectedAsset} index={0} dir={theme.direction}>
          <Instances />
        </TabPanel>
        <TabPanel value={selectedAsset} index={1} dir={theme.direction}>
          <Domains />
        </TabPanel>
        <TabPanel value={selectedAsset} index={2} dir={theme.direction}>
          <Websites />
        </TabPanel>
    </Paper>
  );
}
