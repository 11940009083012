export default  { mutation: `mutation ReadNotification(
    $key: String
    ) {
    ReadNotification(notification: {
    key: $key
  }) {
    index
    topic
    timestamp
    message
  }
}`
};