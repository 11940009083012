import { TableRow, TableCell, LinearProgress, IconButton } from '@material-ui/core/';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Edit as EditIcon, Delete as DeleteIcon } from '@material-ui/icons/';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
    cisGreenBack: {
        backgroundColor: '#cfeab3'
    },
    cisGreenBar: {
        backgroundColor: '#5ebb00'
    },
    cisRedBack: {
        backgroundColor: '#f0bcbc'
    },
    cisRedBar: {
        backgroundColor: '#cd2020'
    },
    cisYellowBack: {
        backgroundColor: '#ffdc59'
    },
    cisYellowBar: {
        backgroundColor: '#eab800'
    }
}));

export default function WebsiteTableRow(props) {
    const classes = useStyles()
    const { website } = props;
    return (
        <TableRow
            tabIndex={-1}
            key={website.key} >
            <TableCell align="left">{website.name}</TableCell>
            <TableCell align="left">{website.url}</TableCell>
            <TableCell align="left">
                {website.vuln}
                <LinearProgress variant="determinate" value={website.vuln} classes={{
                    colorPrimary: website.vuln > 74 ? classes.cisGreenBack: (website.vuln < 62 ? classes.cisRedBack : classes.cisYellowBack),
                    barColorPrimary: website.vuln > 74 ? classes.cisGreenBar: (website.vuln < 62 ? classes.cisRedBar : classes.cisYellowBar),
                }}  />
            </TableCell>
            <TableCell align="left">
                <IconButton className={classes.iconButton} color='primary'
                    component={Link} to={{ 
                        pathname: '/UpdateWebsite',
                        state: {
                            website: website,
                        }
                    }}>
                    <EditIcon />
                </IconButton>
                <IconButton 
                    className={classes.iconButton}
                    color='error'
                    aria-label="delete"        
                    onClick={() => props.handleDelete(website)}>
                    <DeleteIcon />
                </IconButton>
            </TableCell>
        </TableRow>
);
}