export default { mutation: `
mutation AddDomain($domain: DomainInput) {
  AddDomain(domain: $domain) {
	key
	name
	url
	vuln
  }
}`
};
