import React, { useEffect, useState } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { Backdrop, CircularProgress } from '@material-ui/core'
import TableWrapper from '../../components/TableWrapper';
import Alert from '../../components/Alert';
import TableSearch from '../../components/TableSearch';
import style from './Style'
import GetDomains from '../../graphql/queries/GetDomains';
import DomainTableRow from '../../components/DomainTableRow';
import DeleteDomain from '../../graphql/mutations/DeleteDomain';
import ConfirmDeleteDialog from '../../components/ConfirmDeleteDialog';

function Domains(props) {
  const classes = style();
  const [ domains, setDomains ] = useState([])
  const [ selectedDomain, setSelectedDomain ] =  useState({})
  const [ dialogOpen, setDialogVisable] = useState(false);
  const [ deleteLoading, setDeleteLoading] = useState(false);
  const [ loading, setLoading ] = useState(false)
  const [ alert, setAlert ] = useState({open: false, messages: '', success: true})
  const [ query, setQuery ] = useState('');

  useEffect(() => {
    setLoading(true)
    API.graphql(graphqlOperation(GetDomains.query))
    .then(result => { 
        console.log(result.data.GetDomains)
      setDomains(result.data.GetDomains)
      setLoading(false)
    })
  }, []);

  const handleSearch = (e) => { setQuery(e.target.value) }

  const handleDelete = (selected) => {
    setSelectedDomain(selected,
        setDialogVisable(true)
    )
  }

  const  deleteDomain =  () => {
    setDeleteLoading(true)
    API.graphql(graphqlOperation(DeleteDomain.mutation, { key: selectedDomain.key }))
    .then(result => { 
      setDeleteLoading(false)
      setDomains(result.data.DeleteDomain)
      setAlert({ message: `The domain has been removed`, success: true, open: true })
      setSelectedDomain({})
      setDialogVisable(false)  
    })
    .catch(err => {
      setDeleteLoading(false)
      setAlert({ message: `Error removing the domain`, success: false, open: true })
      console.log(err)
    })
  }

  let filteredSearch;
  filteredSearch = domains.filter(
    (item) => {return JSON.stringify(item).toLowerCase().indexOf(query.toLowerCase()) !== -1;}
  );

  return (
      <React.Fragment>
        <Backdrop className={classes.backdrop} open={loading}>
            <CircularProgress color="inherit" />
        </Backdrop>
        <ConfirmDeleteDialog name={selectedDomain.name} 
            type='Domain' 
            open={dialogOpen} 
            handleDialogClose={() => setDialogVisable(false)} 
            loading={deleteLoading}
            confirmDelete={deleteDomain} />
        <Alert alert={alert} handleClose={() =>setAlert({open: false, message: '', success: true})}/>
        <TableSearch 
            tooltip="Add New Domain"
            pathname="/NewDomain"
            handleSearch={handleSearch}
        />
        <TableWrapper 
            columns={columns}
            tableBody={
            filteredSearch.map(domain => {   
                return (
                    <DomainTableRow 
                        domain={domain}
                        handleDelete={handleDelete}
                    />
                )
            })
            }
        />
    </React.Fragment>
  );
}

export default Domains;

const columns = [ 
    { id: 'name', label: 'name' },
    { id: 'domain', label: 'Domain' },
    { id: 'vulnerability', label: 'Vulnerability Score' },
    { id: 'actions', label: 'Actions' },
]