export default { mutation: `mutation EditBillingInfo (
    $customerID: String
    $firstName: String
    $lastName: String
    $address: String
    $city: String
    $region: String
    $postal: String
    $country: String
    $email: String
    $phone: String
    $token: String
    ) {
      EditBillingInfo(
        customer: {
          customerID: $customerID
          firstName: $firstName
          lastName: $lastName
          address: $address
          city: $city
          region: $region
          postal: $postal
          country: $country
          email: $email
          phone: $phone
          token: $token
        }
      ) {
    result
    payload
  }
}`
};
