import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Search as SearchIcon, Add as AddIcon } from '@material-ui/icons/';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { Link } from 'react-router-dom';
import { IconButton, InputBase, Tooltip } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
    search: {
        width: '100%',
        backgroundColor: fade('rgba(228, 233, 237, 50)', 0.15),
        '&:hover': {
            backgroundColor: fade('rgba(228, 233, 237, 1)', 0.25),
        },  
        flexDirection: 'row'
    },
    input: {
        width: '90%'
    },
    iconButton: {
        marginLeft: 'auto'
    }
}));


export default function TableSearch(props) {
    const classes = useStyles();
    const { tooltip, pathname  } = props
    return (
        <div className={classes.search}>
            <IconButton aria-label="search">
                <SearchIcon />
            </IconButton>
            <InputBase 
                className={classes.input} 
                onChange={props.handleSearch}
                placeholder="Search..."
            />
            <Tooltip title={tooltip}>
                <IconButton className={classes.iconButton} color='primary'
                    component={Link} to={{ pathname: pathname }}>
                    <AddIcon />
                </IconButton>
            </Tooltip>
        </div>
    )
}


        