export default { query: `
  query GetCustomer($customerID: String) {
    GetCustomer(customerID: $customerID) {
      firstName
      lastName
      address
      city
      region
      postal
      country
      email
      phone
      cardNo
      cardExpY
      cardExpM
      token
    }
  }`
};
