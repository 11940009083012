import React, { Component } from 'react';
import "tabler-react/dist/Tabler.css";
import { Page, Tab, TabbedCard } from "tabler-react";

export default class Subscriptions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      props: props
    };
  }


  render() {
    return (
        <Page.Content title="Provider Subscriptions">
          <TabbedCard initialTab="AWS">
            <Tab title="AWS">AWS
            </Tab>
            <Tab title="Other">Other
            </Tab>
          </TabbedCard>
        </Page.Content>
    );
  }
}
