export default { mutation: `
    mutation AddProfileEc2(
        $profile: AWSJSON,
        $region: String,
        $credential: String,
        $image: String
    ) {
    AddProfileEc2(
        region: $region
        profile: $profile,
        credential: $credential,
        image: $image
    ) {
        result
        payload
    }
    }`
};