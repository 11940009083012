const style = theme => ({
    list: {
      width: '100%'
    },
    nestedListItem: {
        paddingLeft: 60
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      flexBasis: '33.33%',
      flexShrink: 0,
    },
    secondaryHeading: {
      fontSize: theme.typography.pxToRem(15),
      color: theme.palette.text.secondary,
    },
    buttonProgress: {
      color: '#0B96D8',
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
    taglist: {
      marginTop: 10,
      display: 'flex',
      justifyContent: 'center',
      flexWrap: 'wrap',
    },
    chip: {
      margin: 5
    },
    formControl: {
      width: '100%',
      marginTop: 20
    },
});

export default style;