import React, { Component } from "react";
import { Auth } from "aws-amplify";
import { Page, Card, Button, Alert, Grid, Loader } from "tabler-react";

export default class SignOut extends Component {
    state = { showGoodbye: false };

    handleLogout = async event => {
        await Auth.signOut().then(data => {
            this.props.userHasAuthenticated(false);
            this.props.history.push("/Login");
        });
    }

    render() {
        if (this.state.showGoodbye) {
            setTimeout(() => this.handleLogout(), 1000);
            return (
                    <Page.Content>
                        <Card>
                            <Card.Header>
                                <Card.Title>Thank you for using SecureStack</Card.Title>
                            </Card.Header>
                            <Card.Body>
                                <Alert type="info">
                                    <Grid.Row alignItems="center">
                                        <Grid.Col width={1}><Loader/></Grid.Col>
                                        <Grid.Col width={11}>Signing Off...</Grid.Col>
                                    </Grid.Row>
                                </Alert>
                            </Card.Body>
                        </Card>
                    </Page.Content>
            );
        }

        return (
                <Page.Content>
                    <Card>
                        <Card.Header>
                            <Card.Title>Are you sure?</Card.Title>
                        </Card.Header>
                        <Card.Body>
                            <Button link onClick={() => this.setState({showGoodbye: true})}>Continue to Sign Out</Button>
                        </Card.Body>
                    </Card>
                </Page.Content>
        );
    }
}
