export default { mutation: `
mutation EditUserAccount(
    $key: String
    $firstName: String
  	$lastName: String
  	$username: String
  	$company: String
    $email: String
    $phoneNumber: String
    $isAdmin: Boolean
    ) {
      EditUserAccount(key: $key, userProfile: {
    firstName: $firstName
    lastName: $lastName
    username: $username
    company: $company
    email: $email
    phoneNumber: $phoneNumber
    isAdmin: $isAdmin
  }) {
    result
    payload
  }
}`
};
