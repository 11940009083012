import React, {Component} from 'react';
import { API, graphqlOperation  } from "aws-amplify";
import DeleteProfile from '../../graphql/mutations/DeleteProfile';
import style from './Style'
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Card, Tooltip, CardHeader, IconButton, CardContent, Divider, Toolbar, List, ListItem, ListItemText, Collapse, ListItemIcon } from '@material-ui/core';
import {Edit, Delete, ExpandLess, ExpandMore, AssignmentInd} from '@material-ui/icons';

class ProfileCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cardLoading: false
        };
    } 

    handleClickFirewallRules = () => {
        this.setState({firewallRules: !this.state.firewallRules})
    }

    handleClickIAMRoles = () => {
        this.setState({iamRoles: !this.state.iamRoles})
    }

    render() {
        const { cardLoading, iamRoles } = this.state;
        const {profile, classes } = this.props;
        return (
            <div className={classes.card}>
                <Card key={profile.key}>
                    <CardHeader
                        title={profile.profileName}
                        action= {
                            <Toolbar>
                            <Tooltip title="Edit Profile" aria-label="Edit Profile">     
                                <IconButton aria-label="arrow_back" disabled={cardLoading} 
                                    onClick={() => {
                                        this.props.toggleForm(2, profile);
                                    }}>
                                    <Edit />
                                </IconButton> 
                            </Tooltip>
                            <Tooltip title="Delete Profile" aria-label="Delete Profile">     
                                <IconButton disabled={cardLoading} aria-label="arrow_back" 
                                    onClick={ async () => {
                                        if(profile.active) {
                                            this.props.showAlert('You are unable to delete this Profile as it is currently active in a Product', true)
                                        }
                                        else {
                                            this.setState({cardLoading: true});
                                            var profileInput = { "key": profile.key };
                                            await API.graphql(graphqlOperation(DeleteProfile.mutation, profileInput)).then(result => {
                                                this.props.toggleForm(999, profile);
                                            });
                                        }
                                    }}
                                >
                                <Delete color="error"/>
                                </IconButton> 
                            </Tooltip>
                            </Toolbar>
                        }
                    />
                    <Divider variant="middle" />
                    <CardContent>
                    { /*
                        <List
                        component="nav"
                        aria-labelledby="nested-list-subheader"
                        className={classes.list} >
                    
                        
                            <ListItem button onClick={this.handleClickFirewallRules}>
                                <ListItemIcon>
                                    <VpnLock />
                                </ListItemIcon>
                                <ListItemText primary="Firewall Rules" />
                                {firewallRules ? <ExpandLess /> : <ExpandMore />}
                            </ListItem>
                            <Collapse in={firewallRules} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                {
                                    profile.firewallRules.map((item, index) => {
                                        return <ListItem button className={classes.nested}>
                                            <ListItemText primary={JSON.parse(item).firewallName}/>
                                        </ListItem>

                                    })
                                }  
                                </List>
                            </Collapse>
                       
                    
                    </List>
                     */
                    }                    
                    <List
                        component="nav"
                        aria-labelledby="nested-list-subheader"
                        className={classes.list}
                    >
                    <ListItem button onClick={this.handleClickIAMRoles} key='iam'>
                        <ListItemIcon>
                            <AssignmentInd />
                        </ListItemIcon>
                        <ListItemText primary="IAM Roles" />
                        {iamRoles ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Collapse in={iamRoles} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                        {
                            profile.iamRoles.map((item, index) => {
                                return <ListItem button className={classes.nested} key={index}>
                                    <ListItemText primary={item}/>
                                </ListItem>

                            })
                        }  
                        </List>
                    </Collapse>
                    </List>
                    </CardContent>
                </Card>
            </div>)
    }
}

ProfileCard.propTypes = {
    classes: PropTypes.object.isRequired
};
  
export default withStyles(style)(ProfileCard);