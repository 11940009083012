import React from 'react';
import "tabler-react/dist/Tabler.css";
import { Card, Button, Form, Loader } from "tabler-react";
import { Auth  } from "aws-amplify";
import QRCode from 'qrcode.react';

var mixpanel = require('mixpanel-browser');
mixpanel.init("177283cdf44ca45aa1e52db11ea3b292");

class CompleteSignUp extends React.Component {
    constructor(props) {
        super(props);
        this.handleConfirmation = this.handleConfirmation.bind(this);
        this.handleMFA = this.handleMFA.bind(this);
        this.state = {
            username: props.companyId+'.'+props.username,
            qr: '',
            showAlert: true,
            alertColor: 'info',
            alertMessage: 'Password Policy: minimum 12 characters, at least 1 number, at least 1 special character and at least 1 uppercase character  ',
            showLoading: false,
            showQR: false,
        };
    }


    setPassword() {
        if (this.state.password === this.state.confirmPassword) {
            Auth.signIn(this.state.username, this.props.password)
            .then(user => {
                if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
                    Auth.completeNewPassword(
                        user,        
                        this.state.password,
                    ).then(user => {
                        this.setState({
                            user: user,
                            showQR: true,
                        });
                        Auth.setupTOTP(user).then((code) => {
                            this.setState({
                                qr: "otpauth://totp/AWSCognito:"+ this.state.user.username + "?secret=" + code + "&issuer=securestack",
                            });
                        });
                    }).catch(e => {
                        this.props.showAlert('Password Error', e.message,'danger')
                    });
                }
            }).catch(e => {
                this.props.showAlert('Password Error', e.message,'danger')
            });
        } else {
            this.props.showAlert('Password Mismatch','passwords do not match','danger')
        }
    }

    handleMFA() {
        Auth.verifyTotpToken(this.state.user, this.state.mfaToken)
        .then(() => {
            Auth.setPreferredMFA(this.state.user, 'TOTP');
            this.props.nextStep()
        }).catch(e => this.props.showAlert('Password Error', e.message,'danger'))
    }

    handleConfirmation(event) {
        switch (event.target.name) {
            case 'password-text-input':
                this.setState({password: event.target.value});
                if (event.target.value.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{12,})/)) {
                    this.setState({passwordComplete: true, passwordError: false});
                } 
                else {
                    this.setState({passwordComplete: false, passwordError: true});
                }
                break;
            case 'confirmPassword-text-input': 
                this.setState({confirmPassword: event.target.value});
                if (event.target.value.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{12,})/)) {
                    this.setState({confirmPasswordComplete: true, confirmPasswordError: false});
                }
                else {
                    this.setState({confirmPasswordComplete: false, confirmPasswordError: true});
                }
                break;
            case 'mfaToken-text-input': 
                this.setState({mfaToken: event.target.value});
                if (event.target.value.match(/^[0-9]{6,}$/)) {
                    this.setState({mfaTokenComplete: true, mfaTokenError: false});
                    mixpanel.track("SignUp Complete - MFA success");
                    mixpanel.identify(this.state.username);
                }
                else {
                    this.setState({mfaTokenComplete: false, mfaTokenError: true});
                }
                break;
            default:
                break
        }
    }

    render() {
        const {showQR, showLoading} = this.state
        const {passwordComplete, passwordError, password, confirmPassword, confirmPasswordError, confirmPasswordComplete, mfaToken, mfaTokenComplete, mfaTokenError} = this.state
        return(
            <div>
                { !showQR &&
                    <Card>
                        <Card.Header>
                            <Card.Title>
                                Enter a Password
                            </Card.Title>
                        </Card.Header>
                        <Card.Body>
                            <Form.Group isRequired
                                className="mb-0"
                                label="Password">
                                <Form.Input 
                                    type='password'
                                    name="password-text-input"
                                    tick={passwordComplete}
                                    cross={passwordError}
                                    value={password || ''} 
                                    onChange={this.handleConfirmation} />
                            </Form.Group>
                            <Form.Group isRequired
                                className="mb-0"
                                label="Confirm Password">
                                <Form.Input 
                                    type='password'
                                    name="confirmPassword-text-input"
                                    tick={confirmPasswordComplete}
                                    cross={confirmPasswordError}
                                    value={confirmPassword || ''} 
                                    onChange={this.handleConfirmation} />
                            </Form.Group>
                            <Button color="primary" onClick={() => this.setPassword()}>Confirm</Button>
                        </Card.Body>
                    </Card>
                }
                {showQR &&
                    <Card>
                        <Card.Header>
                            <Card.Title>
                                Setup Multi Factor Authentication
                            </Card.Title>
                        </Card.Header>
                        <Card.Body>
                            <QRCode value={this.state.qr}/>
                            <Form.Input
                                name="mfaToken-text-input"
                                placeholder="MFA Token"
                                tick={mfaTokenComplete}
                                cross={mfaTokenError}
                                value={mfaToken || ''} 
                                onChange={this.handleConfirmation} />
                            <Button color="primary" onClick={this.handleMFA}>Set MFA</Button>
                        </Card.Body>
                    </Card>
                }
                { showLoading &&
                    <Loader/>
                }
                </div>
        );
    }
}

export default CompleteSignUp;
